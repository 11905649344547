import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Cacheable } from 'ngx-cacheable';
import { decode } from 'string-encode-decode';
import { Subject, Subscription } from "rxjs";
import { apiError, authorizeHeader } from 'src/utils/common-function';
import { Shared } from 'src/utils/common';
const cacheBuster$ = new Subject<void>();

const token = decode(localStorage.getItem("userToken"));
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization': `Bearer ${token}` })
};

@Injectable({
  providedIn: 'root'
})
export class FundsDataService {
 checkRequestStart:Subscription;
 
  constructor(public http: HttpClient, public shared: Shared) { }

  @Cacheable({
    cacheBusterObserver:cacheBuster$
  })
  sendFund(model) {
    return this.http
      .post(environment.baseUrl + "sendFund", model, httpOptions)
  }

  @Cacheable({
    cacheBusterObserver:cacheBuster$
  })
  savefunds(userID, model) {
    return this.http
      .post(
        environment.baseUrl + "saveFunds/" + userID,
        model,
        {headers : authorizeHeader()}
      )
  }

  allFundsData(userID) {
    return this.http
      .post<any>(environment.baseUrl + "myAllFunds/" + userID, {headers:authorizeHeader()})
  }

  getMyAllFunds(userID) {
    if(this.checkRequestStart){
      this.checkRequestStart.unsubscribe();
      this.shared.myFundLoading = false;
    }
    this.shared.myFundLoading = true;
    this.shared.myFundSpinner = true;
    this.shared.myFundDataAvailable = false;
    this.checkRequestStart = this.http
      .post<any>(environment.baseUrl + "myAllFunds/" + userID, {headers:authorizeHeader()}).subscribe((res)=>{
        this.shared.myFundLoading = false;
        this.shared.myFundSpinner = false;
        this.shared.myFundDataAvailable = false;
        if (res["data"]) {
          this.shared.checkMyFunds = true;
          this.shared.myFundChartData = res;
          this.shared.myFundDataAvailable = false;
          this.shared.myFundsDataObservable.next(res);
        } else {
          this.shared.myFundDataAvailable = true;
        }
      
      }, (err)=>{
        this.shared.myFundLoading = false;
        this.shared.myFundSpinner = false;
        apiError(err);
      })
  }

  getFunds(){
    this.shared.getAllfund.next();
  }

  // @Cacheable({
  //   cacheBusterObserver:cacheBuster$
  // })
  deleteFunds(userID, secid, settingId) {
    return this.http
      .post(
        environment.baseUrl + "deleteFunds/" + userID + "/" + secid + "/" + settingId,
        {headers:authorizeHeader()}
      )
  }

  searchFund(event){
  return  this.http.get<any>(environment.baseUrl + "search/?name=" + event)
  }

  @Cacheable({
    cacheBusterObserver:cacheBuster$
  })
  marketInsightdata(cat) {
    return this.http
      .get<any>(environment.baseUrl + "market-insights/" + cat)
  }


  myFundsData(category, userId) {
    return this.http
      .get<any>(
        environment.baseUrl + "myFunds/" + category + "/" + userId
      )
  }

  @Cacheable({
    cacheBusterObserver:cacheBuster$
  })
  searchfund(event){
    return  this.http
    .get<any>(environment.baseUrl + "search/?name=" + event)
  }

  @Cacheable({
    cacheBusterObserver:cacheBuster$
  })
  contactData(model){
    return this.http
      .post(environment.baseUrl + "contact", model, httpOptions)
  }
}
