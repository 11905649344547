
import { Component, Input, OnInit, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/services/CommonServices/common.service';
import { DatePipe } from "@angular/common";
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PlatformLocation } from "@angular/common";
import { decode } from "string-encode-decode";
import { DaterangepickerComponent } from 'date-picker/src/daterangepicker';
import { FundsDataService } from 'src/services/ChartServices/funds-data.service';
import { RatingViewService } from 'src/services/rating/rating-view.service';
import { Preference } from '../../utils/constants';
import { GrowthViewService } from 'src/services/growth-view/growth-view.service';
import { PrefrenceSettingsService } from 'src/services/PreferencesServices/prefrence-settings.service';
import { MarketInsightService } from 'src/services/market-insight/market-insight.service';
import { AlertsServiceService } from 'src/services/alerts-service/alerts-service.service';
import { FundDocumentaionService } from 'src/services/fund-documentation/fund-documentaion.service';
import { apiError, currentDate } from 'src/utils/common-function';
import { GoogleAnalyticsService } from 'src/services/google-Analytics/google-analytics.service';
import { Subscription } from 'rxjs';
import { Commons, Shared } from 'src/utils/common';


const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Component({
  selector: 'app-chart-filter',
  templateUrl: './chart-filter.component.html',
  styleUrls: ['./chart-filter.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class ChartFilterComponent implements OnInit {
  @Output("onSelectColumn") onSelectColumn: EventEmitter<any> = new EventEmitter();
  @Input() id: number;
  @ViewChild('DaterangepickerComponent') datePicker: DaterangepickerComponent;
  @ViewChild('DaterangepickerComponent') dateRangePicker: DaterangepickerComponent;
  @Input() showSelectColoum = false;
  checkRequestedDate:Subscription;
  selectedItems = "Austria_Mid_Blend";
  userID: any;
  selectedCatYear = "2021";
  totalyear = "5";
  showAnimation: boolean = false;
  selected: any;
  public showModal = false;
  
  constructor(public http: HttpClient,
    private eRef: ElementRef,
    public common: CommonService,
    public fundService: FundsDataService,
    public router: Router,
    public marketInsightService: MarketInsightService,
    public route: ActivatedRoute,
    public growthChartService: GrowthViewService,
    public rating: RatingViewService,
    public preferenceService: PrefrenceSettingsService,
    private platformLocation: PlatformLocation,
    private datePipe: DatePipe,
    public alertService: AlertsServiceService,
    public fundDocumentService: FundDocumentaionService,
    public googleAnalyticsEventsService: GoogleAnalyticsService,
    public shared: Shared) {
    this.shared.displayFundSize = "On";
    this.shared.showFundSize = true;
    this.shared.selectedIncludeESGChartFilter = Preference.esgOptionsIncludeChart.sort((a, b) => (a.name < b.name ? -1 : 1));
    this.shared.selectedExcludeESGChartFilter = Preference.esgOptionExcludeChart.sort((a, b) => (a.name < b.name ? -1 : 1));
    this.getSelectedDate();
    this.getSelectedFundSize();
    this.userID = decode(localStorage.getItem("ID"));
    const isNew = JSON.parse(localStorage.getItem('IsNew'));
    if (isNew == undefined) {
      this.shared.isNewCategoryChartFilter = true;
    } else {
      this.shared.isNewCategoryChartFilter = isNew;
    }
  }

  ngOnInit() {
    this.today = new Date();
    this.shared.displayFundSize = "On";
    if(Commons.previousDateChange){
      this.changedDate();
    }
    Commons.changeSettingObs.subscribe(() => {
      this.getEsgFromSetting();
    })

    Commons.ESGDataIncludeExclude.forEach((ele) => {
        if(ele.checked = true){
          if (!this.shared.esgDataChartFilter.some((item) => item.Name == ele.Name)) {
            this.shared.esgDataChartFilter.push(ele)
          }
        }
    });
    this.shared.marketUniverseLoading = true;
    this.rating.chartFilterSelectColumn.forEach((ele) => {
      ele.data.forEach(element => {
        if (element.select) {
          this.shared.selectedDataChartFilter.push(element);
          this.selectData.push(element);
          this.shared.selectedSelectColum.push(element.id);
        }
      });
    });
    this.shared.selectedCategory.subscribe(res => {
      this.getSelectedCategory();
    });
    this.filterOptions();
    this.shared.weightedValue[1].show = true;
    this.shared.selectedBestPassiveChartFilter = [];
    this.getSelectedBestPassive();
    this.shared.changeDataChartFilter.subscribe(() => {
      this.getSelectedDate();
    })
    this.getMarketUniverseData();
    this.shared.topFundDataObservable.subscribe(res => {
      this.getMarketUniverseData();
    })
    Commons.bestPassiveObeservable.subscribe(() => {
      this.shared.bestPassive.forEach(element => {
        if (element.id == "1 YEAR-ILIM Indexed Name") {
          Commons.bestPassiveTopFunds.forEach(ele => {
            if (ele.Type == "01") {
              element.value = '1 Yr Passive - ' + ele.Name;
            }
          });
        }
        else if (element.id == "3 YEAR-VANGAURD Name") {
          Commons.bestPassiveTopFunds.forEach(ele => {
            if (ele.Type == "03") {
              element.value = '3 Yr Passive - ' + ele.Name;
            }
          });
        }
        else if (element.id == "5 YEAR-ILIM Indexed Name") {
          Commons.bestPassiveTopFunds.forEach(ele => {
            if (ele.Type == "05") {
              element.value = '5 Yr Passive - ' + ele.Name;
            }
          });
        }
      });
    })
  }

  getEsgFromSetting() {
    this.shared.selectedIncludeESGChartFilter.forEach((ele) => {
      ele.checked = false;
      if (Commons.preferenceSelectedDataESG.includes(ele.value) || this.shared.esgSelectedData.includes(ele.value)) {
        ele.checked = true;
        if (!this.shared.esgDataChartFilter.some((item) => item.Name == ele.value)) {
          this.shared.esgDataChartFilter.push({ "Id": '', "Name": ele.value, "Type": "Included", value: ele.name });
        }
      }
    });
    this.shared.selectedExcludeESGChartFilter.forEach((ele) => {
      ele.checked = false;
      if (Commons.preferenceSelectedDataESG.includes(ele.value) || this.shared.esgSelectedData.includes(ele.value)) {
        ele.checked = true;
        if (!this.shared.esgDataChartFilter.some((item) => item.Name == ele.value)) {
          this.shared.esgDataChartFilter.push({ "Id": '', "Name": ele.value, "Type": "Excluded", value: ele.name });
        }
      }
    });
  }

  getMarketUniverseData() {
    this.shared.marketboxDataChartFilter = [];
    this.shared.marketUniverseDataChartFilter.forEach(element => {
      if(this.selectedItems == element.name && this.shared.isBestPassive){
        element.isDataAvailable = false;
        this.shared.marketboxDataChartFilter.push({name:element.name, isDataAvailable:element.isDataAvailable, isBestPassiveAvailable:element.isBestPassiveAvailable, checked:false, isNew:true });
      }else{
        this.shared.marketboxDataChartFilter.push({name:element.name, isDataAvailable:element.isDataAvailable, isBestPassiveAvailable:element.isBestPassiveAvailable, checked:false, isNew:true });
      }
    });
    if (this.shared.marketboxDataChartFilter.length > 0) {
      // this.loadingMarketUniverse = false;
      this.shared.marketUniverseLoading = false;
    }
    const cate = localStorage.getItem('chartCategory');
    this.shared.marketboxDataChartFilter.forEach((ele) => {
      if (ele.name == cate) {
        ele.checked = true;
      } else {
        ele.checked = false;
      }
    });
    this.shared.changeCategory.subscribe(() => {
      // this.loadingMarketUniverse = false;
      // this.preferenceService.marketUniverData.sort((a, b) => (a.name < b.name ? -1 : 1));
      // this.preferenceService.marketUniverseDataChartFilter.sort((a, b) => (a.name < b.name ? -1 : 1));
      this.shared.marketUniverseLoading = false;
      this.shared.marketboxDataChartFilter = this.shared.marketUniverseDataChartFilter;
    })
  }


  expandIcon(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "expand_select_column",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.selectedColumns = [];
    event.isExpand = !event.isExpand;
  }
  month: any;
  year: any;
  today: any;
  validateValue(item, currency) {
    let value: any;
    return value;
  }

  selectedHeaders(event, i) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "select_column_header_" + event.head,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    event.checked = !event.checked;
    //debugger
    if (event.checked) {
      this.rating.chartFilterSelectColumn.forEach(element => {
        if (event.head == element.head) {
          element.data.forEach((ele) => {
            ele.select = true;
            ele.show = true;
            if (!this.shared.selectedDataChartFilter.some(e => e.value == ele.value)) {
              this.shared.selectedDataChartFilter.push(ele);
            }
          })
        }
      });
      this.rating.tableHeaders.forEach((e) => {
        if (e.title == event.head) {
          e.show = true;
        }
      });
      if (event.head == "Total Weighted Value (% CAGR off best passive) 5 Year") {
        this.rating.chartFilterSelectColumn.forEach(element => {
          if (event.head == element.head) {
            event.check = true;
          }
        })
      }
    } else if (!event.checked) {

      this.rating.chartFilterSelectColumn.forEach((element) => {
        if (event.head == element.head) {
          element.data.forEach((ele) => {
            ele.select = false;
            ele.show = false;
            if (this.shared.selectedDataChartFilter.some(e => e.value == ele.value)) {
              const indexPr = this.shared.selectedDataChartFilter.findIndex(item => item.value === ele.value);
              this.shared.selectedDataChartFilter.splice(indexPr, 1);
            }
            if (this.shared.selectedColumns.some(e => e.value == ele.value)) {
              const indexPr = this.shared.selectedColumns.findIndex(item => item.value === ele.value);
              this.shared.selectedColumns.splice(indexPr, 1);
            }
          })
        }
      })
      if (event.head == "Total Weighted Value (% CAGR off best passive) 5 Year") {
        this.rating.chartFilterSelectColumn.forEach(element => {
          if (event.head == element.head) {
            event.check = false;
          }
        })
      }
    }
  }

  selectHead(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "select_column_" + event.head,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    event.check = !event.check;
    if (event.check) {
      this.selectedColumns = [];
      event.isExpand = true;
      if (event.isExpand) {
        const today = new Date();
        const shortMonth = today.toLocaleString('en-us', { month: 'short' });
        const currentYear = today.getFullYear();
        const twoDigitYear = currentYear.toString().substr(-2);
        this.month = shortMonth;
        this.year = twoDigitYear;
      }
      if (event.head == "Total Weighted Value (% CAGR off best passive) 5 Year") {
        this.rating.chartFilterSelectColumn.forEach(element => {
          if (event.head == element.head) {
            event.checked = true;
            element.data.forEach((ele) => {
              ele.select = true;
              ele.show = true;
              if (!this.shared.selectedDataChartFilter.some(e => e.value == ele.value)) {
                this.shared.selectedDataChartFilter.push(ele);
              }
            })
          }
        })
      }
    } else if (!event.check) {
      event.isExpand = false;
      if (event.head == "Total Weighted Value (% CAGR off best passive) 5 Year") {
        this.rating.chartFilterSelectColumn.forEach((element) => {
          if (event.head == element.head) {
            event.checked = false;
            element.data.forEach((ele) => {
              ele.select = false;
              ele.show = false;
              if (this.shared.selectedDataChartFilter.some(e => e.value == ele.value)) {
                const indexPr = this.shared.selectedDataChartFilter.findIndex(item => item.value === ele.value);
                this.shared.selectedDataChartFilter.splice(indexPr, 1);
              }
              if (this.shared.selectedColumns.some(e => e.value == ele.value)) {
                const indexPr = this.shared.selectedColumns.findIndex(item => item.value === ele.value);
                this.shared.selectedColumns.splice(indexPr, 1);
              }
            })
          }
        })
      }
    }
  }

  getSelectedFundSize() {
    const display = localStorage.getItem('fundSizesDisplay');
    if (display == "On") {
      this.shared.displayFundSize = "On";
    } else {
      this.shared.displayFundSize = "Off";
    }
    const data = JSON.parse(localStorage.getItem("fundSizes"));
    if (data) {
      this.shared.fundSizeChartFilter.forEach((element) => {
        if (element.id) {
          element.show = false;
        }
      })
      this.shared.fundSizeChartFilter.forEach((ele) => {
        data.forEach((res) => {
          if (ele.id == res.id) {
            ele.show = true;
            this.shared.selectedFundSize.push(ele);
          }
        })
      })
    } else {
      this.shared.fundSizeChartFilter.forEach((element) => {
        if (element.id) {
          element.show = true;
        }
      })
    }

  }

  fundSizeDisplay(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "display_fund_size",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if (event) {
      this.shared.displayFundSize = "On";
    }
    if (!event) {
      this.shared.displayFundSize = "Off";

    }
  }

  getSelectedBestPassive() {
    let bestPassiveSelected: any[] = [];
    const selected: any[] = [];
    if (localStorage.getItem('bestPassive')) {
      bestPassiveSelected = JSON.parse(localStorage.getItem('bestPassive'));
      bestPassiveSelected.forEach((ele) => {
        if(ele.show){
          selected.push(ele.id);
        }
      })
      this.shared.bestPassive.forEach((ele) => {
        ele.show = false;
        const index = selected.includes(ele.id);
        if (index) {
          ele.show = true;
          this.shared.selectedBestPassiveChartFilter.push({ id: ele.id });
        }
      })
    }
    this.shared.selectedBestPassive = this.shared.selectedBestPassiveChartFilter;
  }

  removeUnderscore(item) {
    if (item) {
      const value = item.replace(/_/g, " ");
      return value;
    }
  }

  changedDate(){
    const years = localStorage.getItem('date');
      if(Commons.count == 0 ){
        Commons.count++;
        this.getMarketUniverseWithDate(years);
      }
  }

  getSelectedDate() {
    const years = localStorage.getItem('date');
    if (years != null && years != undefined && years != "undefined") {
      this.selected = {
        startDate: moment(years),
        endDate: moment(years)
      }
      if(this.datePicker){
        if(Commons.count == 0 && this.datePicker.chosenLabel != undefined && this.datePicker.chosenLabel != years){
          Commons.count++;
          this.getMarketUniverseWithDate(years);
        }
        this.datePicker.setStartDate(moment(years));
        this.datePicker.setEndDate(moment(years));
        this.datePicker.updateView();
      }
      this.shared.selectedDates = years;
    } else {
      const date = new Date();
      const latest_date = currentDate();
      this.selected = {
        startDate: moment(latest_date),
        endDate: moment(latest_date)
      }
      if(this.datePicker){
        if(Commons.count == 0 && this.datePicker.chosenLabel != undefined &&  this.datePicker.chosenLabel != years){
          Commons.count++;
          this.getMarketUniverseWithDate(years);
        }
        this.datePicker.setStartDate(moment(years));
        this.datePicker.setEndDate(moment(years));
        this.datePicker.updateView();
      }
      this.shared.selectedDates = latest_date;
    }
    Commons.selectedDate = this.selected.startDate;
  }


  getHiddenGem(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "hidden_gems_range",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    event.show = !event.show;
    event.preventDefault();
  }

  getWeightedValue(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "weighted_value",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    event.show = !event.show;
    event.preventDefault();
  }

  getBestPassive(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "chart_options_" + event.id,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    Commons.bestPassiveApplied = true;
    if (event.id) {
      event.show = !event.show;
      if (event.show) {
        if (!this.shared.selectedBestPassiveChartFilter.some((item) => item.id == event.id)) {
          this.shared.selectedBestPassiveChartFilter.push({ id: event.id });
        }
      }
      if (!event.show) {
        const index = this.shared.selectedBestPassiveChartFilter.findIndex(item => item.id === event.id);
        this.shared.selectedBestPassiveChartFilter.splice(index, 1);

      }
    }
  }

  getFundSize(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "chart_options_fund_size_" + event.id,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if (event.id) {
      event.show = !event.show;
      if (event.show) {
        this.shared.selectedFundSize.push(event);
      } else {
        const index = this.shared.selectedFundSize.findIndex(item => item.id === event.id);
        this.shared.selectedFundSize.splice(index, 1);
      }
    }
    event.preventDefault();
  }

  onClick(event) {
    if (!this.eRef.nativeElement.contains(event.target) && this.showModal) {
      this.onLoad();
      this.showAnimation = false;

      setTimeout(() => {
        this.showModal = false;
        this.cancel();
      }, 500);
    }
  }

  selectingYear(event) {
    this.shared.selectedDateChartFilter = event;
    const year = event.split("/");
    this.selectedCatYear = year[2];
    localStorage.setItem('date', this.shared.selectedDateChartFilter);
  }

  handleChange(event, i) {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": event.name,
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.selectedItems = this.shared.marketboxDataChartFilter[i].name;
    this.shared.isNewCategoryChartFilter = event.isNew;
    this.shared.marketboxDataChartFilter.forEach(element => {
      if (element.name == event.name) {
        element.checked = true;
        if (element.checked) {
        }
      } else {
        element.checked = false;
      }
    });
  }

  selectInclude(event) {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": event.name,
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    event.checked = !event.checked;
    if (event.checked) {
      if (!this.shared.esgDataChartFilter.some((item) => item.Name == event.value)) {
        this.shared.esgDataChartFilter.push({ "Id": '', "Name": event.value, "Type": "Included", value: event.name });
      }
    } else {
      const index = this.shared.esgDataChartFilter.findIndex(item => item.Name === event.value);
      this.shared.esgDataChartFilter.splice(index, 1);
      if(Commons.preferenceSelectedDataESG.includes(event.value)){
        const ind = Commons.preferenceSelectedDataESG.findIndex(item => item == event.value)
        Commons.preferenceSelectedDataESG.splice(ind, 1);
      }
      const includeIndex = this.shared.esgSelectedData.findIndex(item => item === event.value);
      this.shared.esgSelectedData.splice(includeIndex, 1);
      let incInd = Commons.ESGDataIncludeExclude.findIndex(item => item.Name === event.value);
      Commons.ESGDataIncludeExclude.splice(incInd, 1);
    }
  }

  selectExclude(event) {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": event.name,
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    event.checked = !event.checked;
    if (event.checked) {
      if (!this.shared.esgDataChartFilter.some((item) => item.Name == event.value)) {
        this.shared.esgDataChartFilter.push({ "Id": '', "Name": event.value, "Type": "Excluded", value: event.name });
      }
    } else {
      const index = this.shared.esgDataChartFilter.findIndex(item => item.Name === event.value);
      this.shared.esgDataChartFilter.splice(index, 1);
      if(Commons.preferenceSelectedDataESG.includes(event.value)){
        const ind = Commons.preferenceSelectedDataESG.findIndex(item => item == event.value)
        Commons.preferenceSelectedDataESG.splice(ind, 1);
      }
      const excludeIndex = this.shared.esgSelectedData.findIndex(item => item === event.value);
      this.shared.esgSelectedData.splice(excludeIndex, 1);
      let excInd = Commons.ESGDataIncludeExclude.findIndex(item => item.Name === event.value);
      Commons.ESGDataIncludeExclude.splice(excInd, 1);
    }
  }

  filterMarketUniverse(event) {
    this.shared.searchMarketData = event;
    if (this.shared.searchMarketData != "" && this.shared.searchMarketData != undefined && this.shared.searchMarketData != null) {
      this.shared.marketboxDataChartFilter = this.shared.marketboxDataChartFilter.filter((item) => {
        return (item.name.toLowerCase().indexOf(event.toLowerCase()) > -1);
      })
    }
    if (this.shared.searchMarketData == "") {
      this.shared.marketboxDataChartFilter = this.shared.marketUniverseDataChartFilter;
    }
  }

  openModal() {
    document.getElementById("showBlock").click();
  }

  filterOptions() {
    switch (this.shared.view) {
      case 'TopFunds':
        this.shared.preferenceOptions = this.removeOptions(['Select Column', 'My Funds', 'Best Passive'])
        this.onLoad();
        break;
      case 'ActivePassive':
        this.shared.preferenceOptions = this.removeOptions(['Select Column', 'Hidden Gems', 'ESG', 'My Funds'])
        this.onLoad();
        break;
      case 'GrowthView':
        this.shared.preferenceOptions = this.removeOptions(['Select Column', 'Hidden Gems', 'Fund Size', 'ESG', 'My Funds'])
        this.onLoad();
        break;
      case 'RiskReward':
        this.shared.preferenceOptions = this.removeOptions(['Select Column', 'Weighted Tenure', 'Hidden Gems', 'Fund Size', 'ESG', 'My Funds'])
        this.onLoad();
        break;
      case 'MyFunds':
        this.shared.preferenceOptions = this.removeOptions(['Select Column', 'My Funds'])
        this.onLoad();
        break;
      case 'Ratting':
        this.shared.preferenceOptions = this.removeOptions(['My Funds', 'Weighted Tenure'])
        this.onLoad();
        break;
      default:
        this.shared.preferenceOptions = this.removeOptions([], true)
        this.onLoad();
        break;
    }
  }

  removeOptions(options: any[], all?: boolean) {
    const allOptions = [
      { option: "Date Picker", select: false, disable: false },
      { option: "Hidden Gems", select: false, disable: false },
      { option: "Market Universe", select: false, disable: false },
      { option: "Weighted Tenure", select: false, disable: false },
      { option: "ESG", select: false, disable: false },
      { option: "Best Passive", select: false, disable: false },
      { option: "Fund Size (EUR)", select: false, disable: false },
      // { option: "Sharpe Ratio", select: false, disable:false  },
      { option: "Select Column", select: false, disable: false },
      { option: "My Funds", select: false, disable: false },
    ]
    const option = allOptions.filter((e) => {
      const data = e;
      const test = options.includes(data.option);
      if (test) {
        data.disable = true;
      } else {
        data.disable = false;
      }
      return data;
      // let index = options.indexOf(e.option)
      // if(index!=-1){
      //   return  false;
      // }else{
      //   return  true;
      // }
    })

    return option
  }

  getSelectedCategory() {
    let diamonds: any = [];
    const cate = localStorage.getItem('chartCategory');
    if (cate && cate != "undefined") {
    }
    const yearsSelect = localStorage.getItem('year');
    diamonds = JSON.parse(localStorage.getItem('showDiamond'));
    if (yearsSelect != null && yearsSelect != undefined) {
      this.selectedCatYear = yearsSelect;
    } else {
      this.selectedCatYear = "2021";
    }
    if (diamonds != null && diamonds != "null" && diamonds != undefined && diamonds.length != 0) {
      this.shared.hiddenGems = diamonds;
      this.shared.hiddenGems.forEach((element) => {
        this.shared.hiddenGemRange.forEach((res) => {
          if (element == res.id) {
            res.show = true;
          }
        })
      });
      // }
    } else {
      this.shared.hiddenGemRange.forEach((ele) => {
        ele.show = false;
      })
    }
    this.getSelectedDate();
    if (cate != null && cate != undefined) {
      this.selectedItems = cate;
      this.shared.marketboxDataChartFilter.forEach(element => {
        if (element.name == cate) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
    } else {
    }
  }

  onLoad() {
    this.getSelectedCategory();
    this.shared.defaultChartFilter = false;
    this.shared.preferenceOptions.forEach(element => {
      element.select = false;
    });
    this.shared.selectedOpt = this.shared.preferenceOptions[0].option;
    this.shared.preferenceOptions[0].select = true;
    this.esgIncludeData = [];
    this.esgExcludeData = [];
  }

  openFilter() {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "open_filter",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.getEsgFromSetting();
    if (!this.showAnimation) {
      setTimeout(() => {
        this.showModal = true;
        setTimeout(() => {
          this.showAnimation = true;
        }, 500);
      }, 10);
    } else if (this.showAnimation) {
      this.showAnimation = false;
      setTimeout(() => {
        this.showModal = false;
        this.onLoad();
      }, 500)
    }
  }

  cancel() {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "chart_option_cancel",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.showAnimation = false;
    setTimeout(() => {
      this.showModal = false;
      this.onLoad();
    }, 500)
    this.shared.esgDataChartFilter = [];
    this.shared.selectedDataChartFilter = [];
    this.getEsgFromSetting();
    this.shared.bestPassive.map(e => e.show = false);
    this.shared.selectedBestPassiveChartFilter = [];
    this.shared.bestPassive.forEach((ele) => {
      const exist = this.shared.selectedBestPassive.find((item) => item.id === ele.id);
      if (exist) {
        ele.show = true;
        if (!this.shared.selectedBestPassiveChartFilter.some((item) => item.id == ele.id)) {
          this.shared.selectedBestPassiveChartFilter.push({ id: ele.id });
        }
      }
    })
    this.shared.selectedFundSize = [];
    this.shared.fundSizeChartFilter.forEach((ele) => {
      ele.show = false;
      const exist = Commons.selectedFundSize.includes(ele.id);
      if (exist) {
        ele.show = true;
        if (!this.shared.selectedFundSize.some((item) => item.id == ele.id)) {
          this.shared.selectedFundSize.push(ele);
        }
      }
    });
    this.selectData.forEach(element => {
      this.shared.selectedDataChartFilter.push(element);
    });
    this.rating.chartFilterSelectColumn.forEach(element => {
      element.data.forEach((ele) => {
        if (this.selectData.some(e => e.value == ele.value)) {
          element.checked = true;
          ele.select = true;
          ele.show = true;
        } else {
          element.checked = false;
          ele.select = false;
          ele.show = false;
        }
      })
    });
  }

  choosedDate(event) {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "chart_options_date_change",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    // console.log('event',event);
    this.datePicker.setStartDate(moment(event.chosenLabel));
    this.datePicker.setEndDate(moment(event.chosenLabel));
    this.datePicker.updateView();
    const year = event.chosenLabel.split("/");
    this.selectedCatYear = year[2];
    this.shared.selectedDates = event.chosenLabel;
    this.shared.selectedDateChartFilter = event.chosenLabel;
    this.selected = {
      startDate: moment(this.shared.selectedDates),
      endDate: moment(this.shared.selectedDates)
    }
    this.shared.isBestPassive = false;
    this.getMarketUniverseWithDate(event.chosenLabel);
  }

  getMarketUniverseWithDate(chosenLabel){
    this.shared.marketUniverseLoading = true;
    if(this.checkRequestedDate){
      this.checkRequestedDate.unsubscribe();
    }
    this.checkRequestedDate = this.preferenceService.getMarketUniverse(this.datePipe.transform(chosenLabel, 'yyyy-MM-dd')).subscribe((res: any) => {
      this.shared.marketUniverseDataChartFilter = [];
      this.shared.marketUniverData = [];
      this.shared.marketUniverses = res;
      this.shared.marketUniverseLoading = false;
      let available: boolean;
      res.forEach(element => {
        const selected = localStorage.getItem("chartCategory");
        if (selected == element.Cluster && element.isDataAvailable) {
          this.shared.marketUniverseDataChartFilter.push({
            name: element.Cluster,
            isDataAvailable: element.isDataAvailable, isBestPassiveAvailable: element.isBestPassiveAvailable,
            checked: true, isNew: true
          })
        } else {
          this.shared.marketUniverseDataChartFilter.push({
            name: element.Cluster,
            isBestPassiveAvailable: element.isBestPassiveAvailable, isDataAvailable: element.isDataAvailable, checked: false, isNew: true
          })
        }
        this.shared.marketUniverData.push({ name: element.Cluster, isDataAvailable: element.isDataAvailable, isBestPassiveAvailable: element.isBestPassiveAvailable, checked: false, isNew: true })
        if (element.isDataAvailable) {
          available = true;
        } else {
          available = false;
        }
      });
      this.shared.marketUniverData.sort((a, b) => (a.name < b.name ? -1 : 1));
      this.shared.marketUniverseDataChartFilter.sort((a, b) => (a.name < b.name ? -1 : 1));
      this.getMarketUniverseData();
    }, (err) => {
      this.shared.marketUniverseLoading = false;
      apiError(err);
    })
  }

  clearSubmissionTimeFromDateTextInput(event) {
  }

  selectSubCate(opt) {
    this.shared.defaultChartFilter = false;
    opt.select = !opt.select;
  }

  reset() {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "clear_all_filter",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.getEsgFromSetting();
    localStorage.setItem('year', "2021");
    this.shared.hiddenGemRange.forEach(ele => {
      ele.show = false;
    });

    this.shared.fundSizeChartFilter.forEach(ele => {
      ele.show = true;
    });
    this.shared.weightedValue.forEach(ele => {
      if (ele.id == "5-Yr") {
        ele.show = true;
      } else {
        ele.show = false;
      }
    });
    const latest_date = currentDate();
    this.shared.selectedDates = latest_date;
    this.selected = {
      startDate: moment(this.shared.selectedDates),
      endDate: moment(this.shared.selectedDates)
    }
    if (!Commons.settingCategory) {
      this.shared.marketboxDataChartFilter.forEach(ele => {
        ele.checked = false;
      });
    } else {
      this.shared.marketboxDataChartFilter.forEach(ele => {
        if (ele.name == Commons.settingCategory) {
          ele.checked = true;
          localStorage.setItem('chartCategory', Commons.settingCategory);
          this.selectedItems = Commons.settingCategory;
        } else {
          ele.checked = false;
        }
      });
    }
    this.shared.defaultChartFilter = true;
    this.shared.selectedOpt = null;
    this.shared.selectedOpt = "";
    this.shared.preferenceOptions.forEach(element => {
      element.select = false;
    });
  }

  goToDetail(opt) {

    if (!opt.disable) {
      let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "chart_option_" + opt.option,
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
      this.shared.defaultChartFilter = false;
      opt.select = true;
      this.shared.selectedOpt = opt.option;
      this.shared.preferenceOptions.forEach(element => {
        if (element.option == opt.option) {
          element.select = true;
        } else {
          element.select = false;
        }
      });
    }
    const cate = localStorage.getItem('chartCategory');
    this.shared.marketboxDataChartFilter.forEach((ele) => {
      if (ele.name == cate) {
        ele.checked = true;
      } else {
        ele.checked = false;
      }
    })
  }
  itemExits = false;
  selectedColumns: any = [];
  selectedHeader(event, index) {
    event.select = !event.select;
    event.show = !event.show;
    if (event.select) {
      const exist = this.shared.selectedDataChartFilter.some(e => e.value == event.value);
      if (!exist) {
        this.shared.selectedDataChartFilter.push(event);
      }
      this.rating.tableHeaders.forEach((ele) => {
        if (ele.id == event.value) {
          ele.show = true;
        }
      });
    } else if (!event.select) {
      this.shared.selectedDataChartFilter.forEach((element, i) => {
        if (element.value == event.value) {
          this.shared.selectedDataChartFilter.splice(i, 1);
        }
      });
      this.shared.selectedColumns.forEach((element, i) => {
        if (element.value == event.value) {
          this.shared.selectedColumns.splice(i, 1);
        }
      });

    }
  }

  esgIncludeData: any = [];
  esgExcludeData: any = [];
  selectData: any = [];
  applyChanges(category) {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "chart_options_apply",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.selectData = [];
    Commons.selectedDate = this.shared.selectedDateChartFilter;
    localStorage.setItem('date', this.shared.selectedDates);
    this.shared.hiddenGems = [];
    this.getSelectedDate();
    const years = localStorage.getItem('date');
    localStorage.setItem('bestPassive', JSON.stringify(this.shared.bestPassive));
    localStorage.setItem('chartCategory', category);
    this.shared.selectCategory = category;
    const gems = [];
    this.shared.hiddenGemRange.forEach((res) => {
      if (res.show == true) {
        gems.push(res.id);
      } else {
      }
    });
    if (gems.length > 0) {
      this.shared.hiddenGems = gems;
    } else {
      this.shared.hiddenGems = null;
    }
    localStorage.setItem('hiddenGem', this.shared.hiddenGems);
    const fundSizeData: any = [];
    this.shared.fundSizeChartFilter.forEach((ele) => {
      if (ele.show == true) {
        fundSizeData.push({ id: ele.id });
      }
    });
    localStorage.setItem('fundSizes', JSON.stringify(fundSizeData));
    localStorage.setItem('fundSizesDisplay', this.shared.displayFundSize);
    this.showAnimation = false;
    setTimeout(() => {
      this.showModal = false;
    }, 500)
    this.selectedItems = category;

    this.common.getTopFundDataWithCategory(category, this.selectedCatYear, this.totalyear, this.shared.hiddenGems, this.shared.isNewCategoryChartFilter, this.datePipe.transform(localStorage.getItem('date'), 'yyyy-MM-dd'), this.shared.esgDataChartFilter);
    setTimeout(() => {
      this.common.getActivePasive(category, this.selectedCatYear, this.shared.isNewCategoryChartFilter, this.datePipe.transform(localStorage.getItem('date'), 'yyyy-MM-dd'));
    }, 1000);
    this.shared.alertSetupObservable.next();
    this.shared.growthChartLoading = true;
    this.shared.growthChartDataNotAvailable = false;
    this.shared.selectedBestPassive = this.shared.selectedBestPassiveChartFilter;
    this.shared.selectedDataChartFilter.forEach(element => {
      this.selectData.push(element);
      this.shared.selectedSelectColum.push(element.id);
      const exist = this.shared.selectedColumns.find((item) => item.id === element.id);
      if (!exist) {
        this.shared.selectedColumns.push(element);
      }
    });
    this.shared.selectedColumns.forEach(element => {
      const exist = this.shared.selectedDataChartFilter.find((item) => item.id === element.id);
      if (!exist) {
        this.shared.selectedDataChartFilter.push(element);
      }
    });
    this.shared.selectedFundSize.forEach((ele) => {
      Commons.selectedFundSize.push(ele.id);
    })

    this.shared.esgDataChartFilter.forEach((ele) => {
      if (!Commons.ESGDataIncludeExclude.some((item) => item.Name == ele.Name)) {
        Commons.ESGDataIncludeExclude.push(ele);
      }
      if (!this.shared.esgSelectedData.includes(ele.Name)) {
        this.shared.esgSelectedData.push(ele.Name);
      }
    });
    if (this.shared.displayFundSize == "On") {
      this.shared.showFundSize = true;
      this.shared.ratingViewHeaders.push({ id: "Fund Size", show: true, totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" })
    } else if (this.shared.displayFundSize == "Off") {
      this.shared.showFundSize = false;
      this.shared.ratingViewHeaders.forEach((element, index) => {
        if (element.id == "Fund Size") {
          this.shared.ratingViewHeaders.splice(index, 1)
        }
      });
    }
    this.onSelectColumn.emit(this.shared.selectedDataChartFilter);
  }

}

function $filter(arg0: string) {
  throw new Error('Function not implemented.');
}

