import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { CommonService } from 'src/services/CommonServices/common.service';
import { UserDashboardComponent } from '../user-dashboard/user-dashboard.component';
import {
  CdkDragDrop,
  CdkDragStart,
  CdkDropList,
  moveItemInArray,
} from "@angular/cdk/drag-drop";
import { RatingViewService } from 'src/services/rating/rating-view.service';
import { PrefrenceSettingsService } from 'src/services/PreferencesServices/prefrence-settings.service';
import { Preference } from 'src/utils/constants';
import { consistencyUpdate, esgExcludeValue, esgIncludeValue } from 'src/utils/common-function';
import { Router } from '@angular/router';
import { Commons, Shared } from 'src/utils/common';
import { GoogleAnalyticsService } from 'src/services/google-Analytics/google-analytics.service';
declare var jquery: any;
declare var $: any;
declare let ga: Function;
@Component({
  selector: 'app-rating-view',
  templateUrl: './rating-view.component.html',
  styleUrls: ['./rating-view.component.scss']
  // pipes: [CategoryPipe]
})
export class RatingViewComponent implements OnInit {
  @Output("closeCharts") closeCharts: EventEmitter<any> = new EventEmitter();
  @Input() public ratingViewArray;
  @Input() colorfortable: [];
  @Input() diamond: boolean;
  @ViewChild('fundNameTable') fundNameTable: ElementRef;
  @ViewChild('userDashboard') userDashBoardComponent: UserDashboardComponent;
  @Output("selectHeaderView") selectHeaderView: EventEmitter<any> = new EventEmitter();
  selectedItems = "Global_Equity_Large_Cap";
  totalyear = "5";
  selectedCatYear = "2021";
  columns: any;
  @Input() dataNotAvailable = false;
  previousIndex: number;
  selectedCategory: any;
  paginateData: any;
  selectColoum = true;
  gemFirst: any;
  gemSecond: any;
  tableHeight: any;
  release: boolean;
  ratingViewData:any[]=[];
  greenLeaf: any = "../../../assets/images/green-leaf.svg";
  redLeaf: any = "../../../assets/images/red-leaf.svg";
  @Input() selectedDataDropDown: any;
  
  constructor(public common: CommonService, public ratingService: RatingViewService, public preferencesSetting: PrefrenceSettingsService, public router: Router,
    public googleAnalyticsEventsService: GoogleAnalyticsService, public shared: Shared) {
    this.selectColoum = true;
  }

  closeChart() {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "rating_closechart",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.closeCharts.emit();
  }

  
  isSticky(column): boolean {
    if (column.id == 'Fund Name') {
      return true;
    }
    else {
      return false;
    }
  }

  async getData() {
    await this.ratingService.dataForTableView(this.shared?.ratingViewArray, this.shared.esgSelectedData, Commons.selectedScore);
  }

  OnDestroy() {
    this.selectColoum = false;
  }

  getChartScore(event, i) {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "consistency",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
      Commons.selectedScore = [];
    this.shared.consistencyScore.map((ele) => { ele.select = false });
    // this.topFundService.chartScore[i].select = true;
    this.shared.consistencyScore.forEach((ele, index) => {
      if (index <= i) {
        ele.select = true;
        Commons.selectedScore.push(ele.value);
      }
    });
    // consistencyUpdate();
    Commons.consistencyObTopFund = 0;
    Commons.consistencyObActivePassive = 0;
    Commons.consistencyObRatingView = 0;
    Commons.consistencyObRiskReward = 0;
    setTimeout(() => {
      Commons.consistencyScoreChange.next();
    }, 20);
  }

  ngOnInit() {
    this.router.config.forEach(event => {
      if (event.path == "tableView") {
        // gtag('config', 'UA-211001570-1', 
        //            {
        //              'page_path': event.path          
        //            }
        //           );
                  ga('send', {
                    hitType: 'pageview',
                    page: event.path
                  });
      }
    });
    // gtag('event', 'tableView-Component', {
    //   page_title: 'FundsaiQ',
    //   page_path: '/tableView-Component',
    //   send_to: 'UA-211001570-1'
    // })
    this.shared.selectedCategory.subscribe((res) => {
      if (res && res != "undefined") {
        this.selectedCategory = res;
      }
    });
    const selectCate = localStorage.getItem("chartCategory");
    if (selectCate && selectCate != "undefined") {
      this.selectedCategory = localStorage.getItem("chartCategory");
    }
    if (this.shared.swappedData.length > 0) {
      this.shared.ratingViewHeaders = [];
      this.shared.swappedData.forEach(ele => {
        this.shared.ratingViewHeaders.push(ele);
      });
    } else {
    }
    setTimeout(()=>{
      this.ratingViewData = this.shared.tableViewData;
    }, 0.1);
  }

  getShareClasses(event){
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "primary_shareclass",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.isShareClassOnn = event;
    if(event == true && this.shared.topFundCategoryData){
      Commons.toggleShareClass.next();
    }else{
      Commons.toggleShareClass.next();
    }
  }

  userGuideLink(){
    window.open("https://storage.googleapis.com/fundsaiq-fundsdocuments/userguide/Fundsaiq_userguide.pdf");
  }

  ngOnDestroy(){
    if(Commons.userDashboardshow){
      this.router.config.forEach(event => {
        if (event.path == 'user-dashboard') {
          // gtag('config', 'UA-211001570-1', 
          //            {
          //              'page_path': event.path              
          //            }
          //           );
                    ga('send', {
                      hitType: 'pageview',
                      page: event.path
                    });
        }
      });
    }
  }
  ngAfterViewInit(){
      // this.tableHeaders = this.shared.ratingViewHeaders;
      // this.ratingViewData = this.ratingService.tableViewData;
  }

  onResizedTable() {
    setTimeout(() => {
      this.tableHeight = this.fundNameTable.nativeElement.offsetHeight + 25;
    }, 200)
  }

  expandTable(item, i) {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "expand_fund_table",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if (item.holdingValues && item.holdingValues.length > 0) {
      this.shared.fundNameData[i].isExpand = !this.shared.fundNameData[i].isExpand;
      this.shared.tableViewData[i].isExpand = !this.shared.tableViewData[i].isExpand;
      this.shared.tableViewData[i].holding_values = item.holdingValues;
    }
  }

  valid(val: any, item, cell, data) {
    //debugger
    let Name: any;
    if (cell.id.includes("ESG")) {
      let value: any;
      Preference.esgOptionsIncludeChart.forEach(ele => {
        if (item) {
          const check = item.hasOwnProperty(ele.value);
          if (check) {
            const id = 'ESG - ' + ele.name;
            if (cell.id == id) {
              value = esgIncludeValue(ele.name, item[ele.value]);
              // value = item[ele.value];
              // data.color = "green";
            }
          }
        }
      });
      Preference.esgOptionExcludeChart.forEach(element => {
        if (item) {
          const check = item.hasOwnProperty(element.value);
          if (check) {
            const id = 'ESG - ' + element.name;
            if (cell.id == id) {
              value = esgExcludeValue(element.name, item[element.value]);
              // value = item[element.value];
              // data.color = "red";
            }
          }
        }
      });
      // this.ratingService.esgTableData.forEach((ele) => {
      //   if (item) {
      //     let check = item.hasOwnProperty(ele);
      //     if (check) {
      //       let id = 'ESG - ' + ele;
      //       if (cell.id == id) {
      //         value = item[ele] + '%';
      //       } 
      //     }
      //   }
      // })
      if (value) {
        return value;
      } else {
        return "--";
        // return "x";
      }
    } else {
      return "--";
      // return "x";
    }

  }

  // set % mark on numeric values
  validate(head) {
    let heading: any;
    heading = head;
    return heading;
  }

  validateValue(row, cell, val: any,) {
    // debugger
    this.gemFirst = "";
    this.gemSecond = "";
    const gems = JSON.parse(localStorage.getItem('showDiamond'));
    if (gems != null && gems != "null" && gems != undefined) {
      gems.forEach(element => {
        if (element == "100m-500m") {
          this.gemFirst = element;
        }
        if (element == "500m-1bn") {
          this.gemSecond = element;
        }
      });
    }
    let result;
    if (val) {
      result = typeof val == 'number' ? val + '%' : val
      if (cell.id == "Fund Size (EUR)") {
        result = val;
      } else {
        result = typeof val == 'number' ? val + '%' : val
      }
    } else {
      result = "--";
    }
    // }

    if (row["Fund Size (EUR)"]) {
      if (cell.id == 'Fund Name') {
        let value: any;
        value = val;
        if (this.gemFirst == "100m-500m") {
          if (row["Fund Size (EUR)"] > 100000000 && row["Fund Size (EUR)"] < 500000000) {
            value = val + '<img class="diamond-image" src="../../../assets/images/diamond.png" width="7" height="25"/>';
          }
        }
        if (row.Include) {
          value = value + '<img  src="../../../assets/images/green-leaf.svg" width="7" height="25"/>';
        }
        if (row.Excluded) {
          value = value + '<img  src="../../../assets/images/red-leaf.svg" width="7" height="25"/>';
        }
        return value;
      }
    }
    return result
  }

  selectColumns(items) {
    const newData: any = [];
    this.shared.ratingViewHeaders.forEach((ele, index) => {
      if (items.length > 0) {
        items.forEach(element => {
          if (ele.id == element.value) {
            newData.push(index);
          } else {
            ele.show = false;
          }
          if (ele.id == "Fund Name") {
            ele.show = true;
          }
        });
      } else {
        if (ele.id == "Fund Name") {
          ele.show = true;
        }
        else {
          ele.show = false;
        }
      }
    });
    newData.forEach(element => {
      this.shared.ratingViewHeaders[element].show = true;
    });
    this.shared.tableViewChangeObservable.next();
  }


  dropRow(event: CdkDragDrop<string[]>) {
  }

  dropCol(event: CdkDragDrop<string[]>) {
    if (event.currentIndex != 0) {
      this.shared.swappedData = [];
      moveItemInArray(this.shared.ratingViewHeaders, event.previousIndex, event.currentIndex);
      this.shared.ratingViewHeaders.forEach(element => {
        this.shared.swappedData.push(element);
        if (element.show) {
          this.shared.selectedTableData.push(element);
        }
      });
      this.shared.tableViewChangeObservable.next();
      this.shared.selectedTableData.forEach((ele, index) => {
        if (index == 0) {
          this.shared.tableViewName = ele.id;
          this.shared.firstColumnData;
          this.shared.tempData.forEach(data => {
            if (ele.id == data.id) {
            }
          });
        }
      });
    }
  }

  onDrag(event, item) {

    this.shared.ratingViewHeaders.forEach((ele) => {
      if (item.id == ele.id) {
        ele.dragDropImage = "../../../assets/images/slide-active.svg";
      } else {
        ele.dragDropImage = "../../../assets/images/slide.svg";
      }
    })
  }

  dragStarted(event: CdkDragStart, index: number) {
    this.previousIndex = index;
  }

  dropListDropped(event: CdkDropList, index: number) {
    if (event) {
      moveItemInArray(this.columns, this.previousIndex, index);
      this.setDisplayedColumns();
    }
  }

  setDisplayedColumns() {
    this.columns.forEach((colunm, index) => {
      colunm.index = index;
    });
  }


  addIconOnHoverAsec(event) {
    event.ascImage = "../../../assets/images/asc-order.png";
  }

  removeIconFrmHoverAsec(event) {
    event.ascImage = "../../../assets/images/asec.png";
  }

  addIconOnHoverDesc(event) {
    event.descImage = "../../../assets/images/desc-order.png";
  }

  removeIconFrmHoverDesc(event) {
    event.descImage = "../../../assets/images/desc.png";
  }

  addIconOnHover(event) {
    event.dragDropImage = "../../../assets/images/slide-active.svg";
  }

  removeIconFrmHover(event) {
    event.dragDropImage = "../../../assets/images/slide.svg";
  }

  showIcon(event) {
    this.shared.ratingViewHeaders.forEach((ele) => {
      ele.showFilters = false;
    })
    this.shared.ratingViewHeaders.forEach((ele) => {
      if (ele.id == event.id) {
        ele.showDrag = true;
      } else {
        ele.showDrag = false;
      }
    })
  }

  removeIcon() {
    this.shared.ratingViewHeaders.forEach((ele) => {
      ele.showDrag = false;
    })
  }

  showIconForAsc(event) {
    this.shared.ratingViewHeaders.forEach((ele) => {
      ele.showDrag = false;
    })
    this.shared.ratingViewHeaders.forEach((ele) => {
      if (ele.id == event.id) {
        ele.showFilters = true;
      } else {
        ele.showFilters = false;
      }
    })
  }

  removeIconForAsc(event) {
    this.showIcon(event)
    this.shared.ratingViewHeaders.forEach((ele) => {
      ele.showFilters = false;
    })
  }

  mouseDown(event) {
  }


}
