
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/services/CommonServices/common.service';
import {
  ViewChild,
  ElementRef,
} from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { decode } from "string-encode-decode";
import { PrefrenceSettingsService } from 'src/services/PreferencesServices/prefrence-settings.service';
import { FundsDataService } from 'src/services/ChartServices/funds-data.service';
import { Preference } from '../../utils/constants';
import { GrowthViewService } from 'src/services/growth-view/growth-view.service';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { AlertsServiceService } from 'src/services/alerts-service/alerts-service.service';
import { FundDocumentaionService } from 'src/services/fund-documentation/fund-documentaion.service';
import { apiError, currentDate } from 'src/utils/common-function';
import { AlertsNotificationsService } from 'src/services/alerts-notifications/alerts-notifications.service';
import { Commons, Shared } from 'src/utils/common';
import { error } from 'util';
import { GoogleAnalyticsService } from 'src/services/google-Analytics/google-analytics.service';



const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Component({
  selector: 'app-prefrences',
  templateUrl: './prefrences.component.html',
  styleUrls: ['./prefrences.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class PrefrencesComponent implements OnInit {
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('menu') menu: ElementRef;
  myFundObservable:Subscription;
  checkSearchRequestStart:Subscription;
  errMsg: any;
  selectedItems = "Austria_Mid_Blend";
  userID: any;
  selectedCatYear = "2021";
  totalyear = "5";
  showDropDownAddSetting = false;
  geographySale:any[]=[];
  
  constructor(public http: HttpClient,
    public common: CommonService,
    public router: Router,
    public prefrenceSetting: PrefrenceSettingsService,
    public route: ActivatedRoute,
    public datePipe:DatePipe,
    public alertService:AlertsServiceService,
    public growthChartService: GrowthViewService,
    public fundService: FundsDataService,
    public fundDocService: FundDocumentaionService,
    public alertsAndNotificationService: AlertsNotificationsService,
    public googleAnalyticsEventsService: GoogleAnalyticsService,
    public shared: Shared) {
    this.shared.prefrenceSettings = Preference.preferenceData;
    this.showDropDownAddSetting = false;
    this.sideOptionsData();
    
    this.userID = decode(localStorage.getItem("ID"));
  }

  sideOptionsData() {
    this.geographySale = Preference.geographySale;
  }

  ngOnInit() {
    this.shared.savedCurrency = "EUR";
    this.shared.getCurrency = 'EUR';
    this.shared.esgDataPreferences = [];
    this.getPreferenceData();
    this.shared.marketUniverseLoading = true;
    this.shared.tempSetting = [];
    this.onLoad();
    this.getSavedSetting(this.shared.getAllSettingsData);
    this.shared.allSettingObservable.subscribe((res)=>{
      this.getSavedSetting(res['data']);
    })
    this.selectCategoryId();
    this.shared.selectedDropDown = {
      name: "Save Settings",
      id: null
    };
    this.shared.myFundsDataObservable.subscribe((res)=>{
      this.getMyAllFunds(res);
      this.getPreferenceData();
    })
    this.fundService.getMyAllFunds(this.userID);
  }

  clickedOut(event) {
    if (event.target.className == "pg-form-pages login-page") {
      document.getElementById("cancel-button").click();
    }
  }

  clickedOutSide(event) {
    if (event.target.lastChild.className == "pg-content-block-save-preference") {
      document.getElementById("close-popup").click();
    }
  }

  onClick(event) {
    if (this.menu != undefined) {
      if (!this.menu.nativeElement.contains(event.target) && this.showDropDownAddSetting) {
        this.shared.showAnimationPreferences = false;
        setTimeout(() => {
          this.showDropDownAddSetting = false;
        }, 500)
      }
    }
  }

  showDrop() {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "settings_dropdown",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if (!this.shared.showAnimationPreferences) {
      setTimeout(() => {
        this.showDropDownAddSetting = true;
        setTimeout(() => {
          this.shared.showAnimationPreferences = true;
        }, 500);
      }, 10);
    } else if (this.shared.showAnimationPreferences) {
      this.shared.showAnimationPreferences = false;
      setTimeout(() => {
        this.showDropDownAddSetting = false;
      }, 500)
    }
  }

  addNewSettingData(event) {
    this.shared.addNewSetting = event;
  }

  //create function for testing purpose only
  itemSettingData(event) {
    this.shared.itemSetting = event;
  }


  getGeographyData(event){
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.name,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    event.checked = !event.checked;
    if(event.checked){
      Commons.geographyData.push(event.name);
    } else{
    const index = Commons.geographyData.findIndex(item => item  === event.name); 
    Commons.geographyData.splice(index, 1);
    }
  }
  

  expandIcon(event){
    event.isExpend = !event.isExpend;
  }

  asiaData(event){
    event.checked = !event.checked;
  }

  getAssetClass(event) {
    this.shared.selectedAssteClass = event.name;
  }

  editSetting(event, i) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "edit_setting",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.itemSetting = event.settingName;
    this.shared.preferenceSaveSettings[i].enableEdit = true;
  }

  getMarketUniverse(event, i) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.name,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.selectedItems = this.shared.selectedOptionData[i].name;
    this.shared.isNewCategoryPreferences = event.isNew;
    localStorage.setItem('IsNew', event.isNew);
    this.shared.selectedOptionData.forEach((ele) => {
      if (ele.name == event.name) {
        ele.checked = true;
      } else {
        ele.checked = false;
      }
    })
  }


  filterGeography(event){
    this.shared.filterGeographyInput = event;
    if (this.shared.filterGeographyInput != "" && this.shared.filterGeographyInput != undefined && this.shared.filterGeographyInput != null) {
      this.geographySale = this.geographySale.filter((item) => {
        return (item.Country_Text.toLowerCase().indexOf(this.shared.filterGeographyInput.toLowerCase()) > -1);
      })
    }
    if (this.shared.filterGeographyInput == "") {
      this.geographySale = this.shared.geographySale;
      this.shared.geographySale.forEach((ele)=>{
          ele.checked = false;
        const exist = Commons.geographyData.includes(ele.Country_Text);
        if(exist){
          ele.checked = true;
        }
      })
    }
  }

  filterData(event, item) {
    this.shared.filterInput = event;
    if (this.shared.filterInput != "" && this.shared.filterInput != undefined && this.shared.filterInput != null) {
      this.shared.selectedOptionData = this.shared.marketUniverData.filter((el) => {
        return (this.removeUnderscore(el.name).toLowerCase().indexOf(this.shared.filterInput.toLowerCase()) > -1);
      })
    }
    if (this.shared.filterInput == "") {
      if (item == "Market Universe") {
        // this.shared.selectedOptionData = Preference.preferenceData[0].sideOption[1].value;
        this.shared.selectedOptionData = this.shared.marketUniverData;
      }
      if (item == "Investment Area") {
        this.shared.selectedOptionData = Preference.preferenceData[0].sideOption[2].subcategory[1].value;
      }
      if (item == "Country of Sales") {
        this.shared.selectedOptionData = Preference.preferenceData[0].sideOption[2].subcategory[0].value;
      }
    }
  }


  close(event) {
    this.shared.showDropDownPreferences = false;
    setTimeout(() => {
      this.shared.showAnimationPreferences = true;
    }, 500);
  }


  openDropDown() {
    this.shared.showDropDownPreferences = true;
  }


  enableSetting() {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "add_new_setting",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.enableAdd = true;
    this.shared.addNewSetting = "";
  }

  clickOnCheck() {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "click_check",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.enableAdd = false;
    if (this.shared.addNewSetting != undefined && this.shared.addNewSetting != "") {
      this.shared.preferenceSaveSettings.push({ settingName: this.shared.addNewSetting, select: true, enableEdit: false, });
      this.shared.tempSetting.push({ settingName: this.shared.addNewSetting, select: false, enableEdit: false, });
      this.shared.selectedDropDown = {
        name: this.shared.addNewSetting,
          id: null
      };
      this.shared.addNewSetting = "";
      this.shared.selectedSettingId = "";
    }
  }

  clickOnDeleteSetting(event, i) {
    let createdTime = new Date();
    this.shared.myFunds = [];
    this.shared.myAllFundIs = [];
    let modal = {
      "createdAt": createdTime,
      "eventName": "delete_setting",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.preferenceSaveSettings.splice(i, 1);
    if (this.shared.selectedDropDown.name == event.settingName) {
      if(this.shared.previousSavedSetting.id != null && event.id != this.shared.previousSavedSetting.id){
        this.shared.selectedDropDown = this.shared.previousSavedSetting;
      }else{
        this.shared.selectedDropDown = {
          name: "Save Settings",
          id: null
        };
      }
      if(this.shared.preferenceSaveSettings.length > 0){
      this.shared.preferenceSaveSettings.forEach(element => {
        if(this.shared.settingId == element.id){
        if(this.shared.selectedDropDown.id != null){
          if (this.shared.selectedDropDown.name == element.settingName && this.shared.selectedDropDown.id == element.id) {
            this.shared.settingId = element.id;
            this.shared.alterSettingId = element.id;
            element.select = true;
            this.shared.selectedSettingId = element.id;
          }
        }else{
          if (this.shared.selectedDropDown.name == element.settingName) {
            this.shared.settingId = element.id;
            this.shared.alterSettingId = element.id;
            element.select = true;
            this.shared.selectedSettingId = element.id;
          }
        }
      }else{
        this.shared.settingId = null;
      }
      });
    }else{
      this.shared.settingId = null;
      this.shared.alterSettingId = null;
    }
    }
    this.shared.myFundsDataObservable.next(this.shared.selectedDataPreferences);
    if (event.id) {
      this.prefrenceSetting.deleteSetting(this.userID, event.id).subscribe((res) => {
      if(res != null && res['message'] == "Setting Deleted."){
        this.shared.selectedDataPreferences.data.forEach(element => {
          if(this.shared.settingId == element.PreferencesID){
            element.checked = true;
            this.shared.myAllFundIs.push({ name: element, showEdit: false, rebates: '0.0', tooltip: true });
          }
          this.shared.myFunds.push(element.Name);
        });
         this.getSavedSettings();
       }
      }, (err) => {

      })
    }
  }

  clickOnClose() {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "close",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.enableAdd = false;
    this.shared.addNewSetting = "";
  }


  itemclickOnCheck(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "edit_setting_checked",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    event.enableEdit = false;
    if (this.shared.itemSetting != undefined && this.shared.itemSetting != "") {
      event.settingName = this.shared.itemSetting;
      this.shared.itemSetting = "";
    }
  }

  itemclickOnClose(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "edit_setting_close",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    event.enableEdit = false;
    this.shared.itemSetting = "";
  }

  getSelectedValue(event, i) {
    event.checked = !event.checked;
    if (event.checked == true) {
      this.shared.selectedFunds.push({ name: event, showEdit: false, rebates: '0.0',  settingId: this.shared.settingId});
      this.shared.myAllFundIs.push({ name: event, showEdit: false, rebates: '0.0' });
      this.SelectedFund.push(event);
    }else if (event.checked == false) {
      const index = this.SelectedFund.findIndex(item => item.Name  === event.Name); 
      this.SelectedFund.splice(index, 1);  
      this.shared.myAllFundIs.forEach((element, index) => {
        if (event.Name == element.name.Name) {
          this.deleteItemFromSelectedFund(element, index);
        }
      });
    }
  }


  deleteItemFromSelectedFund(event, i) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "delete_selected_fund",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    let ind = this.shared.selectedFunds.findIndex(item => item.name.id == event.name.id && item.settingId == this.shared.settingId)
    if(ind != -1){
      this.shared.selectedFunds.splice(ind, 1);
    }
    this.shared.myAllFundIs.splice(i, 1);
    this.shared.myFundSpinner = true;
    this.fundService.deleteFunds(this.userID, event.name.SecId, this.shared.settingId).subscribe((res:any)=>{
    // })
    // this.http
    //   .post(
    //     environment.baseUrl + "deleteFunds/" + this.userID + "/" + event.name.SecId,
    //     {headers:authorizeHeader()}
    //   )
    //   .subscribe((res: any) => {
        this.shared.fundDeleteResponse = res;
        if(res == "Record Deleted"){
          this.shared.topFundGlobalEqualityAlertsX.forEach(ele => {
            if(this.shared.myAllFundIs.some((item) => item.name.Name == ele.name)){
              this.shared.checkFundName = true;
            }else{
              this.shared.checkFundName = false;
            }
          })
          setTimeout(() => {
            this.fundService.getMyAllFunds(this.userID);
          }, 2000);
        }
        setTimeout(() => {
          this.shared.fundDeleteResponse = "";
        }, 2000);
      }, (err)=>{
        this.shared.myFundSpinner = false;
        apiError(err);
      });
  }


  showEdit(event) {
    this.shared.myAllFundIs.forEach(element => {
      if (element.name.Name === event.name.Name) {
        element.showEdit = true;
      } else {
        element.showEdit = false;
      }
    });
  }

  removeEdit(event) {
    this.shared.myAllFundIs.forEach(element => {
      element.showEdit = false;

    });
  }

  openEdit(i) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "edit_rebates",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.enableEditRebates = true;
    this.shared.rebatesSelectedIndex = i;
  }

  changeNumberRebates(event) {
    this.shared.changeRebatesNumber = event;
  }

  clickOnCheckRebates() {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "edit_rebates_check",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.enableEditRebates = false;
    if (this.shared.changeRebatesNumber != undefined && this.shared.changeRebatesNumber != "") {
      this.shared.myAllFundIs[this.shared.rebatesSelectedIndex].rebates = this.shared.changeRebatesNumber
      this.shared.changeRebatesNumber = "";
    }

    if (this.shared.changeRebatesNumber != undefined && this.shared.changeRebatesNumber != "") {
      this.shared.selectedFunds[this.shared.rebatesSelectedIndex].rebates = this.shared.changeRebatesNumber
      this.shared.changeRebatesNumber = "";
    }
  }

  clickOnCloseRebates() {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "edit_rebates_close",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.enableEditRebates = false;
    this.shared.changeRebatesNumber = "";
  }

  openModal() {
    document.getElementById("showBlock").click();
  }

  getMyAllFunds(myFunddata){
    this.shared.myFunds = [];
    this.shared.dropdownList = [];
    this.shared.myAllFundIs = [];
    Commons.myFundsDataApis = null;
      this.SelectedFund = [];
      this.shared.selectedDataPreferences = myFunddata;
      Commons.myFundsDataApis = myFunddata.data;
      if (myFunddata.data) {
        myFunddata.data.forEach(element => {
          if(this.shared.settingId == element.PreferencesID){
            element.checked = true;
            this.shared.myAllFundIs.push({ name: element, showEdit: false, rebates: '0.0', tooltip: true });
          }
          this.shared.myFunds.push(element.Name);
        });
        // this.selectedFunds.forEach(ele => {
        //   if(this.shared.myAllFundIs.length > 0 && this.shared.myAllFundIs.some(item => item.name.Name == ele.name.Name)){
        //     ele.checked = true;
        //   }else{
        //     if(ele.settingId == this.shared.settingId){
        //       ele.checked = true;
        //       this.shared.myAllFundIs.push({ name: ele.name, showEdit: false, rebates: '0.0', tooltip: true });
        //     }
        //   }
        // });
        //this.alertsAndNotificationService.checkFundName = this.alertsAndNotificationService.topFundGlobalEqualityAlertsX.some((item) => item.name == element.Name);
        this.shared.topFundGlobalEqualityAlertsX.forEach(ele => {
          if(this.shared.myAllFundIs.some((item) => item.name.Name == ele.name)){
            this.shared.checkFundName = true;
          }
        })
        if(this.shared.checkFundName && this.shared.selectedDropDown.name != "Save Settings"){
          this.shared.preferenceSettings = this.shared.selectedDropDown.name;
        }
      }
  }


  changeCate(){
    this.shared.changeCategory.subscribe(()=>{
      this.shared.marketUniverseLoading = false;
      this.shared.marketUniverData.sort((a, b) => (a.name < b.name ? -1 : 1));
      this.shared.marketUniverseDataChartFilter.sort((a, b) => (a.name < b.name ? -1 : 1));
      this.shared.marketUniverData.forEach(element => {
        if(this.selectedItems == element.name){
          element.checked = true;
        } else{
          element.checked = false;
        }
        });
        if(this.shared.selectedOptPreferences == "Market Universe"){
          this.shared.selectedOptionData = this.shared.marketUniverData;
        }
    })
  }

  onLoad() {
    setTimeout(() => {
      this.getSavedSetting(this.shared.getAllSettingsData);
      if(this.myFundObservable){
        this.myFundObservable.unsubscribe();
      }
      
      
      this.shared.selectedOptPreferences = "Assets Class";
      this.shared.selectedOptionData = Preference.preferenceData[0].sideOption[0].value;
      
      Preference.preferenceData.forEach(element => {
        element.sideOption.forEach(ele => {
          ele.select = false;
        });
      });
      const value = Preference.preferenceData[0];
      value.sideOption[0].select = true;
      this.shared.dropdownList = [];
      if (this.shared.selectedOptPreferences == undefined && this.shared.selectedOptPreferences == null) {
        this.shared.defaultPreferences = true;
      }
    }, 10);
  }

  getPreferenceData(){
    this.shared.marketUniverseDataChartFilter = [];
    const currentDate = new Date();
    this.prefrenceSetting.getPreferencesData(this.userID, this.datePipe.transform(currentDate, 'yyyy-MM-dd')).subscribe(res => {
      this.shared.marketUniverData = [];
      this.shared.marketUniverseLoading = false;
      if(res['data']){
        res['data'].categories.forEach(element => {
          this.shared.marketUniverseDataChartFilter.push({name:element.Cluster, isDataAvailable:element.isDataAvailable, isBestPassiveAvailable:element.isBestPassiveAvailable, checked:false, isNew:true })
          this.shared.marketUniverData.push({name:element.Cluster, isDataAvailable:element.isDataAvailable, isBestPassiveAvailable:element.isBestPassiveAvailable, checked:false, isNew:true })
        });
        this.shared.marketUniverData.sort((a, b) => (a.name < b.name ? -1 : 1));
        this.shared.marketUniverseDataChartFilter.sort((a, b) => (a.name < b.name ? -1 : 1));
        this.shared.changeCategory.next();
        if( this.shared.selectedOptPreferences == "Market Universe"){
          this.shared.selectedOptionData =  this.shared.marketUniverData;
        }
      }
    },(err)=>{
      apiError(err);
    });
  }

  getSavedSettings() {
    this.prefrenceSetting.getAllSetting(this.userID).subscribe((res) => {
      this.shared.preferenceSaveSettings = [];
      if(res['data'] == null || res['data'] == undefined){
        this.shared.selectedDropDown = {
          name: "Save Settings",
            id: null       
        };
      }
      if (res['data'].length >= 1) {
        res['data'].forEach(element => {
          this.shared.preferenceSaveSettings.push({ settingName: element.Name, id: element.Id, select: false, enableEdit: false, });
        });
        let index = this.shared.preferenceSaveSettings.findIndex(value => value.settingName === this.shared.selectedDropDown.name)
        if(index != -1){
          this.getSettingData(this.shared.preferenceSaveSettings[index], 0);
        }
        if(this.shared.selectedDropDown.name == "Save Settings"){
          this.getSettingData(this.shared.preferenceSaveSettings[0], 0);
        }
        this.shared.tempSelectedSetting = this.shared.selectedDropDown.name;
      } else {
        this.shared.preferenceSaveSettings = [];
      }
      this.shared.tempSetting.forEach((ele) => {
          this.shared.preferenceSaveSettings.push(ele)
        });
      this.shared.preferenceSaveSettings.forEach(element => {
        if (this.shared.selectedDropDown.name == element.settingName && this.shared.selectedDropDown.id == element.id) {
          this.shared.settingId = element.id;
          this.shared.alterSettingId = element.id;
          element.select = true;
        }
      });
      const ids = this.shared.preferenceSaveSettings.map(o => o.id)
      const filtered = this.shared.preferenceSaveSettings.filter(({ id }, index) => !ids.includes(id, index + 1))
      this.shared.preferenceSaveSettings = filtered;
    }, (err) => {
      apiError(err);
    })
  }

  getSavedSetting(savedData){
    this.shared.preferenceSaveSettings = [];
    if(savedData == null || savedData == undefined ){
      this.shared.selectedDropDown = { 
        name: "Save Settings",
        id: null 
      };
    }
    if (savedData.length >= 1) {
      savedData.forEach(element => {
        this.shared.preferenceSaveSettings.push({ settingName: element.Name, id: element.Id, select: false, enableEdit: false, });
        if(!this.shared.savedSettings.includes(element.Id)){
          this.shared.savedSettings.push({ name: element.Name, id: element.Id });
        }
      });
      if(this.shared.selectedDropDown.name == "Save Settings"){
        this.getSettingData(this.shared.preferenceSaveSettings[0], 0);
      }
      this.shared.tempSelectedSetting = this.shared.selectedDropDown.name;
    } else {
      this.shared.preferenceSaveSettings = [];
    }
    this.shared.tempSetting.forEach((ele) => {
      this.shared.preferenceSaveSettings.push(ele)
    });
    this.shared.preferenceSaveSettings.forEach(element => {
      if (this.shared.selectedDropDown.name == element.settingName && this.shared.selectedDropDown.id == element.id) {
        this.shared.settingId = element.id;
        if(!this.shared.alterSettingId){
          this.shared.alterSettingId = element.id;
        }
        element.select = true;
      }
    });
    const ids = this.shared.preferenceSaveSettings.map(o => o.id)
    const filtered = this.shared.preferenceSaveSettings.filter(({ id }, index) => !ids.includes(id, index + 1))
    this.shared.preferenceSaveSettings = filtered;
  }

  removeUnderscore(item) {
    if(item){
      const value = item.replace(/_/g, " ");
      return value;
    }
  }

  selectCategoryId() {
    const cate = localStorage.getItem('chartCategory');
    if (cate != null && cate != undefined) {
      this.selectedItems = cate;
      this.shared.marketUniverData.forEach((ele)=>{
        if(ele.name == cate){
          ele.checked = true;
        } else{
          ele.checked = false;
        }
      })
    } else {
      this.shared.marketUniverData.forEach(element => {
        if (element.name == "Market Universe") {
          element.checked = true;
        }
      });
    }
  }


  ngAfterContentChecked() {

  }

  cancel() {
    this.shared.selectedFunds = [];
    this.onLoad();
    this.fundService.getMyAllFunds(this.userID);
  }

  subCatDeatil(event, opt) {
    this.shared.selectedOptionData = event.value;
    document.getElementById("myDropdown").classList.toggle("hide");
    this.shared.showDropDownPreferences = false;
    this.shared.defaultPreferences = false;
    this.shared.selectedOptPreferences = null;
    this.shared.activeSubCat = null;
    this.shared.selectedOptPreferences = event.name;
    this.shared.activeSubCat = event.name;

    if (event.name == "My Funds") {
      this.myFunds(event.name);
    }

    event.select = true;
    Preference.preferenceData.forEach(element => {
      if (element.title == "FUND SETTINGS") {
        element.sideOption.forEach(element => {
          if (element.optName == opt.optName) {
            element.select = true;
          } else {
            element.select = false;
          }
        });
      }
      else if (element.title == "MY FUNDS") {
        element.sideOption.forEach(element => {
          if (element.optName == opt.optName) {
            element.select = true;
          } else {
            element.select = false;
          }
        });
      }
    });
  }

  choosedDate(event) {
  }

  openDatepicker() {
  }

  selectSubCate(opt) {
    this.shared.showDropDownPreferences = false;
    document.getElementById("myDropdown").classList.toggle("hide");
    this.shared.defaultPreferences = false;
    opt.select = !opt.select;
    Preference.preferenceData.forEach(element => {
      if (element.title == "FUND SETTINGS") {
        element.sideOption.forEach(element => {
          if (element.optName == opt.optName) {
            element.select = true;
          } else {
            element.select = false;
          }
        });
      }
    });
  }

  reset() {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "preferences_clear_all_filter",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
   if(!Commons.settingCategory){
    this.shared.selectedOptionData.forEach((ele) => {
      ele.checked = false;
    })
   }else{
    this.shared.selectedOptionData.forEach((ele) => {
      if (ele.name == Commons.settingCategory) {
        ele.checked = true;
      } else {
        ele.checked = false;
      }
    })
   }
   Commons.geographyData = [];
   this.shared.geographySale.forEach((ele)=>{
    ele.checked = false;
  })
  
  this.shared.selectedIncludeESG.forEach(ele => {
    ele.name.forEach(element => {
      element.checked = false;
    });
  })
  this.shared.selectedExcludeESG.forEach(ele => {
    ele.name.forEach(element => {
      element.checked = false;
    });
  })

    this.shared.defaultPreferences = true;
    this.shared.selectedOptPreferences = "";
    this.shared.activeSubCat = null;
    this.shared.selectedOptPreferences = null;
    Preference.preferenceData.forEach(element => {
      element.sideOption.forEach(ele => {
        ele.select = false;
      });
    })
    // this.getAllfunds();
    this.fundService.getMyAllFunds(this.userID);
  }


  validate(items) {
    if (items.length > 20) {
      return items;
    } else {
      items.tooltip = false;
      return items;
    }
  }

  disableCurrency = false;
  goToDetail(opt, item) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": opt.optName,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if(opt.optName == "Display Currency"){
      opt.value.forEach(element => {
        if(element.name == "EUR"){
          this.disableCurrency = false;
        }else{
         this.disableCurrency = true;
        }
      });
    }
    if (opt.value) {
      this.shared.selectedOptionData = opt.value;
    }
    if(opt.optName == "Market Universe"){
      this.shared.selectedOptionData =  this.shared.marketUniverData;
    }
    if (opt.optName == "ESG") {
      this.shared.esgAllData = opt.data;
      this.shared.esgAllData.forEach(element => {
        if(element.heading == "Included"){
          this.shared.selectedIncludeESG.push({type: element.heading, name:element.value, id:''});
        }
       if(element.name == "Excluded"){
         this.shared.selectedExcludeESG.push({type: element.name, name:element.value, id:''});
       }
      });
      this.shared.selectedIncludeESG[0].name.sort((a, b) => (a.name < b.name ? -1 : 1))
      this.shared.selectedExcludeESG[0].name.sort((a, b) => (a.name < b.name ? -1 : 1))
    }
    if(opt.optName == "Assets Class"){
      this.shared.selectedAssteClass = "Equity";
    }
    if(opt.optName == "Rebates"){
     this.shared.myAllFundIs.forEach(ele => {
      this.shared.rebatesData.push({"Id":'', "Name":ele.name.Name, "Value":0});
     })
    }
    if(opt.optName != "Rebates"){
      this.shared.rebatesData = [];
      this.shared.rebatesData.push({"Id":'', "Name":"", "Value":0})
    }

    this.shared.searchMyFundPreferences = "";
    this.shared.showDropDownPreferences;
    document.getElementById("myDropdown").classList.toggle("hide");
    this.shared.defaultPreferences = false;
    this.shared.activeSubCat = null;
    if (opt.optName) {
      this.shared.selectedOptPreferences = opt.optName;
    }
    Preference.preferenceData.forEach(element => {
      if (element.title == "FUND SETTINGS") {
        element.sideOption.forEach(element => {
          if (element.optName == opt.optName) {
            element.select = true;
          } else {
            element.select = false;
          }
        });
      }
      else if (element.title == "MY FUNDS") {
        element.sideOption.forEach(element => {
          if (element.optName == opt.optName) {
            element.select = true;
          } else {
            element.select = false;
          }
        });
      }
    });

  }

  selectedHeader(event, value) {
  }
  

  selectInclude(event, type) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.name,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    event.checked = !event.checked;
    if(event.checked){
      this.shared.esgDataPreferences.push({"Id": this.shared.selectedSettingId ? this.shared.selectedSettingId : '', "Name":event.name, "Type":type, value: event.value});
    } else{
      const index = this.shared.esgDataPreferences.findIndex(item => item.Name  === event.name); 
    this.shared.esgDataPreferences.splice(index, 1);  
    }
  }

  selectExclude(event, type) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.name,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    event.checked = !event.checked;
    if(event.checked){
      this.shared.esgDataPreferences.push({"Id": this.shared.selectedSettingId ? this.shared.selectedSettingId : '', "Name":event.name, "Type": type, value: event.value});
    }else{
      const index = this.shared.esgDataPreferences.findIndex(item => item.Name  === event.name); 
      this.shared.esgDataPreferences.splice(index, 1);  
    }
  }

  myFunds(category: string) {

  }

  applyPreference(category){
    //document.getElementById("cancel-button").click();
    this.selectedItems = category;
    this.selectedCatYear = localStorage.getItem('year');
    if (this.shared.selectedDropDown.name == "Save Settings") {
      this.shared.selectedDropDown.name = null;
    }
    this.shared.marketUniverseDataChartFilter.forEach((ele) => {
      if (ele.name == this.selectedItems) {
        ele.checked = true;
      } else {
        ele.checked = false;
      }
    })
    if (this.shared.isNewCategoryPreferences == undefined) {
      this.shared.isNewCategoryPreferences = true;
    } else {
      this.shared.isNewCategoryPreferences = false;
    }
    this.shared.alertSetupObservable.next();
    this.common.getTopFundDataWithCategory(category, this.selectedCatYear, this.totalyear, null, true, this.datePipe.transform(localStorage.getItem('date'), 'yyyy-MM-dd'), this.shared.esgDataPreferences);
    this.common.getActivePasive(category, this.selectedCatYear, this.shared.isNewCategoryPreferences, this.datePipe.transform(localStorage.getItem('date'), 'yyyy-MM-dd'));
    this.shared.growthChartLoading = true;
    const bestPassives = JSON.parse(localStorage.getItem('bestPassive'));
    if (bestPassives.length == 0) {
      if (localStorage.getItem('time')) {
        this.growthChartService.getGrowthData(this.userID, this.selectedItems, this.datePipe.transform(localStorage.getItem('date'), 'yyyy-MM-dd'));
      } else if (localStorage.getItem('startDate')) {
        this.growthChartService.getGrowthDataDates(this.userID, this.selectedItems, this.datePipe.transform(localStorage.getItem('date'), 'yyyy-MM-dd'));
      }
    }
    
    
      // this.prefrenceSetting.getGeographyOfSale(this.common.fundsShareClassId, this.common.geographyData).subscribe(res => {
      //   // this.common.isShowTopFundLoading = false;
      //   this.common.geographyCountries = [];
      //   res["data"].forEach(ele => {
      //     this.common.geographyFunds.push(ele.ShareClass_Id);
      //     this.common.geographyCountries.push(ele);
      //   })
      // });
    this.onLoad();
  }

  closeWindow(category){
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "preferences_no",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    document.getElementById("close-popup").click();
    document.getElementById("cancel-button").click();
  //  this.shared.selectedOptionData.forEach((ele) => {
  //     if (ele.name == category) {
  //       ele.checked = true;
  //     } else {
  //       ele.checked = false;
  //     }
  //   })
    this.applyPreference(category);
  }

  applyChanges(category){
    const newArray: any = [];
    this.selectedItems = category;
    this.shared.isNewCategoryPreferences = JSON.parse(localStorage.getItem('IsNew'));
    if (this.shared.isNewCategoryPreferences == undefined) {
      this.shared.isNewCategoryPreferences = true;
    } else {
      this.shared.isNewCategoryPreferences = false;
    }
    if (this.SelectedFund.length > 0) {
      const model = {
        data: this.SelectedFund,
      };
      // this.prefrenceSetting.saveFunds(this.userID, model).subscribe((res) => {
      //   this.fundService.getMyAllFunds(this.userID);
      // }, (err)=>{
      //   apiError(err);
      // })
    }
    this.selectedCatYear = localStorage.getItem('year');

    this.shared.alertSetupObservable.next();
    this.common.getTopFundDataWithCategory(category, this.selectedCatYear, this.totalyear, null, true, this.datePipe.transform(localStorage.getItem('date'), 'yyyy-MM-dd'), this.shared.esgDataPreferences);
    this.common.getActivePasive(category, this.selectedCatYear, this.shared.isNewCategoryPreferences, this.datePipe.transform(localStorage.getItem('date'), 'yyyy-MM-dd'));
    if (localStorage.getItem('time')) {
      this.growthChartService.getGrowthData(this.userID, this.selectedItems, this.datePipe.transform(localStorage.getItem('date'), 'yyyy-MM-dd'));
    } else if (localStorage.getItem('startDate')) {
      this.growthChartService.getGrowthDataDates(this.userID, this.selectedItems, this.datePipe.transform(localStorage.getItem('date'), 'yyyy-MM-dd'));
    }
  } 

  saveSettingData(category){
    this.shared.tempSetting = [];
    document.getElementById("close-preference-popup").click();
    this.selectedItems = category;
    this.shared.isNewCategoryPreferences = JSON.parse(localStorage.getItem('IsNew'));
    // if (this.SelectedFund.length > 0) {
    //   const model = {
    //     data: this.SelectedFund,
    //   };
    //   this.prefrenceSetting.saveFunds(this.userID, model).subscribe((res) => {
    //     this.fundService.getMyAllFunds(this.userID);
    //   }, (err)=>{
    //     apiError(err);
    //   })
    // }else{
    //   this.fundService.getMyAllFunds(this.userID);
    // }
    const data = [];
    this.esgGetData = this.shared.esgDataPreferences;



    const modal = {
        "Id": this.shared.selectedSettingId ? this.shared.selectedSettingId : "",
        "UserId": this.userID,
        "Name": this.shared.selectedDropDown.name,
        "Market_Universe": this.selectedItems,
        "Geography": Commons.geographyData,
        "Currency": this.shared.selectedCurrency,
        "Assets_Class": this.shared.selectedAssteClass,
        "Esg": this.shared.esgDataPreferences,
        "Rebate": this.shared.rebatesData,
        "isSelected": false,
        "Funds": this.SelectedFund
      }
    this.prefrenceSetting.saveSetting(modal).subscribe((res) => {
      if(res["message"] == "Settings Saved Successfully!"){
        setTimeout(() => {
          this.fundService.getMyAllFunds(this.userID);
        }, 2000);
      }
      if (this.shared.selectedDropDown.name == "Save Settings") {
        this.shared.selectedDropDown = {
          name: "Save Settings",
            id: null
        };
      }
      this.getSavedSettings();
      this.prefrenceSetting.getSettingById(this.userID, this.shared.selectedSettingId).subscribe((res) => {
        this.shared.selectedSettingData = res["data"];
      })
    }, (err) => {
      this.shared.selectedDropDown = {
        name: "Save Settings",
          id: null
      };
      apiError(err);
    })
    this.onLoad();
  }

  apply(category) {
  }

  applyChange(category) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "preferences_yes",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if (this.shared.selectedDropDown.name === "Save Settings" || !this.shared.selectedDropDown.name || this.shared.selectedDropDown.name == null || this.shared.selectedDropDown.name == undefined) {
      document.getElementById("close-popup").click();
      if (this.shared.preferenceSaveSettings.length >= 1) {
        this.errMsg = "Please select setting";
        setTimeout(() => {
          this.errMsg = "";
        }, 2000)
      } else {
        this.errMsg = "Please add new setting";
        setTimeout(() => {
          this.errMsg = "";
        }, 2000)
      }
      this.showDrop();
    }
    else {
      this.shared.myFundLoading = true;
    this.shared.myFundSpinner = true;
    this.shared.myFundDataAvailable = false;
    this.saveSettingData(this.selectedItems);
      this.applyPreference(category);
      
      this.getSavedSetting(this.shared.getAllSettingsData);
    }
    this.shared.myFundsPreferencesObservable.next(this.shared.selectedDataPreferences);
  }

  esgGetData:any = [] = [];
  openPermmission(){
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "preferences_apply",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    currentDate();
    this.shared.alterSettingId = this.shared.settingId;
    this.shared.changeDataChartFilter.next();
    if(this.shared.tempSelectedSetting != this.shared.selectedDropDown.name){
      document.getElementById("save-setting-popup").click();
    } 
    else if(this.shared.tempSelectedSetting == this.shared.selectedDropDown.name){
      const data = [];
      const modal = {
        //{
          "Id": this.shared.selectedSettingId ? this.shared.selectedSettingId : "",
          "UserId": this.userID,
          "Name": this.shared.selectedDropDown.name,
          "Market_Universe": this.selectedItems,
          "Geography": Commons.geographyData,
          "Currency": this.shared.selectedCurrency,
          "Assets_Class": this.shared.selectedAssteClass,
          "Esg": this.esgGetData,
          "Rebate": this.shared.rebatesData,
          "Region_Of_Sale": this.shared.regionOfSale
      }
      let change = false;
      if(this.shared.selectedSettingData){
        this.shared.selectedSettingData.map(element => {
          let countryData:any[]=[];
          countryData = [];
          countryData = JSON.parse(element.Country);
          if(modal.Region_Of_Sale){
            change = true;
            return;
          }
          if(modal.Name == element.Name){
          } else{
            change = true;
            return;
          }
          if(modal.Market_Universe == element.Market_Universe){
          } else{
            change = true;
            return;
          }
          if(modal.Currency == element.Currency){
          } else{
            change = true;
            return;
          }
          if(countryData.length == Commons.geographyData.length){
          } else{
            change = true;
            return;
          }
          if(element.esg.length == this.shared.esgDataPreferences.length){
          } else{
            change = true;
            return;
          }
          if(this.SelectedFund.length > 0){
            change = true;
            return;
          }
      });
      if(change){
        document.getElementById("save-setting-popup").click();
      } else{
        document.getElementById("close-preference-popup").click();
        this.shared.myFundsPreferencesObservable.next(this.shared.selectedDataPreferences);
      }
    } else{
      document.getElementById("save-setting-popup").click();
    }
  }
  Commons.preferenceSelectedDataESG = [];
  this.shared.esgDataPreferences.forEach(element => {
    Commons.preferenceSelectedDataESG.push(element.Name);
  });
  Commons.changeSettingObs.next();
}

  closePopup() {
    document.getElementById("close-popup").click();
    document.getElementById("cancel-button").click();
  }

  SelectedFund = [];
  notAllowed = false;
  prefrenceLoader = false;
  search(event) {
    const newArray: any = [];
    this.shared.dropdownList = [];
    this.prefrenceLoader = true;
    if(this.checkSearchRequestStart){
      this.checkSearchRequestStart.unsubscribe();
    }
    this.checkSearchRequestStart = this.fundService.searchFund(event).subscribe((res) => {
      res.forEach(element => {
        if(this.shared.dropdownList.length > 0 && this.shared.dropdownList.some(item => item.id == element.id)){
        }else{
          this.shared.dropdownList.push(element);
        }
      });
      this.shared.dropdownList.forEach((res) => {
        res.checked = false;
      })
      this.shared.dropdownList.forEach((ele) => {
        this.shared.myAllFundIs.forEach((res) => {
          if (ele.Name == res.name.Name) {
            ele.checked = true;
            newArray.push(ele)
          }
        })
      })
      this.prefrenceLoader = false;
    }, (err) => {
      this.prefrenceLoader = false;
      apiError(err);
    });
  }

  selectCurrency(item) {
    this.shared.selectedCurrency = item.name;
    this.shared.savedCurrency = item.name;
    this.shared.getCurrency = item.name;
     item.checked = !item.checked;
  }

  selectRegionOfSale(data, event) {
    this.shared.regionOfSale = event.name;
     data.forEach(ele => {
       if(ele.name == event.name){
        ele.checked = true;
       }else{
        ele.checked = false;
       }
     });
  }

  selectRegion(item) {
    item.checked = !item.checked;
  }

  getInvestMentArea(event) {
    event.checked = !event.checked;
  }

  getCountryData(event) {
    event.checked = !event.checked;
  }

  closeSaveSetting(){
    if(this.showDropDownAddSetting){
      setTimeout(() => {
        this.shared.showAnimationPreferences = false;
        setTimeout(() => {
          this.showDropDownAddSetting = false;
        }, 500)
      }, 10);
    }
  }

  selectedItem: any = [];
  savedDrop(event, index) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.settingName,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.settingId = event.id;
    if(this.showDropDownAddSetting){
      setTimeout(() => {
        this.shared.showAnimationPreferences = false;
        setTimeout(() => {
          this.showDropDownAddSetting = false;
        }, 500)
      }, 10);
    }
    this.getMyAllFunds(this.shared.selectedDataPreferences)
    this.getSettingData(event, index)
  }


  getSettingData(event, index) {
    this.esgGetData = [];
    Commons.preferenceSelectedDataESG = [];
    this.shared.previousSavedSetting = this.shared.selectedDropDown;
    this.shared.selectedDropDown = { 
      name: event.settingName,
      id: event.id ? event.id : null
    };
    this.shared.selectedPreSetting = event.settingName;
    event.select = true;
    this.shared.selectedSettingId = event.id;
    this.shared.preferenceSaveSettings.forEach((ele) => {
      if (ele.settingName == event.settingName && ele.id == event.id) {
        this.shared.settingId = ele.id;
        ele.select = true;
      } else {
        ele.select = false;
      }
    })
    this.closeSaveSetting();
    this.shared.selectedDropDown = { 
      name: event.settingName,
        id: event.id ? event.id : null 
    };
    this.prefrenceSetting.getSettingById(this.userID, event.id).subscribe((res) => {
      Commons.geographyData = [];
      this.shared.selectedSettingData = [];
      const data = res["data"];
      this.shared.selectedSettingData = res["data"];
      this.selectedItems = data ? data[0].Market_Universe : this.selectedItems;
      Commons.settingCategory = data ? data[0].Market_Universe : Commons.settingCategory;
      Commons.geographyData = data ?  JSON.parse(data[0].Country) : [];
      this.shared.marketUniverseDataChartFilter.forEach((ele)=>{
        if(ele.name == this.selectedItems){
          ele.checked = true;
        } else{
          ele.checked = false;
        }
      });
      this.geographySale.forEach((ele)=>{
        if(Commons.geographyData.includes(ele.name)){
          ele.checked = true;
        } else{
          ele.checked = false;
        }
      });
      this.shared.marketUniverData.forEach((ele)=>{
        if(ele.name == this.selectedItems){
          ele.checked = true;
        } else{
          ele.checked = false;
        }
      });
      // this.geographySale.map(a=>a.checked=false);
      // this.geographyData = [];
      // this.geographySale.forEach((ele)=>{
      //   const value = data[0].Country.includes(ele.name);
      //   if(value){
      //     ele.checked = true;
      //     this.geographyData.push(ele.name);
      //   }
      // })
      Preference.preferenceData.forEach(element => {
        element.sideOption.forEach(ele => {
          if (ele.optName == "Assets Class") {
            ele.value.forEach(asset => {
              if (asset.name == data[0].Assets_Class) {
                asset.checked = true;
              } else {
                asset.checked = false;
              }
            });
          }
          if (ele.optName == "Display Currency") {
            ele.value.forEach(asset => {
              if (asset.name == data[0].Currency) {
                asset.checked = true;
              } else {
                asset.checked = false;
              }
            });
          }
          if (ele.optName == "ESG"){
            ele.data[0].value.map(a=>a.checked=false);
            ele.data[1].value.map(a=>a.checked=false);
            this.shared.esgDataPreferences = [];
            this.esgGetData = data[0].esg;
            
            ele.data.forEach(asset => {
              data[0].esg.forEach(element => {
                    asset.value.forEach(e => {
                      if(e.name == element.Name){
                        e.checked = true;
                        // this.esgData.push({"Id":element.Id, "Name":element.Name, "Type":element.Type});
                        this.shared.esgDataPreferences.push({"Id":element.Id, "Name":element.Name, "Type":element.Type, value: element.Value});
                        if(!Commons.preferenceSelectedDataESG.includes(element.Value)){
                          Commons.preferenceSelectedDataESG.push(element.Value);
                        }
                      }
                    });
              });
            });

          }
        });
      });
      Commons.changeSettingObs.next();
    }, (err) => {

    })
  }

}
