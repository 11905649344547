import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class NewsFundsService {

  constructor(public http: HttpClient) { }

  getNewsForFund(fundId){
    return this.http.get(environment.baseUrl + 'getNewsForFund/' + fundId);
  }
}
