import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MnFullpageService } from "ngx-fullpage";
import { Router } from "@angular/router";
import { FundsDataService } from 'src/services/ChartServices/funds-data.service';
import { decode } from "string-encode-decode";
import { GoogleAnalyticsService } from 'src/services/google-Analytics/google-analytics.service';
import { Commons } from 'src/utils/common';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // @Input() loggedIn:boolean = false;
  loggedIn = false;
  isLoginPressed=false;
  isMenuPressed=false;

  userID:any;
  @Input() loginSelected:boolean;
  @Output() goToLoginScreen = new EventEmitter<string>();
  constructor( public fullpageService: MnFullpageService,public router: Router, public fundService:FundsDataService,
    public googleAnalyticsService: GoogleAnalyticsService ) { }

  ngOnInit(): void {
  this.userID = decode(localStorage.getItem("ID"));
  const user = localStorage.getItem('userId');
  if(user){
    this.loggedIn = true;
  } else{
    this.loggedIn = false;
  }
  }

  logout(){
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "logout",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsService.webWorkerAddEvent(modal);
    //localStorage.setItem("login", '0');
    localStorage.removeItem("userId");
    // localStorage.setItem('userId', '123');
  }

  goToUserLogin(){
    this.goToLoginScreen.emit();
  }

  methodology(){
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "methodology",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsService.webWorkerAddEvent(modal);
  }

  getAllfunds(){
    // this.getAllfund.next();
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "preferences",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsService.webWorkerAddEvent(modal);
    this.fundService.getFunds();
  }

  alertSetup(){
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "alert_setup",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsService.webWorkerAddEvent(modal);
  }

  profile(){
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "profile",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsService.webWorkerAddEvent(modal);
  }

  selected(event){
    if(event == 'login'){
      this.isLoginPressed = true;
      this.isMenuPressed = false;
    } 
    if(event == 'menu'){
      let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "menu",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsService.webWorkerAddEvent(modal);
      this.isMenuPressed = true;
      this.isLoginPressed = false;
    }
    // isLoginPressed
  }

  clearOldDataOfContactUsModel(){
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "contact_us",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsService.webWorkerAddEvent(modal);
  }

  goToLink(event){
  }

  feedbackLink(){
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "feedback",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsService.webWorkerAddEvent(modal);
    window.open("https://0vkux83kk8p.typeform.com/to/Ezhj6dWq?");
  }

  userGuideLink(){
    window.open("https://storage.googleapis.com/fundsaiq-fundsdocuments/userguide/Fundsaiq_userguide.pdf");
  }

  termsAndCon(){
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "terms_and_conditions",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsService.webWorkerAddEvent(modal);
  }

  goToLogin(){
    this.loginSelected = true;
    this.router.navigateByUrl("/user-dashboard");
  }

  ClickedOut(event){
    if(event.target.className == "dropdown-menu side-links"){
      document.getElementById("close-menu").click();
      this.isMenuPressed = false;
    }
  }

  goToHome(){
    this.isLoginPressed = false;
    this.isMenuPressed = false;
    this.fullpageService.moveTo(1,1)
  }

  goToHomeAbout(){
    this.isLoginPressed = false;
    this.isMenuPressed = false;
    this.fullpageService.moveTo(3,3);
  }
  
}
