import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Cacheable } from 'ngx-cacheable';
// import { environment } from '../../environment';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class VerfyAuthCodeService {

  constructor(public http: HttpClient,) { }

  // @Cacheable()
  verifyCode(user){
   return this.http.post(environment.baseUrl + 'verifycode' ,user, httpOptions)
  }
}
