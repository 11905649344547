import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss']
})
export class TermsConditionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  ClickedOut(event){
    if(event.target.className == "pg-form-pages login-page"){
      document.getElementById("close-terms").click();
    }
  }

}
