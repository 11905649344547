
export const constants: any = {
  paths: {
    login: 'login', full: "full", blank: "",
    activePassive: "active-component",
    forgetPassword: "forget-component", reset: "reset-component",
    verifyMail: "verifymail-component", preference: "prefrences",
    methodology: "methedology", userDashboard: "user-dashboard",
    resetPassword: "resetpassword", changePassword: "changePassword"
  }
}

export const AlertsData: any = {
  sideOPtions: [
    { name: "Fund", select: false },
    { name: "News", select: false },
    { name: "Marketing", select: false },
    { name: "Channel", select: false },
  ],
  topFunds: [
    { name: "New Top Fund", select: false },
    { name: "EquityFlex Y", select: false },
    { name: "MS INVF US Insight A", select: false },
    { name: "Ninety One American franchise A AccE", select: false },
    { name: "PineBridge US Large Cap RsrchEnh C", select: false },
    { name: "Protea BAM US Equities R USD Acc", select: false },
    { name: "Allianz Invest Nachhaltigkeltsfds T", select: false },
  ],
  myFunds: [
    { name: "Atlantas SICAV Multi World", select: false },
    { name: "BPI Aceos Mundlais FlAA", select: false },
    { name: "BPI GIF Opportunities R", select: false },
    { name: "DPAM INVEST B Equities World Sust B Cap", select: false },
    { name: "Erient hiutabrefasain IV", select: false },
  ],
  MSUnight: [
    { name: "Fund Closure", select: false },
    { name: "Fund Manager Change", select: false },
    { name: "Changes in Funds Composition", select: false },
    { name: "Fund Flows", select: false },
    { name: "Fund Name Change", select: false },
    { name: "Re-classification of Funds", select: false },
    { name: "ESG", select: false },
  ],
  MyFundsMsUnight: [
    { name: "Fund Closure", select: false },
    { name: "Fund Manager Change", select: false },
    { name: "Changes in Funds Composition", select: false },
    { name: "Fund Flows", select: false },
    { name: "Fund Name Change", select: false },
    { name: "Re-classification of Funds", select: false },
    { name: "ESG", select: false },
  ],
  category: [
    { name: "General News", select: false },
    { name: "Fund Manager News", select: false },
    { name: "ESG", select: false },
    { name: "Social Media", select: false },
  ],

  marketData: [
    { name: "Marketing", select: false },
    { name: "Surveys", select: false },
    { name: "New Feature", select: false },
    { name: "Other", select: false },
  ],
  ChannelData: [
    { name: "Email", select: false, disable: false },
    // { name: "SMS (Text Message)", select: false },
    { name: "Desktop", select: false, disable: false },
  ],
  marketUniverseData: [
    { name: "ASIA_Mid_Blend_", checked: false, isNew: true },
    { name: "COMMUNICATION SERVICES", checked: false, isNew: true },
    { name: "CONSUMER DISCRETIONARY", checked: false, isNew: true },
    { name: "EASTERN ASIA", checked: false, isNew: true },
    { name: "EASTERN EUROPE", checked: false, isNew: true },
    { name: "EMERGINGMARKETS", checked: false, isNew: true },
    { name: "ENERGY", checked: false, isNew: true },
    { name: "EUROPE_Mid_Blend_", checked: false, isNew: true },
    { name: "FRANCE_Mid_Blend_", checked: false, isNew: true },
    { name: "GERMANY_Mid_Value_", checked: false, isNew: true },
    { name: "GLOBAL_Mid_Blend_", checked: false, isNew: true },
    { name: "GLOBAL_Mid_Value_", checked: false, isNew: true },
    { name: "HEALTH CARE", checked: false, isNew: true },
    { name: "INFORMATION TECHNOLOGY", checked: false, isNew: true },
    { name: "JAPAN_Mid_Blend_", checked: false, isNew: true },
    { name: "MATERIALS", checked: false, isNew: true },
    { name: "MEXICO_Mid_Blend_", checked: false, isNew: true },
    { name: "NORWAY_Mid_Value_", checked: false, isNew: true },
    { name: "OCEANIA", checked: false, isNew: true },
    { name: "Other", checked: false, isNew: true },
    { name: "SOUTH AMERICA", checked: false, isNew: true },
    { name: "SWEDEN_Mid_Blend_", checked: false, isNew: true },
    { name: "UNITED KINGDOM_Mid_Blend_", checked: false, isNew: true },
    { name: "UNITED KINGDOM_Mid_Value_", checked: false, isNew: true },
    { name: "UNITED STATES_Mid_Blend_", checked: false, isNew: true },
    { name: "UNITED STATES_Mid_Growth_", checked: false, isNew: true },
    { name: "UNITED STATES_Small_Value_", checked: false, isNew: true },
    { name: "WESTERN EUROPE", checked: false, isNew: true },
  ],
  fundTopFundAlerts: [
    { name: "Fund Closure", select: false },
    { name: "Fund Manager Change", select: false },
    { name: "Changes in Funds Composition", select: false },
    { name: "Fund Flows", select: false },
    { name: "Fund Name Change", select: false },
    { name: "Re-classification of Funds", select: false },
    { name: "ESG", select: false },
  ],
  fundMFundAlerts: [
    { name: "General News", select: false },
    { name: "Fund Manager News", select: false },
    { name: "ESG", select: false },
    { name: "Social Media", select: false },
  ],
  newsTopFundAlerts: [
    { name: "Fund Closure", select: false },
    { name: "Fund Manager Change", select: false },
    { name: "Changes in Funds Composition", select: false },
    { name: "Fund Flows", select: false },
    { name: "Fund Name Change", select: false },
    { name: "Re-classification of Funds", select: false },
    { name: "ESG", select: false },
  ],
  newsMyFundAlerts: [
    { name: "General News", select: false },
    { name: "Fund Manager News", select: false },
    { name: "ESG", select: false },
    { name: "Social Media", select: false },
  ],
}

export const ProfileData: any = {
  sideOptions: [
    { name: "User", select: false },
    { name: "Security", select: false },
    { name: "Organisation", select: false }
  ],
  userOptions: [
    { value: "", placeholder: "Username", name: "userName", select: false, errMsg: "", type: "text" },
    { value: "", placeholder: "First Name", name: "firstName", select: false, errMsg: "", type: "text" },
    { value: "", placeholder: "Surname", name: "surName", select: false, errMsg: "", type: "text" },
    { value: "", placeholder: "Email Primary", name: "emailPrimary", select: false, errMsg: "", type: "email" },
    { value: "", placeholder: "Email Secondary", name: "emailSecondary", select: false, errMsg: "", type: "email" },
    { value: "", placeholder: "Mobile", name: "mobile", select: false, errMsg: "", type: "text" },
  ],
  security: [
    { value: "", placeholder: "Verify current password", name: "currentPassword", select: false, errMsg: "", type: "password" },
    { value: "", placeholder: "New password", name: "newPassword", select: false, errMsg: "", type: "password" },
    { value: "", placeholder: "Confirm new password", name: "confirmNewPassword", select: false, errMsg: "", type: "password" },
  ],
  organization: [
    { value: "", placeholder: "Company Name", name: "companyName", select: false, errMsg: "", type: "text" },
    { value: "", placeholder: "Role", name: "role", select: false, errMsg: "", type: "text" },
    { value: "", placeholder: "Location", name: "location", select: false, errMsg: "", type: "text" },
    { value: "", placeholder: "Investor Type", name: "investorType", select: false, errMsg: "", type: "text" },
    { value: "", placeholder: "Investment Preferences", name: "investmentPreference", select: false, errMsg: "", type: "text" },
    { value: "", placeholder: "Investment Style (Risk Appetite)", name: "investmentStyle", select: false, errMsg: "", type: "text" },
  ]
}

export const MarketNews: any = {
  title: "Market Insights",
  placeHolder: "Search Funds",
  filterTitle: "News Type - Filter",
  filterItems: [
    { name: "FUNDSaiQ Curated", select: false, disable: false },
    { name: "General Fund News", select: false, disable: false },
    { name: "Social Media", select: false, disable: true }
  ],
  newsTableHeadings: [
    { name: "Date" },
    { name: "News Type" },
    { name: "Headline" },
    { name: "Abstract" },
    { name: "Author" },
    { name: "Source" },
  ],
  marketNewsData: [
    { name: "FUNDSaiQ Curated", title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", curation: "Curation Corp", id: 1, showDatePicker: false },
    { name: "Social Media", title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", curation: "Curation Corp", id: 2, showDatePicker: false },
    { name: "General News", title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", curation: "Curation Corp", id: 3, showDatePicker: false },
    { name: "FUNDSaiQ Curated", title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", curation: "Curation Corp", id: 4, showDatePicker: false },
    { name: "Social Media", title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", curation: "Curation Corp", id: 5, showDatePicker: false },
  ]
}

export const AlertsAndNews: any = {
  title: "Alerts",
  placeHolder: "Search Alerts",
  filterTitle: "Alert Type Filter",
  filterItems: [
    { name: "Manager Change", select: false },
    { name: "Fund Size Change", select: false },
    { name: "Fund Closure", select: false }
  ],
  newsTableHeadings: [
    { name: "Date" },
    { name: "Alert Type" },
    { name: "Alert Details" },
    { name: "Fund Name" },
    { name: "Category" },
    // { name: "Insight" },
  ],
  alertNewsFeed: [
    { name: "Manager Change", title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", curation: "Curation Corp", id: 1, showDatePicker: false },
    { name: "Fund Closure", title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", curation: "Curation Corp", id: 2, showDatePicker: false },
    { name: "Fund Size Change", title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", curation: "Curation Corp", id: 3, showDatePicker: false },
    { name: "Manager Change", title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", curation: "Curation Corp", id: 4, showDatePicker: false },
    { name: "Fund Closure", title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.", curation: "Curation Corp", id: 5, showDatePicker: false },
  ]
}

export const Preference: any = {
  prefrenceOptions: [
    {
      name: "FUND SETTINGS",
      option: [{ optName: "Assets Class", select: false },
      { optName: "  categories: ['Jan', 'Feb', 'Mar', 'Apr', 'Jan', 'Feb', 'Mar', 'Apr', 'Jan', 'Feb', 'Mar', 'Apr',],", select: false },
      {
        optName: "Country of Sale",
        select: false,
        subcategory: [{ name: "Country of Sales" },
        { name: "Investment Area" }, { name: "Region of Sales" }]
      }, { optName: "Display Currency", select: false }, { optName: "ESG", select: false }, { name: "GBP", checked: false }],
      select: false
    },
    {
      name: "MY FUNDS", option: [{ optName: "Funds", select: false },
      { optName: "Rebates", select: false }], select: false
    },
  ],
  regionData: [
    { name: "Europe", select: false },
    { name: "EMEA", select: false },
    { name: "North America", select: false },
    { name: "Asia", select: false },
    { name: "Emerging Markets", select: false },
  ],
  assetClassData: [
    { name: "Equity", select: true },
    { name: "Fixed Income", select: false }
  ],
  checkboxData: [
    { head1: "Asia", checked: false, image: "../../../assets/images/expandb.svg", img1: "../../../assets/images/expandActiv.svg" },
    { name1: "Japan", checked: false },
    { name1: "Hong Kong", checked: false },
    { name1: "Singapore", checked: false },
    { head2: "Europe", checked: false, image: "../../../assets/images/expandb.svg", img2: "../../../assets/images/expandActiv.svg" },
    { name2: "Russia", checked: false },
    { name2: "Germany", checked: false },
    { head: "EMEA", checked: false, image: "../../../assets/images/expandb.svg", img: "../../../assets/images/expandActiv.svg" },
    { head3: "North America", checked: false, image: "../../../assets/images/expandb.svg", img3: "../../../assets/images/expandActiv.svg" },
    { name3: "Canada", checked: false },
    { name3: "Greenland", checked: false },
    { head: "Emerging Markets", checked: false, image: "../../../assets/images/expandb.svg", img: "../../../assets/images/expandActiv.svg" },
  ],
  countryCheckboxData: [
    { name: "France", checked: false },
    { name: "Hong Kong", checked: false },
    { name: "Japan", checked: false },
    { name: "Singapore", checked: false },
    { name: "United Arab Emirates", checked: false },
    { name: "United kingdom of Great Britain", checked: false },
    { name: "United States of America", checked: false },
  ],
  esgOptionsInclude: [
    { name: "Alternative Energy ", checked: false },
              { name: "Board Independence ", checked: false },
              { name: "Energy Efficient ", checked: false },
              { name: "Female Directors ", checked: false },
              { name: "Human Rights Compliance ", checked: false },
              { name: "Customer Controversies ", checked: false },
              // { name: "Pollution Prevention ", checked: false },
              { name: "UN Global Compact Compliance Violation ", checked: false },
    // { name: "Alternative Energy ", checked: false },
    // { name: "Energy Efficient ", checked: false },
    // // { name: "Pollution Prevention ", checked: false },
    // { name: "UN Global Compact Compliance Violation ", checked: false },
    //           { name: "Human Rights Compliance ", checked: false },
    //           { name: "Female Directors ", checked: false },
    // { name: "Board Independence ", checked: false },
  ],
  esgOptionExclude: [
    { name: "Severe Labor Controversies ", checked: false },
    { name: "Severe Human Rights and Community Controversies ", checked: false },
    // { name: "UN Global Compact Compliance Violation ", checked: false },
    // { name: "Human Rights Compliance ", checked: false },
    { name: "Adult Entertainment ", checked: false },
    { name: "Alcohol ", checked: false },
    { name: "Gambling ", checked: false },
    { name: "Genetic Engineering - GMO ", checked: false },
    { name: "Nuclear Power ", checked: false },
    { name: "Predatory Lending ", checked: false },
    { name: "Tobacco ", checked: false },
    { name: "Controversial Weapons ", checked: false },
    { name: "Weapons", checked: false },
    { name: "Fossil Fuel Reserves", checked: false },
    // { name: "Female Directors ", checked: false },
    { name: "Carbon Emissions", checked: false },
    { name: "Social Controversy ", checked: false },
  ],
  esgOptionsIncludeChart: [
    { name: "Alternative Energy ", checked: false, value: "ALTERNATIVE_ENERGY_NM" },
    { name: "Board Independence ", checked: false, value: "BOARD_INDEP_PCT" },
    { name: "Energy Efficient ", checked: false, value: "ENERGY_EFFICIENCY_NM" },
    { name: "Female Directors ", checked: false, value: "GENDER_DIVERSITY" },
    { name: "Human Rights Compliance ", checked: false, value: "HR_COMPLIANCE" },
    { name: "Customer Controversies ", checked: false, value: "CUSTOMER_CONTROVERSY_FLAG" },
    // { name: "Pollution Prevention ", checked: false },
    { name: "UN Global Compact Compliance Violation ", checked: false, value: "UNGC_COMPLIANCE" },
  ],
  esgOptionExcludeChart: [
    //{ name: "Severe Labor Controversies ", checked: false, value: "CONTR_SUPPLY_CHAIN_LABOR_N_SEVERE" },
    // { name: "Severe Human Rights and Community Controversies ", checked: false, value: "COMM_REL_CONFLICT_SCORE" },
    // { name: "UN Global Compact Compliance ", checked: false, value: "UNGC_COMPLIANCE" },
    // { name: "Human Rights Compliance ", checked: false, value: "HR_COMPLIANCE" },
    { name: "Adult Entertainment Ties ", checked: false, value: "AE_TIE" },
    { name: "Alcohol Ties ", checked: false, value: "ALC_TIE" },
    { name: "Gambling Ties", checked: false, value: "GAM_TIE" },
    { name: "GMO Ties ", checked: false, value: "GMO_TIE" },
    { name: "Nuclear Ties ", checked: false, value: "NUC_TIE" },
    { name: "Predatory Lending ", checked: false, value: "PRED_LEND_DIRECT" },
    { name: "Tobacco Ties ", checked: false, value: "TOB_TIE" },
    { name: "Controversial Weapons Ties ", checked: false, value: "CWEAP_TIE" },
    { name: "Weapons Manufacture Ties", checked: false, value: "WEAP_GTIE" },
    { name: "Fossil Fuel Reserve Links", checked: false, value: "FOSSIL_FUEL_RESERVES" },
    // { name: "Female Directors ", checked: false, value: "GENDER_DIVERSITY" },
    { name: "Carbon Emissions Score (0-10)", checked: false, value: "CARBON_EMISSIONS_SCORE" },
    { name: "Social Controversy Score (0-10) ", checked: false, value: "SOCIAL_CONTROVERSY_SCORE" },
    { name: "Customer Controversies ", checked: false, value: "CUSTOMER_CONTROVERSY_FLAG" },
  ],
  currencyData: [
    { name: "USD", checked: false },
    { name: "EUR", checked: false }
  ],

  marketBoxData: [
    { name: "Global Equity Large Cap", checked: false },
    { name: "Global Emerging Markets Equity", checked: false },
    { name: "US Equity Large Cap Blend", checked: false },
    { name: "Europe Equity Large Cap", checked: false },
    { name: "ESG Funds UK", checked: false },
    { name: "ESG Funds Europe", checked: false }
  ],
  settings: [
    {
      select: false, enableEdit: false,
      headings: [
        {
          title: "FUND SETTINGS",
          sideOption: [{
            optName: "Assets Class", select: false,
            value: [{ name: "Equity", checked: true },
            { name: "Multi-asset", checked: false },
            { name: "Fixed Income", checked: false }],
          },
          {
            optName: "Market Universe", select: false,
            value: [{ name: "Global Equity Large Cap", checked: false, isNew: false },
            { name: "Global Emerging Markets Equity", checked: false, isNew: false },
            { name: "US Equity Large Cap Blend", checked: false, isNew: false },
            { name: "Europe Equity Large Cap", checked: false, isNew: false },
            { name: "ESG Funds UK", checked: false, isNew: false },
            { name: "ESG Funds Europe", checked: false, isNew: false },
            { name: "ENERGY", checked: false, isNew: true },
            { name: "OCEANIA", checked: false, isNew: true },
            { name: "MATERIALS", checked: false, isNew: true },
            { name: "HEALTH CARE", checked: false, isNew: true },
            { name: "EASTERN ASIA", checked: false, isNew: true },
            { name: "SOUTH AMERICA", checked: false, isNew: true },
            { name: "EASTERN EUROPE", checked: false, isNew: true },
            { name: "WESTERN EUROPE", checked: false, isNew: true },
            { name: "ASIA_Mid_Blend_", checked: false, isNew: true },
            { name: "EMERGINGMARKETS", checked: false, isNew: true },
            { name: "JAPAN_Mid_Blend_", checked: false, isNew: true },
            { name: "EUROPE_Mid_Blend_", checked: false, isNew: true },
            { name: "FRANCE_Mid_Blend_", checked: false, isNew: true },
            { name: "SWEDEN_Mid_Blend_", checked: false, isNew: true },
            { name: "NORWAY_Mid_Value_", checked: false, isNew: true },
            { name: "GERMANY_Mid_Value_", checked: false, isNew: true },
            { name: "GLOBAL_Mid_Blend_", checked: false, isNew: true },
            { name: "GLOBAL_Mid_Value_", checked: false, isNew: true },
            { name: "MEXICO_Mid_Blend_", checked: false, isNew: true },
            { name: "COMMUNICATION SERVICES", checked: false, isNew: true },
            { name: "CONSUMER DISCRETIONARY", checked: false, isNew: true },
            { name: "INFORMATION TECHNOLOGY", checked: false, isNew: true },
            { name: "UNITED STATES_Mid_Blend_", checked: false, isNew: true },
            { name: "UNITED KINGDOM_Mid_Blend_", checked: false, isNew: true },
            { name: "UNITED KINGDOM_Mid_Value_", checked: false, isNew: true },
            { name: "UNITED STATES_Mid_Growth_", checked: false, isNew: true },
            { name: "UNITED STATES_Small_Value_", checked: false, isNew: true },
            { name: "Other", checked: false, isNew: true },
            ]

          },
          {
            optName: "Country of Sale", select: false,
          },
          {
            optName: "Display Currency", select: false, value: [{ name: "USD (coming soon)", checked: false },
            { name: "EUR", checked: false }, { name: "GBP (coming soon)", checked: false }]
          }, {
            optName: "ESG", select: false, data: [{
              heading: "Included",
              value: [{ name: "Alternative Energy ", checked: false, value: "ALTERNATIVE_ENERGY_NM" },
              { name: "Board Independence ", checked: false, value: "BOARD_INDEP_PCT" },
              { name: "Energy Efficient ", checked: false, value: "ENERGY_EFFICIENCY_NM" },
              { name: "Female Directors ", checked: false, value: "GENDER_DIVERSITY" },
              { name: "Human Rights Compliance ", checked: false, value: "HR_COMPLIANCE" },
              { name: "Customer Controversies ", checked: false, value: "CUSTOMER_CONTROVERSY_FLAG" },
              // { name: "Pollution Prevention ", checked: false },
              { name: "UN Global Compact Compliance Violation ", checked: false, value: "UNGC_COMPLIANCE" }, ]
            },
            {
              name: "Excluded", value: [
                //{ name: "Severe Labor Controversies ", checked: false, value: "CONTR_SUPPLY_CHAIN_LABOR_N_SEVERE" },
              // { name: "Severe Human Rights and Community Controversies ", checked: false, value: "COMM_REL_CONFLICT_SCORE" },
              // { name: "UN Global Compact Compliance Violation ", checked: false, value: "UNGC_COMPLIANCE" },
              // { name: "Human Rights Compliance ", checked: false, value: "HR_COMPLIANCE" },
              { name: "Adult Entertainment ", checked: false, value: "AE_TIE" },
              { name: "Alcohol ", checked: false, value: "ALC_TIE" },
              { name: "Gambling", checked: false, value: "GAM_TIE" },
              { name: "Genetic Engineering - GMO ", checked: false, value: "GMO_TIE" },
              { name: "Nuclear Power ", checked: false, value: "NUC_TIE" },
              { name: "Predatory Lending ", checked: false, value: "PRED_LEND_DIRECT" },
              { name: "Tobacco ", checked: false, value: "TOB_TIE" },
              { name: "Controversial Weapons ", checked: false, value: "CWEAP_TIE" },
              { name: "Weapons", checked: false, value: "WEAP_GTIE" },
              { name: "Fossil Fuel Reserves", checked: false, value: "FOSSIL_FUEL_RESERVES" },
              // { name: "Female Directors ", checked: false, value: "GENDER_DIVERSITY" },
              { name: "Carbon Emissions", checked: false, value: "CARBON_EMISSIONS_SCORE" },
              { name: "Social Controversy ", checked: false, value: "SOCIAL_CONTROVERSY_SCORE" },
              { name: "Customer Controversies ", checked: false, value: "CUSTOMER_CONTROVERSY_FLAG" },
              ]
            }
            ]
          }]
        },
        {
          title: "MY FUNDS", sideOption: [{ optName: "Funds", select: false },
          { optName: "Rebates", select: false }]
        },
      ]
    },
    {
      settingName: "Global", select: false, enableEdit: false,
      headings: [
        {
          title: "FUND SETTINGS",
          sideOption: [{
            optName: "Assets Class", select: false,
            value: [{ name: "Equity", checked: true },
            { name: "Multi-asset", checked: false },
            { name: "Fixed Income", checked: false }],
          },
          {
            optName: "Market Universe", select: false,
            value: [{ name: "Global Equity Large Cap", checked: false, isNew: false },
            { name: "Global Emerging Markets Equity", checked: false, isNew: false },
            { name: "US Equity Large Cap Blend", checked: false, isNew: false },
            { name: "Europe Equity Large Cap", checked: false, isNew: false },
            { name: "ESG Funds UK", checked: false, isNew: false },
            { name: "ESG Funds Europe", checked: false, isNew: false },
            { name: "ENERGY", checked: false, isNew: true },
            { name: "OCEANIA", checked: false, isNew: true },
            { name: "MATERIALS", checked: false, isNew: true },
            { name: "HEALTH CARE", checked: false, isNew: true },
            { name: "EASTERN ASIA", checked: false, isNew: true },
            { name: "SOUTH AMERICA", checked: false, isNew: true },
            { name: "EASTERN EUROPE", checked: false, isNew: true },
            { name: "WESTERN EUROPE", checked: false, isNew: true },
            { name: "ASIA_Mid_Blend_", checked: false, isNew: true },
            { name: "EMERGINGMARKETS", checked: false, isNew: true },
            { name: "JAPAN_Mid_Blend_", checked: false, isNew: true },
            { name: "EUROPE_Mid_Blend_", checked: false, isNew: true },
            { name: "FRANCE_Mid_Blend_", checked: false, isNew: true },
            { name: "SWEDEN_Mid_Blend_", checked: false, isNew: true },
            { name: "NORWAY_Mid_Value_", checked: false, isNew: true },
            { name: "GERMANY_Mid_Value_", checked: false, isNew: true },
            { name: "GLOBAL_Mid_Blend_", checked: false, isNew: true },
            { name: "GLOBAL_Mid_Value_", checked: false, isNew: true },
            { name: "MEXICO_Mid_Blend_", checked: false, isNew: true },
            { name: "COMMUNICATION SERVICES", checked: false, isNew: true },
            { name: "CONSUMER DISCRETIONARY", checked: false, isNew: true },
            { name: "INFORMATION TECHNOLOGY", checked: false, isNew: true },
            { name: "UNITED STATES_Mid_Blend_", checked: false, isNew: true },
            { name: "UNITED KINGDOM_Mid_Blend_", checked: false, isNew: true },
            { name: "UNITED KINGDOM_Mid_Value_", checked: false, isNew: true },
            { name: "UNITED STATES_Mid_Growth_", checked: false, isNew: true },
            { name: "UNITED STATES_Small_Value_", checked: false, isNew: true },
            { name: "Other", checked: false, isNew: true },
            ]
          },
          {
            optName: "Country of Sale", select: false,
          },
          {
            optName: "Display Currency", select: false, value: [{ name: "USD (coming soon)", checked: false },
            { name: "EUR", checked: false }, { name: "GBP (coming soon)", checked: false }]
          }, {
            optName: "ESG", select: false, data: [{
              name: "Included",
              value: [{ name: "Alternative Energy ", checked: false, value: "ALTERNATIVE_ENERGY_NM" },
              { name: "Board Independence ", checked: false, value: "BOARD_INDEP_PCT" },
              { name: "Energy Efficient ", checked: false, value: "ENERGY_EFFICIENCY_NM" },
              { name: "Female Directors ", checked: false, value: "GENDER_DIVERSITY" },
              { name: "Human Rights Compliance ", checked: false, value: "HR_COMPLIANCE" },
              { name: "Customer Controversies ", checked: false, value: "CUSTOMER_CONTROVERSY_FLAG" },
              // { name: "Pollution Prevention ", checked: false },
              { name: "UN Global Compact Compliance Violation ", checked: false, value: "UNGC_COMPLIANCE" },]
            },
            {
              name: "Excluded", value: [
                //{ name: "Severe Labor Controversies ", checked: false, value: "CONTR_SUPPLY_CHAIN_LABOR_N_SEVERE" },
              // { name: "Severe Human Rights and Community Controversies ", checked: false, value: "COMM_REL_CONFLICT_SCORE" },
              // { name: "UN Global Compact Compliance Violation ", checked: false, value: "UNGC_COMPLIANCE" },
              // { name: "Human Rights Compliance ", checked: false, value: "HR_COMPLIANCE" },
              { name: "Adult Entertainment ", checked: false, value: "AE_TIE" },
              { name: "Alcohol ", checked: false, value: "ALC_TIE" },
              { name: "Gambling", checked: false, value: "GAM_TIE" },
              { name: "Genetic Engineering - GMO ", checked: false, value: "GMO_TIE" },
              { name: "Nuclear Power ", checked: false, value: "NUC_TIE" },
              { name: "Predatory Lending ", checked: false, value: "PRED_LEND_DIRECT" },
              { name: "Tobacco ", checked: false, value: "TOB_TIE" },
              { name: "Controversial Weapons ", checked: false, value: "CWEAP_TIE" },
              { name: "Weapons", checked: false, value: "WEAP_GTIE" },
              { name: "Fossil Fuel Reserves", checked: false, value: "FOSSIL_FUEL_RESERVES" },
              // { name: "Female Directors ", checked: false, value: "GENDER_DIVERSITY" },
              { name: "Carbon Emissions", checked: false, value: "CARBON_EMISSIONS_SCORE" },
              { name: "Social Controversy ", checked: false, value: "SOCIAL_CONTROVERSY_SCORE" },
              { name: "Customer Controversies ", checked: false, value: "CUSTOMER_CONTROVERSY_FLAG" },
              ]
            }
            ]
          }]
        },
        {
          title: "MY FUNDS", sideOption: [{ optName: "Funds", select: false },
          { optName: "Rebates", select: false }]
        },
      ]
    },
    {
      settingName: "Emerging", select: false, enableEdit: false,
      headings: [
        {
          title: "FUND SETTINGS",
          sideOption: [{
            optName: "Assets Class", select: false,
            value: [{ name: "Equity", checked: true },
            { name: "Multi-asset", checked: false },
            { name: "Fixed Income", checked: false }],
          },
          {
            optName: "Market Universe", select: false,
            value: [{ name: "Global Equity Large Cap", checked: false, isNew: false },
            { name: "Global Emerging Markets Equity", checked: false, isNew: false },
            { name: "US Equity Large Cap Blend", checked: false, isNew: false },
            { name: "Europe Equity Large Cap", checked: false, isNew: false },
            { name: "ESG Funds UK", checked: false, isNew: false },
            { name: "ESG Funds Europe", checked: false, isNew: false },
            { name: "ENERGY", checked: false, isNew: true },
            { name: "OCEANIA", checked: false, isNew: true },
            { name: "MATERIALS", checked: false, isNew: true },
            { name: "HEALTH CARE", checked: false, isNew: true },
            { name: "EASTERN ASIA", checked: false, isNew: true },
            { name: "SOUTH AMERICA", checked: false, isNew: true },
            { name: "EASTERN EUROPE", checked: false, isNew: true },
            { name: "WESTERN EUROPE", checked: false, isNew: true },
            { name: "ASIA_Mid_Blend_", checked: false, isNew: true },
            { name: "EMERGINGMARKETS", checked: false, isNew: true },
            { name: "JAPAN_Mid_Blend_", checked: false, isNew: true },
            { name: "EUROPE_Mid_Blend_", checked: false, isNew: true },
            { name: "FRANCE_Mid_Blend_", checked: false, isNew: true },
            { name: "SWEDEN_Mid_Blend_", checked: false, isNew: true },
            { name: "NORWAY_Mid_Value_", checked: false, isNew: true },
            { name: "GERMANY_Mid_Value_", checked: false, isNew: true },
            { name: "GLOBAL_Mid_Blend_", checked: false, isNew: true },
            { name: "GLOBAL_Mid_Value_", checked: false, isNew: true },
            { name: "MEXICO_Mid_Blend_", checked: false, isNew: true },
            { name: "COMMUNICATION SERVICES", checked: false, isNew: true },
            { name: "CONSUMER DISCRETIONARY", checked: false, isNew: true },
            { name: "INFORMATION TECHNOLOGY", checked: false, isNew: true },
            { name: "UNITED STATES_Mid_Blend_", checked: false, isNew: true },
            { name: "UNITED KINGDOM_Mid_Blend_", checked: false, isNew: true },
            { name: "UNITED KINGDOM_Mid_Value_", checked: false, isNew: true },
            { name: "UNITED STATES_Mid_Growth_", checked: false, isNew: true },
            { name: "UNITED STATES_Small_Value_", checked: false, isNew: true },
            { name: "Other", checked: false, isNew: true },
            ]
          },
          {
            optName: "Country of Sale", select: false,
          },
          {
            optName: "Display Currency", select: false, value: [{ name: "USD (coming soon)", checked: false },
            { name: "EUR", checked: false }, { name: "GBP (coming soon)", checked: false }]
          }, {
            optName: "ESG", select: false, data: [{
              heading: "Included",
              value: [{ name: "Alternative Energy ", checked: false},
              { name: "Board Independence ", checked: false },
              { name: "Energy Efficient ", checked: false },
              { name: "Female Directors ", checked: false },
              { name: "Human Rights Compliance ", checked: false},
              { name: "Customer Controversies ", checked: false },
              // { name: "Pollution Prevention ", checked: false },
              { name: "UN Global Compact Compliance Violation ", checked: false},
            ]
            },
            {
              name: "Excluded", value: [
                //{ name: "Severe Labor Controversies ", checked: false },
              { name: "Severe Human Rights and Community Controversies ", checked: false },
              // { name: "UN Global Compact Compliance Violation ", checked: false },
              // { name: "Human Rights Compliance ", checked: true },
              { name: "Adult Entertainment ", checked: false },
              { name: "Alcohol ", checked: false },
              { name: "Gambling ", checked: false },
              { name: "Genetic Engineering - GMO ", checked: false },
              { name: "Nuclear Power ", checked: false },
              { name: "Predatory Lending ", checked: false },
              { name: "Tobacco ", checked: false },
              { name: "Controversial Weapons ", checked: false },
              { name: "Weapons", checked: false },
              { name: "Fossil Fuel Reserves", checked: false },
              // { name: "Female Directors ", checked: false },
              { name: "Carbon Emissions", checked: false },
              { name: "Social Controversy ", checked: false },
              { name: "Customer Controversies ", checked: false },
              ]
            }
            ]
          }]
        },
        {
          title: "MY FUNDS", sideOption: [{ optName: "Funds", select: false },
          { optName: "Rebates", select: false }]
        },
      ]
    }
  ],
  preferenceData: [
    {
      title: "FUND SETTINGS",
      sideOption: [{
        optName: "Assets Class", select: false, disable:false,
        value: [{ name: "Equity", checked: true },
        { name: "Fixed Income ", italic: "(Coming Soon)", checked: false },
        { name: "Multi-asset ", italic: "(Coming Soon)", checked: false }
        ],
      },
      {
        optName: "Market Universe", select: false,disable:false,
        value: [
          //   { name: "Global Equity Large Cap", checked: false, isNew:false },
          // { name: "Global Emerging Markets Equity", checked: false, isNew:false },
          // { name: "US Equity Large Cap Blend", checked: false, isNew:false },
          // { name: "Europe Equity Large Cap", checked: false, isNew:false },
          // { name: "ESG Funds UK", checked: false, isNew:false },
          // { name: "ESG Funds Europe", checked: false, isNew:false },
          { name: "ASIA_Mid_Blend_", checked: false, isNew: true },
          { name: "COMMUNICATION SERVICES", checked: false, isNew: true },
          { name: "CONSUMER DISCRETIONARY", checked: false, isNew: true },
          { name: "EASTERN ASIA", checked: false, isNew: true },
          { name: "EASTERN EUROPE", checked: false, isNew: true },
          { name: "EMERGINGMARKETS", checked: false, isNew: true },
          { name: "ENERGY", checked: false, isNew: true },
          { name: "EUROPE_Mid_Blend_", checked: false, isNew: true },
          { name: "FRANCE_Mid_Blend_", checked: false, isNew: true },
          { name: "GERMANY_Mid_Value_", checked: false, isNew: true },
          { name: "GLOBAL_Mid_Blend_", checked: false, isNew: true },
          { name: "GLOBAL_Mid_Value_", checked: false, isNew: true },
          { name: "HEALTH CARE", checked: false, isNew: true },
          { name: "INFORMATION TECHNOLOGY", checked: false, isNew: true },
          { name: "JAPAN_Mid_Blend_", checked: false, isNew: true },
          { name: "MATERIALS", checked: false, isNew: true },
          { name: "MEXICO_Mid_Blend_", checked: false, isNew: true },
          { name: "NORWAY_Mid_Value_", checked: false, isNew: true },
          { name: "OCEANIA", checked: false, isNew: true },
          { name: "Other", checked: false, isNew: true },
          { name: "SOUTH AMERICA", checked: false, isNew: true },
          { name: "SWEDEN_Mid_Blend_", checked: false, isNew: true },
          { name: "UNITED KINGDOM_Mid_Blend_", checked: false, isNew: true },
          { name: "UNITED KINGDOM_Mid_Value_", checked: false, isNew: true },
          { name: "UNITED STATES_Mid_Blend_", checked: false, isNew: true },
          { name: "UNITED STATES_Mid_Growth_", checked: false, isNew: true },
          { name: "UNITED STATES_Small_Value_", checked: false, isNew: true },
          { name: "WESTERN EUROPE", checked: false, isNew: true },
        ]
      },
      {
        optName: "Region of Sale", select: false, value: [{ name: "Europe", checked: true },
        { name: "North America", checked: false }]
      },
      {
        optName: "Country of Sale", select: false,disable:false,
      },
      {
        optName: "Display Currency", select: false, value: [{ name: "USD (coming soon)", checked: false },
        { name: "EUR", checked: true }, { name: "GBP (coming soon)", checked: false }]
      }, {
        optName: "ESG", select: false, disable:false, data: [{
          heading: "Included",
          value: [{ name: "Alternative Energy ", checked: false, value: "ALTERNATIVE_ENERGY_NM" },
          { name: "Board Independence ", checked: false, value: "BOARD_INDEP_PCT" },
          { name: "Energy Efficient ", checked: false, value: "ENERGY_EFFICIENCY_NM" },
          { name: "Female Directors ", checked: false, value: "GENDER_DIVERSITY" },
          { name: "Human Rights Compliance ", checked: false, value: "HR_COMPLIANCE" },
          { name: "Customer Controversies ", checked: false, value: "CUSTOMER_CONTROVERSY_FLAG" },
          // { name: "Pollution Prevention ", checked: false },
          { name: "UN Global Compact Compliance Violation ", checked: false, value: "UNGC_COMPLIANCE" },]
        },
        {
          name: "Excluded", value: [
            // { name: "Severe Labor Controversies ", checked: false, value: "CONTR_SUPPLY_CHAIN_LABOR_N_SEVERE" },
          // { name: "Severe Human Rights and Community Controversies ", checked: false, value: "COMM_REL_CONFLICT_SCORE" },
          // { name: "UN Global Compact Compliance Violation ", checked: false, value: "UNGC_COMPLIANCE" },
          // { name: "Human Rights Compliance ", checked: false, value: "HR_COMPLIANCE" },
          { name: "Adult Entertainment ", checked: false, value: "AE_TIE" },
          { name: "Alcohol ", checked: false, value: "ALC_TIE" },
          { name: "Gambling", checked: false, value: "GAM_TIE" },
          { name: "Genetic Engineering - GMO ", checked: false, value: "GMO_TIE" },
          { name: "Nuclear Power ", checked: false, value: "NUC_TIE" },
          { name: "Predatory Lending ", checked: false, value: "PRED_LEND_DIRECT" },
          { name: "Tobacco ", checked: false, value: "TOB_TIE" },
          { name: "Controversial Weapons ", checked: false, value: "CWEAP_TIE" },
          { name: "Weapons", checked: false, value: "WEAP_GTIE" },
          { name: "Fossil Fuel Reserves", checked: false, value: "FOSSIL_FUEL_RESERVES" },
          // { name: "Female Directors ", checked: false, value: "GENDER_DIVERSITY" },
          { name: "Carbon Emissions", checked: false, value: "CARBON_EMISSIONS_SCORE" },
          { name: "Social Controversy ", checked: false, value: "SOCIAL_CONTROVERSY_SCORE" },
          { name: "Customer Controversies ", checked: false, value: "CUSTOMER_CONTROVERSY_FLAG" },
          ]
        }
        ]
      }]
    },
    {
      title: "MY FUNDS",  sideOption: [{ optName: "Funds", select: false },
      { optName: "Rebates", select: false }]
    },
  ],
  // geographySale: [
  //   { name: "Asia", checked: false, isExpend: false, data: [{ name: "Japan", checked: false }, { name: "Hong Kong", checked: false }, { name: "Singapore", checked: false }] },
  //   { name: "Europe", checked: false, },
  //   { name: "EMEA", checked: false, isExpend: false, data: [{ name: "Japan", checked: false }, { name: "Hong Kong", checked: false }, { name: "Singapore", checked: false }] },
  //   { name: "North America", checked: false, },
  //   { name: "Emerging Markets", checked: false, isExpend: false, data: [{ name: "Japan", checked: false }, { name: "Hong Kong", checked: false }, { name: "Singapore", checked: false }] },
  // ]
  geographySale: [
    {name: "Andorra", checked: false},
    {name: "Argentina", checked: false},
    {name: "Australia", checked: false},
    {name: "Austria", checked: false},
    {name: "Bahamas", checked: false},
    {name: "Bahrain", checked: false},
    {name: "Belgium", checked: false},
    {name: "Bermuda", checked: false},
    {name: "Botswana", checked: false},
    {name: "Brazil", checked: false},
    {name: "British Virgin Islands", checked: false},
    {name: "Brunei Darussalam", checked: false},
    {name: "Bulgaria", checked: false},
    {name: "Cambodia", checked: false},
    {name: "Canada", checked: false},
    {name: "Cayman Islands", checked: false},
    {name: "Chile", checked: false},
    {name: "Colombia", checked: false},
    {name: "Croatia", checked: false},
    {name: "Curacao", checked: false},
    {name: "Cyprus", checked: false},
    {name: "Czech Republic", checked: false},
    {name: "Denmark", checked: false},
    {name: "Egypt", checked: false},
    {name: "Estonia", checked: false},
    {name: "Europe", checked: false},
    {name: "Faroe Islands", checked: false},
    {name: "Fiji", checked: false},
    {name: "Finland", checked: false},
    {name: "France", checked: false},
    {name: "Georgia", checked: false},
    {name: "Germany", checked: false},
    {name: "Gibraltar", checked: false},
    {name: "Greece", checked: false},
    {name: "Greenland", checked: false},
    {name: "Guernsey", checked: false},
    {name: "Hong Kong", checked: false},
    {name: "Hungary", checked: false},
    {name: "Iceland", checked: false},
    {name: "Indonesia", checked: false},
    {name: "Ireland", checked: false},
    {name: "Isle of Man", checked: false},
    {name: "Israel", checked: false},
    {name: "Italy", checked: false},
    {name: "Japan", checked: false},
    {name: "Jersey", checked: false},
    {name: "Jordan", checked: false},
    {name: "Korea", checked: false},
    {name: "Korea, Democratic People's Republic Of", checked: false},
    {name: "Kuwait", checked: false},
    {name: "Latvia", checked: false},
    {name: "Lebanon", checked: false},
    {name: "Liechtenstein", checked: false},
    {name: "Lithuania", checked: false},
    {name: "Luxembourg", checked: false},
    {name: "Macau", checked: false},
    {name: "Malaysia", checked: false},
    {name: "Malta", checked: false},
    {name: "Mauritius", checked: false},
    {name: "Mexico", checked: false},
    {name: "Monaco", checked: false},
    {name: "Netherlands", checked: false},
    {name: "New Zealand", checked: false},
    {name: "Norway", checked: false},
    {name: "Oman", checked: false},
    {name: "Peru", checked: false},
    {name: "Philippines", checked: false},
    {name: "Poland", checked: false},
    {name: "Portugal", checked: false},
    {name: "Qatar", checked: false},
    {name: "Romania", checked: false},
    {name: "Russian Federation", checked: false},
    {name: "Saint Martin", checked: false},
    {name: "Saudi Arabia", checked: false},
    {name: "Singapore", checked: false},
    {name: "Sint Maarten", checked: false},
    {name: "Slovakia", checked: false},
    {name: "Slovenia", checked: false},
    {name: "South Africa", checked: false},
    {name: "Spain", checked: false},
    {name: "Swaziland", checked: false},
    {name: "Sweden", checked: false},
    {name: "Switzerland", checked: false},
    {name: "Taiwan", checked: false},
    {name: "Thailand", checked: false},
    {name: "Tunisia", checked: false},
    {name: "Turkey", checked: false},
    {name: "United Arab Emirates", checked: false},
    {name: "United Kingdom", checked: false},
    {name: "United States", checked: false},
    {name: "Yemen", checked: false},
  ]
}
