import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "src/services/CommonServices/common.service";
import { ForgetPasswordService } from "src/services/AuthServices/forget-password.service";
import { UUID } from "angular2-uuid";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  userEmail: any;
  userEmailForget: any;
  Password: string;
  isPressed = false;
  errorMsg: any;
  forgotPassError: any;
  uuidValue: any;

  constructor(
    public http: HttpClient,
    public cookieService: CookieService,
    public forgetPass: ForgetPasswordService,
    public router: Router,
    public common: CommonService,
  ) {
    const check = cookieService.check("browserToken");
    if (check == false) {
      this.uuidValue = UUID.UUID();
      this.cookieService.set("browserToken", this.uuidValue);
    } else {
      this.uuidValue = this.cookieService.get("browserToken");
    }

    this.isPressed = false;
    const user = localStorage.getItem("activeRaoter");
    //localStorage.setItem("login", "0");
    localStorage.removeItem("userId");
    // localStorage.setItem("userId", "123");
    localStorage.removeItem("ID");
  }

  goToLogin() {
    this.isPressed = false;
    this.userEmailForget = "";
  }

  requestForget() {
    this.isPressed = true;
    this.forgotPassError = "";
    const email = this.userEmailForget;
    if (this.userEmailForget != "") {
      this.forgetPass.sendRequestForResetPassword(email).subscribe(
        (res) => {
          this.isPressed = false;
          if (res["status"] == 400) {
            this.forgotPassError = res["message"];
          } else {
            document.getElementById("openModalButton").click();
          }
        },
        (err) => {
          console.log("Erro", err);
          this.isPressed = false;
        }
      );
    }
  }

  onEmailInputForgetPass(event) {
    this.forgotPassError = "";
  }

  fillEmail(event){

  }

  ngOnInit() {
    localStorage.setItem("activeRaoter", "home");
  }

}
