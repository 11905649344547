import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  Input,
} from "@angular/core";
import { DatePipe, DecimalPipe } from "@angular/common";
import { CommonService } from "src/services/CommonServices/common.service";
import { Router } from "@angular/router";
import { PlatformLocation } from "@angular/common";
import { PrefrenceSettingsService } from "src/services/PreferencesServices/prefrence-settings.service";
import { FundsDataService } from "src/services/ChartServices/funds-data.service";
import { ActivePassiveComponent } from "../active-passive/active-passive.component";
import { TopFundChartComponent }  from "../top-fund-chart/top-fund-chart.component";
import { RiskRewardChartComponent } from "../risk-reward-chart/risk-reward-chart.component";
import { Commons, Shared } from "../../utils/common";
import { MsGrowthViewComponent } from "../ms-growth-view/ms-growth-view.component";
import { FundDocumentationComponent } from "../fund-documentation/fund-documentation.component";
import { ProfileService } from "src/services/profile/profile.service";
import { decode } from "string-encode-decode";
import { GrowthViewService } from "src/services/growth-view/growth-view.service";
import { SummaryPopupService } from "src/services/summary-popup/summary-popup.service";
import { AlertsNotificationsService } from "src/services/alerts-notifications/alerts-notifications.service";
import { RatingViewService } from "src/services/rating/rating-view.service";
import { MyFundsComponent } from "../my-funds/my-funds.component";
import { AlertsServiceService } from "src/services/alerts-service/alerts-service.service";
import { FundDocumentaionService } from "src/services/fund-documentation/fund-documentaion.service";
import { apiError, currentDate } from "src/utils/common-function";
import 'rxjs/add/operator/debounceTime';
import { Subscription } from "rxjs";
import { GoogleAnalyticsService } from "src/services/google-Analytics/google-analytics.service";
import { ActivePassiveViewComponent } from "../active-passive-view/active-passive-view.component";
import { environment } from "src/environments/environment";
//import { debounce } from 'ts-debounce';

declare let ga: Function;
@Component({
  selector: "app-user-dashboard",
  templateUrl: "./user-dashboard.component.html",
  styleUrls: ["./user-dashboard.component.scss"],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None,
  host: {
    "(document:click)": "onClick($event)",
  },
})
export class UserDashboardComponent implements OnInit {
  @ViewChild("myFundChart") myFundChart: MyFundsComponent;
  @ViewChild("addViewTabs") addViewTabs: ElementRef;
  @ViewChild("growtHChart") growtHChart: MsGrowthViewComponent;
  @ViewChild("fundDocumentation") fundDocumentation: FundDocumentationComponent;
  @ViewChild("riskRewardChart") riskRewardChart: RiskRewardChartComponent;
  @ViewChild("topFund") topFund: TopFundChartComponent;
  @ViewChild("topFundChartContainer") topFundChartContainer: ElementRef;
  @ViewChild("msGrowthChartContainer") msGrowthChartContainer: ElementRef;
  name = "Angular";
  @ViewChild("tooltipPopup") tooltipPopup: ElementRef;
  @ViewChild("activePassiveReference") activePassiveReference: ActivePassiveComponent;
  @ViewChild("activePassiveViewReference") activePassiveViewReference: ActivePassiveViewComponent;
  @Input() id: number;
  callResizeStart:Subscription;
  isShareClass:Subscription;
  initialTime: number = 1800;
  timeLeft: number = 15;
  isDuplicate = false;

  constructor(
    public alertsNotificationService: AlertsNotificationsService,
    public common: CommonService,
    public router: Router,
    public alertService: AlertsServiceService,
    public ratingService: RatingViewService,
    public summaryPopupService: SummaryPopupService,
    public datePipe: DatePipe,
    public profileService: ProfileService,
    private platformLocation: PlatformLocation,
    public fundsService: FundsDataService,
    public growthChartService: GrowthViewService,
    public prefrenceSetting: PrefrenceSettingsService,
    public fundService: FundDocumentaionService,
    public decimalPipe: DecimalPipe,
    public googleAnalyticsEventsService: GoogleAnalyticsService,
    public shared: Shared
  ) {
    this.shared.addViewSelected = false;
    this.shared.addViewEnable = false;
    this.shared.userDashboard = true;
    Commons.userDashboardshow = true;
    this.shared.dropDownData = [
      { name: "TOP FUNDS", select: false },
      { name: "ACTIVE VS PASSIVE", select: false },
      { name: "TABLE VIEW", select: false },
      { name: "GROWTH VIEW", select: false },
      { name: "RISK REWARD", select: false },
      { name: "MY FUNDS", select: false },
    ];

    this.shared.isShowLoading = true;
    platformLocation.onPopState(() => {
      this.router.navigateByUrl("/user-dashboard");
      history.forward();
    });
    if (!this.shared.isLogin) {
      this.router.navigateByUrl("/user-dashboard");
      localStorage.setItem("activeRaoter", "dashboard");
    }
    let checkSession = sessionStorage.getItem("browserSession");
    let user = localStorage.getItem("currentUserDetail");
    Commons.platform = localStorage.getItem("platform");
    if(user != null && user != "null" && user != undefined && Commons.platform != null && Commons.platform != undefined){
    Commons.userDetails = JSON.parse(user);
    let timer = localStorage.getItem("initialTime");
    let initial = parseInt(timer);
    this.initialTime = initial > 0 ? initial : 1800;
    setInterval(() => {
      localStorage.setItem("initialTime", this.initialTime.toString());
      if(this.initialTime > 0) {
        this.initialTime--;
      }else{
        Commons.sessionId = null;
        sessionStorage.setItem("browserSession", Commons.sessionId);
        this.timeLeft = 15;
        this.createSession();
      }
    },1000)
    if (checkSession != "undefined" &&  checkSession != null && this.initialTime > 0) {
      Commons.sessionId = sessionStorage.getItem("browserSession");
      this.updateSession();
    } else if (checkSession == null || checkSession == "undefined" && this.initialTime > 0){
      this.timeLeft = 15;
      this.createSession();
    } else {
      this.timeLeft = 15;
      this.createSession();
    }
  }else{
    localStorage.removeItem("userId");
  }
  }

  ngOnInit() {
    //localStorage.removeItem("bestPassive")
    // Start loading
    this.startLoad();
    this.prefrenceSetting.getAllSavedSettings(
      decode(localStorage.getItem("ID"))
    );
    // All setting observable
    this.shared.allSettingObservable.subscribe((res) => {
      this.getAllSettingObservable(res);
    });
    this.alertsNotificationService.getAlertsNotifications(decode(localStorage.getItem("ID")), 0);
    this.getDateAndChartHeight();
    this.profileService.getProfile(decode(localStorage.getItem("ID")));
    // Table view observable
    this.shared.tableViewChangeObservable.subscribe((res) => {
      this.updateTableView();
    });
    // Consistecny update
    Commons.consistencyScoreChange.subscribe(async () => {
      this.updateConsistency();
    });
    this.isShareClass = Commons.toggleShareClass.subscribe((res) => {
      this.updateConsistency();
    });
    // Top fund observable
    this.shared.topFundDataObservable.subscribe((res) => {
      this.updateTopFunds(res);
      if(Commons.selectedTab == 'home'){
        this.updateConsistency();
      }
    });
    this.shared.blinks = true;
    setTimeout(() => {
      this.shared.blinks = false;
    }, 5 * 60 * 1000);
    this.shared.summaryPopupGrowth.subscribe(() => {
      this.openDashbord("msGrowthView");
    });
    // My fund observable
    this.shared.myFundsDataObservable.subscribe((res) => {
      this.updateMyFund(res);
    });

    let client = localStorage.getItem('userId');
    if (client) {
      ga('create', 'UA-211001570-1', {
        'storage': 'none',
        'clientId': client
      });
      ga('send', 'pageview');
    }
  }

  ngOnDestroy(){
  if(this.isShareClass){
    this.isShareClass.unsubscribe();
  }
  }

  createSession(){
    this.initialTime = 1800;
    var startTime = new Date();
    let modal = {
      "id": "",
      "createdAt": startTime,
      "sessionName": "FundsaiQ Session",
      "sessionduration": 30,
      "startTime": startTime,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform,
    }
    this.googleAnalyticsEventsService.webWorkerCreateSession(modal);
  }

  updateSession(){
    setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      }else{
        this.timeLeft = 15;
        var startTime = new Date();
        var initialTime = localStorage.getItem("initialTime");
        var seconds = parseInt(initialTime);
        var minutes =  seconds / 60;
        var endTime = new Date();
        endTime.setMinutes( endTime.getMinutes() + minutes );
        let model = {
          "id": Commons.sessionId,
          "createdAt": startTime,
          "sessionName": "FundsaiQ Session",
          "sessionduration": 30,
          "startTime": startTime,
          "endTime": endTime,
          "userId": Commons.userDetails.userID,
          "userEmail": Commons.userDetails.Email,
          "userName": Commons.userDetails.Name,
          "platform": Commons.platform,
        }
        this.googleAnalyticsEventsService.webWorkerUpdateSession(model);
      }
    },1000)
  }

  getDateAndChartHeight() {
    currentDate();
    this.shared.clickEvents = [];
    Commons.userDashboardshow = true;
    localStorage.setItem("year", "2021");
    localStorage.setItem("startDate", "02/01/2021");
    // let currentDate = new Date();
  // this.datePipe.transform(currentDate, "yyyy-MM-dd")
    // localStorage.setItem("endDate", "06/30/2021");
    // localStorage.setItem("endDate", "06/30/2021");
    // localStorage.setItem("time", "1Y");
    this.shared.showDashboard = true;
    this.shared.userDashboard = true;
    this.shared.activePassiveGraphHeight = "310px";
    localStorage.setItem("activeRaoter", "dashboard");
    setTimeout(() => {
      Commons.chartHeightCollapseView = this.msGrowthChartContainer.nativeElement.offsetHeight;
      this.shared.chartWidthCollapseView = this.msGrowthChartContainer.nativeElement.offsetWidth;
      this.shared.getGraphDivHeight = this.msGrowthChartContainer.nativeElement.offsetHeight;
      this.shared.getGraphDivWidth = this.msGrowthChartContainer.nativeElement.offsetWidth;
      this.shared.topFunddChartHeight = this.msGrowthChartContainer.nativeElement.offsetHeight;
      this.shared.topFunddChartWidth = this.msGrowthChartContainer.nativeElement.offsetWidth;
      this.shared.riskGraphHeight = this.msGrowthChartContainer.nativeElement.offsetHeight - 100;
      this.shared.riskGraphWidth = this.msGrowthChartContainer.nativeElement.offsetWidth - 20;
    }, 10);
  }

  startLoad() {
    this.shared.isShowTopFundLoading = true;
    this.shared.isShowLoading = true;
    this.shared.growthChartLoading = true;
    this.shared.marketInsightLoading = true;
  }

  getAllSettingObservable(res) {
    const currentDate = new Date();
    if (res["data"] && res["data"].length > 0) {
      this.prefrenceSetting
        .getSettingById(decode(localStorage.getItem("ID")), res["data"][0].Id)
        .subscribe((settingData) => {
          this.shared.selectedItems = settingData["data"][0].Market_Universe;
          localStorage.setItem("chartCategory", settingData["data"][0].Market_Universe);
          this.getDataForCharts();
        });
    } else {
      this.prefrenceSetting
        .getPreferencesData(
          decode(localStorage.getItem("ID")),
          this.datePipe.transform(currentDate, "yyyy-MM-dd")
        )
        .subscribe((response: any) => {
          this.preferenceApiData(response, res);
        }, (err)=>{
          apiError(err);
        });
    }
  }

  getDataForCharts() {
    // let date = new Date();
    let bestPassiveData;
    localStorage.setItem("chartCategory", this.shared.selectedItems);
    this.shared.alertSetupObservable.next();
    this.common.getTopFundDataWithCategory(this.shared.selectedItems, "2021", "5", null, true, this.datePipe.transform(localStorage.getItem('date'), "yyyy-MM-dd"), []);
    setTimeout(() => {
      this.common.getActivePasive(this.shared.selectedItems, "2021", true, this.datePipe.transform(localStorage.getItem('date'), "yyyy-MM-dd"));
    }, 1000);
    bestPassiveData  = JSON.parse(localStorage.getItem("bestPassive"));
    // if(bestPassiveData && bestPassiveData.length > 3){
    //   localStorage.removeItem("bestPassive");
    // }
    if(bestPassiveData && bestPassiveData.length > 0){
      this.shared.bestPassive = [];
      this.shared.bestPassive = bestPassiveData;
    }
  }

  getShareClasses(event){
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "primary_shareclass",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform,
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.isShareClassOnn = event;
    if(event == true && this.shared.topFundCategoryData){
      Commons.isShareClassData.topData = [];
      for(var i = 0; i < this.shared.topFundCategoryData.topData.length; i++){
        if (Commons.isShareClassData.topData.length > 0) {
           Commons.isShareClassData.topData.forEach((ele, ind) => {
             if(this.shared.topFundCategoryData.topData[i]["ISIN"] == ele.ISIN){
               this.isDuplicate = true;
               if(this.shared.topFundCategoryData.topData[i]["IsPrimaryShareClass"] == "TRUE"){
                  Commons.isShareClassData.topData.push(this.shared.topFundCategoryData.topData[i]);
               }else if(ele.IsPrimaryShareClass == "TRUE"){
               }else {
                if (ele["compounded_return_05"] > this.shared.topFundCategoryData.topData[i]["compounded_return_05"]) {
                 } else {
                   Commons.isShareClassData.topData.splice(ind, 1);
                   Commons.isShareClassData.topData.splice(ind, 0, this.shared.topFundCategoryData.topData[i]);
                 }
               }
             }
           });
           if(!this.isDuplicate){
            Commons.isShareClassData.topData.push(this.shared.topFundCategoryData.topData[i]);
           }
          } else {
           Commons.isShareClassData.topData.push(this.shared.topFundCategoryData.topData[i]);
          }
      }
      Commons.toggleShareClass.next({ data: Commons.isShareClassData, year: Commons.selectedTotalYear, hiddenGem: Commons.selectedHiddenGem });
    }else{
      Commons.toggleShareClass.next({ data: this.shared.topFundCategoryData, year: Commons.selectedTotalYear, hiddenGem: Commons.selectedHiddenGem });
    }
    if (this.activePassiveViewReference) {
        this.activePassiveViewReference.reDrawChart();
    }
  }
  tempData: any = [];
  async updateConsistency() {
    this.tempData = [];
    if (this.shared.topFundCategoryData.topData && this.shared.topFundCategoryData.topData.length > 0) {
      this.shared.fundsSecIds = [];
      Commons.consistencyFundId = [];
      if(this.shared.isShareClassOnn){
        this.shared.topFundCategoryData.topData.forEach((element) => {
          if (Commons.selectedScore.includes(element["consistency"])) {
            this.tempData.push(element);
          }
        });
        this.tempData.sort(this.comparee);
        this.tempData.forEach(ele => {
          this.shared.fundsSecIds.push(ele["id"]);
          Commons.consistencyFundId.push(ele["id"]);
        });
      }else{
        this.shared.topFundCategoryData.topData.forEach((element) => {
          if (Commons.selectedScore.includes(element["consistency"])) {
            this.shared.fundsSecIds.push(element["id"]);
            Commons.consistencyFundId.push(element["id"]);
          }
        });
      }
      this.growthChartService.getChartData();
      if (this.shared.rar1items) {
        this.shared.activePassivehighchartDataFor1Yr =
          this.common.convertRar1itemsDataForHighchart(this.shared.rar1items);
      }
      if (this.shared.rar3items) {
        this.shared.activePassivehighchartDataFor3Yr =
          this.common.convertRar3itemsDataForHighchart(this.shared.rar3items);
      }
      if (this.shared.rar5items) {
        this.shared.activePassivehighchartDataFor5Yr =
          this.common.convertRar5itemsDataForHighchart(this.shared.rar5items);
      }
      if(this.shared.isShareClassOnn){
        this.shared.ratingViewArray.sort(this.compare);
      }
      await this.ratingService.dataForTableView(
        this.shared?.ratingViewArray,
        this.shared.esgSelectedData,
        Commons.selectedScore
      );
      await this.getRattingData();
    }
  }

  comparee(item1, item2){
    if(item1.ISIN == item2.ISIN){
      if(item1.IsPrimaryShareClass == "TRUE"){
       return item1;
      }else if(item2.IsPrimaryShareClass == "TRUE"){
       return item2;
      }else if (item1.IsPrimaryShareClass == "FALSE" && item2.IsPrimaryShareClass == "FALSE"){
        if(item1["compounded_return_05"] > item2["compounded_return_05"]){
          return item1;
          // this.tempData.forEach((element, ind) => {
          //   if(element["compounded_return_05"] == item2["compounded_return_05"]){
          //     this.tempData.splice(ind, 1);
          //   }
          // });
        }else if(item1["compounded_return_05"] < item2["compounded_return_05"]){
          return item2;
        }else{
        }
      }
    }else{
      return;  
    }
  }

   compare(item1, item2){
    if(item1.ISIN == item2.ISIN){
      if(item1.IsPrimaryShareClass == "TRUE"){
       return item1;
      }else if(item2.IsPrimaryShareClass == "TRUE"){
       return item2;
      }else if (item1.IsPrimaryShareClass == "FALSE" && item2.IsPrimaryShareClass == "FALSE"){
        if(item1["compounded_return_05"] > item2["compounded_return_05"]){
          return item1;
        }else if(item1["compounded_return_05"] < item2["compounded_return_05"]){
          return item2;
        }else{
        }
      }
    }else{
      return;
    }
  }

  updateTableView() {
    this.shared.tableData = [];
    this.shared.ratingViewHeaders;
    for (let i = 0; i < this.shared.ratingViewHeaders.length; i++) {
      if (i != 0) {
        if (this.shared.ratingViewHeaders[i].show) {
          this.shared.tableData.push({
            id: this.shared.ratingViewHeaders[i].id,
            value: [],
          });
          break;
        }
      }
    }
    const value = this.shared.tableData[0].id;
    this.shared.tableViewData.forEach((element) => {
      const check = element.hasOwnProperty(value);
      if (check) {
        this.shared.tableData[0].value.push({ value: element[value] });
      }
    });
  }

  updateTopFunds(res) {
    this.shared.myFundsChart = false;
    this.growthChartService.addBestPassive(
      this.datePipe.transform(localStorage.getItem("date"), "yyyy-MM-dd")
    );
    this.fundService.getFundDocs(localStorage.getItem("chartCategory"));
    if (res.data.topData.length > 0) {
      this.shared.isDataTopFundNotAvailable = false;
      this.shared.bestPassiveData = Commons.bestPassiveTopFunds;
      this.ratingService.dataForTableView(
        this.shared?.ratingViewArray,
        this.shared.esgSelectedData,
        Commons.selectedScore
      );
      this.shared.tableViewChangeObservable.next();
    } else if (res.data.bestPassive.length > 0) {
      this.ratingService.dataForTableView(
        this.shared?.ratingViewArray,
        this.shared.esgSelectedData,
        Commons.selectedScore
      );
    } else {
      this.shared.isDataTopFundNotAvailable = true;
    }
  }

  updateMyFund(res) {
    setTimeout(() => {
      if (this.myFundChart) {
        this.myFundChart.getMyFundchartData(res);
      }
    }, 50);
  }

  preferenceApiData(response, res) {
    const categories: any[] = [];
    response.data.categories.forEach((element) => {
      if (element.isDataAvailable) {
        categories.push(element.Cluster);
      }
    });
    categories.sort((a, b) => (a < b ? -1 : 1));
    this.shared.selectedItems = categories.length > 0 ? categories[0] : "Austria_Mid_Blend";
    localStorage.setItem("chartCategory", this.shared.selectedItems);
    const setting = res["data"];
    if (setting.length < 1) {
      this.getDataForCharts();
      document.getElementById("prefrence").click();
    } else {
      this.getDataForCharts();
    }
  }


  getRattingData() {
    this.shared.tableData = [];
    this.shared.ratingViewHeaders;
    for (let i = 0; i < this.shared.ratingViewHeaders.length; i++) {
      if (
        this.shared.ratingViewHeaders[i].show &&
        this.shared.ratingViewHeaders[i].id != "Fund Name"
      ) {
        this.shared.tableData.push({
          id: this.shared.ratingViewHeaders[i].id,
          value: [],
        });
        break;
      }
    }
    const value = this.shared.tableData[0].id;
    this.shared.tableViewData.forEach((element) => {
      const check = element.hasOwnProperty(value);
      if (check) {
        this.shared.tableData[0].value.push({ value: element[value] });
      }
    });
  }

  validateValue(val) {
    let result;
    if (val) {
      result = val;
    } else {
      result = "--";
    }
    return result;
  }


  onResizedDivActivePassiveChart() {
    this.shared.graphHeighthData =
      this.msGrowthChartContainer.nativeElement.offsetHeight - 120;
    this.shared.graphWidthData =
      this.msGrowthChartContainer.nativeElement.offsetWidth - 20;
      setTimeout(() => {
        if (this.activePassiveReference) {
          this.activePassiveReference.initPlot();
        }
      }, 100);
  }

  onResizedDivRiskChart() {
    this.shared.riskGraphHeight =
      this.msGrowthChartContainer.nativeElement.offsetHeight - 90;
    this.shared.riskGraphWidth =
      this.msGrowthChartContainer.nativeElement.offsetWidth - 20;
  }

  
  expandView = false;
  selectCheckbox(event, i) {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": event.name,
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    Commons.addViewEnable = true;
    this.shared.addView = true;
    this.shared.addViewEnable = true;
    this.shared.showmarketInsight = true;
    event.select = !event.select;
    if (event.select == true) {
      // this.addViewSelected = true;
      this.shared.selectedDataDropDown.push({
        name: event.name,
        select: event.select,
      });
    } else {
      this.shared.selectedDataDropDown.forEach((element, i) => {
        if (element.name == event.name) {
          this.shared.selectedDataDropDown.splice(i, 1);
        }
      });
    }
    if (this.shared.selectedDataDropDown.length > 1) {
      this.shared.navHeight = '78px';
      this.shared.addView = true;
      this.shared.addViewSelected = true;
      this.shared.showInformation = true;
    } else {
      this.shared.addViewSelected = false;
      this.shared.showInformation = false;
      this.shared.isAddView = false;
    }
    if (event.name == "TOP FUNDS") {
      this.shared.istopFunds = event.select;
    }
    if (event.name == "ACTIVE VS PASSIVE") {
      this.shared.isactivePassive = event.select;
    }
    if (event.name == "TABLE VIEW") {
      this.shared.isratingView = event.select;
    }
    if (event.name == "GROWTH VIEW") {
      this.shared.ismsGrowthView = event.select;
    }
    if (event.name == "RISK REWARD") {
      this.shared.ismsRiskReward = event.select;
    }
    if (event.name == "MY FUNDS") {
      this.shared.ismyFunds = event.select;
    }
    if (this.shared.selectedDataDropDown.length == 1) {
      this.shared.addView = false;
      this.shared.navHeight = '45px';
    }
    if (this.shared.selectedDataDropDown.length > 0) {
      this.shared.riskGraphHeight = 200;
      setTimeout(() => {
        this.shared.riskGraphHeight =
          this.addViewTabs.nativeElement.offsetHeight - 142;
      }, 600);
    }
  }

  goToExpand(event) {
    this.shared.navHeight = '45px';
    this.shared.addView = false;
    Commons.addViewEnable = false;
    this.fundDocumentation.showScrollBar();
    this.riskRewardChart.detailRecivedSubscription.unsubscribe();
    this.growtHChart ? this.growtHChart.dataSubscription.unsubscribe() : null;
    this.shared.getGraphDivHeight = this.topFundChartContainer.nativeElement.offsetHeight;
    this.shared.getGraphDivWidth = this.topFundChartContainer.nativeElement.offsetWidth;
    this.shared.showTooltipOnActive = true;
    if (event == "topFunds") {
      this.shared.topfundAnimation = true;
    }
    if (event == "activePassive") {
      this.shared.activePassiveAnimation = true;
    }
    if (event == "ratingView") {
      this.shared.tableViewAnimation = true;
    }
    if (event == "msGrowthView") {
      this.shared.MsGrowthAnimation = true;
    }
    if (event == "msRiskReward") {
      this.shared.MsRiskRewardAnimation = true;
    }
    setTimeout(() => {
      this.shared.userDashboard = false;
      this.shared.showDashboard = false;
      Commons.userDashboardshow = false;
      this.openDashbord(event);
    }, 500);
  }

  openAddView() {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "open_add_view",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.isAddView = true;
  }

 async openDashbord(categorie) {
  let createdTime = new Date();
  if(categorie != "myFunds"){
    this.shared.myFundsChart = false;
  }
  let modal = {
    "createdAt": createdTime,
    "eventName": categorie,
    "sessionId": Commons.sessionId,
    "userId": Commons.userDetails.userID,
    "userEmail": Commons.userDetails.Email,
    "userName": Commons.userDetails.Name,
    "platform": Commons.platform
  }
  this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
  this.shared.navHeight = '45px';
   Commons.selectedTab = categorie;
   this.shared.addView = false;
   Commons.addViewEnable = false;
    // this.riskGraphHeight = "630";
    this.shared.showInformation = false;
    this.shared.addViewEnable = false;
    this.shared.addViewSelected = false;
    this.shared.bottomView = false;
    this.shared.selectedDataDropDown = [];
    this.shared.showmarketInsight = true;
    this.shared.istopFunds = false;
    this.shared.isHome = false;
    this.shared.isactivePassive = false;
    this.shared.isratingView = false;
    this.shared.ismsGrowthView = false;
    this.shared.ismsRiskReward = false;
    this.shared.ismyFunds = false;
    this.shared.isAddView = false;
    this.shared.dropDownData.forEach((element) => {
      element.select = false;
    });
    if (categorie == "topFunds") {
      this.shared.view = 'TopFunds';
      this.shared.selectedTopFund = true;
      this.shared.selectedDataDropDown.push({ name: "TOP FUNDS", select: true });
      this.shared.istopFunds = true;
    await  this.shared.dropDownData.forEach((element) => {
        if (element.name == "TOP FUNDS") {
          element.select = true;
        } else {
          element.select = false;
        }
      });
    } else {
      this.shared.selectedTopFund = false;
    }
    if (categorie == "activePassive") {
      this.shared.view = 'ActivePassive';
      this.shared.selectedActivePassive = true;
      this.shared.selectedDataDropDown.push({
        name: "ACTIVE VS PASSIVE",
        select: true,
      });
      this.shared.isactivePassive = true;
      this.shared.dropDownData.forEach((element) => {
        if (element.name == "ACTIVE VS PASSIVE") {
          element.select = true;
        } else {
          element.select = false;
        }
      });
    } else {
      this.shared.selectedActivePassive = false;
    }
    if (categorie == "ratingView") {
      this.shared.view = 'Ratting';
      this.shared.selectedTableView = true;
      this.shared.selectedDataDropDown.push({ name: "TABLE VIEW", select: true });
      this.shared.isratingView = true;
      this.shared.dropDownData.forEach((element) => {
        if (element.name == "TABLE VIEW") {
          element.select = true;
        } else {
          element.select = false;
        }
      });
    } else {
      this.shared.selectedTableView = false;
    }
    if (categorie == "msGrowthView") {
      this.shared.view = 'GrowthView';
      this.shared.selectedGrowth = true;
      this.shared.selectedDataDropDown.push({ name: "GROWTH VIEW", select: true });
      this.shared.ismsGrowthView = true;
    await  this.shared.dropDownData.forEach((element) => {
        if (element.name == "GROWTH VIEW") {
          element.select = true;
        } else {
          element.select = false;
        }
      });
    } else {
      this.shared.selectedGrowth = false;
    }
    if (categorie == "msRiskReward") {
      this.shared.view = 'RiskReward';
      this.shared.selectedRiskReward = true;
      // this.fundDocumentation.showScrollBar();
      this.shared.riskGraphHeight = "630";
      this.shared.addViewSelected = false;
      this.shared.selectedDataDropDown.push({ name: "RISK REWARD", select: true });
      if (!this.shared.ismsRiskReward) {
        this.shared.showmarketInsight = false;
        this.shared.ismsRiskReward = true;
      await  this.shared.dropDownData.forEach((element) => {
          if (element.name == "RISK REWARD") {
            element.select = true;
          } else {
            element.select = false;
          }
        });
      }
    } else {
      this.shared.selectedRiskReward = false;
    }
    if (categorie == "myFunds") {
      this.shared.view = 'MyFunds';
      this.shared.selectedMyFunds = true;
      this.shared.selectedDataDropDown.push({ name: "MY FUNDS", select: true });
      this.shared.ismyFunds = true;
    await  this.shared.dropDownData.forEach((element) => {
        if (element.name == "MY FUNDS") {
          element.select = true;
        } else {
          element.select = false;
        }
      });
    } else {
      this.shared.selectedMyFunds = false;
    }
    if (categorie == "addView") {
      this.shared.isAddView = true;
      this.shared.addView = true;
      Commons.addViewEnable = true;
    }
    if (categorie == "home") {
      setTimeout(() => {
        this.riskRewardChart.redrawChart();
      }, 100);
      Commons.userDashboardshow = true;
      setTimeout(() => {
        this.onResizedDivTopFundChart();
        this.onResizedDivActivePassiveChart();
        this.onResizedDivRiskChart();
        setTimeout(() => {
          if(this.growtHChart){
            this.growtHChart.drawMsGrowthChart();
          }
        }, 10);
      }, 50);
      if (this.shared.topfundAnimation) {
        this.shared.topFundzIndex = 10000;
        setTimeout(() => {
          this.shared.topfundAnimation = false;
        }, 200);
        setTimeout(() => {
          this.shared.topFundzIndex = "";
        }, 1000);
      }
      setTimeout(() => {
        this.shared.animation = false;
        this.shared.showTooltipOnActive = false;
        this.shared.tableViewAnimation = false;
        this.shared.activePassiveAnimation = false;
        this.shared.MsGrowthAnimation = false;
        this.shared.MsRiskRewardAnimation = false;
      }, 200);
      this.shared.userDashboard = true;
      this.shared.showDashboard = true;
      localStorage.setItem("activeRaoter", "dashboard");
    }
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
      tabcontent[i].style.color = "#606f88";
    }
    tablinks = document.getElementsByClassName("tablinks");

    setTimeout(() => {
      document.getElementById(categorie).style.display = "block";
      document.getElementById(categorie).style.color = "#48eee0";
    }, 100);
  }

  DataTestZerolength = [];
  //onResizedDivTopFundChart = debounce(this.updateChartHeight.bind(this), 100, {isImmediate:false});

  onResizedDivTopFundChart(){
    setTimeout(() => {
        this.shared.topFunddChartHeight = this.msGrowthChartContainer.nativeElement.offsetHeight;
        this.shared.topFunddChartWidth = this.msGrowthChartContainer.nativeElement.offsetWidth;
        Commons.chartHeightCollapseView = this.msGrowthChartContainer.nativeElement.offsetHeight;
        this.shared.chartWidthCollapseView = this.msGrowthChartContainer.nativeElement.offsetWidth;
        setTimeout(() => {
          if (this.shared.topFundCategoryData) {
            if (this.shared.topFundCategoryData.topData.length > 0) {
              this.shared.isDataTopFundNotAvailable = false;
              this.topFund.topFundchart();
              // this.topFund.updateChartHeight(this.common.chartWidthCollapseView, this.common.chartHeightCollapseView);
            } else if (this.shared.topFundCategoryData.bestPassive.length > 0) {
              this.shared.isDataTopFundNotAvailable = false;
              this.topFund.topFundchart();
              // this.topFund.updateChartHeight(this.common.chartWidthCollapseView, this.common.chartHeightCollapseView);
            } else {
              this.shared.isShowTopFundLoading = false;
              this.shared.isDataTopFundNotAvailable = true;
            }
          }
          // setTimeout(() => {
            if (this.growtHChart) {
              this.growtHChart.drawMsGrowthChart();
            }
          // }, 10);
        }, 100);
      }, 10);  
  }
  
  openSummaryCardTopFund(event) {
    this.shared.fundName = event.target.category;
    this.shared.showPopupOfSummary = true;
    this.summaryPopupService.fundName = event.target.category;
    this.shared.summaryCardDataLoading = true;
    this.summaryPopupService
      .getSummaryPopupdetail(event.target.fundId)
      .subscribe(
        (res: any) => {
          this.shared.summaryCardDataLoading = false;
          if (res.data) {
            this.shared.fundDetails = res.data[0];
          }
          this.shared.isObservableCall = 0;
          this.shared.summaryChartObservable.next();
        },
        (err) => {
          this.shared.summaryCardDataLoading = false;
          apiError(err);
        }
      );
  }

  openSummaryCardMyFund(event) {
    this.shared.showPopupOfSummary = true;
    this.shared.fundName = event;
  }

  onResizedDivMSGrowthChart(event) {
    this.onResizedDivTopFundChart();
    this.onResizedDivActivePassiveChart();
    this.onResizedDivRiskChart();
    // setTimeout(() => {
    //   if (this.growtHChart) {
    //     this.growtHChart.drawMsGrowthChart();
    //   }
    // }, 10);
  }

  openSummaryCardMsGrowth(event) { }

  getSummeryState(event) {
    this.shared.showPopupOfSummary = event.showState;
  }

  count: any = 0;
  onClick(event) {
    event.click = new Date();
    this.count++;
    this.shared.clickEvents.push({
      name: event.target.innerText,
      time: event.click,
      click: this.count,
    });
    if (this.tooltipPopup != undefined) {
      if (
        !this.tooltipPopup.nativeElement.contains(event.target) &&
        this.shared.showGraphdata
      ) {
        this.shared.showGraphdata = false;
      }
    }
  }

  showBottomView() {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "show_bottom",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.bottomView = !this.shared.bottomView;
  }

  closeChart(event) {
    if (this.shared.selectedDataDropDown.length > 1) {
      this.shared.selectedDataDropDown.forEach((element, i) => {
        if (element.name == event) {
          this.shared.selectedDataDropDown.splice(i, 1);
        }
      });
      if(this.shared.selectedDataDropDown.length == 1){
        this.shared.navHeight = '45px'
        this.shared.addView = false;
        this.shared.showInformation = false;
        this.shared.isAddView = false;
        this.shared.selectedDataDropDown.forEach(element => {
          if(element.name == "TOP FUNDS" || element.name == "TABLE VIEW" || element.name == "MY FUNDS"){
            this.shared.addViewSelected = false;
          }else{
            this.shared.addViewSelected = true;
          }
        });
      }
      if (event == "TOP FUNDS") {
        this.shared.istopFunds = event.select;
      }
      if (event == "ACTIVE VS PASSIVE") {
        this.shared.isactivePassive = event.select;
      }
      if (event == "TABLE VIEW") {
        this.shared.isratingView = event.select;
      }
      if (event == "GROWTH VIEW") {
        this.shared.ismsGrowthView = event.select;
      }
      if (event == "RISK REWARD") {
        this.shared.ismsRiskReward = event.select;
      }
      if (event == "MY FUNDS") {
        this.shared.ismyFunds = event.select;
      }
      this.shared.dropDownData.forEach((element) => {
        if (element.name == event) {
          element.select = false;
        }
      });
    }
  }

  openSummaryCardForfunds(event) {
    this.shared.showPopupOfSummary = true;
    this.shared.fundName = event.name;
    this.shared.showPopupOfSummary = true;
    this.summaryPopupService.fundName = event.name;
    this.shared.summaryCardDataLoading = true;
    this.summaryPopupService.getSummaryPopupdetail(event.fundId).subscribe(
      (res: any) => {
        this.shared.summaryCardDataLoading = false;
        if (res.data) {
          this.shared.fundDetails = res.data[0];
        }
        this.shared.isObservableCall = 0;
        this.shared.summaryChartObservable.next();
      },
      (err) => {
        this.shared.summaryCardDataLoading = false;
        apiError(err);
      }
    );
  }
}

