import { Component, OnInit, Input, Output,  EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { AlertsNotificationsService } from 'src/services/alerts-notifications/alerts-notifications.service';
import { CommonService } from 'src/services/CommonServices/common.service';
import { GrowthViewService } from 'src/services/growth-view/growth-view.service';
import { PrefrenceSettingsService } from 'src/services/PreferencesServices/prefrence-settings.service';
import { SummaryPopupService } from 'src/services/summary-popup/summary-popup.service';
import { Shared } from 'src/utils/common';


const Highcharts = require("highcharts");
@Component(
  {
    selector: 'app-tooltip-alerts',
    templateUrl: './tooltip-alerts.component.html',
    styleUrls: ['./tooltip-alerts.component.scss'],
  })
export class TooltipAlertsComponent implements OnInit {
  @ViewChild('menu') menu:ElementRef;
  @ViewChild("summaryChart") summaryChart: ElementRef;
  showGraph = true;
  commonFund = false;
  @Input() showGraphdatas: boolean;
  isSummeryCardShowing = false
  @Output("closePopup") closePopup: EventEmitter<any> = new EventEmitter();
  XaxesData = [];
  holdingNames:any[]=[];
  private wasInside = false;
  myDataSets: any;
  public text: string;
  public fundName = "Funds Name:";
  public growthChart: any;
  chartData: any[] = [];
  chartYaxisMax:any[]=[];
  maxValueForYaxis:any;
  tooltipChartData:any = [];
  @Input() fundDetail: any;
  @Input() titleName: string;
  @Input() showSummaryPopups = false;
  title:any;
  maxData:any;
  showToolTip:boolean;
  showChart:boolean;
  showTooltipChart:boolean;
  constructor(private eRef: ElementRef, 
              public alertsAndNotificationService: AlertsNotificationsService, 
              public summaryPopup: SummaryPopupService,
              public growthChartservice: GrowthViewService,
              public common: CommonService,
              public preferencesSetting: PrefrenceSettingsService,
              public shared: Shared) {

    this.text = 'no clicks yet';

  }

  ClickedOut(event){
  }

  ngOnChanges() {
    this.openPopup();
  }

  validate(item, name) {
    if(name == 'Price'){
      if (item) {
      const value = new Intl.NumberFormat('fr-FR').format(item);
        return value;
      }else{
        return '--';
      }
    }else{
      if (item) {
        return item.toFixed(2) + '%';
      }else{
        return '--';
      }
    }
  }

  cal(topFund, item){
    if(item == "Best Passive"){
      if(topFund){
        const result = topFund * 100;
        return result.toFixed(2) + '%';
      }else{
        return '--';
      }
    }
    if(item == "Annualized"){
      if(topFund){
        const result = topFund * 100;
        return result.toFixed(2) + '%';
      }else{
        return '--';
      }
    }
    if(item  == "Standard"){
      if(topFund){
        const result = topFund * 100;
        return result.toFixed(2) + '%';
      }else{
        return '--';
      }
    }
    if(item == "ESG"){
      if(topFund){
        return topFund;
      } else{
        return '--';
      }
    }
    if(item == "Refinitiv"){
      if(topFund){
        return topFund;
      } else{
        return '--';
      }
    }
    if(item == "Sharp ratio"){
      if(topFund){
        return topFund.toFixed(2) + '%';
      } else{
        return '--';
      }
    }
  }

  goToGrowthView() {
    this.close();
    setTimeout(() => {
      this.common.goToGrowthView();
    }, 500);
  }

  removeUnderscore(item) {
    if (item) {
      const value = item.replace(/_/g, " ");
      return value;
    }
  }

  openPopup(){
    if(this.shared.topFundGlobalEqualityAlertsX.length > 0){
      this.title = this.shared.topFundGlobalEqualityAlertsX[0].name;
    }
    if(this.fundDetail && this.fundDetail != undefined){
      this.shared.showToolTip = false;
      this.showChart = false;
    }if(this.fundDetail.length == 0 || this.fundDetail == undefined){
      this.shared.showToolTip = true;
      this.showChart = true;
    }
    this.holdingNames = this.fundDetail.top10holding;
    this.chartData = [];
    if (this.shared.performanceToggle == undefined) {
      this.commonFund = false;
      this.relativeChart();
    }
    if (!this.shared.performanceToggle) {
      this.commonFund = false;
      this.relativeChart();
    } else {
      this.commonFund = true;
      this.absoluteChart();
    }
  }

  getPerformance(event) {
    this.shared.performanceToggle = event;
    if (!event) {
      this.relativeChart();
    } else {
      this.absoluteChart();
    }
  }


  
  relativeChart(){
    this.maxData = [];
    this.chartData = [];
    this.chartYaxisMax = [];
    this.tooltipChartData = [];
    if(this.shared.myFundsChart){
      this.tooltipChartData = this.shared.summaryChartData;
    }else{
      this.tooltipChartData = this.shared.chartData;
    }
    this.tooltipChartData.forEach(element => {
      if (element.name == this.title) {
        this.chartData.push(element);
        element.data.forEach(element => {
          this.chartYaxisMax.push(element[1]);
        });
      }
      if (element.isBestPassive) {
        this.chartData.push(element);
        element.data.forEach(element => {
          this.chartYaxisMax.push(element[1]);
        });
        this.maxData = this.maxValueForYaxis;
      }
    });
    setTimeout(()=>{
      this.maxValueForYaxis = Math.max(...this.chartYaxisMax);
      this.maxData = this.maxValueForYaxis;
      if(this.chartData.length > 0){
        if(this.chartData[0].data.length > 0){
          this.showChart = false;
          this.drawChart();
        }else{
          this.showChart = true;
        }
        }else{
          this.showChart = true;
        }
    },200)
  }

  absoluteChart(){
    this.maxData = [];
    this.chartData = [];
    this.chartYaxisMax = [];
    this.tooltipChartData = [];
    if(this.shared.myFundsChart){
      this.tooltipChartData = this.shared.summaryChartData;
    }else{
      this.tooltipChartData = this.shared.chartData;
    }
    this.tooltipChartData.forEach(element => {
      if (element.name == this.title) {
        this.chartData.push({ name: element.name, color: element.color, isBestPassive: element.isBestPassive, isTopFund: element.isTopFund, data: [] });
        if(this.fundDetail.absolute){
          this.fundDetail.absolute.forEach(element => {
            this.chartYaxisMax.push(element.price_value);
            this.maxData = Math.max(...this.chartYaxisMax);
            this.chartData[0].data.push([(new Date(element.date.replace('-', '/'))).getTime(), element.price_value])
          });
        }
      }
    });

    setTimeout(()=>{
      if(this.chartData.length > 0){
        if(this.chartData[0].data.length > 0){
          this.drawChart();
          this.showChart = false;
        }else{
          this.showChart = true;
        }
        }else{
          this.showChart = true;
        }
    },200)
  }
   
  ngOnInit(): void {
    this.shared.summaryChartObservable.subscribe((res) => {
      this.openPopup();
    });
  }

  drawChart() {
    this.showChart = false;
    let maximum = []; 
    let interval:any;
    maximum = this.maxData + 10;
    if(this.maxData < 500){
      interval = 115;
    }else{
      interval = 520;
    }
    if(this.chartData.length > 0){
      this.showTooltipChart = true;
      }else{
        this.showTooltipChart = false;
      }
    const width = this.summaryChart.nativeElement.offsetWidth + 20;
    const condition = Boolean(Math.round(Math.random()))
    this.growthChart = Highcharts.chart(
      "toolTipChart",
      {
        chart: {
          height: "140px",
          width: width,
          backgroundColor: "#000f19",
          color: "#fffff",
          style: {
            fontFamily: "sans-serif",
          },
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        yAxis: {
          categories: [0, maximum],
          tickInterval: interval,
          // max:100,
          // min:0,
          showInLegend: false,
          // categories: [0,max],
          labels: {
            enabled: true,
            style: {
              color: "#606f88",
              fontSize: "14px",
            },
          },
          gridLineWidth: 0.5,
          title: {
            text: "",
            style: { color: "#606f88", fontSize: "17px", },
          },
        },
        tooltip: {
          enabled: true,
          disable: true,
          useHTML: true,
          stickOnContact: true,
          crosshairs: false,
          split: false,
          formatter: function () {
            if (!this.point.series.userOptions.isBestPassive) {
              // return true;
              return this.point.series.name + ' : ' + this.point.y.toFixed(2) + '%';
            } else {
              return false;
            }
          }
          // enabled:this.point.series.userOptions.isBestPassive
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            stickyTracking: false,
            marker: {
              enabled: false,
              symbol: 'circle'
            },
          }
        },
        legend: {
          enabled: false
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            day: condition ? "%e-%b-%y" : "%e\%b\%y",
            month: condition ? "%b-%y" : '%b \'%y'
          },
          labels: {
            enabled: true,
            style: {
              color: "#606f88",
              fontSize: "13px",
            },
          },
        },
        series: this.chartData,

      },
    );
  }

  close() {
    this.closePopup.emit();
  }
  
}
