import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Cacheable } from 'ngx-cacheable';
import { authorizeHeader } from 'src/utils/common-function';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  secEmail:any;
  mobNo:any;
  constructor(public http: HttpClient) { }

  userLogin(userDetail){
   return this.http.post(environment.baseUrl + "login", userDetail, httpOptions);
  }

  @Cacheable()
  acceptTerms(modal){
    return this.http.post(environment.baseUrl + 'agreeTerms',  modal, httpOptions);
  }
  userProfileUpdate(details){
   return this.http.post(environment.baseUrl + 'updateProfile/', details, {headers : authorizeHeader()});
  }
}
