import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { PrefrenceSettingsService } from 'src/services/PreferencesServices/prefrence-settings.service';
import { ProfileService } from 'src/services/profile/profile.service';
import { apiError } from 'src/utils/common-function';
import { decode } from 'string-encode-decode';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class OrganizationComponent implements OnInit {
  @ViewChild('menu') menu: ElementRef;
  @Input() organisationData: any;
  public showAnimation: boolean;
  @Input() userDetailsProfile:any 
  public showDropDownAddSetting: boolean;
  public dropDownValues: any;
  public roleValue: any;
  public companyName: any = "Fundsaiq";
  public location: any;
  public investorType: any;
  public investmentPreference: any;
  public investmentStyle: any;
  public userId: any;
  public userDetails:any;
  public errMsg:any;
  public editCompany:boolean;
  constructor(public prefrenceSetting: PrefrenceSettingsService, public profileService:ProfileService) { }

  ngOnInit(): void {
    this.editCompany = true;
    this.profileService.isPressed = false; 
    if(this.profileService.profileData){
      this.getData(this.profileService.profileData);
    }
    this.profileService.profileObservable.subscribe((res)=>{
      this.getData(res);
      this.userDetails = res;
    })
    this.dropDownValues = [
      { value: "1", select: false },
      { value: "2", select: false },
      { value: "3", select: false },
      { value: "4", select: false },
      { value: "5", select: false },
      { value: "6", select: false },
      { value: "7", select: false },
      { value: "8", select: false },
      { value: "9", select: false },
      { value: "10", select: false },
    ]
  }

  onClick(event) {
    if (this.showDropDownAddSetting != undefined) {
      if (this.showDropDownAddSetting) {
        if (!this.menu.nativeElement.contains(event.target) && this.showDropDownAddSetting) {
          this.showAnimation = false;
          this.showDropDownAddSetting = false;
        }
      }
    }
  }

  updateOrganisationModal() {
    this.profileService.isPressed = true; 
    this.userId = decode(localStorage.getItem("ID"));
    const modal =     {
      "UserId": decode(localStorage.getItem("ID")),
      "Role": this.roleValue == null ? "" : this.roleValue,
      "Location": this.location == null ? "" : this.location,
      "Investor_Type": this.investorType == null ? "" : this.investorType,
      "Investment_Preferences": this.investmentPreference == null ? "" : this.investmentPreference,
      "Investment_Style": this.investmentStyle == null ? "" : this.investmentStyle,
      "Company_Name":this.companyName == null ? "" : this.companyName,
    }
    this.prefrenceSetting.updateOrganisation(modal).subscribe((res) => {
      this.profileService.isPressed = false; 
      if(res["message"] == "Update Successful!"){
        setTimeout(() =>{
          this.profileService.getProfile(decode(localStorage.getItem("ID")));
      }, 1000)
      }
    }, (err) =>{
      this.profileService.isPressed = false; 
      apiError(err);
    });
  }

  getData(res){
      const users = res.data;
      this.companyName = users.Company_Name;
      this.roleValue = users.Role;
      this.location = users.Location;
      this.investorType = users.Investor_Type;
      this.investmentPreference = users.Investment_Preferences;
      this.investmentStyle = users.Investment_Style;
    if(users.Company_Name){
      this.editCompany = true;
    } else{
      this.editCompany = false;
    }
  }

  openDropDown() {
    setTimeout(() => {
      this.showAnimation = !this.showAnimation;
      this.showDropDownAddSetting = !this.showDropDownAddSetting;
    }, 10);
  }

  selectFromDropDown(event) {
    this.roleValue = event.value;
    event.select = true;
    this.showAnimation = false;
    this.showDropDownAddSetting = false;
    this.dropDownValues.forEach(element => {
      if (element.value == event.value) {
        element.select = true;
      } else {
        element.select = false;
      }
    });
  }

  onPressCompanyName(event) {
    this.companyName = event.target.value;
  }

  onPressLocation(event) {
    this.location = event.target.value;
  }

  onPressInvestorType(event) {
    this.investorType = event.target.value;
  }

  onPressInvestmentPreference(event) {
    this.investmentPreference = event.target.value;
  }

  onPressInvestmentStyle(event) {
    this.investmentStyle = event.target.value;
  }

}
