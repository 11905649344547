import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/services/CommonServices/common.service';
import { FundDocumentaionService } from 'src/services/fund-documentation/fund-documentaion.service';
import { GoogleAnalyticsService } from 'src/services/google-Analytics/google-analytics.service';
import { Commons, Shared } from 'src/utils/common';


@Component({
  selector: 'app-fund-documentation',
  templateUrl: './fund-documentation.component.html',
  styleUrls: ['./fund-documentation.component.scss']
})
export class FundDocumentationComponent implements OnInit {
  scrollaBar:boolean;
  category:any;
  constructor(public commonService:CommonService,
             public fundService: FundDocumentaionService,
             public googleAnalyticsEventsService: GoogleAnalyticsService,
             public shared: Shared) {
              this.category = localStorage.getItem('chartCategory');
            }
  
  ngOnInit(): void {
    this.showScrollBar();

  Commons.consistencyScoreChange.subscribe(()=>{
    this.fundService.getConsistencyData();
  });
  }

  selectFund(event, item, url){
    if(event == "kid"){
      let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "fund_documentaion_kiid",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
      item.select = !item.select;
      item.selectFact = false;
      item.selectFunsProspectus = false;
      window.open(url);
    }
    if(event == "fact"){
      let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "fund_documentaion_factsheet",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
      item.select = false;
      item.selectFact = !item.selectFact;
      item.selectFunsProspectus = false;
      window.open(url);
    }
    if(event == "fund"){
      let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "fund_documentaion_prospect",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
      item.select = false;
      item.selectFact = false;
      item.selectFunsProspectus = !item.selectFunsProspectus;
      window.open(url);
    }
  }
  
  showScrollBar(){
    if(this.shared.fundDocumentationData){
      const length = this.shared.fundDocumentationData.length;
      if(length > 6){
        this.scrollaBar = true;
      } else{
        this.scrollaBar = false;
      }
    }
  }

}
