import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subscription } from "rxjs";
import { CommonService } from '../CommonServices/common.service';
import { Commons, Shared } from 'src/utils/common';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class MarketInsightService {
  checkRequestSub:Subscription;
  paging:any;
  
  constructor(public http: HttpClient, public commonService:CommonService, public shared: Shared) { }

  getMarketInsight(paging) {
    return this.http.post(environment.baseUrl + 'market-insights?isTopFund=false', paging, httpOptions)
  }

  getMarketInsightFilteredData(paging){
    return this.http.post(environment.baseUrl + 'market-insights?isTopFund=true' , paging, httpOptions)
  }

  getDataFromApi(modal){
    this.shared.marketInsightLoading = true;
    this.shared.marketInsightDataIsAvailable = true;
    if(this.checkRequestSub){
      this.checkRequestSub.unsubscribe();
    }
    this.checkRequestSub = this.getMarketInsightFilteredData(modal).subscribe((data:any[])=>{
     this.shared.listItems = [];
      this.shared.marketInsightTopFund = [];
      if(data.length > 0){
        this.shared.listData = data;
        this.shared.toggleMarket = false;
        data.forEach((ele)=>{
        let newData:any;
          ele.show = false;
          if(ele.Content){
           newData = ele.Content.replace("<p>", "").replace("<\/p>", "").replace("↵", "");
          }
          ele.Content = newData;
          let newDataDescription = ele.Description.replace("<p>", "").replace("<\/p>", "").replace("↵", "");
          ele.Description = newDataDescription;
        })
        
        this.shared.marketInsightTopFund = this.shared.listItems;
      }
      else{
        this.shared.toggleMarket = true;
        this.shared.marketInsightDataIsAvailable = true;
        this.shared.marketInsightLoading = true;
      }
      this.getDataForConsistency();
    },(err)=>{
      this.shared.marketInsightLoading = false;
      this.shared.marketInsightDataIsAvailable = true;
    })
  }

  getDataForConsistency(){
    this.shared.listItems = [];
    if(this.shared.toggleMarket){
      if(this.shared.newsFeedsData.length > 0){
        this.shared.marketInsightDataIsAvailable = true;
        this.shared.marketInsightLoading = false;
        this.shared.listItems = this.shared.newsFeedsData;
      }else{
        this.shared.marketInsightDataIsAvailable = false;
      }
    }else{
      if(this.shared.listData && this.shared.listData.length > 0){
        this.shared.marketInsightLoading = false;
        if(this.shared.isBestPassive){
          this.shared.listItems = this.shared.listData;
        }else{
          this.shared.listData.forEach((ele)=>{
            if(Commons.consistencyFundId.includes(ele.Fund_Id)){
              this.shared.listItems.push(ele);
            }
          });
        }
      }else{
        this.shared.marketInsightLoading = false;
        this.shared.marketInsightDataIsAvailable = false;
      }
    }
    Commons.fundIds;
    if(this.shared.listItems && this.shared.listItems.length > 0){
      this.shared.marketInsightDataIsAvailable = true;
    }else{
      this.shared.marketInsightDataIsAvailable = false;
    }
  }

}
