import { Component, OnInit, ViewChild, Output, EventEmitter, Input, ElementRef } from '@angular/core';
import { UserDashboardComponent } from '../user-dashboard/user-dashboard.component';
import { CommonService } from 'src/services/CommonServices/common.service';
import { Shared } from 'src/utils/common';

const Highcharts = require("highcharts");
@Component({
  selector: 'app-top-fund',
  templateUrl: './top-fund.component.html',
  styleUrls: ['./top-fund.component.scss']
})
export class TopFundComponent implements OnInit {
@ViewChild('userDashboard') userDashBoardComponent:UserDashboardComponent;
@ViewChild('topFundContainer') topFundContainer:ElementRef;
@Output("topFund")topFund: EventEmitter<any> = new EventEmitter();
@Input () diamond:boolean;
@Input () dataNotAvailable:boolean;
@Output("checkDiamond")checkDiamond: EventEmitter<any> = new EventEmitter();
selectedItems = "Global_Equity_Large_Cap";
totalyear = "5";
selectedCatYear = "2021";
selectedCategory:any;
selectedDataDropDown:any;
selected;
selectColoum = false;
// dataNotAvailable = false;
  constructor(public common:CommonService, public shared: Shared) { }

  ngOnInit(): void {
    this.shared.selectedCategory.subscribe((res) => {
      if(res && res != "undefined"){
        this.selectedCategory = res;
      }
    });
    const selectCate = localStorage.getItem("chartCategory");
    if(selectCate && selectCate != "undefined") {
      this.selectedCategory = localStorage.getItem("chartCategory");
    } 
    
    this.selectColoum = false;
    this.topFund.emit({value:this.selectedItems,selectYear:this.selectedCatYear, totalYear:this.totalyear, selectdValue:true });
  }

  topFundchart(event){
    this.checkDiamond.emit(event);
  }


  getTopFundGlobalEquality(event, select, total, bool){
    this.topFund.emit({value:event,selectYear:select, totalYear:total, selectdValue:bool });
  }

  resi(){
    alert('test');
    Highcharts.resize();
  }
}
