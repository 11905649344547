import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "src/services/CommonServices/common.service";
declare const Plotly: any;
import { NavigationEnd, Router } from "@angular/router";
import { NewsFundsService } from "src/services/news-for-funds/news-funds.service";
import { SummaryPopupService } from "src/services/summary-popup/summary-popup.service";
import { FundDocumentaionService } from "src/services/fund-documentation/fund-documentaion.service";
import { FundAlertsService } from "src/services/fund-Alerts/fund-alerts.service";
import { apiError } from "src/utils/common-function";
import { GoogleAnalyticsService } from "src/services/google-Analytics/google-analytics.service";
import { Commons, Shared } from "src/utils/common";
var Highcharts = require('highcharts');
declare var $: any;
declare let gtag: Function;
@Component({
  selector: "app-active-passive",
  templateUrl: "./active-passive.component.html",
  styleUrls: ["./active-passive.component.scss"],
})
export class ActivePassiveComponent implements OnInit {
  @ViewChild('newsComponent') newsComponent: ElementRef;
  @ViewChild('alertComponent') alertComponent: ElementRef;
  @Input() showTooltip: boolean;
  @Input() show: boolean;
  @Input() heightGraph: any;
  @Input() widthGraph: any;
  newsFundData: any;
  newsLoading: boolean;
  dataNot: boolean;
  showPopupOfSummary = false;
  showAlertInboxFeed = false;
  newsFeedData: any[] = [];
  fundDetails: any[] = [];
  fundName: any;
  selectedCategory: any;
  maxMinRange: any;
  selectColoum = false;
  fundAlertsData:any = [];
  alertsData:any;
  alertLoading:boolean;
  dataAvailable:boolean;
  /* The plot target container. */
  @ViewChild("plotContainer") plotContainer: ElementRef;

  constructor(public http: HttpClient, public common: CommonService, public newsFund: NewsFundsService,
    public router: Router, public summaryPopupService: SummaryPopupService, public fundService: FundDocumentaionService,
    public fundAlerts: FundAlertsService, public googleAnalyticsEventsService: GoogleAnalyticsService,
    public shared: Shared) {
  }

  ngOnInit() {
    this.shared.selectedCategory.subscribe((res) => {
      if (res && res != "undefined") {
        this.selectedCategory = res;
      }
    });
    const selectCate = localStorage.getItem("chartCategory");
    if (selectCate && selectCate != "undefined") {
      this.selectedCategory = localStorage.getItem("chartCategory");
    }

    this.selectColoum = false;
  }

  ngAfterViewInit() {
    if(Commons.userDashboardshow){
      setTimeout(() => {
        this.initPlot();
      }, 100);
    }
  }

  initPlot() {
    let testMaxMin = [];
    let min = [];
    let max = [];
    let minTopFund = [];
    let maxTopFund = [];
    let allTopFunds = [];

    this.shared.activePassivehighchartDataFor1Yr.forEach(element => {
      if(!this.shared.isBestPassive){
        if(element.isTopFund){
          allTopFunds.push(element.x);
        }
      }else if(this.shared.isBestPassive){
        allTopFunds.push(element.x);
      }
    });
    this.shared.activePassivehighchartDataFor3Yr.forEach(element => {
      if(!this.shared.isBestPassive){
        if(element.isTopFund){
          allTopFunds.push(element.x);
        }
      }else if(this.shared.isBestPassive){
        allTopFunds.push(element.x);
      }
    });
    this.shared.activePassivehighchartDataFor5Yr.forEach(element => {
      if(!this.shared.isBestPassive){
        if(element.isTopFund){
          allTopFunds.push(element.x);
        }
      }else if(this.shared.isBestPassive){
        allTopFunds.push(element.x);
      }
    });
    min.push(Math.min(...this.shared.activePassive_Y_percenties_1yr));
    min.push(Math.min(...this.shared.activePassive_Y_percenties_3yr));
    min.push(Math.min(...this.shared.activePassive_Y_percenties_5yr));
    min.push(Math.min(...this.shared.activePassive_Y_yearly1));
    min.push(Math.min(...this.shared.activePassive_Y_yearly3));
    min.push(Math.min(...this.shared.activePassive_Y_yearly5));

    max.push(Math.max(...this.shared.activePassive_Y_percenties_1yr));
    max.push(Math.max(...this.shared.activePassive_Y_percenties_3yr));
    max.push(Math.max(...this.shared.activePassive_Y_percenties_5yr));
    max.push(Math.max(...this.shared.activePassive_Y_yearly1));
    max.push(Math.max(...this.shared.activePassive_Y_yearly3));
    max.push(Math.max(...this.shared.activePassive_Y_yearly5));
    testMaxMin.push({ min: Math.min(...min), max: Math.max(...max) });
    minTopFund.push(Math.min(...allTopFunds));
    maxTopFund.push(Math.max(...allTopFunds));
    if(minTopFund[0] == maxTopFund[0]){
      minTopFund = [];
      maxTopFund = [];
      minTopFund.push(0);
      maxTopFund.push(1);
    }
    if (this.show) {
      this.showTooltip = false;
    } else {
      this.showTooltip = true;
    }
    this.maxMinRange = testMaxMin;
    (function (H) {
      H.Legend.prototype.setItemEvents = function (item, legendItem, useHTML) {

        const legend = this,
          boxWrapper = legend.chart.renderer.boxWrapper,
          activeClass = 'highcharts-legend-' + (item.series ? 'point' : 'series') + '-active',
          hasLinkedSeries = function (item) {
            if (item.linkedSeries && item.linkedSeries.length) {
              return true;
            }
            else {
              item.linkedSeries = [];
              item.linkedSeries.push(item.linkedParent);
              // item.linkedSeries = ;
              return true;
            }
          },
          setLinkedSeriesState = function (item, state) {
            item.linkedSeries.forEach(function (elem) {
              if(elem){
                elem.setState(state)
              }
            })
          };
        // Set the events on the item group, or in case of useHTML, the item itself (#1249)
        (useHTML ? legendItem : item.legendGroup).on('mouseover', function () {
          if (item.visible) {
            item.setState('hover');
            // Add hover state to linked series
            if (hasLinkedSeries(item)) {
              setLinkedSeriesState(item, 'hover')
            }
            // A CSS class to dim or hide other than the hovered series
            boxWrapper.addClass(activeClass);
            /*= if (build.classic) { =*/
            legendItem.css(legend.options.itemHoverStyle);
            /*= } =*/
          }
        })
          .on('mouseout', function () {
            /*= if (build.classic) { =*/
            legendItem.css(H.merge(item.visible ? legend.itemStyle : legend.itemHiddenStyle));
            /*= } =*/
            // A CSS class to dim or hide other than the hovered series
            boxWrapper.removeClass(activeClass);
            // Remove hover state from linked series
            if (hasLinkedSeries(item)) {
              setLinkedSeriesState(item, null)
              // setLinkedSeriesStateRemove(item)
            }
            item.setState();
          })
          .on('click', function (event) {
            const strLegendItemClick = 'legendItemClick',
              fnLegendItemClick = function () {
                if (item.setVisible) {
                  item.setVisible();
                }
              };
            // Pass over the click/touch event. #4.
            event = {
              browserEvent: event
            };
            // click the name or symbol
            if (item.firePointEvent) { // point
              item.firePointEvent(strLegendItemClick, event, fnLegendItemClick);
            } else {
              H.fireEvent(item, strLegendItemClick, event, fnLegendItemClick);
            }
          });
      };
    })(Highcharts)
    const controls = document.getElementById('chart-controls');
    const chart = Highcharts.chart("containers-active-passive", {
      chart: {
        // zoomBySingleTouch: true,
        backgroundColor: "#000f19",
        zoomType: 'xy',
        height: this.heightGraph,
        width: this.widthGraph,
        resetZoomButton: {
          theme: {
            display: 'none'
          }
        },
        events: {
          load: function (event) {
            if (event.target.chartWidth > 1000) {
              event.target.legend.maxItemWidth = 400;
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      mapNavigation: {
        enabled: false
      },
      title: {
        text: '',
      },
      subtitle: {
        text: "",
      },
      legend: {
        enabled: this.showTooltip,
        color: "#617087",
        layout: "horizontal",
        width: this.widthGraph / 1.3,
        itemWidth: this.widthGraph / 3.8,
        itemDistance: 20,
        itemMarginTop: 10,
        itemStyle: {
          color: '#617087',
          width: this.widthGraph / 1.7,
        },
        itemHoverStyle: {
          color: null
        },
      },
      xAxis: {
        title: {
          margin: 2,
          text: "Percentiles",
          style: { color: "#606f88", fontSize: "12px" },
        },
        gridcolor: "rgb(65, 64, 64);",
        max: maxTopFund,
        min: minTopFund,
        allowDecimals: true,
        startOnTick: true,
        gridLineWidth: 0.5,
        endOnTick: true,
        showLastLabel: true,
        labels: {
          padding:20,
          style: {
            fontWeight: "bold"
          },
          formatter: function () {
            const val = this.value * 100;
            return val.toFixed(2) + '%';
          }
        }
      },
      yAxis: {
        min: testMaxMin[0].min - ((testMaxMin[0].max - testMaxMin[0].min) * 0.05),
        max: testMaxMin[0].max,
        startOnTick: true,
        gridcolor: "rgb(65, 64, 64);",
        gridLineWidth: 0.5,
        title: {
          margin: 2,
          text:"Absolute Return",
          style: { color: "#606f88", fontSize: "12px" },
        },
        
        labels: {
          padding: 40,
          formatter: function () {
            const val = this.value;
            return val.toFixed(2) + '%';
          }
        }
      },
      tooltip: {
        enabled: this.showTooltip,
        useHTML: true,
        followPointer: false,
        borderRadius: 10,
        stickOnContact: true,
        outside: false,
        padding: 18,
        backgroundColor: '#000f19',
        hideDelay: null,
        style: {
          pointerEvents: 'auto'
        },
        formatter: function () {
          console.log('this.point',this.point);
            if (this.point.isTopFund) {
              let newVal:any;
                if (this.point.fundSize != null) {
                  newVal = Math.abs(Number(this.point.fundSize)) >= 1.0e+9

                    ? Math.round((Math.abs(Number(this.point.fundSize)) / 1.0e+9)) + "B"
                    : Math.abs(Number(this.point.fundSize)) >= 1.0e+6

                      ? Math.round((Math.abs(Number(this.point.fundSize)) / 1.0e+6)) + "M"
                      // Three Zeroes for Thousands
                      : Math.abs(Number(this.point.fundSize)) >= 1.0e+3
                        ?
                        Math.round((Math.abs(Number(this.point.fundSize)) / 1.0e+3)) + "K"
                        : Math.abs(Number(this.point.fundSize));
                }
              return `<div id="tooltip-buttons-active"><div class="tooltip-buttons-parent"><button class="button-tooltip" id="summary-card-popup-active" onmouseover="this.style.borderColor='` + this.point.color + `'" onmouseout="this.style.borderColor='#58677f'"
                  type="button">SUMMARY CARD</button><br><div class="btn-flex"><button class="button-tooltip fact-btn" id="fact-sheet"
                  onmouseover="this.style.borderColor='`+ this.point.color + `'" onmouseout="this.style.borderColor='#58677f'">FACTSHEET</button><button class="button-tooltip" id="kiid"
                  onmouseover="this.style.borderColor='`+ this.point.color + `'" onmouseout="this.style.borderColor='#58677f'">KIID</button></div><br><div class="btn-flex" style="margin-top:-15px;"><button class="button-tooltip fact-btn" id="alerts-active"
                  onmouseover="this.style.borderColor='`+ this.point.color + `'" onmouseout="this.style.borderColor='#58677f'">ALERTS</button><button class="button-tooltip" id="news-feeds-active"
                  onmouseover="this.style.borderColor='`+ this.point.color + `'" onmouseout="this.style.borderColor='#58677f'">MARKET INSIGHTS</button></div></div>`
                + `<br/>` +
                `<span style="color:#617088;max-width:100%; white-space: normal;margin-bottom: -8px;">` + this.point.options.name + `</span><br/>`
                +
                `<span style="color:#617088;max-width:100%; white-space: normal;margin-bottom: -8px;">` + Highcharts.dateFormat('%d-%m-%Y', new Date(this.point.date)) + `</span><br/>` + '<span style="color:' + this.point.color + '">' + 'Absolute Return' + `: ` + this.point.y.toFixed(2) + '% (' + this.point.year + 
                `)</span><br/></div><div id="newsFeedDataOnTooltip-active" style="position: relative;top: -15px;left: -12px;"> </div><span style="color:white;max-width:100%; white-space: normal;display: block;  margin-bottom: -8px;">`
                  + "Size=€"+(newVal != undefined ? newVal : 0)+" Consistency= 5 "+'</span>';
            } else {
              return `<span style="color:` + this.point.color + `">` + this.point.y.toFixed(2) + '% (' + this.point.year + `)</span><br><span style="color:#617088;position: relative;top: 5px;">` + this.point.options.name + `</span>`;
            }
        },
      },
      plotOptions: {
        series: {
          stickyTracking: false,
          enableMouseTracking: this.showTooltip,
          point: {
            events: {
              mouseOver: (function (event) {
                if (this.showTooltip) {
                  setTimeout(() => {
                    // alert('tes')
                    if(document.getElementById("news-feeds-active")){
                      document.getElementById("news-feeds-active").addEventListener('click', this.getNewsDataActive.bind(this, event))
                    }
                  }, 100);
                  setTimeout(() => {
                    if (document.getElementById("fact-sheet")) {
                      document
                        .getElementById("fact-sheet")
                        .addEventListener("click", this.getFactsheet.bind(this, event));
                    }
                  }, 100);
                  setTimeout(() => {
                    if (document.getElementById("kiid")) {
                      document
                        .getElementById("kiid")
                        .addEventListener("click", this.getKiid.bind(this, event));
                    }
                  }, 100);
                  setTimeout(() => {
                    if(document.getElementById("summary-card-popup-active")){
                      document.getElementById("summary-card-popup-active").addEventListener('click', this.openSummaryCard.bind(this, event))
                    }
                  }, 100);
                  setTimeout(() => {
                    if(document.getElementById("alerts-active")){
                      document.getElementById("alerts-active").addEventListener('click', this.getAlertsDataActive.bind(this, event))
                    }
                  }, 100);
                }
              }).bind(this),
            }
          },
        },
      },
      series: [
        {
          id: "1year",
          name: "1 Yr Active",
          linkedTo: "1yearPassive",
          showInLegend: true,
          type: "line",
          lineWidth: 0,
          borderWidth: 2,
          turboThreshold: 5000,
          color: "#AB036E",
          marker: {
            enabled: true,
            symbol: "circle",
          },
          showgrid: true,
          data: this.shared.activePassivehighchartDataFor1Yr.sort(),
          states: {
            hover: {
              lineWidth: 0,
              enabled: false,
            },
            inactive: {
              lineWidth: 0,
            },
            normal: {
              lineWidth: 0,
            },
            select: {
              lineWidth: 0,
            }
          },
        },
        {
          linkedTo: "1year",
          id: "1yearPassive",
          showInLegend: this.shared.legendOneYear ? true : false,
          name: this.shared.legendOneYear,
          type: "line",
          turboThreshold: 5000,
          enableMouseTracking: true,
          marker: {
            enabled: false,
            symbol: "circle",
            radius:2
          },
          color: "#FFC302",
          data: this.shared.activePassiveHighchartDataFor_yearly1
        },
        {
          id: "3year",
          name: "3 Yr Active",
          linkedTo: "3yearPassive",
          showInLegend: true,
          type: "line",
          lineWidth: 0,
          borderWidth: 2,
          turboThreshold: 5000,
          color: "#C71D39",
          marker: {
            enabled: true,
            // lineWidth: 2,
            symbol: "circle",
          },
          showgrid: true,
          data: this.shared.activePassivehighchartDataFor3Yr.sort(),
          states: {
            hover: {
              lineWidth: 0,
              enabled: false,
            },
            inactive: {
              lineWidth: 0,
            },
            normal: {
              lineWidth: 0,
            },
            select: {
              lineWidth: 0,
            }
          }
        },
        {
          linkedTo: "3year",
          id: "3yearPassive",
          turboThreshold: 5000,
          showInLegend: this.shared.legendThreeYear ? true : false,
          name: this.shared.legendThreeYear,
          type: "line",
          color: "#99FF66",
          enableMouseTracking: true,
          marker: {
            enabled: false,
            symbol: "circle",
            radius:2
          },
          data: this.shared.activePassiveHighchartDataFor_yearly3
        },
        {
          id: "5year",
          name: "5 Yr Active",
          linkedTo: "5yearPassive",
          showInLegend: true,
          type: "line",
          lineWidth: 0,
          borderWidth: 2,
          turboThreshold: 5000,
          color: "#FF5734",
          marker: {
            enabled: true,
            symbol: "circle",
          },
          showgrid: true,
          data: this.shared.activePassivehighchartDataFor5Yr.sort(),
          states: {
            hover: {
              lineWidth: 0,
              enabled: false,
            },
            inactive: {
              lineWidth: 0,
            },
            normal: {
              lineWidth: 0,
            },
            select: {
              lineWidth: 0,
            }
          }
        },
        {
          linkedTo: "5year",
          turboThreshold: 5000,
          id: "5yearPassive",
          showInLegend: this.shared.legenedFiveYear ? true : false,
          enableMouseTracking: true,
          name: this.shared.legenedFiveYear,
          type: "line",
          color: "#66CCCC",
          marker: {
            enabled: false,
            radius:2
          },
          data: this.shared.activePassiveHighchartDataFor_yearly5
        },
      ],
    }, function (chart) {
      $(chart.container).bind('dblclick', function () {
        chart.xAxis[0].setExtremes(0, 1);
        // chart.xAxis[0].update({
        //   range: [0.9, 1],
        // });
        chart.yAxis[0].update({
          range: testMaxMin,
        });

      });
    },
      Highcharts.setOptions({
        lang: {
          resetZoom: ""
        }
      })
    );
    const gd = document.getElementById('graph');
    setTimeout(() => {
      if(document.getElementById('zoom-in')){
        document.getElementById('zoom-in').addEventListener('click', () => {
          let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "zoomIn",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
          if (chart.xAxis) {
            if (chart.xAxis.length > 0) {
              const min = chart.xAxis[0].getExtremes().min,
                max = chart.xAxis[0].getExtremes().max;
              const ymin = chart.yAxis[0].getExtremes().min,
                ymax = chart.yAxis[0].getExtremes().max;
              if (max === 1) {
                if (min === 0) {
                  chart.xAxis[0].setExtremes((0.4), (max));
                  chart.yAxis[0].setExtremes((ymin), (ymax + 0.2));
                } else {
                  if(min < 0.99){
                    if(min > 0.98 || min == 0.98){
                      chart.xAxis[0].setExtremes((min + 0.002), (max));
                      chart.yAxis[0].setExtremes((ymin), (ymax + 0.2));
                    } if( min > 0.80 && min < 0.98){
                      chart.xAxis[0].setExtremes((min + 0.02), (max));
                      chart.yAxis[0].setExtremes((ymin), (ymax + 0.2));
                    }else if(min < 0.80 || min == 0.80){
                      chart.xAxis[0].setExtremes((min + 0.05), (max));
                      chart.yAxis[0].setExtremes((ymin), (ymax + 0.2));
                    }
                  }else{
                    chart.xAxis[0].setExtremes((max), (max));
                    chart.yAxis[0].setExtremes((ymin), (ymax + 0.2));
                  }
                }
              }
              if (max != 1) {
                chart.xAxis[0].setExtremes((min), (1));
                chart.yAxis[0].setExtremes((testMaxMin[0].min / 0.4), (testMaxMin[0].max / 0.4));
              }
            }
          }
        });
      }
    if(document.getElementById('zoom-out')){
      document.getElementById('zoom-out').addEventListener('click', () => {
        let createdTime = new Date();
        let modal = {
          "createdAt": createdTime,
          "eventName": "zoomOut",
          "sessionId": Commons.sessionId,
          "userId": Commons.userDetails.userID,
          "userEmail": Commons.userDetails.Email,
          "userName": Commons.userDetails.Name,
          "platform": Commons.platform
        }
        this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
        if (chart.xAxis) {
          if (chart.xAxis.length > 0) {
            const min = chart.xAxis[0].getExtremes().min,
              max = chart.xAxis[0].getExtremes().max;
            const ymin = chart.yAxis[0].getExtremes().min,
              ymax = chart.yAxis[0].getExtremes().max;
            if (max != 1) {
              chart.xAxis[0].setExtremes((min / 1.1), (1));
              chart.yAxis[0].setExtremes((ymin / 1.1), (ymax + 0.25));
            } if (max === 1) {
              const yamax = ymax + 0.25;
              chart.xAxis[0].setExtremes((min / 1.1), (max));
              chart.yAxis[0].setExtremes((ymin / 1.1), (yamax));
            }
          }
        }
      });
    }
    
    if(document.getElementById('home')){
      document.getElementById('home').addEventListener('click', () => {
        let createdTime = new Date();
        let modal = {
          "createdAt": createdTime,
          "eventName": "active_home",
          "sessionId": Commons.sessionId,
          "userId": Commons.userDetails.userID,
          "userEmail": Commons.userDetails.Email,
          "userName": Commons.userDetails.Name,
          "platform": Commons.platform
        }
        this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
        chart.xAxis[0].setExtremes(null, null);
        chart.yAxis[0].setExtremes(null, null);
      });
    }
     
    }, 20)

  }


  openSummaryCard(event) {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": event.target.fundId + "_summary_popup",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.alertsAndNotificationSummary = false;
    this.showPopupOfSummary = true;
    this.fundName = event.target.name;
    this.showPopupOfSummary = true;
    this.summaryPopupService.fundName = event.target.name;
    this.shared.summaryCardDataLoading = true;
    this.summaryPopupService.getSummaryPopupdetail(event.target.fundId).subscribe((res: any) => {

      this.shared.summaryCardDataLoading = false;
      if (res.data) {
        this.fundDetails = res.data[0];
      }
      this.shared.isObservableCall = 0;
      this.shared.summaryChartObservable.next();
    
    }, (err) => {
      this.shared.summaryCardDataLoading = false;
      apiError(err);
    })
  }

  getSummeryState(event) {
    this.showPopupOfSummary = event.showState;
  }

  getAlertState(event) {
    this.showAlertInboxFeed = event.showState;
  }

  openAlertsForActive() {
    this.showAlertInboxFeed = true;
  }

  getNewsDataActive(event) {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": event.target.fundId + "_market_tooltip",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.newsFundData = event;
    this.newsLoading = true;
    this.dataNot = false;
    this.newsFeedData = [];
    this.newsFund.getNewsForFund(event.target.fundId).subscribe((res) => {
      this.newsLoading = false;
      if (res["data"]) {
        res["data"].forEach(element => {
          if(!this.newsFeedData.some((item) => item.Title == element.Title && item.Provider == element.Provider)){
            this.newsFeedData.push(element);
          }
        });
      }
      else {
        this.dataNot = true;
      }
      this.newsLoading = false;
      // this.dataNot = true;
      this.getMarketNewsData();
    }, (err) => {
      this.dataNot = true;
      this.newsLoading = false;
    })
    document.getElementById("tooltip-buttons-active").style.display = "none";
    document.getElementById("newsFeedDataOnTooltip-active").style.display = "block";
    setTimeout(() => {
      document.getElementById("newsFeedDataOnTooltip-active").innerHTML = this.newsComponent.nativeElement.innerHTML;
    }, 10)

  }

  ClickedOut(event) {
    if(event.target.className === "pg-form-pages login-page") {
      document.getElementById("close-documentation").click();
    }
  }

  openDialog() {
    this.shared.popupTimer = 3;
      document.getElementById("openModalButton").click();
      const interval = setInterval(() => {
        if (this.shared.popupTimer > 0) {
          this.shared.popupTimer--;
        } else if (this.shared.popupTimer == 0) {
          document.getElementById("close-documentation").click();
          clearInterval(interval);
        }
      }, 1000);
  }

  getFactsheet(event){
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": event.target.fundId + "_factsheet_tooltip",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if (this.shared.fundDocumentationData.length > 0) {
      this.shared.fundDocumentationData.forEach(element => {
        if (event.target.name == element.Name && element.Factsheet_Url != "null" && element.Factsheet_Url != undefined) {
          window.open(element.Factsheet_Url);
        }else{
          this.openDialog();
        }
      });
    } else {
      this.openDialog();
    }
   }

   getKiid(event){
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.target.fundId + "_kiid_tooltip",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if (this.shared.fundDocumentationData.length > 0) {
     this.shared.fundDocumentationData.forEach(element => {
       if(event.target.name == element.Name && element.KIID_Url != "null" && element.KIID_Url != undefined){
         window.open(element.KIID_Url);
        }else{
          this.openDialog();
        }
     });
    } else {
      this.openDialog();
    }
   }

  getMarketNewsData() {
    setTimeout(() => {
      document.getElementById("newsFeedDataOnTooltip-active").innerHTML = this.newsComponent.nativeElement.innerHTML;
    }, 5)
    setTimeout(() => {
      document.getElementById("closeNewsActive").addEventListener('click', this.closeNewsActive.bind(this))
    }, 10)
  }

  getAlertsDataActive(event) {
    let data:any = [];
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.target.fundId + "_alerts_tooltip",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.alertsData = event;
     this.alertLoading = true;
     this.dataAvailable = true;
     
    this.fundAlerts.getFundAlerts(event.target.fundId).subscribe((res) => {
       this.alertLoading = false;
       this.fundAlertsData = [];
       if(res["length"] > 0){
         data = res;
         data.forEach(element => {
        const test = element.event_value;
        const regex = /\s* :* *\T.*\)(|(?=\s+\}))/g;
          const subst = `""`;
          var d = test.replace(regex, subst);
          var obj = d.replace(/'/g, '"').replace(/None/g, '"None"');
          
          element.event_value = JSON.parse(obj);
        this.fundAlertsData.push({
          occurrenceDate: element.effective_date,
          publishedData: element.date_event_noticed,
          newText: element.event_value.new_value != "None" ? element.event_value.new_value : "",
          name: element.Fund_Name, selectLike: false, fundId: element.ShareClass_Id,
          data: element.alert_type + ' : ' + element.event_type.replace(/_/g, " "),
          text: element.event_value.old_value != "None" ? element.event_value.old_value : "",
          selectDelete: false, selectClose: false, show: false
        });
      });
       }else{
        this.dataAvailable = false;
       }
       this.alertData();
      }, (err) => {
        this.alertLoading = false;
        this.dataAvailable = false;
      });
      document.getElementById("tooltip-buttons-active").style.display = "none";
    document.getElementById("newsFeedDataOnTooltip-active").style.display = "block";
    setTimeout(() => {
      document.getElementById("newsFeedDataOnTooltip-active").innerHTML = this.alertComponent.nativeElement.innerHTML;
    }, 10)
  }

  alertData() {
    setTimeout(() => {
      document.getElementById("newsFeedDataOnTooltip-active").innerHTML = this.alertComponent.nativeElement.innerHTML;
    }, 5)
    setTimeout(() => {
      document.getElementById("closeNewsActive").addEventListener('click', this.closeNewsActive.bind(this))
    }, 10)
  }


  closeNewsActive() {
    setTimeout(() => {
      document.getElementById("newsFeedDataOnTooltip-active").style.display = "none";
      document.getElementById("tooltip-buttons-active").style.display = "block";
    }, 10)
  }

  goToAction() {
    // alert('test data on active');
  }

  /** On resize this method triggers & resize the plot. */
  public onResize() {
    // Plotly.Plots.resize(this.plotContainer.nativeElement);
  }
}
