import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/services/CommonServices/common.service';
import { Router } from '@angular/router';
import { Shared } from 'src/utils/common';

@Component({
  selector: 'app-methedology',
  templateUrl: './methedology.component.html',
  styleUrls: ['./methedology.component.scss']
})
export class MethedologyComponent implements OnInit {

  constructor(public common:CommonService,public router:Router, public shared: Shared ) { 
    if(!this.shared.isLogin){
      this.router.navigateByUrl('/login')
      }
  }

  ngOnInit() {
    // localStorage.setItem('activeRaoter','dashboard');
    // localStorage.setItem('login', '1')
  }
  
  ClickedOut(event){
    if(event.target.className == "pg-form-pages login-page"){
      document.getElementById("close-mthodology-popup").click();
    }
  }
  
}
