import { Component, Input, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { CommonService } from 'src/services/CommonServices/common.service';
import { GrowthViewService } from 'src/services/growth-view/growth-view.service';
import { PrefrenceSettingsService } from 'src/services/PreferencesServices/prefrence-settings.service';
import { SummaryPopupService } from 'src/services/summary-popup/summary-popup.service';
import { DatePipe } from "@angular/common";
import { GoogleAnalyticsService } from 'src/services/google-Analytics/google-analytics.service';
import { Commons, Shared } from 'src/utils/common';
// import { events } from '@angular'

const Highcharts = require("highcharts");

@Component({
  selector: 'app-summary-popup',
  templateUrl: './summary-popup.component.html',
  styleUrls: ['./summary-popup.component.scss'],
})
export class SummaryPopupComponent implements OnInit {
  commonFund = false;
  title = "Funds Name :";
  @ViewChild("menu") menu: ElementRef;
  @ViewChild("summaryChart") summaryChart: ElementRef;
  showGraph = true;
  closePopup = false;
  @Input() showSummaryPopups = false;
  @Input() titleName: string;
  @Input() fundDetail: any;
  @Output() sendState: EventEmitter<any> = new EventEmitter();
  summaryPopupGrowth: Subject<any> = new Subject<any>();
  XaxesData = [];
  holdingNames:any[]=[];
  public growthChart: any;
  maxValueForYaxis:any;
  myDataSets: any;
  ESGData:any[]=[];
  chartData: any[] = [];
  chartYaxisMax:any[]=[];
  maxData:any;
  showChart:boolean;
  summaryData:any = [];
  constructor(private eRef: ElementRef, public common: CommonService, public growthChartservice: GrowthViewService,
    public summaryPopup: SummaryPopupService, public preferencesSetting: PrefrenceSettingsService, public datePipe: DatePipe,
    public googleAnalyticsEventsService: GoogleAnalyticsService, public shared: Shared) {
    setTimeout(() => {
    }, 100)
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.titleName == undefined) {
        this.titleName = this.title;
      } else {
        this.titleName;
      }
    }, 100)
    this.shared.summaryChartObservable.subscribe((res) => {
      if(this.shared.isObservableCall == 0){
        this.openPopup();
      }
      this.shared.isObservableCall++;
    });
  }

  goToGrowthView() {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "summary_goto_growth_view",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.showSummaryPopups = false;
    this.sendState.emit({ 'showState': this.showSummaryPopups });
    setTimeout(() => {
      this.common.goToGrowthView();
    }, 500);
  }

  validate(item, name) {
    if(name == 'Price'){
      if (item) {
      const value = new Intl.NumberFormat('fr-FR').format(item);
      // return item.toFixed(2);
        return value;
      }else{
        return '--';
      }
    }else if(name == 'Risk'){
      if (item) {
        return item.toFixed(2) + '%';
      }else{
        return '--';
      }
    }
  }

  index: any
  ngOnChanges() {
    this.openPopup();
  }

  cal(topFund, item){
    if(item == "Best Passive"){
      if(topFund){
        const result = topFund * 100;
        return result.toFixed(2) + '%';
      }else{
        return '--';
      }
    }
    if(item == "Annualized"){
      if(topFund){
        const result = topFund * 100;
        return result.toFixed(2) + '%';
      }else{
        return '--';
      }
    }
    if(item  == "Standard"){
      if(topFund){
        const result = topFund * 100;
        return result.toFixed(2) + '%';
      }else{
        return '--';
      }
    }
    if(item == "ESG"){
      if(topFund){
        return topFund;
      } else{
        return '--';
      }
    }
    if(item == "RefESG"){
      if(topFund){
        return topFund;
      } else{
        return '--';
      }
    }
    if(item == "Sharp ratio"){
      if(topFund){
        return topFund.toFixed(2) + '%';
      } else{
        return '--';
      }
    }

  }

  openPopup(){
    Commons.ESGRatting.forEach((ele)=>{
      if(ele.name == this.titleName){
        this.ESGData = ele.ESG;
      }
    });
    this.holdingNames = this.fundDetail.top10holding;
    this.chartData = [];
    if (this.shared.performanceToggle == undefined) {
      this.commonFund = false;
      this.relativeChart();
    }
    if (!this.shared.performanceToggle) {
      this.commonFund = false;
      this.relativeChart();
    } else {
      this.commonFund = true;
      this.absoluteChart();
    }
  }

  getPerformance(event) {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "summary_performance",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.performanceToggle = event;
    if (!event) {
      this.relativeChart();
    } else {
      this.absoluteChart();
    }
  }

  relativeChart(){
    this.maxData = [];
    this.chartData = [];
    this.chartYaxisMax = [];
    this.summaryData = [];
    if(this.shared.myFundsChart){
      this.summaryData = this.shared.summaryChartData;
    }else{
      this.summaryData = this.shared.chartData;
    }
      this.summaryData.forEach(element => {
        if (element.name == this.titleName) {
          this.chartData.push(element);
          element.data.forEach(element => {
            this.chartYaxisMax.push(element[1]);
          });
        }
        if (element.isBestPassive) {
          this.chartData.push(element);
          element.data.forEach(element => {
            this.chartYaxisMax.push(element[1]);
          });
          this.maxData = this.maxValueForYaxis;
        }
      });
    setTimeout(()=>{
      this.maxValueForYaxis = Math.max(...this.chartYaxisMax);
      this.maxData = this.maxValueForYaxis;
      if(this.chartData.length > 0){
        if(this.chartData[0].data.length > 0){
          this.drawChart();
          this.showChart = true;
        }else{
          this.showChart = false;
        }
        }else{
          this.showChart = false;
        }
    },200)
  }

  absoluteChart(){
    this.maxData = [];
    this.chartData = [];
    this.chartYaxisMax = [];
    this.summaryData;
    if(this.shared.myFundsChart){
      this.summaryData = this.shared.summaryChartData;
    }else{
      this.summaryData = this.shared.chartData;
    }
    this.summaryData.forEach(element => {
      if (element.name == this.titleName) {
        this.chartData.push({ name: element.name, color: element.color, isBestPassive: element.isBestPassive, isTopFund: element.isTopFund, data: [] });
        if(this.fundDetail.absolute){
          this.fundDetail.absolute.forEach(element => {
            this.chartYaxisMax.push(element.price_value);
            this.maxData = Math.max(...this.chartYaxisMax);
            const date = element.date.split('-');
            this.chartData[0].data.push([Date.UTC(date[0], date[1], date[2]), element.price_value])
          });
        }
      
      }
    });
    setTimeout(()=>{
      if(this.chartData.length > 0){
        if(this.chartData[0].data.length > 0){
          this.drawChart();
          this.showChart = true;
        }else{
          this.showChart = false;
        }
        }else{
          this.showChart = false;
        }
    },200)
  }

  drawChart() {
    let maximum = []; 
    let interval:any;
    maximum = this.maxData + 10;
    if(this.maxData < 500){
      interval = 100;
    }else{
      interval = 520;
    }
    const width = this.summaryChart ? this.summaryChart.nativeElement.offsetWidth + 20:100;
    const condition = Boolean(Math.round(Math.random()))
    this.growthChart = Highcharts.chart(
      "containers-summary",
      {
        chart: {
          height: "140px",
          width: width,
          backgroundColor: "#000f19",
          color: "#fffff",
          style: {
            fontFamily: "sans-serif",
          },
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        yAxis: {
          categories: [0, maximum],
          tickInterval: interval,
          showInLegend: false,
          labels: {
            enabled: true,
            style: {
              color: "#606f88",
              fontSize: "14px",
            },
          },
          gridLineWidth: 0.5,
          title: {
            text: "",
            style: { color: "#606f88", fontSize: "17px", },
          },
        },
        tooltip: {
          enabled: true,
          disable: true,
          useHTML: true,
          stickOnContact: true,
          crosshairs: false,
          split: false,
          backgroundColor: "#000f19",
          borderRadius: 10,
          formatter: function () {
            if (!this.point.series.userOptions.isBestPassive) {
              // return true;
              return `<span style="color:`+this.point.color+`;max-width:100%; white-space: normal; position: relative;bottom: 3px;">`+Highcharts.dateFormat('%d %b %Y',new Date(this.point.x))+`</span><br><span style="color:#617088;">`
              + this.point.series.name + ' : ' + this.point.y.toFixed(2) + '%</span>';
            } else {
              return false;
            }
          }
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            stickyTracking: false,
            marker: {
              enabled: false,
              symbol: 'circle'
            },
          }
        },
        legend: {
          enabled: false
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            day: condition ? "%e-%b-%y" : "%e\%b\%y",
            month: condition ? "%b-%y" : '%b \'%y'
          },
          labels: {
            enabled: true,
            style: {
              color: "#606f88",
              fontSize: "13px",
            },
          },
        },
        series: this.chartData

      },
    );
  }

  closeModal() {
    this.showSummaryPopups = false;
    this.sendState.emit({ 'showState': this.showSummaryPopups })
  }

  ClickedOut(event) {
    if (event.target.className == "modal-content fullscreen-modal") {
      this.showSummaryPopups = false;
      this.sendState.emit({ 'showState': this.showSummaryPopups })
    }
  }

}
