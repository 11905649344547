import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuardActivePathService {

  constructor(private router: Router, ) { }
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const localData = localStorage.getItem("userId");
    if(localData && localData != null) {
      this.router.navigate(["/user-dashboard"]);
      return false;
    }else{
      return true
    }
  }

}
