import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'highlightSearch'
})
export class HighlightSearchPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value: any, args: any): any {
    if (!args) {
      return value;
    }

    const regex = new RegExp(args, 'gi');
    const match = value.match(regex);

    if (!match) {
      return value;
    }
    const test = value.replace(regex, `<span class='highlight-search-item-news'>${match[0]}</span>`);
    return this.sanitized.bypassSecurityTrustHtml(test);
  }
//   transform(value: any, args: any): any {
//     if (!args) {return value;}
//     var re = new RegExp(args, 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
//     return value.replace(re, "<mark>$&</mark>");
// }
}