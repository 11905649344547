import { Injectable } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Commons, Shared } from 'src/utils/common';
import { moveItemInArray } from "@angular/cdk/drag-drop";
import { Preference } from 'src/utils/constants';
import { CommonService } from '../CommonServices/common.service';
@Injectable({
  providedIn: 'root'
})
export class RatingViewService {
  
  
  public chartFilterSelectColumn = [
    {
      head: "Price (EUR)", checked: false, isExpand: false, isNew: true,
      data: [{ id: "Price Current", currency: "(EUR)", show: false, value: 'Price (Current)', select: false },
      { id: "Price 12M Low (EUR) ", show: false, value: 'Price 12M Low (EUR)', select: false }, { id: "Price 12M High (EUR) ", show: false, value: 'Price 12M High (EUR)', select: false }]
    },
    {
      head: "Best Passive 1Y/3Y/5Y (%)", checked: false, isExpand: false, isNew: true,
      data: [{ id: "Vs. Best Passive 1y (%) ", show: false, value: 'Vs. Best Passive 1y (%)', select: false, },
      {
        id: "Vs. Best Passive 3y (%) ",
        show: false, value: 'Vs. Best Passive 3y (%)', select: false,
      }, { id: "Vs. Best Passive 5y (%) ", show: false, value: 'Vs. Best Passive 5y (%)', select: false }]
    },
    {
      head: "Sharpe Ratio", checked: false, isExpand: false, isNew: true,
      data: [{ id: "Sharpe Ratio 1y ", show: false, value: 'Sharpe Ratio 1y', select: false, }, { id: "Sharpe Ratio 3y ", show: false, value: 'Sharpe Ratio 3y', select: false, },
      { id: "Sharpe Ratio 5y ", show: false, value: 'Sharpe Ratio 5y', select: false, }]
    },
    {
      head: "Risk-Adjusted Return (%)", checked: false, isExpand: false, isNew: true,
      data: [{ id: "Risk-Adjusted Return 1y (%) ", show: false, value: 'Risk-Adjusted Return 1y (%)', select: false, }, { id: "Risk-Adjusted Return 3y (%) ", show: false, value: 'Risk-Adjusted Return 3y (%)', select: false, },
      { id: "Risk-Adjusted Return 5y (%) ", show: false, value: 'Risk-Adjusted Return 5y (%)', select: false, }]
    },
    {
      head: "MSCI ESG Rating", checked: false, isExpand: false, isNew: true,
      data: [{ id: "MSCI ESG Rating (Current) ", show: false, value: 'MSCI ESG Rating (Current)', select: false, }, { id: "MSCI ESG Rating 12M Low ", show: false, value: 'MSCI ESG Rating 12M Low', select: false, },
      { id: "MSCI ESG Rating 12M High ", show: false, value: 'MSCI ESG Rating 12M High', select: false, }]
    },
    {
      head: "Refinitiv ESG Rating", checked: false, isExpand: false, isNew: true,
      data: [{ id: "Refinitiv ESG Rating (Current) ", show: false, value: 'Refinitiv ESG Rating (Current)', select: false, },
      { id: "Refinitiv ESG Rating 12M High ", show: false, value: 'Refinitiv ESG Rating 12M High', select: false, },
      { id: "Refinitiv ESG Rating 12M Low ", show: false, value: 'Refinitiv ESG Rating 12M Low', select: false, }]
    },
    {
      head: "Total Return (%)", checked: true, isNew: false, isExpand: false,
      data: [{ id: "Total Return 1y (%) ", show: true, value: '1 Year Total Return (%)', select: true, },
      { id: "Total Return 3y (%) ", show: true, value: '3 Year Total Return (%)', select: true, },
      { id: "Total Return 5y (%) ", show: true, value: '5 Year Total Return (%)', select: true, }]
    },
    {
      head: "Standard Deviation (%)", checked: false, isExpand: false, isNew: false,
      data: [{ id: "Standard Deviation (%) 1 Year ", show: false, value: 'Standard Deviation (%) 1 Year', select: false, },
      { id: "Standard Deviation (%) 3 Year ", show: false, value: 'Standard Deviation (%) 3 Year', select: false, },
      { id: "Standard Deviation (%) 5 Year ", show: false, value: 'Standard Deviation (%) 5 Year', select: false, }]
    },
    { head: "Total Weighted Value (% CAGR off best passive) 5 Year", checked: false, isExpand: false, isNew: false, data: [{ show: false, value: 'Total Weighted Value (% CAGR off best passive) 5 Year', select: false, }] },
  ];

  
  tableHeaders = [
    { id: "Fund Name", show: true, name: 'Fund', return: "", showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "Price (Current)", show: false,name: 'SelectColumn',  title: "Price (EUR)", totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "Price 12M Low (EUR)", show: false,name: 'SelectColumn',  title: "Price (EUR)", totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "Price 12M High (EUR)", show: false,name: 'SelectColumn',  title: "Price (EUR)", totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "1 Year Total Return (%)", show: true,name: 'SelectColumn',  title: "Total Return (%)", totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "3 Year Total Return (%)", show: true,name: 'SelectColumn',  title: "Total Return (%)", totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "5 Year Total Return (%)", show: true,name: 'SelectColumn',  title: "Total Return (%)", totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "Vs. Best Passive 1y (%)", show: false,name: 'SelectColumn',  title: "Best Passive 1Y/3Y/5Y (%)", totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "Vs. Best Passive 3y (%)", show: false,name: 'SelectColumn',  title: "Best Passive 1Y/3Y/5Y (%)", totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "Vs. Best Passive 5y (%)", show: false,name: 'SelectColumn',  title: "Best Passive 1Y/3Y/5Y (%)", totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "Sharpe Ratio 1y", show: false,name: 'SelectColumn',  title: "Sharpe Ratio", totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "Sharpe Ratio 3y", show: false,name: 'SelectColumn',  title: "Sharpe Ratio", totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "Sharpe Ratio 5y", show: false,name: 'SelectColumn',  title: "Sharpe Ratio", totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "Risk-Adjusted Return 1y (%)", show: false,name: 'SelectColumn',  title: "Risk-Adjusted Return (%)", totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "Risk-Adjusted Return 3y (%)", show: false,name: 'SelectColumn',  title: "Risk-Adjusted Return (%)", totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "Risk-Adjusted Return 5y (%)", show: false,name: 'SelectColumn',  title: "Risk-Adjusted Return (%)", totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "MSCI ESG Rating (Current)", show: false, name: 'SelectColumn', title: "MSCI ESG Rating", totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "MSCI ESG Rating 12M Low", show: false,name: 'SelectColumn',  title: "MSCI ESG Rating", totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "MSCI ESG Rating 12M High", show: false,name: 'SelectColumn',  title: "MSCI ESG Rating", totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "Refinitiv ESG Rating (Current)", show: false,name: 'SelectColumn',  title: "Refinitiv ESG Rating", totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "Refinitiv ESG Rating 12M High", show: false,name: 'SelectColumn',  title: "Refinitiv ESG Rating", totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "Refinitiv ESG Rating 12M Low", show: false,name: 'SelectColumn',  title: "Refinitiv ESG Rating", totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "Standard Deviation (%) 1 Year",name: 'SelectColumn',  title: "Standard Deviation (%)", show: false, stdDev: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "Standard Deviation (%) 3 Year",name: 'SelectColumn',  title: "Standard Deviation (%)", show: false, stdDev3: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "Standard Deviation (%) 5 Year", name: 'SelectColumn',  title: "Standard Deviation (%)", show: false, stdDev5: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
    { id: "Total Weighted Value (% CAGR off best passive) 5 Year",name: 'SelectColumn',  title: "Total Weighted Value (% CAGR off best passive) 5 Year", show: false, totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" },
  ];

  constructor(public decimalPipe: DecimalPipe, public common: CommonService, public shared: Shared) { }
  async dataForTableView(data, esg, consistencyScore) {
    const k = 1;
    this.shared.tableViewData = [];
    this.shared.fundNameData = [];
    this.shared.ratingViewHeaders = [];
    this.shared.tempData = [];
    this.shared.firstColumnData = [];
    this.shared.selectedTableData = [];
    this.shared.ESGValue = [];
    this.tableHeaders.forEach((ele) => {
      if (ele.show) {
        this.shared.ratingViewHeaders.push(ele);
      }
      if (ele.show) {
        this.shared.selectedTableData.push(ele);
      }
    });
    if (this.shared.showFundSize) {
      this.shared.ratingViewHeaders.push({ id: "Fund Size (EUR)", show: true, name: 'SelectColumn',  totalRet: '', showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" });
    }
     data.forEach((element) => {
        if (consistencyScore.includes(element.consistency)) {
          let allData: any;
          if (element.data.Holding_Names && element.data.Holding_Names.length > 0) {
            allData = element.data;
              if (!this.shared.ratingViewHeaders.some((item) => item.id == element.data.Holding_Names)) {
                if (esg) {
                  this.getEsg(esg, allData, k);
                }
              }
          }
          this.getESGValues(element);
          let data = {
            "Fund Name": element.name,
            "Price (Current)": this.cal(element.priceCurrent, 'Price'),
            "Price 12M Low (EUR)": this.cal(element.priceLow, 'Price'),
            "Price 12M High (EUR)": this.cal(element.priceHigh, 'Price'),
            "Vs. Best Passive 1y (%)": this.cal(element.vsBestPassive1Yr * 100, 'vsBestPassive'),
            "Vs. Best Passive 3y (%)": this.cal(element.vsBestPassive3Yr * 100, 'vsBestPassive'),
            "Vs. Best Passive 5y (%)": this.cal(element.vsBestPassive5Yr * 100, 'vsBestPassive'),
            "Sharpe Ratio 1y": this.cal(element.sharpRatio_1, 'SharpRatio'),
            "Sharpe Ratio 3y": this.cal(element.sharpRatio_3, 'SharpRatio'),
            "Sharpe Ratio 5y": this.cal(element.sharpRatio_5, 'SharpRatio'),
            "Risk-Adjusted Return 1y (%)": this.cal(element.RiskAdjustedRet * 100, 'Risk'),
            "Risk-Adjusted Return 3y (%)": this.cal(element.RiskAdjustedRet3 * 100, 'Risk'),
            "Risk-Adjusted Return 5y (%)": this.cal(element.RiskAdjustedRet5 * 100, 'Risk'),
            // "Relative Return 1y (%)" : this.cal(element.relativeReturn_1_Y * 100, 'Relative'),
            // "Relative Return 3y (%)" : this.cal(element.relativeReturn_3_Y * 100, 'Relative'),
            // "Relative Return 5y (%)" : this.cal(element.relativeReturn_5_Y * 100, 'Relative'),
            "MSCI ESG Rating (Current)": element.EsgCurrent,
            "MSCI ESG Rating 12M Low": element.EsgLow,
            "MSCI ESG Rating 12M High": element.EsgHigh,
            "Refinitiv ESG Rating (Current)": element.Refinitiv_ESG_Current,
            "Refinitiv ESG Rating 12M High": element.Refinitiv_ESG_High,
            "Refinitiv ESG Rating 12M Low": element.Refinitiv_ESG_Low,
            "1 Year Total Return (%)": this.cal(element.totalRet * 100, 'Total Return'),
            "3 Year Total Return (%)": this.cal(element.totalRet3 * 100, 'Total Return'),
            "5 Year Total Return (%)": this.cal(element.totalRet5 * 100, 'Total Return'),
            "Standard Deviation (%) 1 Year": this.cal(element.stdDev * 100, 'Standard'),
            "Standard Deviation (%) 3 Year": this.cal(element.stdDev3 * 100, 'Standard'),
            "Standard Deviation (%) 5 Year": this.cal(element.stdDev5 * 100, 'Standard'),
            "color": element.color,
            "Fund Size (EUR)": this.calculate(element.fundSize, 'Fund Size'),
            "Total Weighted Value (% CAGR off best passive) 5 Year": this.calculate(element.fifthYrAddValue, '5 Year'),
            "Include": element.data.Included ? true : false,
            isExpand: false,
            "Excluded": element.data.Excluded ? true : false,
            holdingValues: element.data.Holding_Names
          }
  
          if (esg) {
            for (let i = 0; i < esg.length; i++) {
              if (allData) {
                let check = allData.hasOwnProperty(esg[i]);
                if (check) {
                  data['ESG - ' + esg[i]] = "--";
                  break;
                } else {
                  data['ESG - ' + esg[i]] = "--";
                }
              }
            }
          } else {
          }
          this.shared.tableViewData.push(data);
        }
    })
    if (localStorage.getItem('bestPassive')) {
      this.shared.bestPassives = JSON.parse(localStorage.getItem('bestPassive'));
      if (this.shared.bestPassiveData != undefined) {
        this.shared.bestPassives.forEach(element => {
          if (element.id == "1 YEAR-ILIM Indexed Name" && element.show) {
            Commons.bestPassiveData.forEach((ele) => {
              if (ele.Type == "01") {
                this.shared.fundNameData.push({ "Fund_Name": ele.Name, })
                this.shared.tableViewData.push({
                  "Fund Name": ele.Name,
                  "Price (Current)": this.cal(ele.Price_Current, 'Price'),
                  "Price 12M Low (EUR)": this.cal(ele.Low_Price_Value, 'Price'),
                  "Price 12M High (EUR)": this.cal(ele.High_Price_Value, 'Price'),
                  "Vs. Best Passive 1y (%)": "--",
                  "Vs. Best Passive 3y (%)": "--",
                  "Vs. Best Passive 5y (%)": "--",
                  "Sharpe Ratio 1y": "--",
                  "Sharpe Ratio 3y": "--",
                  "Sharpe Ratio 5y": "--",
                  "Risk-Adjusted Return 1y (%)": this.cal(ele.RAR_1_Year * 100, 'Risk'),
                  "Risk-Adjusted Return 3y (%)": this.cal(ele.RAR_3_Year * 100, 'Risk'),
                  "Risk-Adjusted Return 5y (%)": this.cal(ele.RAR_5_Year * 100, 'Risk'),
                  "MSCI ESG Rating (Current)": "--",
                  "MSCI ESG Rating 12M Low": "--",
                  "MSCI ESG Rating 12M High": "--",
                  "Refinitiv ESG Rating (Current)": "--",
                  "Refinitiv ESG Rating 12M High": "--",
                  "Refinitiv ESG Rating 12M Low": "--",
                  "1 Year Total Return (%)": this.cal(ele.Total_Ret_1_Yr__Mo_End__USD * 100, 'Total'),
                  "3 Year Total Return (%)": this.cal(ele.Total_Ret_Annlzd_3_Yr__Mo_End__USD * 100, 'Total'),
                  "5 Year Total Return (%)": this.cal(ele.Total_Ret_Annlzd_5_Yr__Mo_End__USD * 100, 'Total'),
                  "Standard Deviation (%) 1 Year": this.cal(ele.Std_Dev_1_Yr__Mo_End__USD * 100, 'Standard'),
                  "Standard Deviation (%) 3 Year": this.cal(ele.Std_Dev_3_Yr__Mo_End__USD * 100, 'Standard'),
                  "Standard Deviation (%) 5 Year": this.cal(ele.Std_Dev_5_Yr__Mo_End__USD * 100, 'Standard'),
                  "color": element.color,
                  "Fund Size": this.cal(element.monthendtna_value, 'Fund Size'),
                  "Total Weighted Value (% CAGR off best passive) 5 Year": "--"
                })
              }
            })
          }
          else if (element.id == "3 YEAR-VANGAURD Name" && element.show) {
            Commons.bestPassiveData.forEach((ele) => {
              if (ele.Type == "03") {
                this.shared.fundNameData.push({ "Fund_Name": ele.Name, })
                this.shared.tableViewData.push({
                  "Fund Name": ele.Name,
                  "Price (Current)": this.cal(ele.Price_Current, 'Price'),
                  "Price 12M Low (EUR)": this.cal(ele.Low_Price_Value, 'Price'),
                  "Price 12M High (EUR)": this.cal(ele.High_Price_Value, 'Price'),
                  "Vs. Best Passive 1y (%)": "--",
                  "Vs. Best Passive 3y (%)": "--",
                  "Vs. Best Passive 5y (%)": "--",
                  "Sharpe Ratio 1y": "--",
                  "Sharpe Ratio 3y": "--",
                  "Sharpe Ratio 5y": "--",
                  "Risk-Adjusted Return 1y (%)": this.cal(ele.RAR_1_Year * 100, 'Risk'),
                  "Risk-Adjusted Return 3y (%)": this.cal(ele.RAR_3_Year * 100, 'Risk'),
                  "Risk-Adjusted Return 5y (%)": this.cal(ele.RAR_5_Year * 100, 'Risk'),
                  "MSCI ESG Rating (Current)": "--",
                  "MSCI ESG Rating 12M Low": "--",
                  "MSCI ESG Rating 12M High": "--",
                  "Refinitiv ESG Rating (Current)": "--",
                  "Refinitiv ESG Rating 12M High": "--",
                  "Refinitiv ESG Rating 12M Low": "--",
                  "1 Year Total Return (%)": this.cal(ele.Total_Ret_1_Yr__Mo_End__USD * 100, 'Total'),
                  "3 Year Total Return (%)": this.cal(ele.Total_Ret_Annlzd_3_Yr__Mo_End__USD * 100, 'Total'),
                  "5 Year Total Return (%)": this.cal(ele.Total_Ret_Annlzd_5_Yr__Mo_End__USD * 100, 'Total'),
                  "Standard Deviation (%) 1 Year": this.cal(ele.Std_Dev_1_Yr__Mo_End__USD * 100, 'Standard'),
                  "Standard Deviation (%) 3 Year": this.cal(ele.Std_Dev_3_Yr__Mo_End__USD * 100, 'Standard'),
                  "Standard Deviation (%) 5 Year": this.cal(ele.Std_Dev_5_Yr__Mo_End__USD * 100, 'Standard'),
                  "color": element.color,
                  "Fund Size": this.cal(element.monthendtna_value, 'Fund Size'),
                  "Total Weighted Value (% CAGR off best passive) 5 Year": "--"
                })
              }
            })
          }
          else if (element.id == "5 YEAR-ILIM Indexed Name" && element.show) {
            Commons.bestPassiveData.forEach((ele) => {
              if (ele.Type == "05") {
                this.shared.fundNameData.push({ "Fund_Name": ele.Name, })
                this.shared.tableViewData.push({
                  "Fund Name": ele.Name,
                  "Price (Current)": this.cal(ele.Price_Current, 'Price'),
                  "Price 12M Low (EUR)": this.cal(ele.Low_Price_Value, 'Price'),
                  "Price 12M High (EUR)": this.cal(ele.High_Price_Value, 'Price'),
                  "Vs. Best Passive 1y (%)": "--",
                  "Vs. Best Passive 3y (%)": "--",
                  "Vs. Best Passive 5y (%)": "--",
                  "Sharpe Ratio 1y": "--",
                  "Sharpe Ratio 3y": "--",
                  "Sharpe Ratio 5y": "--",
                  "Risk-Adjusted Return 1y (%)": this.cal(ele.RAR_1_Year * 100, 'Risk'),
                  "Risk-Adjusted Return 3y (%)": this.cal(ele.RAR_3_Year * 100, 'Risk'),
                  "Risk-Adjusted Return 5y (%)": this.cal(ele.RAR_5_Year * 100, 'Risk'),
                  "MSCI ESG Rating (Current)": "--",
                  "MSCI ESG Rating 12M Low": "--",
                  "MSCI ESG Rating 12M High": "--",
                  "Refinitiv ESG Rating (Current)": "--",
                  "Refinitiv ESG Rating 12M High": "--",
                  "Refinitiv ESG Rating 12M Low": "--",
                  "1 Year Total Return (%)": this.cal(ele.Total_Ret_1_Yr__Mo_End__USD * 100, 'Total'),
                  "3 Year Total Return (%)": this.cal(ele.Total_Ret_Annlzd_3_Yr__Mo_End__USD * 100, 'Total'),
                  "5 Year Total Return (%)": this.cal(ele.Total_Ret_Annlzd_5_Yr__Mo_End__USD * 100, 'Total'),
                  "Standard Deviation (%) 1 Year": this.cal(ele.Std_Dev_1_Yr__Mo_End__USD * 100, 'Standard'),
                  "Standard Deviation (%) 3 Year": this.cal(ele.Std_Dev_3_Yr__Mo_End__USD * 100, 'Standard'),
                  "Standard Deviation (%) 5 Year": this.cal(ele.Std_Dev_5_Yr__Mo_End__USD * 100, 'Standard'),
                  "color": element.color,
                  "Fund Size": this.cal(element.monthendtna_value, 'Fund Size'),
                  "Total Weighted Value (% CAGR off best passive) 5 Year": "--"
                })
              }
            })
          }
        })
      }
    }
    this.getColor(this.shared.tableViewData, this.shared.fundNameData);
    this.shared.ratingServiceObservable.next();
    this.shared.tableViewChangeObservable.next();
    // console.log('this.shared.tableViewData',this.shared.ratingViewHeaders);
    this.sortingHeaders();
  }

  sortingHeaders(){
    const head = this.shared.ratingViewHeaders;
    this.shared.ratingViewHeaders = [];
    head.map((ele)=> { if(ele.name == "Fund"){this.shared.ratingViewHeaders.push(ele)} });
    head.map((ele)=> { if(ele.name == "ESGInclude"){this.shared.ratingViewHeaders.push(ele)} });
    head.map((ele)=> { if(ele.name == "ESGExclude"){this.shared.ratingViewHeaders.push(ele)} });
    head.map((ele)=> { if(ele.name == "SelectColumn"){this.shared.ratingViewHeaders.push(ele)} });
  }

  getESGValues(element){
    const values = {
      "Fund_Name": element.name,
      "Include": element.data.Included ? true : false, "fundSize": element.fundSize,
      isExpand: false,
      "Excluded": element.data.Excluded ? true : false,
      holdingValues: element.data.Holding_Names
    }  
    this.shared.fundNameData.push(values);
  }

  getEsg(esg, allData, k){
    if (esg) {
      const socialValues:any[]=[];
      const majorControversyFlag:any[]=[];
      const alternativeEnergy:any[]=[];
      const energyEfficiency:any[]=[];
      const carbonEmissionzScore:any[]=[];
      const boardIdepPercent:any[]=[];
      const globalCompact:any[]=[];
      const humanRights:any[]=[];
      const femaleDirectors:any[]=[];
      let d = 0; const len = allData.Holding_Names.length;
      while (d < len){
        // console.log(allData.Holding_Names[d].SOCIAL_CONTROVERSY_SCORE);
        if(allData.Holding_Names[d].hasOwnProperty('SOCIAL_CONTROVERSY_SCORE')){
          socialValues.push(allData.Holding_Names[d].SOCIAL_CONTROVERSY_SCORE);
        }
        if(allData.Holding_Names[d].hasOwnProperty('CUSTOMER_CONTROVERSY_FLAG')){
          majorControversyFlag.push(allData.Holding_Names[d].CUSTOMER_CONTROVERSY_FLAG);
        }
        if(allData.Holding_Names[d].hasOwnProperty('ALTERNATIVE_ENERGY_NM')){
          alternativeEnergy.push(allData.Holding_Names[d].ALTERNATIVE_ENERGY_NM);
        }
        if(allData.Holding_Names[d].hasOwnProperty('ENERGY_EFFICIENCY_NM')){
          energyEfficiency.push(allData.Holding_Names[d].ENERGY_EFFICIENCY_NM);
        }
        if(allData.Holding_Names[d].hasOwnProperty('CARBON_EMISSIONS_SCORE')){
          carbonEmissionzScore.push(allData.Holding_Names[d].CARBON_EMISSIONS_SCORE);
        }
        if(allData.Holding_Names[d].hasOwnProperty('BOARD_INDEP_PCT')){
          boardIdepPercent.push(allData.Holding_Names[d].BOARD_INDEP_PCT);
        }
        if(allData.Holding_Names[d].hasOwnProperty('UNGC_COMPLIANCE')){
          globalCompact.push(allData.Holding_Names[d].UNGC_COMPLIANCE);
        }
        if(allData.Holding_Names[d].hasOwnProperty('HR_COMPLIANCE')){
          humanRights.push(allData.Holding_Names[d].HR_COMPLIANCE);
        }
        if(allData.Holding_Names[d].hasOwnProperty('GENDER_DIVERSITY')){
          femaleDirectors.push(allData.Holding_Names[d].GENDER_DIVERSITY);
        }
        d++;
      }
      const equalsTen = socialValues.every(val => val === 10);
      const checkGreenExist =  majorControversyFlag.every(val => val === "Green");
      const checkAltEnergyNull = alternativeEnergy.every(val => val === null);
      const checkAltZero = alternativeEnergy.includes(0);
      const checkEnergyNull = energyEfficiency.every(val => val === null);
      const checkEnergyZero = energyEfficiency.includes(0);
      const checkCarbonEmission = carbonEmissionzScore.every(val => val === 10);
      const checkBoardPercent = boardIdepPercent.every(val => val === 100);
      const checkGlobalCompact = globalCompact.every(val => val === 'Pass');
      const checkHumanRights = humanRights.every(val => val === 'Pass');
      const checkDiractors = femaleDirectors.every(val => val === 0);

      // if(!lessThanFive){
      let esgName: any;
      let esgType: any;
      for (let i = 0; i < esg.length; i++) {
        if (allData) {
          // let check = allData.hasOwnProperty(esg[i]);
          if(esg[i] == "SOCIAL_CONTROVERSY_SCORE"){
            if(equalsTen){
            }else{
              this.addEsgColumn(allData, esg[i], esgName, esgType);
            }
            }else if(esg[i] == "CUSTOMER_CONTROVERSY_FLAG"){
              if(checkGreenExist){
                if(!this.shared.ratingViewHeaders.some(function(e) { return e.id == "ESG - Customer Controversies " })){
                  this.shared.ratingViewHeaders.push({ id: 'ESG - ' + 'Customer Controversies ', value: this.shared.ESGValue, color: '#5bbf93',name: 'ESGInclude',  show: true, totalRet: '', include: true, exclude: false, showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" })
                }
              }else{
                this.addEsgColumn(allData, esg[i], esgName, esgType);
              }
            }else if(esg[i] == "ALTERNATIVE_ENERGY_NM"){
              if(checkAltEnergyNull || checkAltZero){
              }else{
                this.addEsgColumn(allData, esg[i], esgName, esgType);
              }
            }else if(esg[i] == "ENERGY_EFFICIENCY_NM"){
              if(checkEnergyNull || checkEnergyZero){
              }else{
                this.addEsgColumn(allData, esg[i], esgName, esgType);
              }
            }else if(esg[i] == "CARBON_EMISSIONS_SCORE"){
              if(checkCarbonEmission){
               }else{
                this.addEsgColumn(allData, esg[i], esgName, esgType);
              }
            }else if(esg[i] == "BOARD_INDEP_PCT"){
              if(checkBoardPercent){
                this.addEsgColumn(allData, esg[i], esgName, esgType);
               }else{
              }
            }else if(esg[i] == "UNGC_COMPLIANCE"){
              if(checkGlobalCompact){
                if(!this.shared.ratingViewHeaders.some(function(e) { return e.id == "ESG - UN Global Compact Compliance " })){
                  this.shared.ratingViewHeaders.push({ id: 'ESG - ' + 'UN Global Compact Compliance ', value: this.shared.ESGValue, color: '#5bbf93',name: 'ESGInclude',  show: true, totalRet: '', include: true, exclude: false, showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" })
                }
               }else{
              }
            }else if(esg[i] == "HR_COMPLIANCE"){
              if(checkHumanRights){
                if(!this.shared.ratingViewHeaders.some(function(e) { return e.id == "ESG - Human Rights Compliance " })){
                  this.shared.ratingViewHeaders.push({ id: 'ESG - ' + 'Human Rights Compliance ', value: this.shared.ESGValue, color: '#5bbf93',name: 'ESGInclude',  show: true, totalRet: '', include: true, exclude: false, showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" })
                }
               }else{
              }
            }else if(esg[i] == "GENDER_DIVERSITY"){
              if(checkDiractors){
                if(!this.shared.ratingViewHeaders.some(function(e) { return e.id == "ESG - Female Directors " })){
                  this.shared.ratingViewHeaders.push({ id: 'ESG - ' + 'Female Directors ', value: this.shared.ESGValue, color: '#5bbf93',name: 'ESGInclude',  show: true, totalRet: '', include: true, exclude: false, showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" })
                }
               }else{
              }
            }else{
            this.addEsgColumn(allData, esg[i], esgName, esgType);
          }
        }
      }
      if (allData?.Included || allData?.Excluded) {
        let ind: any = [];
        this.shared.ratingViewHeaders.forEach((pos, index) => {
          if (pos.include || pos.exclude) {
            ind.push(index);
            moveItemInArray(this.shared.ratingViewHeaders, ind, k)
            ind = [];
            k++;
          }
        });
      }
      //debugger
      this.shared.tableData = [];
      for (let j = 0; j < this.shared.ratingViewHeaders.length; j++) {
        if (j != 0) {
          if (this.shared.ratingViewHeaders[j].show) {
            this.shared.tableData.push({ id: this.shared.ratingViewHeaders[j].id, value: [] });
            break;
          }
        }
      }
      const value = this.shared.tableData[0].id;
      let val: any;
      this.shared.tableViewData.forEach(element => {
        const check = element.hasOwnProperty(value);
        if (check) {
          val = element[value];
        } else {
          val = "--"
        }
        if (check) {
          this.shared.tableData[0].value.push({ "value": val });
        }
      });
    // }
    }
  }

  addEsgColumn(allData, esg, esgName, esgType){
    const check = allData.Holding_Names.some(item => item.hasOwnProperty(esg));
    if (check) {
      esgName = esg;
      let name:any;
      if (!this.shared.ESGValue.includes(esg)) {
        this.shared.ESGValue.push(esg);
        if (!this.shared.ratingViewHeaders.some((item) => item.id == 'ESG - ' + esgName)) {
          let included = false;
          let excluded = false;
          if (allData?.Included) {
            included = true;
          }
          if (allData?.Excluded) {
            excluded = true;
          }
          for (let a = 0; a < Preference.esgOptionsIncludeChart.length; a++) {
            if (esg == Preference.esgOptionsIncludeChart[a].value) {
              esgType = "#5bbf93";
              name = "ESGInclude"
              if (!esgName.includes(Preference.esgOptionsIncludeChart[a].name)) {
                esgName = Preference.esgOptionsIncludeChart[a].name;
              }

              // return
            }
          }
          for (let b = 0; b < Preference.esgOptionExcludeChart.length; b++) {
            if (esg == Preference.esgOptionExcludeChart[b].value) {
              esgType = "red";
              name = "ESGExclude"
              if (!esgName.includes(Preference.esgOptionExcludeChart[b].name)) {
                esgName = Preference.esgOptionExcludeChart[b].name;
              }
            }
          }
          if (esgType) { } else {
            esgType = 'white';
          }
          this.shared.ratingViewHeaders.push({ id: 'ESG - ' + esgName, name:name, value: this.shared.ESGValue, color: esgType, show: true, totalRet: '', include: included, exclude: excluded, showDrag: false, showFilters: false, ascImage: "../../../assets/images/asec.png", descImage: "../../../assets/images/desc.png", dragDropImage: "../../../assets/images/slide.svg" })
        }
      }
    }
  }

  calculate(item, fund) {
    if (fund == "Fund Size") {
      if (item) {
        const data = Math.trunc(item);
        return data;
      } else {
        return '--';
      }
    }
    if (fund == "5 Year") {
      if (item) {
        const value = item * 100;
        const data = this.decimalPipe.transform(value, "2.1-2") + '%';
        return data;
      } else {
        return '--';
      }
    }
  }

  getColor(data, funds) {
    let colorToPushIndex = 0;
    data.forEach((element, index) => {
      const j = colorToPushIndex.toString();
      element.color = Commons.allcolor[j.charAt(j.length - 1)];
      funds[index].color = Commons.allcolor[j.charAt(j.length - 1)];
      colorToPushIndex++;
      if (colorToPushIndex == Commons.allcolor.length) {
        colorToPushIndex = 0;
      }
    });
    this.sortedNames = [];
    for (let j = 0; j < this.shared.tableViewData.length; j++)
      this.sortedNames.push({ 'data': this.shared.tableViewData[j], 'name': this.shared.fundNameData[j] });
  }

  cal(item, fund) {
    if (fund == "Price") {
      if (item) {
        const data = item;
        return data;
      } else {
        return 0;
      }
    } else {
      if (item) {
        const data = this.decimalPipe.transform(item, "2.1-2") + '%';
        return data;
      } else {
        return "--";
      }
    }
  }


  sortedNames: any = [];
  sortItemAsc(item) {
    item.ascImage = "../../../assets/images/asc-order.png";
    if (item.id == "Fund Size (EUR)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Fund Size (EUR)"] == "--" || b.data["Fund Size (EUR)"] == "--") {
          if (a.data["Fund Size (EUR)"] == "--") {
            return -1;
          }
          if (b.data["Fund Size (EUR)"] == "--") {
            return 1;
          }
        } else {
          if (a.data["Fund Size (EUR)"] > b.data["Fund Size (EUR)"])
            return 1;
          if (a.data["Fund Size (EUR)"] < b.data["Fund Size (EUR)"])
            return -1;
          return 0;
        }
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Price (Current)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Price 12M Low (EUR)"] > b.data["Price 12M Low (EUR)"])
          return 1;
        if (a.data["Price 12M Low (EUR)"] < b.data["Price 12M Low (EUR)"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Price 12M Low (EUR)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Price 12M Low (EUR)"] > b.data["Price 12M Low (EUR)"])
          return 1;
        if (a.data["Price 12M Low (EUR)"] < b.data["Price 12M Low (EUR)"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Price 12M High (EUR)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Price 12M High (EUR)"] > b.data["Price 12M High (EUR)"])
          return 1;
        if (a.data["Price 12M High (EUR)"] < b.data["Price 12M High (EUR)"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Vs. Best Passive 1y (%)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Vs. Best Passive 1y (%)"] == "--" || b.data["Vs. Best Passive 1y (%)"] == "--") {
          if (a.data["Vs. Best Passive 1y (%)"] == "--") {
            return -1;
          }
          if (b.data["Vs. Best Passive 1y (%)"] == "--") {
            return 1;
          }
        } else {
          if (a.data["Vs. Best Passive 1y (%)"] > b.data["Vs. Best Passive 1y (%)"])
            return 1;
          if (a.data["Vs. Best Passive 1y (%)"] < b.data["Vs. Best Passive 1y (%)"])
            return -1;
          return 0;
        }
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Vs. Best Passive 3y (%)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Vs. Best Passive 3y (%)"] == "--" || b.data["Vs. Best Passive 3y (%)"] == "--") {
          if (a.data["Vs. Best Passive 3y (%)"] == "--") {
            return -1;
          }
          if (b.data["Vs. Best Passive 3y (%)"] == "--") {
            return 1;
          }
        } else {
          if (a.data["Vs. Best Passive 3y (%)"] > b.data["Vs. Best Passive 3y (%)"])
            return 1;
          if (a.data["Vs. Best Passive 3y (%)"] < b.data["Vs. Best Passive 3y (%)"])
            return -1;
          return 0;
        }
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Vs. Best Passive 5y (%)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Vs. Best Passive 5y (%)"] == "--" || b.data["Vs. Best Passive 5y (%)"] == "--") {
          if (a.data["Vs. Best Passive 5y (%)"] == "--") {
            return -1;
          }
          if (b.data["Vs. Best Passive 5y (%)"] == "--") {
            return 1;
          }
        } else {
          if (a.data["Vs. Best Passive 5y (%)"] > b.data["Vs. Best Passive 5y (%)"])
            return 1;
          if (a.data["Vs. Best Passive 5y (%)"] < b.data["Vs. Best Passive 5y (%)"])
            return -1;
          return 0;
        }
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Sharpe Ratio 1y") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Sharpe Ratio 1y"] > b.data["Sharpe Ratio 1y"])
          return 1;
        if (a.data["Sharpe Ratio 1y"] < b.data["Sharpe Ratio 1y"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Sharpe Ratio 3y") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Sharpe Ratio 3y"] > b.data["Sharpe Ratio 3y"])
          return 1;
        if (a.data["Sharpe Ratio 3y"] < b.data["Sharpe Ratio 3y"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Sharpe Ratio 5y") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Sharpe Ratio 5y"] > b.data["Sharpe Ratio 5y"])
          return 1;
        if (a.data["Sharpe Ratio 5y"] < b.data["Sharpe Ratio 5y"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }

    if (item.id == "Risk-Adjusted Return 1y (%)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Risk-Adjusted Return 1y (%)"] > b.data["Risk-Adjusted Return 1y (%)"])
          return 1;
        if (a.data["Risk-Adjusted Return 1y (%)"] < b.data["Risk-Adjusted Return 1y (%)"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Risk-Adjusted Return 3y (%)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Risk-Adjusted Return 3y (%)"] > b.data["Risk-Adjusted Return 3y (%)"])
          return 1;
        if (a.data["Risk-Adjusted Return 3y (%)"] < b.data["Risk-Adjusted Return 3y (%)"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Risk-Adjusted Return 5y (%)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Risk-Adjusted Return 5y (%)"] > b.data["Risk-Adjusted Return 5y (%)"])
          return 1;
        if (a.data["Risk-Adjusted Return 5y (%)"] < b.data["Risk-Adjusted Return 5y (%)"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Relative Return 1y (%)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Relative Return 1y (%)"] > b.data["Relative Return 1y (%)"])
          return 1;
        if (a.data["Relative Return 1y (%)"] < b.data["Relative Return 1y (%)"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Relative Return 3y (%)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Relative Return 3y (%)"] > b.data["Relative Return 3y (%)"])
          return 1;
        if (a.data["Relative Return 3y (%)"] < b.data["Relative Return 3y (%)"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Relative Return 5y (%)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Relative Return 5y (%)"] > b.data["Relative Return 5y (%)"])
          return 1;
        if (a.data["Relative Return 5y (%)"] < b.data["Relative Return 5y (%)"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "MSCI ESG Rating (Current)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["MSCI ESG Rating (Current)"] > b.data["MSCI ESG Rating (Current)"])
          return 1;
        if (a.data["MSCI ESG Rating (Current)"] < b.data["MSCI ESG Rating (Current)"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "MSCI ESG Rating 12M Low") {
      this.sortedNames.sort((a, b) => {
        if (a.data["MSCI ESG Rating 12M Low"] > b.data["MSCI ESG Rating 12M Low"])
          return 1;
        if (a.data["MSCI ESG Rating 12M Low"] < b.data["MSCI ESG Rating 12M Low"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "MSCI ESG Rating 12M High") {
      this.sortedNames.sort((a, b) => {
        if (a.data["MSCI ESG Rating 12M High"] > b.data["MSCI ESG Rating 12M High"])
          return 1;
        if (a.data["MSCI ESG Rating 12M High"] < b.data["MSCI ESG Rating 12M High"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Refinitiv ESG Rating (Current)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Refinitiv ESG Rating (Current)"] > b.data["Refinitiv ESG Rating (Current)"])
          return 1;
        if (a.data["Refinitiv ESG Rating (Current)"] < b.data["Refinitiv ESG Rating (Current)"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Refinitiv ESG Rating 12M High") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Refinitiv ESG Rating 12M High"] > b.data["Refinitiv ESG Rating 12M High"])
          return 1;
        if (a.data["Refinitiv ESG Rating 12M High"] < b.data["Refinitiv ESG Rating 12M High"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Refinitiv ESG Rating 12M Low") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Refinitiv ESG Rating 12M Low"] > b.data["Refinitiv ESG Rating 12M Low"])
          return 1;
        if (a.data["Refinitiv ESG Rating 12M Low"] < b.data["Refinitiv ESG Rating 12M Low"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "1 Year Total Return (%)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["1 Year Total Return (%)"] > b.data["1 Year Total Return (%)"])
          return 1;
        if (a.data["1 Year Total Return (%)"] < b.data["1 Year Total Return (%)"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "3 Year Total Return (%)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["3 Year Total Return (%)"] > b.data["3 Year Total Return (%)"])
          return 1;
        if (a.data["3 Year Total Return (%)"] < b.data["3 Year Total Return (%)"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "5 Year Total Return (%)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["5 Year Total Return (%)"] > b.data["5 Year Total Return (%)"])
          return 1;
        if (a.data["5 Year Total Return (%)"] < b.data["5 Year Total Return (%)"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Standard Deviation (%) 1 Year") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Standard Deviation (%) 1 Year"] > b.data["Standard Deviation (%) 1 Year"])
          return 1;
        if (a.data["Standard Deviation (%) 1 Year"] < b.data["Standard Deviation (%) 1 Year"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Standard Deviation (%) 3 Year") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Standard Deviation (%) 3 Year"] > b.data["Standard Deviation (%) 3 Year"])
          return 1;
        if (a.data["Standard Deviation (%) 3 Year"] < b.data["Standard Deviation (%) 3 Year"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Standard Deviation (%) 5 Year") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Standard Deviation (%) 5 Year"] > b.data["Standard Deviation (%) 5 Year"])
          return 1;
        if (a.data["Standard Deviation (%) 5 Year"] < b.data["Standard Deviation (%) 5 Year"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Total Weighted Value (% CAGR off best passive) 5 Year") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Total Weighted Value (% CAGR off best passive) 5 Year"] > b.data["Total Weighted Value (% CAGR off best passive) 5 Year"])
          return 1;
        if (a.data["Total Weighted Value (% CAGR off best passive) 5 Year"] < b.data["Total Weighted Value (% CAGR off best passive) 5 Year"])
          return -1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
  }



  // DESCENDING

  sortItemDesc(item) {
    item.descImage = "../../../assets/images/desc-order.png";
    if (item.id == "Fund Size (EUR)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Fund Size (EUR)"] == "--" || b.data["Fund Size (EUR)"] == "--") {
          if (a.data["Fund Size (EUR)"] == "--") {
            return 1;
          }
          if (b.data["Fund Size (EUR)"] == "--") {
            return -1;
          }
        } else {
          if (a.data["Fund Size (EUR)"] > b.data["Fund Size (EUR)"])
            return -1;
          if (a.data["Fund Size (EUR)"] < b.data["Fund Size (EUR)"])
            return 1;
          return 0;
        }
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Price (Current)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Price (Current)"] > b.data["Price (Current)"])
          return -1;
        if (a.data["Price (Current)"] < b.data["Price (Current)"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Price 12M Low (EUR)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Price 12M Low (EUR)"] > b.data["Price 12M Low (EUR)"])
          return -1;
        if (a.data["Price 12M Low (EUR)"] < b.data["Price 12M Low (EUR)"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Price 12M High (EUR)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Price 12M High (EUR)"] > b.data["Price 12M High (EUR)"])
          return -1;
        if (a.data["Price 12M High (EUR)"] < b.data["Price 12M High (EUR)"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Vs. Best Passive 1y (%)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Vs. Best Passive 1y (%)"] == "--" || b.data["Vs. Best Passive 1y (%)"] == "--") {
          if (a.data["Vs. Best Passive 1y (%)"] == "--") {
            return 1;
          }
          if (b.data["Vs. Best Passive 1y (%)"] == "--") {
            return -1;
          }
        } else {
          if (a.data["Vs. Best Passive 1y (%)"] > b.data["Vs. Best Passive 1y (%)"])
            return -1;
          if (a.data["Vs. Best Passive 1y (%)"] < b.data["Vs. Best Passive 1y (%)"])
            return 1;
          return 0;
        }
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Vs. Best Passive 3y (%)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Vs. Best Passive 3y (%)"] == "--" || b.data["Vs. Best Passive 3y (%)"] == "--") {
          if (a.data["Vs. Best Passive 3y (%)"] == "--") {
            return 1;
          }
          if (b.data["Vs. Best Passive 3y (%)"] == "--") {
            return -1;
          }
        } else {
          if (a.data["Vs. Best Passive 3y (%)"] > b.data["Vs. Best Passive 3y (%)"])
            return -1;
          if (a.data["Vs. Best Passive 3y (%)"] < b.data["Vs. Best Passive 3y (%)"])
            return 1;
          return 0;
        }
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Vs. Best Passive 5y (%)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Vs. Best Passive 5y (%)"] == "--" || b.data["Vs. Best Passive 5y (%)"] == "--") {
          if (a.data["Vs. Best Passive 5y (%)"] == "--") {
            return 1;
          }
          if (b.data["Vs. Best Passive 5y (%)"] == "--") {
            return -1;
          }
        } else {
          if (a.data["Vs. Best Passive 5y (%)"] > b.data["Vs. Best Passive 5y (%)"])
            return -1;
          if (a.data["Vs. Best Passive 5y (%)"] < b.data["Vs. Best Passive 5y (%)"])
            return 1;
          return 0;
        }
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Sharpe Ratio 1y") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Sharpe Ratio 1y"] > b.data["Sharpe Ratio 1y"])
          return -1;
        if (a.data["Sharpe Ratio 1y"] < b.data["Sharpe Ratio 1y"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Sharpe Ratio 3y") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Sharpe Ratio 3y"] > b.data["Sharpe Ratio 3y"])
          return -1;
        if (a.data["Sharpe Ratio 3y"] < b.data["Sharpe Ratio 3y"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Sharpe Ratio 5y") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Sharpe Ratio 5y"] > b.data["Sharpe Ratio 5y"])
          return -1;
        if (a.data["Sharpe Ratio 5y"] < b.data["Sharpe Ratio 5y"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Risk-Adjusted Return 1y (%)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Risk-Adjusted Return 1y (%)"] > b.data["Risk-Adjusted Return 1y (%)"])
          return -1;
        if (a.data["Risk-Adjusted Return 1y (%)"] < b.data["Risk-Adjusted Return 1y (%)"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Risk-Adjusted Return 3y (%)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Risk-Adjusted Return 3y (%)"] > b.data["Risk-Adjusted Return 3y (%)"])
          return -1;
        if (a.data["Risk-Adjusted Return 3y (%)"] < b.data["Risk-Adjusted Return 3y (%)"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Risk-Adjusted Return 5y (%)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Risk-Adjusted Return 5y (%)"] > b.data["Risk-Adjusted Return 5y (%)"])
          return -1;
        if (a.data["Risk-Adjusted Return 5y (%)"] < b.data["Risk-Adjusted Return 5y (%)"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Relative Return 1y") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Relative Return 1y (%)"] > b.data["Relative Return 1y (%)"])
          return -1;
        if (a.data["Relative Return 1y (%)"] < b.data["Relative Return 1y (%)"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Relative Return 3y (%)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Relative Return 3y (%)"] > b.data["Relative Return 3y (%)"])
          return -1;
        if (a.data["Relative Return 3y (%)"] < b.data["Relative Return 3y (%)"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Relative Return 5y (%)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Relative Return 5y (%)"] > b.data["Relative Return 5y (%)"])
          return -1;
        if (a.data["Relative Return 5y (%)"] < b.data["Relative Return 5y (%)"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "MSCI ESG Rating (Current)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["MSCI ESG Rating (Current)"] > b.data["MSCI ESG Rating (Current)"])
          return -1;
        if (a.data["MSCI ESG Rating (Current)"] < b.data["MSCI ESG Rating (Current)"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "MSCI ESG Rating 12M Low") {
      this.sortedNames.sort((a, b) => {
        if (a.data["MSCI ESG Rating 12M Low"] > b.data["MSCI ESG Rating 12M Low"])
          return -1;
        if (a.data["MSCI ESG Rating 12M Low"] < b.data["MSCI ESG Rating 12M Low"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "MSCI ESG Rating 12M High") {
      this.sortedNames.sort((a, b) => {
        if (a.data["MSCI ESG Rating 12M High"] > b.data["MSCI ESG Rating 12M High"])
          return -1;
        if (a.data["MSCI ESG Rating 12M High"] < b.data["MSCI ESG Rating 12M High"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Refinitiv ESG Rating (Current)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Refinitiv ESG Rating (Current)"] > b.data["Refinitiv ESG Rating (Current)"])
          return -1;
        if (a.data["Refinitiv ESG Rating (Current)"] < b.data["Refinitiv ESG Rating (Current)"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Refinitiv ESG Rating 12M High") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Refinitiv ESG Rating 12M High"] > b.data["Refinitiv ESG Rating 12M High"])
          return -1;
        if (a.data["Refinitiv ESG Rating 12M High"] < b.data["Refinitiv ESG Rating 12M High"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Refinitiv ESG Rating 12M Low") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Refinitiv ESG Rating 12M Low"] > b.data["Refinitiv ESG Rating 12M Low"])
          return -1;
        if (a.data["Refinitiv ESG Rating 12M Low"] < b.data["Refinitiv ESG Rating 12M Low"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "1 Year Total Return (%)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["1 Year Total Return (%)"] > b.data["1 Year Total Return (%)"])
          return -1;
        if (a.data["1 Year Total Return (%)"] < b.data["1 Year Total Return (%)"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "3 Year Total Return (%)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["3 Year Total Return (%)"] > b.data["3 Year Total Return (%)"])
          return -1;
        if (a.data["3 Year Total Return (%)"] < b.data["3 Year Total Return (%)"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "5 Year Total Return (%)") {
      this.sortedNames.sort((a, b) => {
        if (a.data["5 Year Total Return (%)"] > b.data["5 Year Total Return (%)"])
          return -1;
        if (a.data["5 Year Total Return (%)"] < b.data["5 Year Total Return (%)"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Standard Deviation (%) 1 Year") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Standard Deviation (%) 1 Year"] > b.data["Standard Deviation (%) 1 Year"])
          return -1;
        if (a.data["Standard Deviation (%) 1 Year"] < b.data["Standard Deviation (%) 1 Year"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Standard Deviation (%) 3 Year") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Standard Deviation (%) 3 Year"] > b.data["Standard Deviation (%) 3 Year"])
          return -1;
        if (a.data["Standard Deviation (%) 3 Year"] < b.data["Standard Deviation (%) 3 Year"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Standard Deviation (%) 5 Year") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Standard Deviation (%) 5 Year"] > b.data["Standard Deviation (%) 5 Year"])
          return -1;
        if (a.data["Standard Deviation (%) 5 Year"] < b.data["Standard Deviation (%) 5 Year"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
    if (item.id == "Total Weighted Value (% CAGR off best passive) 5 Year") {
      this.sortedNames.sort((a, b) => {
        if (a.data["Total Weighted Value (% CAGR off best passive) 5 Year"] > b.data["Total Weighted Value (% CAGR off best passive) 5 Year"])
          return -1;
        if (a.data["Total Weighted Value (% CAGR off best passive) 5 Year"] < b.data["Total Weighted Value (% CAGR off best passive) 5 Year"])
          return 1;
        return 0;
      });
      this.shared.tableViewData = [];
      this.shared.fundNameData = [];
      this.sortedNames.forEach(element => {
        this.shared.tableViewData.push(element.data);
        this.shared.fundNameData.push(element.name);
      });
    }
  }
}
