import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "src/services/CommonServices/common.service";
import { encode } from "string-encode-decode";
import { VerfyAuthCodeService } from "src/services/AuthServices/verfy-auth-code.service";
import { Shared } from "src/utils/common";

declare let ga: Function;
@Component({
  selector: 'app-verification-code',
  templateUrl: './verification-code.component.html',
  styleUrls: ['./verification-code.component.scss']
})
export class VerificationCodeComponent implements OnInit {
  authCode: any;
  userEmailForget: any;
  Password: string;
  remember: boolean;
  isPressed = false;
  errorMsg: any;
  uuidValue: any;
  rememberMeValue = false;

  constructor(
    public http: HttpClient,
    public verifyService: VerfyAuthCodeService,
    public router: Router,
    public common: CommonService,
    public shared: Shared
  ) {

    this.isPressed = false;
    this.authCode = null;
    const user = localStorage.getItem("activeRaoter");
    //localStorage.setItem("login", "0");
    localStorage.removeItem("userId");
    // localStorage.setItem("userId", "123");
    localStorage.removeItem("ID");
  }

  saveRemember(event: any): void {
    this.rememberMeValue = event;
  }

  goToLogin() {
    this.isPressed = false;
  }

  verifyCode() {
    this.isPressed = true;
    this.Password = localStorage.getItem("userPassword");
    this.uuidValue = localStorage.getItem("uuidValue");
    const user = {
      email: localStorage.getItem("userName"),
      password: this.Password,
      code: this.authCode,
      token: this.uuidValue,
      isRemember: this.rememberMeValue,
    };
    if (this.authCode != undefined && this.authCode != null) {
      this.verifyService.verifyCode(user).subscribe(
        (res) => {
          this.authCode = null;
          this.isPressed = false;
          if (res["message"] == "Inccorect Code.") {
            this.errorMsg = "Please enter valid code";
            setTimeout(() => {
              this.errorMsg = "";
            }, 2000);
          } else if (res["message"] == "Login SuccessFully") {
            //localStorage.setItem("login", "1");
            localStorage.setItem("userId", res["data"].userID);
            this.shared.isLogin = true;
            this.shared.userId = res["data"].userID;
            localStorage.setItem("ID", encode(res["data"].userID));
            if (res["data"].isFirstLogin == false) {
              this.shared.isFirstLogin = false;
            } else {
              this.shared.isFirstLogin = true;
            }
            ga('create', 'UA-211001570-1', {
              'storage': 'none',
              'clientId': this.shared.userId
            });
            ga('send', 'pageview');
            this.router.navigateByUrl("/user-dashboard");
          } else if (
            res["message"] ==
            "User Not Found, Please check your username/password"
          ) {
            this.errorMsg = res["message"];
            setTimeout(() => {
              this.errorMsg = "";
            }, 2000);
          }
        },
        (err) => {
          console.log("err", err);
          this.isPressed = false;
        }
      );
    } else {
      this.isPressed = false;
      this.errorMsg = "Please enter valid code";
      setTimeout(() => {
        this.errorMsg = "";
      }, 2000);
    }
  }

  ngOnInit() {
    localStorage.setItem("activeRaoter", "home");
  }

}
