import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TopFundsService } from 'src/services/ChartServices/top-funds.service';
import { CommonService } from 'src/services/CommonServices/common.service';
import { GoogleAnalyticsService } from 'src/services/google-Analytics/google-analytics.service';
import { GrowthViewService } from 'src/services/growth-view/growth-view.service';
import { SummaryPopupService } from 'src/services/summary-popup/summary-popup.service';
import { apiError } from 'src/utils/common-function';
import { Commons, Shared } from 'src/utils/common';

@Component({
  selector: 'app-chart-headers',
  templateUrl: './chart-headers.component.html',
  styleUrls: ['./chart-headers.component.scss']
})
export class ChartHeadersComponent implements OnInit {
  @Input() diamond:boolean;
  @Input() userDashboard: boolean;
  commonFund:boolean;
  graphheight:any;
  selectedCategory:any;
  graphWidth:any;
  selectColoum = false;
  showPopupOfSummary = false;
  fundName:any;
  fundDetails:any[]=[];
  isDuplicate = false;
  
  @Input() selectedDataDropDown:any;
  @ViewChild('topFundContainerCanvas') topFundContainerCanvas: ElementRef;
  @Output("closeCharts")closeCharts: EventEmitter<any> = new EventEmitter();
  @Input () dataNotAvailable:boolean;
  constructor(public common:CommonService, public summaryPopupService:SummaryPopupService, public growthChartService:GrowthViewService, public topFundService:TopFundsService,
    public googleAnalyticsEventsService: GoogleAnalyticsService, public shared: Shared) {
  }

  ngOnInit(): void {
    this.shared.selectedCategory.subscribe((res) =>{
      if(res && res != "undefined"){
        this.selectedCategory = res;
      }
    });
    const selectCate = localStorage.getItem("chartCategory");
    if(selectCate && selectCate != "undefined") {
    this.selectedCategory = localStorage.getItem("chartCategory");
  } 
    setTimeout(() =>{
    this.graphheight = this.topFundContainerCanvas.nativeElement.offsetHeight;
    this.graphWidth = this.topFundContainerCanvas.nativeElement.offsetWidth;
    this.shared.topFundChartHeight = this.graphheight;
    this.shared.topFundChartWidth = this.graphWidth;
    }, 10)
  }

  

  closeChart(){
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "topFund_closechart",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.closeCharts.emit();
  }

  onResizedDivTopFundChart(){
    setTimeout(() =>{
      this.graphheight = this.topFundContainerCanvas.nativeElement.offsetHeight;
      this.graphWidth = this.topFundContainerCanvas.nativeElement.offsetWidth;
      this.shared.topFundChartHeight = this.graphheight;
      this.shared.topFundChartWidth = this.graphWidth;

      }, 10)
  }

  async getChartScore(event, i){
    Commons.selectedScore = [];
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "topfund_consistency",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    await this.shared.consistencyScore.map((ele)=>{ele.select = false});

    await this.shared.consistencyScore.forEach((ele, index)=>{
      if(index <= i){
        ele.select = true;
        Commons.selectedScore.push(ele.value);
      }
    });
    Commons.consistencyObTopFund = 0;
    Commons.consistencyObActivePassive = 0;
    Commons.consistencyObRatingView = 0;
    Commons.consistencyObRiskReward = 0;
    setTimeout(()=>{
      Commons.consistencyScoreChange.next();
    }, 20);
    setTimeout(()=>{
      Commons.consistencyChange.next();
    }, 20);
  }


  openSummaryCardTopFund(event) {
    this.showPopupOfSummary = true;
    this.fundName = event.target.series.name;
    this.summaryPopupService.fundName = event.target.series.name;
    this.growthChartService.fundName = this.fundName;
    this.shared.summaryCardDataLoading = true;
    this.summaryPopupService.getSummaryPopupdetail(event.target.series.userOptions.id).subscribe((res:any)=>{
      
      this.shared.summaryCardDataLoading = false;
      if(res.data){
        this.fundDetails = res.data[0];
      }
      this.shared.isObservableCall = 0;
      this.shared.summaryChartObservable.next();
      
    },(err)=>{
      this.shared.summaryCardDataLoading = false;
      apiError(err);
    })
  }

  getSummeryState(event) {
    this.showPopupOfSummary = event.showState;
  }

  userGuideLink(){
    window.open("https://storage.googleapis.com/fundsaiq-fundsdocuments/userguide/Fundsaiq_userguide.pdf");
  }
  
  getShareClasses(event){
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "topFund_primary_shareclass",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.isShareClassOnn = event;
    if(event == true && this.shared.topFundCategoryData){
      Commons.isShareClassData.topData = [];
      for (var i = 0; i < this.shared.topFundCategoryData.topData.length; i++) {
        if (Commons.isShareClassData.topData.length > 0 && Commons.isShareClassData.topData.every(item => item.ISIN === this.shared.topFundCategoryData.topData[i]["ISIN"])) {
          Commons.isShareClassData.topData.forEach((ele, ind) => {
            // if(this.shared.topFundCategoryData.topData[i]["ISIN"] == ele.ISIN) {
            if (ele.IsPrimaryShareClass == "TRUE") {
            } else if (this.shared.topFundCategoryData.topData[i]["IsPrimaryShareClass"] == "TRUE") {
              Commons.isShareClassData.topData.splice(ind, 1, this.shared.topFundCategoryData.topData[i]);
            } else if (ele.IsPrimaryShareClass == "FALSE" && this.shared.topFundCategoryData.topData[i]["IsPrimaryShareClass"] == "FALSE") {
              if (ele["compounded_return_05"] > this.shared.topFundCategoryData.topData[i]["compounded_return_05"]) {
              } else if (ele["compounded_return_05"] < this.shared.topFundCategoryData.topData[i]["compounded_return_05"]) {
                Commons.isShareClassData.topData.splice(ind, 1, this.shared.topFundCategoryData.topData[i]);
              } else {

              }
            }
            //}
          });

        } else {
          Commons.isShareClassData.topData.push(this.shared.topFundCategoryData.topData[i]);
        }
      }
      Commons.toggleShareClass.next({ data: Commons.isShareClassData, year: Commons.selectedTotalYear, hiddenGem: Commons.selectedHiddenGem });
    }else{
      Commons.toggleShareClass.next({ data: this.shared.topFundCategoryData, year: Commons.selectedTotalYear, hiddenGem: Commons.selectedHiddenGem });
    }
  }

}
