import { Component, OnInit, Input } from '@angular/core';
import { Commons } from '.././../utils/common';

@Component({
  selector: 'app-security-profile',
  templateUrl: './security-profile.component.html',
  styleUrls: ['./security-profile.component.scss']
})
export class SecurityProfileComponent implements OnInit {
  public errMsg:any;
  public currentPassowrd:any;
  public newPassowrd:any;
  public conirmPassowrd:any;
  @Input() matchError:any;

  constructor() { }

  ngOnInit(): void {
    this.currentPassowrd = Commons.currentPass;
    this.newPassowrd = Commons.newPass;
    this.conirmPassowrd = Commons.confirmPass;
  }

  onPressCurrentPassword(event){
    this.currentPassowrd = event.target.value;
    Commons.currentPass = this.currentPassowrd;
  }

  onPressNewPassord(event){
    this.newPassowrd = event.target.value;
    Commons.newPass = this.newPassowrd;
  }

  onPressConfirmPasword(event){
    this.conirmPassowrd = event.target.value;
    Commons.confirmPass = this.conirmPassowrd;
  }

}
