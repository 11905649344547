import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/services/CommonServices/common.service";
import { GoogleAnalyticsService } from "src/services/google-Analytics/google-analytics.service";
import { SummaryPopupService } from "src/services/summary-popup/summary-popup.service";
import { Commons, Shared } from "src/utils/common";
import { apiError, consistencyUpdate } from "src/utils/common-function";

var anychart = require("anychart");
declare let ga: Function;

@Component({
  selector: "app-ms-risk-reward",
  templateUrl: "./ms-risk-reward.component.html",
  styleUrls: ["./ms-risk-reward.component.scss"],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    "(document:click)": "onClick($event)",
  },
})
export class MsRiskRewardComponent implements OnInit {
  @ViewChild("tooltipPopup") tooltipPopup: ElementRef;
  @Input() selectedDataDropDown:any;
  @Output("closeCharts") closeCharts: EventEmitter<any> = new EventEmitter();
  @ViewChild("riskreward") riskchartcontainer: ElementRef;
  totalFiveYearRisk: any = [];
  selectColoum = false;
  @Input() showMarketInsightData: boolean;
  showGraphdata = false;
  showPopupOfSummary = false;
  fundDetails:any[]=[];
  @Input() addViewEnable:boolean;
  @Input() set onFilterChange(val) {
    if (this.riskchartcontainer) {
      this.riskchartcontainer.nativeElement.innerHTML = null;
    }
  }
  selectedCategory:any;
  fundName: any;
  // chartHeight:any;
  @Input() chartHeight:any;
  blink: boolean;
  constructor(public common: CommonService, public cd: ChangeDetectorRef, public summaryPopupService:SummaryPopupService, public router: Router,
    public googleAnalyticsEventsService: GoogleAnalyticsService, public shared: Shared) {}

  ngOnInit() {
    this.router.config.forEach(event => {
      if (event.path == "riskReward") {
        // gtag('config', 'UA-211001570-1', 
        //            {
        //              'page_path': event.path          
        //            }
        //           );
                  ga('send', {
                    hitType: 'pageview',
                    page: event.path
                  });
      }
    });
    this.shared.selectedCategory.subscribe((res) =>{
      if(res && res != "undefined"){
        this.selectedCategory = res;
      }
    });
    const selectCate = localStorage.getItem("chartCategory");
    if(selectCate && selectCate != "undefined")   {
      this.selectedCategory = localStorage.getItem("chartCategory");
    } 
    if(!this.addViewEnable){
      this.chartHeight = "630";
    }else{
      // this.chartHeight = "630";
    }

    this.blink = true;
    setTimeout(() => {
      this.blink = false;
    }, 5 * 60 * 1000);
  //  this.getShareClasses(this.common.isShareClassOnnRisk);
  }

  getShareClasses(event){
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "primary_shareclass",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.isShareClassOnn = event;
    if(event == true && this.shared.topFundCategoryData){
      Commons.toggleShareClass.next();
    }else{
      Commons.toggleShareClass.next();
    }
  }

  userGuideLink(){
    window.open("https://storage.googleapis.com/fundsaiq-fundsdocuments/userguide/Fundsaiq_userguide.pdf");
  } 
  
  ngOnDestroy(){
    if(Commons.userDashboardshow){
      this.router.config.forEach(event => {
        if (event.path == 'user-dashboard') {
          // gtag('config', 'UA-211001570-1', 
          //            {
          //              'page_path': event.path              
          //            }
          //           );
          ga('send', {
            hitType: 'pageview',
            page: event.path
          });
        }
      });
    }
  }

  getChartScore(event, i){
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "consistency",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
      Commons.selectedScore = [];
    this.shared.consistencyScore.map((ele)=>{ele.select = false});
    this.shared.consistencyScore.forEach((ele, index)=>{
      if(index <= i){
        ele.select = true;
        Commons.selectedScore.push(ele.value);
      }
    });
    Commons.consistencyObTopFund = 0;
    Commons.consistencyObActivePassive = 0;
    Commons.consistencyObRatingView = 0;
    Commons.consistencyObRiskReward = 0;
    setTimeout(()=>{
      Commons.consistencyScoreChange.next();
    }, 10);
    // consistencyUpdate();
  }

  closeChart() {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "close_risk_chart",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.closeCharts.emit();
  }

  showGraph() {
    if (this.showGraphdata) {
      this.showGraphdata = false;
    } else {
      setTimeout(() => {
        this.showGraphdata = !this.showGraphdata;
      }, 10);
    }
  }

  onClick(event) {
    if (this.tooltipPopup != undefined) {
      if (!this.tooltipPopup.nativeElement.contains(event.target) && this.showGraphdata) {
        this.showGraphdata = false;
      }
    }
  }

  openSummaryPopup(event){
    this.shared.alertsAndNotificationSummary = false;
    this.showPopupOfSummary = true;
    this.fundName = event.point.getSeries().name();
    this.shared.summaryCardDataLoading = true;
    this.summaryPopupService.getSummaryPopupdetail(event.point.getSeries().id()).subscribe((res:any)=>{
      this.shared.summaryCardDataLoading = false;
      if(res.data){
        this.fundDetails = res.data[0];
      }
      this.shared.isObservableCall = 0;
      this.shared.summaryChartObservable.next();
      
    },(err)=>{
      this.shared.summaryCardDataLoading = false;
      apiError(err);
    })
  }

  getSummeryState(event) {
    this.showPopupOfSummary = event.showState;
  }

  openSummaryCardForfunds(event) {
    this.shared.alertsAndNotificationSummary = false;
    this.showPopupOfSummary = true;
    this.summaryPopupService.fundName = event;
    this.fundName = event;
    this.shared.summaryCardDataLoading = true;
    this.summaryPopupService.getSummaryPopupdetail(event.fundId).subscribe((res:any)=>{
      this.shared.summaryCardDataLoading = false;
      if(res.data){
        this.fundDetails = res.data[0];
      }
      this.shared.isObservableCall = 0;
      this.shared.summaryChartObservable.next();
      
    },(err)=>{
      this.shared.summaryCardDataLoading = false;
      apiError(err);
    })
  }



}
