import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { constants } from "src/utils/constants";
import { PrefrencesComponent } from "../components/prefrences/prefrences.component";
import { MethedologyComponent } from "../components/methedology/methedology.component";
import { UserDashboardComponent } from "../components/user-dashboard/user-dashboard.component";
import { LoginComponent } from "../pages/login/login.component";
import { AuthenticatedGuardService } from "../services/AuthServices/authenticated-guard.service";
import { AuthenticatedGuardActivePathService } from "../services/AuthServices/authenticated-guard-active-path.service";
import { VerifymailComponent } from "src/pages/verifymail/verifymail.component";
import { ResetComponent } from "src/pages/reset/reset.component";
import { ActivePassiveComponent } from "../components/active-passive/active-passive.component";
import { ChangePasswordComponent } from "src/pages/change-password/change-password.component";
import { RatingViewComponent } from "src/components/rating-view/rating-view.component";
import { MsGrowthViewComponent } from "src/components/ms-growth-view/ms-growth-view.component";
import { MsRiskRewardComponent } from "src/components/ms-risk-reward/ms-risk-reward.component";
import { MyFundsComponent } from "src/components/my-funds/my-funds.component";
import { TopFundChartComponent } from "src/components/top-fund-chart/top-fund-chart.component";

const routes: Routes = [
  {
    path: constants.paths.blank,
    pathMatch: constants.paths.full,
    redirectTo: constants.paths.login,
  },
  {
    path: constants.paths.login,
    component: LoginComponent,
    canActivate: [AuthenticatedGuardActivePathService]
  },
  { path: "active-component", component: ActivePassiveComponent },
  { path: "reset-component", component: ResetComponent },
  { path: "verifymail-component", component: VerifymailComponent },
  { path: "tableView", component: RatingViewComponent },
  { path: "growthView", component: MsGrowthViewComponent },
  { path: "riskReward", component: MsRiskRewardComponent },
  { path: "myFunds", component: MyFundsComponent },
  { path: "topFunds", component: TopFundChartComponent },
  {
    path: "prefrences",
    component: PrefrencesComponent,
    canActivate: [AuthenticatedGuardActivePathService],
  },
  {
    path: "methedology",
    component: MethedologyComponent,
    canActivate: [AuthenticatedGuardActivePathService],
  },
  {
    path: "user-dashboard",
    component: UserDashboardComponent,
    canActivate: [AuthenticatedGuardService],
  },
  { path: "resetpassword", component: ResetComponent },
  { path: "changePassword", component: ChangePasswordComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }), ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
