import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subject } from "rxjs";
import { apiError } from 'src/utils/common-function';
import { Shared } from 'src/utils/common';

const cacheBuster$ = new Subject<void>();
@Injectable({
  providedIn: 'root'
})

export class AlertsNotificationsService {
  
  constructor(public http: HttpClient, public shared: Shared) { }


  getAlertsNotifications(userId, paging){
    this.shared.alertsAndNotificationLoading = true;
    this.shared.alertsAndNotificationDataAvailable = true;
    this.http.get(environment.baseUrl + 'getAlertsNotifications/' + userId + '?paging=' + paging).subscribe((res)=>{
      this.shared.topFundGlobalEqualityAlertsX = [];
      this.shared.alertsAndNotificationData = [];
      this.shared.newsfeedDataAlerts = [];
        this.shared.alertsAndNotificationLoading = false;
        if (res['data'] && res['data'].length > 0) {
          res['data'].forEach(element => {
            const a = element.event_value.replace(/'/g, '"')
            const x = a.replace(/'/g, '"').replace(/}"/g, "}'");
            let da = x.replace(/None/g, '"None"').replace(/datetime/g, '"datetime').replace(/Timestamp/g, '"Timestamp').replace(/}:/g, "}")
          element.event_value = JSON.parse(da);
            this.shared.topFundGlobalEqualityAlertsX.push({
              occurrenceDate:element.effective_date,
              publishedData:element.date_event_noticed,
              newText: element.event_value.new_value != "None" ? element.event_value.new_value : "",
              name: element.Fund_Name, selectLike: false, fundId: element.ShareClass_Id,
              data: element.alert_type + ' : ' + element.event_type,
              text: element.event_value.old_value != "None" ? element.event_value.old_value : "",
              category: element.Category,
              selectDelete: false, selectClose: false, show: false
            })
            this.shared.alertsAndNotificationData.push(element);
            this.shared.newsfeedDataAlerts.push(element);
          });
          this.filterByDate();
          this.shared.alertsAndNotificationObservable.next();
        } else {
          this.shared.alertsAndNotificationDataAvailable = false;
        }
    },(err)=>{
      this.shared.alertsAndNotificationLoading = false;
      this.shared.alertsAndNotificationDataAvailable = false;
      apiError(err);
    })
  }

  filterByDate() {
    this.shared.topFundGlobalEqualityAlertsX.sort((a, b) => {
       if(a.publishedData > b.publishedData) {
        return -1;
       }else{
        return 1;
       }
    });
  }


  getALertsNotificationsScroll(userId, paging){
    return this.http.get(environment.baseUrl + 'getAlertsNotifications/' + userId + '?paging=' + paging);
  }

  getSummaryPopupdetail(id) {
    return this.http.get(environment.baseUrl + 'fundsummerydetails?fundid=' + id);
  }

}
