import { DatePipe } from '@angular/common';
import { Component, OnInit, Output, EventEmitter, Input, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FundsDataService } from 'src/services/ChartServices/funds-data.service';
import { CommonService } from 'src/services/CommonServices/common.service';
import { FundAlertsService } from 'src/services/fund-Alerts/fund-alerts.service';
import { FundDocumentaionService } from 'src/services/fund-documentation/fund-documentaion.service';
import { GoogleAnalyticsService } from 'src/services/google-Analytics/google-analytics.service';
import { GrowthViewService } from 'src/services/growth-view/growth-view.service';
import { NewsFundsService } from 'src/services/news-for-funds/news-funds.service';
import { PrefrenceSettingsService } from 'src/services/PreferencesServices/prefrence-settings.service';
import { SummaryPopupService } from 'src/services/summary-popup/summary-popup.service';
import { Commons, Shared } from 'src/utils/common';
import { apiError } from 'src/utils/common-function';
import { decode } from "string-encode-decode";
declare let ga: Function;
var Highcharts = require("highcharts");
@Component({
  selector: 'app-my-funds',
  templateUrl: './my-funds.component.html',
  styleUrls: ['./my-funds.component.scss'],
  providers: [DatePipe],
})


export class MyFundsComponent implements OnInit {
  @Output("closeCharts") closeCharts: EventEmitter<any> = new EventEmitter();
  @Output() summaryPopupTooltip = new EventEmitter<string>();
  @ViewChild('myFundChartContainer') myFundChartContainer: ElementRef;
  @ViewChild("newsComponentMyFund") newsComponentMyFund: ElementRef;
  @ViewChild("alertComponentMyFund") alertComponentMyFund: ElementRef;
  @Input() dataNotAvailable: boolean;
  @Input() selectedDataDropDown: any;
  checkRequestStartMyFunds: Subscription;
  checkRequestMyFunds: Subscription;
  checkRequest: Subscription;
  selectColoum = false;
  userID: any;
  graphHeigt: any;
  showPopupOfSummary = false;
  fundAlertsData: any = [];
  fundDetails: any[] = [];
  dataAvailable: boolean;
  fundName: any;
  graphWidth: any;
  newsFeedData: any[] = [];
  topFundsX: any[] = [];
  topFundsY: any[] = [];
  selectedCategory: any;
  newsLoading: boolean;
  alertLoading: boolean;
  dataNot: boolean;
  alertsDataMyFund: any;
  newsFundDataMyFund: any;

  constructor(public fundService: FundsDataService, public summaryPopupService: SummaryPopupService,
    public newsFund: NewsFundsService, public fundAlerts: FundAlertsService, public fundDocService: FundDocumentaionService,
    public preferenceService: PrefrenceSettingsService, public common: CommonService, public router: Router,
    public googleAnalyticsEventsService: GoogleAnalyticsService,
    public growthService: GrowthViewService,
    public datePipe: DatePipe,
    public shared: Shared) { }

  ngOnInit() {
    this.shared.myFundsChart = false;
    this.router.config.forEach(event => {
      if (event.path == "myFunds") {
        // gtag('config', 'UA-211001570-1', 
        //            {
        //              'page_path': event.path          
        //            }
        //           );
        ga('send', {
          hitType: 'pageview',
          page: event.path
        });
      }
    });
    this.shared.selectedCategory.subscribe((res) => {
      if (res && res != "undefined") {
        this.selectedCategory = res;
      }
    });
    const selectCate = localStorage.getItem("chartCategory");
    if (selectCate && selectCate != "undefined") {
      this.selectedCategory = localStorage.getItem("chartCategory");
    }
    setTimeout(() => {
      this.graphHeigt = this.myFundChartContainer.nativeElement.offsetHeight;
      this.graphWidth = this.myFundChartContainer.nativeElement.offsetWidth;
    }, 10)
    this.userID = decode(localStorage.getItem("ID"));
    this.selectColoum = false;
    this.checkRequestMyFunds = this.shared.myFundsDataObservable.subscribe(() => {
      this.getMyFundchartData(this.shared.myFundChartData);
      this.shared.checkMyFunds = false;
    })
    this.checkRequest = this.shared.myFundsPreferencesObservable.subscribe((res) => {
      this.getMyFundchartData(res);
    })
    Commons.myFundsChart.subscribe(() => {
      this.shared.checkMyFunds = true;
      this.getMyFundchartData(this.shared.myFundChartData);
    })
    this.getMyFundchartData(this.shared.myFundChartData);
    this.shared.checkMyFunds = false;
  }

  userGuideLink() {
    window.open("https://storage.googleapis.com/fundsaiq-fundsdocuments/userguide/Fundsaiq_userguide.pdf");
  }

  ngOnDestroy() {
    if (Commons.userDashboardshow) {
      this.router.config.forEach(event => {
        if (event.path == 'user-dashboard') {
          // gtag('config', 'UA-211001570-1', 
          //            {
          //              'page_path': event.path              
          //            }
          //           );
          ga('send', {
            hitType: 'pageview',
            page: event.path
          });
        }
      });
    }
    if (this.checkRequestMyFunds) {
      this.checkRequestMyFunds.unsubscribe();
    }
    if (this.checkRequest) {
      this.checkRequest.unsubscribe();
    }
  }

  closeChart() {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "myfunds_closechart",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.closeCharts.emit();
  }

  getMyFundchartData(res) {
    this.topFundsX = [];
    this.topFundsY = [];
    if (res.data && res.data.length > 0) {
      res.data.forEach((data) => {
        if (this.shared.alterSettingId == data.PreferencesID) {
          let value: any;
          value = (data["compounded_return_05"] ? data["compounded_return_05"] : 0);
          this.topFundsX.push(data["Name"]);
          this.topFundsY.push({
            y: value * 100,
            color: "#1F639B",
            name: data["Fund_Size_USD"],
            Index_Fund: data["Index_Fund"],
            id: data["Id"],
            cate: this.removeUnderscore(data["Global_Category"])
          });
          this.shared.myFundIds.push(data["Id"]);
        }
      });
      this.shared.myFundsChart = true;
      if (this.shared.checkMyFunds) {
        this.growthService.addBestPassive(
          this.datePipe.transform(localStorage.getItem("date"), "yyyy-MM-dd")
        );
      }
      this.generateMyFundsChart();
    }
  }

  removeUnderscore(item) {
    if (item) {
      const value = item.replace(/_/g, " ");
      return value;
    }
  }

  generateMyFundsChart() {
    this.shared.isShowTopFundLoading = false;
    let graphHeight;
    let graphWidth;
    graphHeight = this.graphHeigt - 100;
    graphWidth = this.graphWidth - 80;
    const chart = Highcharts.chart("myFundContainers", {
      chart: {
        backgroundColor: "#000f19",
        color: "#fffff",
        height: graphHeight,
        width: graphWidth,
        spacingRight: 3,
        style: {
          fontFamily: "sans-serif",
        },
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: this.topFundsX,
        labels: {
          style: {
            color: "#606f88",
            fontSize: "13px",
          },
        },
      },
      credits: {
        enabled: false
      },
      yAxis: {
        labels: {
          style: {
            color: "#606f88",
            fontSize: "14px",
          },
        },
        gridLineWidth: 0.5,
        title: {
          text: "5 year Weighted Return",
          style: { color: "#606f88", fontSize: "12px" },
        },
      },
      tooltip: {
        enabled: true,
        disable: false,
        useHTML: true,
        borderRadius: 10,
        stickOnContact: true,
        padding: 18,
        outside: false,
        backgroundColor: "#000f19",
        formatter: function () {
          var fundType = !this.point.Index_Fund ? 'Passive Fund':'Active Fund'
          return (
            `<div id='tooltip-buttons-my-fund'><div class="tooltip-buttons-parent"><button class="button-tooltip" id="summary-card-my-fund" type="button" onmouseover="this.style.borderColor='` + this.point.color + `'" onmouseout="this.style.borderColor='#58677f'">SUMMARY CARD</button><br><div class="btn-flex"><button class="button-tooltip fact-btn" id="fact-sheet"
              onmouseover="this.style.borderColor='`+ this.point.color + `'" onmouseout="this.style.borderColor='#58677f'">FACTSHEET</button><button class="button-tooltip" id="kiid"
              onmouseover="this.style.borderColor='`+ this.point.color + `'" onmouseout="this.style.borderColor='#58677f'">KIID</button></div><br><div class="btn-flex" style="margin-top:-15px;"><button id="alerts-data-my-fund" class="button-tooltip fact-btn"
              onmouseover="this.style.borderColor='`+ this.point.color + `'" onmouseout="this.style.borderColor='#58677f'">ALERTS</button><button class="button-tooltip" id="news-feeds-my-fund"
              onmouseover="this.style.borderColor='`+ this.point.color + `'" onmouseout="this.style.borderColor='#58677f'">MARKET INSIGHTS</button></div></div>` +
            "<br/>" +
            '<span style="color:#617088;max-width:100%; white-space: normal;display: block;  margin-bottom: -12px;">' +
            this.point.category +
            "</span><br/>" +
            '<span style="color:#617088;max-width:100%; white-space: normal;display: block;  margin-bottom: -12px;">20-10-2020</span><br/>' +
            '<span style="color:#617088;display: block; margin-bottom: -12px;">' + fundType + '</span><br>' +
            '<span style="color:#617088;">' + this.point.cate + '</span><br><span style="color:' +
            this.point.color +
            '">' +
            "Total Weighted Value" +
            ": <b>" +
            this.point.y.toFixed(2) + '% (5Y)' +
            '</span></b><br/></div><div id="newsFeedDataOnTooltipMyFund" style="position: relative;top: -15px;left: -12px;"> </div>'
          );
        },
      },
      series: [
        {
          type: "column",
          colorByPoint: true,
          borderWidth: 0,
          style: {
            color: "#606f88",
            fontSize: "18px",
          },
          dataLabels: {
            enabled: true,
            rotation: 0,
            color: "#48EEE0",
            align: "center",
            // format: "{y}%", // one decimal
            formatter: function () {
              // return Highcharts.numberFormat(this.y,1);
              // return Math.round(this.y)
              if (Number.isInteger(this.y)) {
                return this.y + '%';
              } else {
                return this.y.toFixed(1) + '%';
              }
            },
            y: 0, // 10 pixels down from the top
            style: {
              fontSize: "10px",
              fontFamily: "Verdana, sans-serif",
            },
          },
          data: this.topFundsY,
          showInLegend: false,
        },
      ],
      plotOptions: {
        series: {
          point: {
            events: {
              mouseOver: function (event) {
                setTimeout(() => {
                  if (document.getElementById("news-feeds-my-fund")) {
                    document
                      .getElementById("news-feeds-my-fund")
                      .addEventListener("click", this.getNewsData.bind(this, event));
                  }
                }, 100);
                setTimeout(() => {
                  if (document.getElementById("fact-sheet")) {
                    document
                      .getElementById("fact-sheet")
                      .addEventListener("click", this.getFactsheet.bind(this, event));
                  }
                }, 100);
                setTimeout(() => {
                  if (document.getElementById("kiid")) {
                    document
                      .getElementById("kiid")
                      .addEventListener("click", this.getKiid.bind(this, event));
                  }
                }, 100);
                setTimeout(() => {
                  if (document.getElementById("summary-card-my-fund")) {
                    document.getElementById("summary-card-my-fund").addEventListener(
                      "click",
                      this.openSummaryCard.bind(this, event)
                    );
                  }
                }, 100);
                setTimeout(() => {
                  if (document.getElementById("alerts-data-my-fund")) {
                    document.getElementById("alerts-data-my-fund")
                      .addEventListener("click", this.getAlertsData.bind(this, event));
                  }
                }, 100);
              }.bind(this),
            },
          },
        },
      },
    });
    chart.reflow();
  }

  onResizedDivMyFundChart() {
    setTimeout(() => {
      let height;
      let width;
      height = this.myFundChartContainer.nativeElement.offsetHeight;
      width = this.myFundChartContainer.nativeElement.offsetWidth;
      this.graphHeigt = height;
      this.graphWidth = width;
      this.generateMyFundsChart();
    }, 10)
  }



  openSummaryCard(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.target.id + "_summary_tooltip",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.alertsAndNotificationSummary = false;
    this.showPopupOfSummary = true;
    this.summaryPopupService.fundName = event.target.category;
    this.fundName = event.target.category;
    this.shared.summaryCardDataLoading = true;
    if (this.checkRequestStartMyFunds) {
      this.checkRequestStartMyFunds.unsubscribe();
    }
    this.checkRequestStartMyFunds = this.summaryPopupService.getSummaryPopupdetail(event.target.id).subscribe((res: any) => {
      this.shared.summaryCardDataLoading = false;
      if (res.data) {
        this.fundDetails = res.data[0];
      } else {
        this.fundDetails = [];
      }
      this.shared.summaryChartObservable.next();
      this.shared.isObservableCall = 0;
    }, (err) => {
      this.shared.summaryCardDataLoading = false;
      apiError(err);
    })
    event.preventDefault();
  }

  getSummeryState(event) {
    this.showPopupOfSummary = event.showState;
  }

  getNewsData(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.target.id + "_market_tooltip",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.newsFundDataMyFund = event;
    this.dataNot = false;
    this.newsLoading = true;
    this.newsFeedData = [];
    this.newsFund.getNewsForFund(event.target.id).subscribe((res) => {
      this.newsLoading = false;
      if (res["data"]) {
        res["data"].forEach(element => {
          if (!this.newsFeedData.some((item) => item.Title == element.Title && item.Provider == element.Provider)) {
            this.newsFeedData.push(element);
          }
        });
      }
      else {
        this.dataNot = true;
      }
      this.newsLoading = false;
      this.getMarketNewsData();
    }, (err) => {
      this.dataNot = true;
      this.newsLoading = false;
    })
    document.getElementById("tooltip-buttons-my-fund").style.display = "none";
    document.getElementById("newsFeedDataOnTooltipMyFund").style.display = "block";
    setTimeout(() => {
      document.getElementById(
        "newsFeedDataOnTooltipMyFund"
      ).innerHTML = this.newsComponentMyFund.nativeElement.innerHTML;
    }, 10)
  }

  getMarketNewsData() {
    setTimeout(() => {
      document.getElementById(
        "newsFeedDataOnTooltipMyFund"
      ).innerHTML = this.newsComponentMyFund.nativeElement.innerHTML;
    }, 5)
    setTimeout(() => {
      document
        .getElementById("closeNewsMyFund")
        .addEventListener("click", this.closeNewsMyFund.bind(this));
    }, 10)
  }

  closeNewsMyFund() {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "close_tooltip",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    document.getElementById("newsFeedDataOnTooltipMyFund").style.display = "none";
    document.getElementById("tooltip-buttons-my-fund").style.display = "block";
  }

  ClickedOut(event) {
    if (event.target.className === "pg-form-pages login-page") {
      document.getElementById("close-documentation").click();
    }
  }

  getFactsheet(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "factsheet_tooltip",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if (this.shared.fundDocumentationData.length > 0) {
      this.shared.fundDocumentationData.forEach(element => {
        if (event.target.category == element.Name && element.Factsheet_Url != "null" && element.Factsheet_Url != undefined) {
          window.open(element.Factsheet_Url);
        }
      });
    } else {
      this.shared.popupTimer = 3;
      document.getElementById("openModalButton").click();
      const interval = setInterval(() => {
        if (this.shared.popupTimer > 0) {
          this.shared.popupTimer--;
        } else if (this.shared.popupTimer == 0) {
          document.getElementById("close-documentation").click();
          clearInterval(interval);
        }
      }, 1000);
    }
  }

  getKiid(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "kiid_tooltip",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if (this.shared.fundDocumentationData.length > 0) {
      this.shared.fundDocumentationData.forEach(element => {
        if (event.target.category == element.Name) {
          if (element.KIID_Url != "null" && element.KIID_Url != undefined) {
            window.open(element.KIID_Url);
          }
        }
      });
    } else {
      this.shared.popupTimer = 3;
      document.getElementById("openModalButton").click();
      const interval = setInterval(() => {
        if (this.shared.popupTimer > 0) {
          this.shared.popupTimer--;
        } else if (this.shared.popupTimer == 0) {
          document.getElementById("close-documentation").click();
          clearInterval(interval);
        }
      }, 1000);
    }
  }

  getAlertsData(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.target.id + "_alerts_tooltip",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.alertsDataMyFund = event;
    this.alertLoading = true;
    this.dataAvailable = true;
    this.fundAlerts.getFundAlerts(event.target.id).subscribe((res) => {
      this.alertLoading = false;
      this.fundAlertsData = [];
      if (res["length"] > 0) {
        const test = res[0].event_value.replace(/'/g, '"');
        const da = test.replace(/None/g, '"None"').replace(/datetime/g, '"datetime').replace(')', ')"').replace(/Timestamp/g, '"Timestamp')
        res[0].event_value = JSON.parse(da);
        this.fundAlertsData.push({
          occurrenceDate: res[0].effective_date,
          publishedData: res[0].date_event_noticed,
          newText: res[0].event_value.new_value != "None" ? res[0].event_value.new_value : "",
          name: res[0].Fund_Name, selectLike: false, fundId: res[0].ShareClass_Id,
          data: res[0].alert_type + ' : ' + res[0].event_type.replace(/_/g, " "),
          text: res[0].event_value.old_value != "None" ? res[0].event_value.old_value : "",
          selectDelete: false, selectClose: false, show: false
        });
      } else {
        this.dataAvailable = false;
      }
      this.alertLoading = false;
      this.alertData();
    }, (err) => {
      this.alertLoading = false;
      this.dataAvailable = false;
    });
    document.getElementById("tooltip-buttons-my-fund").style.display = "none";
    document.getElementById("newsFeedDataOnTooltipMyFund").style.display = "block";
    setTimeout(() => {
      document.getElementById("newsFeedDataOnTooltipMyFund").innerHTML = this.alertComponentMyFund.nativeElement.innerHTML;
    }, 10)
  }

  alertData() {
    setTimeout(() => {
      document.getElementById(
        "newsFeedDataOnTooltipMyFund"
      ).innerHTML = this.alertComponentMyFund.nativeElement.innerHTML;
    }, 5)
    setTimeout(() => {
      document
        .getElementById("closeNewsMyFund")
        .addEventListener("click", this.closeNewsMyFund.bind(this));
    }, 10)
  }

}
