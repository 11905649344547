import {
  Component,
  OnInit,
  ViewChild,
  Output,
  ElementRef,
  EventEmitter,
  SimpleChanges,
  Input,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "src/services/CommonServices/common.service";
import { ActivePassiveComponent } from "../active-passive/active-passive.component";
import { consistencyUpdate } from "src/utils/common-function";
import { GoogleAnalyticsService } from "src/services/google-Analytics/google-analytics.service";
import { Router } from "@angular/router";
import { Commons, Shared } from "src/utils/common";
import { Subscription } from "rxjs";
declare const Plotly: any;
declare let ga: Function;
@Component({
  selector: 'app-active-passive-view',
  templateUrl: './active-passive-view.component.html',
  styleUrls: ['./active-passive-view.component.scss']
})
export class ActivePassiveViewComponent implements OnInit {
  @ViewChild("activePassiveReference") activePassiveReference:ActivePassiveComponent;
  @Output("closeCharts")closeCharts: EventEmitter<any> = new EventEmitter();
  @ViewChild("plotContainer") plotContainer: ElementRef;
  @ViewChild("activePassiveChartContainer") activePassiveChartContainer:ElementRef;
  @Input() selectedDataDropDown:any;
  @Input() dataNotAvailable = false;
  getActiveChartData:Subscription;
  showTooltipOnActive = true;
  testisShowLoading = true;
  selectColoum = false;
  selectedCategory:any;
  /* The plot target container. */
  graphHeighthData:any;
  graphWidthData:any;
 
  
  constructor(public http: HttpClient, public common: CommonService,
    public googleAnalyticsEventsService: GoogleAnalyticsService, public router: Router, public shared: Shared) {
    this.testisShowLoading = true;
  }

  onResizedDivActivePassiveChart(event){
    this.getActivePassiveHeightWidth();
    setTimeout(() =>{
      if(this.activePassiveReference){
        this.activePassiveReference.initPlot();
      }
    },100)
  }

  closeChart(){
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "active_passive_close_chart",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.closeCharts.emit();
  }

  reDrawChart(){
    this.getActivePassiveHeightWidth();
    setTimeout(() =>{
        if(this.activePassiveReference){
          this.activePassiveReference.initPlot();
          }
    },50)
  }

  getChartScore(event, i){
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "consistency",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
      Commons.selectedScore = [];
    this.shared.consistencyScore.map((ele)=>{ele.select = false});
    // this.topFundService.chartScore[i].select = true;
    this.shared.consistencyScore.forEach((ele, index)=>{
      if(index <= i){
        ele.select = true;
        Commons.selectedScore.push(ele.value);
      }
    });
    consistencyUpdate();
    Commons.consistencyObTopFund = 0;
    Commons.consistencyObActivePassive = 0;
    Commons.consistencyObRatingView = 0;
    Commons.consistencyObRiskReward = 0;
    setTimeout(()=>{
      Commons.consistencyScoreChange.next();
      this.reDrawChart();
    }, 20);
  }

  getActivePassiveHeightWidth(){
    if(Commons.userDashboardshow){
      this.graphHeighthData =
      this.activePassiveChartContainer.nativeElement.offsetHeight - 120;
    this.graphWidthData =
      this.activePassiveChartContainer.nativeElement.offsetWidth - 20;
    }else{
      setTimeout(()=>{
        const heightActivePassive = this.activePassiveChartContainer.nativeElement.offsetHeight;
        const widthActivePassive = this.activePassiveChartContainer.nativeElement.offsetWidth;
        this.graphHeighthData = heightActivePassive - 90;
        this.graphWidthData = widthActivePassive - 70;
      }, 5)
    }
  }

  ngOnInit() {
    this.shared.selectedCategory.subscribe((res) =>{
      if(res && res != "undefined"){
        this.selectedCategory = res;
      }
    });
    const selectCate = localStorage.getItem("chartCategory");
    if(selectCate && selectCate != "undefined") {
      this.selectedCategory = localStorage.getItem("chartCategory");
    } 
    this.selectColoum = false;
    this.getActiveChartData = this.shared.riskRewardPercenties.subscribe((res)=>{
      this.getActivePassiveHeightWidth();
      setTimeout(()=>{
        if(this.activePassiveReference){
          this.activePassiveReference.initPlot();
          }
        },1150)
    })
    this.reDrawChart();
  }

  getShareClasses(event){
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "primary_shareclass",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.isShareClassOnn = event;
    if(event == true && this.shared.topFundCategoryData){
      setTimeout(()=>{
        Commons.toggleShareClass.next();
        this.reDrawChart();
      }, 20);
      
    }else{
      setTimeout(()=>{
        Commons.toggleShareClass.next();
        this.reDrawChart();
      }, 20);
    }
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  userGuideLink(){
    window.open("https://storage.googleapis.com/fundsaiq-fundsdocuments/userguide/Fundsaiq_userguide.pdf");
  }

  ngOnDestroy() {
    if(Commons.userDashboardshow){
      this.router.config.forEach(event => {
        if (event.path == 'user-dashboard') {
          // gtag('config', 'UA-211001570-1', 
          //            {
          //              'page_path': event.path              
          //            }
          //           );
                    ga('send', {
                      hitType: 'pageview',
                      page: event.path
                    });
        }
      });
    }
    if(this.getActiveChartData){
      this.getActiveChartData.unsubscribe();
    }
  }

}

