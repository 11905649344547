import { Component,  OnInit,  ViewChild, ElementRef, Input, ChangeDetectionStrategy } from '@angular/core';

import { CommonService } from 'src/services/CommonServices/common.service';
import { MarketNews } from '../../utils/constants';
import { DateRangeComponent } from '../date-range/date-range.component';
import {DomSanitizer} from "@angular/platform-browser";
import { DatePipe } from "@angular/common";
import { Commons, Shared } from "src/utils/common";
import { MarketInsightService } from 'src/services/market-insight/market-insight.service';
import { Subscription } from 'rxjs';
import { GoogleAnalyticsService } from 'src/services/google-Analytics/google-analytics.service';

export enum SideEnum {
  left = 'left',
  right = 'right'
}

@Component({
  selector: 'app-market-insight',
  templateUrl: './market-insight.component.html',
  styleUrls: ['./market-insight.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class MarketInsightComponent implements OnInit {
  @ViewChild('dateRange') dateRange:DateRangeComponent;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @ViewChild('menu') menu: ElementRef;
  @Input() showMarketInsightFilterPopup: boolean;
  marketInsightObservable: Subscription;
  marketInsightReq: Subscription;
  consistencyChange: Subscription;
  showDropDown = false;
  scrolled: boolean;
  paging:any;
  isDataAvailable:boolean;
  
  constructor(public commonService:CommonService, public sanitized: DomSanitizer,public datePipe:DatePipe, 
    public marketInsightService:MarketInsightService, public googleAnalyticsEventsService: GoogleAnalyticsService, public shared: Shared) {
    this.shared.marketInsightFilerData = [
      { name: "Date Range Picker", select: false },
      { name: "News Feeds", select: false },
    ];
  
  }

  ngOnInit(): void {
    if(this.shared.listItems && this.shared.listItems.length >= 1){
    }else{
      this.shared.listItems = this.shared.marketInsightTopFund;
    }

    this.shared.filterCheckBoxes.forEach((ele)=>{
     if(!ele.disable){
      ele.select = true;
      this.shared.selectedFilter.push(ele.value);
     }
    })
    this.shared.defaultMarketInsight = false;  
    this.shared.marketInsightDataIsAvailable = true;
    this.shared.marketInsightLoading = false;
    this.shared.selectedNewsDate = this.shared.marketInsightFilerData[0].name;
    this.shared.marketInsightFilerData[0].select = true;
    // this.getMarketInsightData()
    this.paging = 0;
    this.shared.isLoading = true;
    if(this.shared.listItems.length < 1){
      this.shared.marketInsightDataIsAvailable = false;
      this.shared.marketInsightLoading = false;
    }
    this.marketInsightObservable = this.shared.marketInsightObservable.subscribe(()=>{
      this.openAlerts();
    })
    this.consistencyChange = Commons.consistencyScoreChange.subscribe(() => {

      this.marketInsightService.getDataForConsistency();
    });
  }

  ngOnDestroy(){
    if(this.marketInsightObservable){
      this.marketInsightObservable.unsubscribe();
    }
  }

  getMarketInsightData(){
    this.paging = 0;
    let startDate = this.datePipe.transform(localStorage.getItem('startDate'), 'yyyy-MM-dd');
    let endDate = this.datePipe.transform(localStorage.getItem('endDate'), 'yyyy-MM-dd');
    let modal = {
      "page": this.paging,
      "startDate": startDate,
      "endDate": endDate,
      "type": this.shared.selectedFilter,
      "funds": Commons.topFundSecId,
      //"funds": Commons.fundsShareClassId
      //"funds" : Commons.geographyFunds
    }
    this.marketInsightService.getDataFromApi(modal);
  }

  getNews(event){
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "market_insight_all_news",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.toggleMarket = event;
    this.paging = 0;
    if(event){
      this.marketInsightService.getDataForConsistency();
    }else{
      this.marketInsightService.getDataForConsistency();
    }
  }

  applyFilter(){
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "market_insight_filter_apply",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.marketInsightDataIsAvailable = true;
    setTimeout(() => {
      this.showDropDown = false;
    }, 10);
    this.paging = 0;
    this.shared.isLoading = true;
    this.shared.marketInsightLoading = true;
    if(this.shared.selectedFilter){
      this.paging = 0;
    let startDate = this.datePipe.transform(localStorage.getItem('startDate'), 'yyyy-MM-dd');
    let endDate = this.datePipe.transform(localStorage.getItem('endDate'), 'yyyy-MM-dd');
    let modal = {
      "page": this.paging,
      "startDate": startDate,
      "endDate": endDate,
      "type": this.shared.selectedFilter,
      "funds": Commons.topFundSecId,
    }
      this.marketInsightService.getDataFromApi(modal);
    } else{
      this.marketInsightService.getMarketInsightFilteredData(0).subscribe((data:any[])=>{
        this.shared.isLoading = false;
        this.shared.marketInsightLoading = false;
      })
    }
  }


  validateContents(content, show, container){
    if(content){
      if(!show)
      return container.offsetWidth<500 ? content.replace(/(([^\s]+\s\s*){15})(.*)/,"$1"):content.replace(/(([^\s]+\s\s*){35})(.*)/,"$1")//contentarray.splice(0,13).join(' ')
      else
      return content
    }
  }

  onScroll() {
    const element = this.myScrollContainer.nativeElement
    const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
    if (this.scrolled && atBottom) {
      this.paging++;
      this.shared.moreLoadData = true;
      this.scrolled = false;
      if(this.shared.toggleMarket){
        if(this.shared.selectedFilter){
          let startDate = this.datePipe.transform(localStorage.getItem('startDate'), 'yyyy-MM-dd');
          let endDate = this.datePipe.transform(localStorage.getItem('endDate'), 'yyyy-MM-dd');
          let modal = {
            "page": this.paging,
            "startDate": startDate,
            "endDate": endDate,
            "type": this.shared.selectedFilter,
          }
          this.marketInsightService.getMarketInsight(modal).subscribe((data:any[])=>{
            this.shared.marketInsightLoading = false;
            this.shared.moreLoadData = false;
            if(data.length > 0){
              data.forEach((ele)=>{
                let newData:any;
                ele.show = false;
                if(ele.Content){
                  newData = ele.Content.replace("<p>", "").replace("<\/p>", "").replace("↵", "");
                }
                ele.Content = newData;
                let newDataDescription = ele.Description.replace("<p>", "").replace("<\/p>", "").replace("↵", "");
                ele.Description = newDataDescription;
                this.shared.listItems.push(ele);
              })
              this.shared.marketInsightTopFund = this.shared.listItems;
            }
            else{
            }
  
          },(err)=>{
            this.shared.isLoading = false;
            this.shared.marketInsightLoading = false;
            this.shared.marketInsightDataIsAvailable = true;
            this.shared.moreLoadData = false;
          })
        }
      }else{
      if(this.shared.selectedFilter){
        const startDate = this.datePipe.transform(localStorage.getItem('startDate'), 'yyyy-MM-dd');
        const endDate = this.datePipe.transform(localStorage.getItem('endDate'), 'yyyy-MM-dd');
        const modal = {
          "page": this.paging,
          "startDate": startDate,
          "endDate": endDate,
          "type": this.shared.selectedFilter,
          "funds": Commons.topFundSecId,
        }
        this.marketInsightService.getMarketInsightFilteredData(modal).subscribe((data:any[])=>{
          this.shared.marketInsightLoading = false;
          this.shared.moreLoadData = false;
          if(data.length > 0){
            data.forEach((ele)=>{
              let newData:any;
              ele.show = false;
              if(ele.Content){
                newData = ele.Content.replace("<p>", "").replace("<\/p>", "").replace("↵", "");
              }
              ele.Content = newData;
              const newDataDescription = ele.Description.replace("<p>", "").replace("<\/p>", "").replace("↵", "");
              ele.Description = newDataDescription;
              this.shared.listItems.push(ele);
            })
            this.shared.marketInsightTopFund = this.shared.listItems;
          }
          else{
          }

        },(err)=>{
          this.shared.isLoading = false;
          this.shared.marketInsightLoading = false;
          this.shared.marketInsightDataIsAvailable = true;
          this.shared.moreLoadData = false;
        })
      }
    }
    } else {
      this.scrolled = true;
    }
  }


  onClick(event) {
    if(this.menu != undefined){
      if (!this.menu.nativeElement.contains(event.target) && this.showDropDown) {
       this.showDropDown = false;

         setTimeout(() => {
         this.shared.marketInsightFilerData.forEach((ele) => {
          ele.select = false;
         })
        this.shared.selectedNewsDate = this.shared.marketInsightFilerData[0].name;
        this.shared.marketInsightFilerData[0].select = true;
        }, 200);
      }
    }
  }

  openConfigPopup() {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "open_market_insight_filter",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    setTimeout(() => {
      this.showDropDown = !this.showDropDown;
    }, 10);
  }


  selectCheckboxNewsFeedFilter(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "market_insight_newsfeed_" + event.name,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    event.select = !event.select;
    if(event.select){
      this.shared.selectedFilter.push(event.value);
    } else{
      const index = this.shared.selectedFilter.findIndex(item => item  === event.value); 
      this.shared.selectedFilter.splice(index, 1);  
    }
    event.preventDefault();
  }

  select(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "market_insight_filter_" + event.name,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.defaultMarketInsight = false;
    this.shared.selectedNewsDate = event.name;
    this.shared.marketInsightFilerData.forEach((ele) => {
      ele.select = false;
    })
    event.select = true;
  }

  reset() {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "market_insight_clear_all_filter",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.marketInsightFilerData[0].select = true
    this.shared.marketInsightFilerData[1].select = false
    this.shared.selectedNewsDate = this.shared.marketInsightFilerData[0].name;
    this.shared.filterCheckBoxes.forEach(element => {
        element.select = false;
    });
    this.dateRange.reset();
  }

  cancel() {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "market_insight_filter_cancel",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.showDropDown = false;
    setTimeout(() => {
      this.shared.marketInsightFilerData.forEach((ele) => {
        ele.select = false;
      })
      this.shared.selectedNewsDate = this.shared.marketInsightFilerData[0].name;
      this.shared.marketInsightFilerData[0].select = true;
    }, 200);
  }

  selectButton(event, item, i) {
    if (event == "thumbs-up") {
      let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "market_insight_thumbs_up",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
      item.selectLike = !item.selectLike;
      item.selectDislike = false;
      item.selectClose = false;
    }
    if (event == "thumbs-down") {
      let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "market_insight_thumbs_down",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
      item.selectLike = false;
      item.selectDislike = !item.selectDislike;
      item.selectClose = false;
    }
    if (event == "close") {
      let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "market_insight_close",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
      item.selectLike = false;
      item.selectDislike = false;
      item.selectClose = !item.selectClose;
      this.shared.listItems.forEach(ele => {
        if(item.Headline == ele.Headline && item.selectClose == true){
          this.shared.listItems.splice(i, 1);
          item.selectClose = false;
        }
      });
    }
  }

  openAlerts(){
    let createdTime = new Date();
    let model = {
      "createdAt": createdTime,
      "eventName": "market_insight_expand_view",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(model);
    this.shared.newsFeedLoading = true;
    this.shared.newsFeedDataAvailable = true;
    this.shared.alertsTitle = MarketNews.title;
    this.shared.placeHolder = MarketNews.placeHolder;
    this.shared.filterTitle = MarketNews.filterTitle;
    this.shared.chartFilterNews = this.shared.filterCheckBoxes;
    this.shared.newsTableHeading = MarketNews.newsTableHeadings;
    Commons.newdFeedsData = this.shared.listItems;
    this.shared.paging = 0;
    this.marketInsightService.paging = 0;
    const startDate = this.datePipe.transform(localStorage.getItem('startDate'), 'yyyy-MM-dd');
    const endDate = this.datePipe.transform(localStorage.getItem('endDate'), 'yyyy-MM-dd');
    const modal = {
      "page": this.shared.paging,
      "type": this.shared.selectedFilter,
      "funds":[]
    }
    if(this.marketInsightReq){
      this.marketInsightReq.unsubscribe();
    }
    this.marketInsightReq = this.marketInsightService.getMarketInsight(modal).subscribe((data:any[])=>{
      this.shared.newsFeedLoading = false;
      this.shared.marketInsightLoading = false;
      this.shared.marketInsightDataIsAvailable = true;
      this.getMarketInsightData();
      if(data.length > 0){
        this.shared.newsFeedDataAvailable = true;
        data.forEach((ele)=>{
          ele.show = false;
          let newData:any;
          if(ele.Content){
          newData = ele.Content.replace("<p>", "").replace("<\/p>", "").replace("↵", "");
          }
          ele.Content = newData;
          const newDataDescription = ele.Description.replace("<p>", "").replace("<\/p>", "").replace("↵", "");
          ele.Description = newDataDescription;
        })
                 
        this.shared.marketInsightData = data;
        this.shared.newsFeedsData = data;
      }
      else{
        this.shared.newsFeedDataAvailable = false;
      }
    }, (err)=>{
      this.shared.newsFeedLoading = false;
      this.shared.newsFeedDataAvailable = false;
    })
  }

}
