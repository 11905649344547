import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Cacheable } from 'ngx-cacheable';
import { Subject, Subscription } from "rxjs";

import { apiError, authorizeHeader } from 'src/utils/common-function';
import { Commons, Shared } from 'src/utils/common';
import { CommonService } from '../CommonServices/common.service';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class PrefrenceSettingsService {
  checkRequestedGeography: Subscription;
  
  constructor(public http: HttpClient, public common: CommonService, public shared: Shared) { }

  @Cacheable()
  savePrefrenceSetting(userId,settingModel ){
  return  this.http
      .post(
        environment.baseUrl + "savePreferences/" + userId,
        settingModel,
        httpOptions
      )
  }

  @Cacheable()
  getPrefrenceSetting(userId){
    return  this.http
    .get(
      environment.baseUrl + "getPreferences/" + userId,
    )
  }


  saveFunds(userId, model){
   return this.http
    .post(
      environment.baseUrl + "saveFunds/" + userId,
      model,
      {headers : authorizeHeader()}
    )
  }

  getOrganisation(userId){
    return this.http.get(environment.baseUrl + 'getorganisation/' + userId,)
  }

  updateOrganisation(modal){
   return this.http.post(environment.baseUrl + 'updateorganisation/' , modal,  {headers:authorizeHeader()});
 }

  saveSetting(modal){
    return this.http.post(environment.baseUrl + 'saveSettings' , modal, {headers:authorizeHeader()});
  }

  getAllSetting(userId){
    return this.http.get(environment.baseUrl + 'getAllSettings/' + userId);
  }

  getAllSavedSettings(userId){
    this.http.get(environment.baseUrl + 'getAllSettings/' + userId).subscribe((res)=>{
      this.shared.getAllSettingsData = res['data'];
      this.shared.allSettingObservable.next(res);
    }, (err)=>{
      apiError(err);
    });
  }

  getSettingDataById(userId, settingId){
    this.http.get(environment.baseUrl + 'getSettingById/' + userId + '/' + settingId).subscribe((res)=>{
      this.shared.getSettingByIdObservable.next(res);
    }, (err)=>{
      apiError(err);
    })
  }

  getSettingById(userId, settingId){
    return this.http.get(environment.baseUrl + 'getSettingById/' + userId + '/' + settingId);
  }

  deleteSetting(userId, settingId){
    return this.http.post(environment.baseUrl + 'deleteSetting/' + userId + '/' + settingId,  {headers:authorizeHeader()});
  }

  @Cacheable()
  getPreferencesData(userId, date){
    return this.http.get(environment.baseUrl + 'getpreferencesdata/' + userId + '?date=' + date);
  }

  getMarketUniverse(date){
    return this.http.get(environment.baseUrl + 'getMarketUniverse/' + date);
  }

}
