import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
enum EmailStatus {
  Verifying,
  Failed
}
@Component({
  selector: 'app-verifymail',
  templateUrl: './verifymail.component.html',
  styleUrls: ['./verifymail.component.scss']
})
export class VerifymailComponent implements OnInit {

  EmailStatus = EmailStatus;
    emailStatus = EmailStatus.Verifying;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
    
    ) { }

    ngOnInit() {
        const token = this.route.snapshot.queryParams['token'];
        this.router.navigate([], { relativeTo: this.route, replaceUrl: true });
    }

}
