import { Component, OnInit, EventEmitter, Output, Input, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { CommonService } from 'src/services/CommonServices/common.service';
import { AlertsAndNews } from "../../utils/constants";
import { Commons, Shared } from '../../utils/common';
import { NewsFundsService } from 'src/services/news-for-funds/news-funds.service';
import { Subscription } from 'rxjs';
import { DatePipe } from "@angular/common";
import { FundDocumentaionService } from 'src/services/fund-documentation/fund-documentaion.service';
import { FundAlertsService } from 'src/services/fund-Alerts/fund-alerts.service';
import { GoogleAnalyticsService } from 'src/services/google-Analytics/google-analytics.service';
//import { debounce } from 'ts-debounce';
const anychart = require("anychart");

@Component({
  selector: 'app-risk-reward-chart',
  templateUrl: './risk-reward-chart.component.html',
  styleUrls: ['./risk-reward-chart.component.scss']
})
export class RiskRewardChartComponent implements OnInit, OnDestroy {
  @Output("closeCharts") closeCharts: EventEmitter<any> = new EventEmitter();
  @Output("openSummary") openSummary: EventEmitter<any> = new EventEmitter();
  @ViewChild("newsComponent") newsComponent: ElementRef;
  @ViewChild("alertComponent") alertComponent: ElementRef;
  @ViewChild('tooltipPopup') tooltipPopup: ElementRef;
  @Input() isShowLoadingYearRiskRew: boolean;
  // @Input() TopFundGlobalEqualityAlertsX: any[] = [];
  @ViewChild("riskreward") riskchartcontainer: ElementRef;
  @Input() showGraphAdd: any;
  selectColoum = false;
  @Input() showMarketInsightData: boolean;
  @Output("openRiskAlert") openRiskAlert: EventEmitter<any> = new EventEmitter();
  showGraphdata = false;
  showPopupOfSummary = false;
  @Output("openAlertMarket") openAlertMarket: EventEmitter<any> = new EventEmitter();
  @Input() set onFilterChange(val) {
    if (this.riskchartcontainer) {
      this.riskchartcontainer.nativeElement.innerHTML = null;
    }
  }
  @Input() showLegend: boolean;
  fundName: any;
  topfunds: any = [];
  bestPassive: any[] = [];
  isNew: boolean;
  middleXValue: any;
  middleYValues: any;
  newsFeedData: any[] = [];
  dataNot: boolean;
  chartData: any;
  category = "Global_Equity_Large_Cap";
  selectedCatYear = "2021";
  oneYearRiskRewNew: any[] = [];
  threeYearRiskRewNew: any[] = [];
  fiveYearRiskRewNew: any[] = [];
  chartHoverPointData: any;
  colorToPushNew: any;
  @Input() chartHeight: any;
  @Input() chartWidth: any;
  userDashboard: boolean;
  riskRewardData: any;
  selectedCategory: any;
  detailRecivedSubscription: Subscription;
  consistencyScoreObs: Subscription;
  toggleShareClass: Subscription;
  bestPassiveObs: Subscription;
  @ViewChild('riskRewardChart') riskRewardChart: ElementRef;
  chartDraw: any;
  constructor(public common: CommonService, public newsFund: NewsFundsService, public datePipe: DatePipe,
    public fundService: FundDocumentaionService, public fundAlerts: FundAlertsService,
    public googleAnalyticsEventsService: GoogleAnalyticsService,
    public shared: Shared) { }

  ngOnInit() {
    this.userDashboard = Commons.userDashboardshow;
    this.shared.selectedCategory.subscribe((res) => {
      if (res && res != "undefined") {
        this.selectedCategory = res;
      }
    });
    const selectCate = localStorage.getItem("chartCategory");
    if (selectCate && selectCate != "undefined") {
      this.selectedCategory = localStorage.getItem("chartCategory");
    }
    Commons.riskChartLoader = true;
    this.selectColoum = false;
    this.chartDraw = null;
    if (Commons.drawRiskChart == "quadrant") {
      this.getRiskRewardData();
      this.collectData();
    }

    if (this.shared.topFundCategoryData && this.shared.msRiskRewardData) {
      this.getRiskRewardData();
      this.collectData();
    }
    this.shared.riskRewardPercenties.subscribe((res) => {
      if (this.shared.riskChartGetData == 0) {
        // this.topfunds = [];
        this.getRiskRewardData();
        this.collectData();
      }
      this.shared.riskChartGetData++;
    })
    this.bestPassiveObs = Commons.changeBestPassives.subscribe((res) => {
      if (Commons.bestPassiveApplied) {
        this.topfunds = [];
        this.getRiskRewardData();
        this.collectData();
      }
      res++;
    });
    this.detailRecivedSubscription = this.shared.commonTopFundPrecentileOb.subscribe(async (str) => {
      this.topfunds = [];
      this.getRiskRewardData();
      this.collectData();
      //this.updateTopFundsData();
    });
    this.toggleShareClass = Commons.toggleShareClass.subscribe(async (res) => {
      if (Commons.selectedTab == "msRiskReward" || Commons.addViewEnable || this.shared.isShareClassOnn) {
        await this.getTopFundConsistency();
        await this.updateTopFundConsistency();
      }
    });
    this.consistencyScoreObs = Commons.consistencyScoreChange.subscribe(async (res) => {
      if (Commons.consistencyObRiskReward == 0) {
        if (Commons.selectedTab == "msRiskReward" || Commons.addViewEnable) {
          // alert('test risk reward');
          await this.getTopFundConsistency();
          await this.updateTopFundConsistency();
        }
        // await  this.updateTopFundsData();
      }
      Commons.consistencyObRiskReward++;
    });
  }

  ngOnDestroy() {
    if (this.detailRecivedSubscription) {
      this.detailRecivedSubscription.unsubscribe();
    }
    if (this.consistencyScoreObs) {
      this.consistencyScoreObs.unsubscribe();
    }
    if (this.toggleShareClass) {
      this.toggleShareClass.unsubscribe();
    }
    if (this.bestPassiveObs) {
      this.bestPassiveObs.unsubscribe();
    }
  }

  closeChart() {
    this.closeCharts.emit();
  }

  redrawChart() {
    if (this.riskchartcontainer) {
      this.riskchartcontainer.nativeElement.innerHTML = null;
    }
    this.getRiskRewardData();
    this.drawRiskChart();
    // this.getRiskRewardData(this.common.topFundCategoryData);
    // this.collectData();
  }

  riskChartChange() {
    if (this.riskchartcontainer) {
      this.riskchartcontainer.nativeElement.innerHTML = null;
    }
    this.getRiskRewardData();
    this.drawRiskChart();
  }

  getRiskRewardData() {
    this.fiveYearRiskRewNew = [];
    this.oneYearRiskRewNew = [];
    this.threeYearRiskRewNew = [];
    if (this.riskchartcontainer) {
      this.riskchartcontainer.nativeElement.innerHTML = null;
    }
    let fundSize: any;
    let fundActive: boolean;
    if (localStorage.getItem("fundSizes")) {
      fundSize = JSON.parse(localStorage.getItem("fundSizes"));
      if (fundSize) {
        const fundSizeDisplay = localStorage.getItem('fundSizesDisplay');
        if (fundSizeDisplay == "On") {
          fundActive = true;
        }
      }
    }
    this.colorToPushNew = 0;
    if (this.shared.topFundCategoryData) {
      // this.common.topFundCategoryData.topData.forEach((data) => {
      //   // if(data["Fund_Size_USD"] == "NULL" || data["Fund_Size_USD"] == null){
      //   //   data["Fund_Size_USD"] = "0";
      //   // }
      //   if (data["monthendtna_value"] == "NULL") {
      //     data["monthendtna_value"] = "0";
      //   }
      //   this.addTopFundsData(data);
      // });

      let i = 0, len = this.shared.topFundCategoryData.topData.length;
      while (i < len) {
        if (this.shared.topFundCategoryData.topData[i]["monthendtna_value"] == "NULL") {
          this.shared.topFundCategoryData.topData[i]["monthendtna_value"] = "0";
        }
        this.addTopFundsData(this.shared.topFundCategoryData.topData[i]);
        i++;
      }
      this.shared.topFundCategoryData.bestPassive
      if (!Commons.userDashboardshow) {
        this.addBestPassive();
      }

      // this.topfunds = this.fiveYearRiskRewNew;
    }
    // if(this.common.topFundCategoryData.bestPassive.length > 0){
    //   let bestPassive = this.common.topFundCategoryData.bestPassive;
    //   if(bestPassive[0].Name == bestPassive[1].Name){

    //    }else{

    //    }
    // }
  }


  getPassiveValueX(stdReturn1y, stdReturn3y, stdReturn5y,) {
    // let stdReturnValue = stdReturn1y ? stdReturn1y : 0 + stdReturn3y ? stdReturn3y : 0 + stdReturn5y ?  stdReturn5y : 0
    const return1Yr = stdReturn1y ? 20 * stdReturn1y : 0;
    const return3Yr = stdReturn3y ? 50 * stdReturn3y : 0;
    const return5Yr = stdReturn5y ? 30 * stdReturn5y : 0;
    const value = return1Yr + return3Yr + return5Yr;
    return value;
  }

  getPassiveValueY(totalReturn1Y, totalReturn3Y, totalReturn5Y) {
    const return1Yr = totalReturn1Y ? 20 * totalReturn1Y : 0;
    const return3Yr = totalReturn3Y ? 50 * totalReturn3Y : 0;
    const return5Yr = totalReturn5Y ? 30 * totalReturn5Y : 0;
    const value = return1Yr + return3Yr + return5Yr;
    return value;
  }

  addBestPassive() {
    this.bestPassive = [];
    let fiveYearObject: any;
    if (localStorage.getItem('bestPassive')) {
      this.bestPassive = JSON.parse(localStorage.getItem('bestPassive'));
      if (Commons.bestPassiveTopFunds != undefined) {
        this.bestPassive.forEach(element => {
          if (element.id == "1 YEAR-ILIM Indexed Name" && element.show) {
            Commons.bestPassiveTopFunds.forEach(ele => {
              if (ele.Type == "01") {
                // let totalReturn = ele[""]
                fiveYearObject = {
                  name: ele["Name"],
                  x: this.getPassiveValueX(ele["Std_Dev_1_Yr__Mo_End__USD"], ele["Std_Dev_3_Yr__Mo_End__USD"], ele["Std_Dev_5_Yr__Mo_End__USD"]),
                  value: this.getPassiveValueY(ele["Total_Ret_1_Yr__Mo_End__USD"], ele["Total_Ret_Annlzd_3_Yr__Mo_End__USD"], ele["Total_Ret_Annlzd_5_Yr__Mo_End__USD"]),
                  date: this.datePipe.transform(ele["Year"], 'dd-MM-yyyy'),
                  fundId: ele["id"],
                  isBestPassive: true,
                  normal: {
                    //       fill: this.allcolor[j.charAt(j.length-1)],
                    markerSize: 7,
                    type: "circle",
                    stroke: "3 #FFFFFF",
                  },
                  hovered: {
                    markerSize: 7,
                    type: "circle",
                    cursor: "pointer",
                    stroke: "3 #FFFFFF",
                  },
                  selected: {
                    fill: '#AB036E',
                    stroke: "3 #FFFFFF",
                    markerSize: 8,
                    type: "circle"
                  },
                  show: false,
                  color: '#AB036E',
                };
                this.fiveYearRiskRewNew.push(fiveYearObject);
              }
            })
          } else if (element.id == "3 YEAR-VANGAURD Name" && element.show) {
            Commons.bestPassiveTopFunds.forEach(ele => {
              if (ele.Type == "03") {
                fiveYearObject = {
                  name: ele["Name"],
                  x: this.getPassiveValueX(ele["Std_Dev_1_Yr__Mo_End__USD"], ele["Std_Dev_3_Yr__Mo_End__USD"], ele["Std_Dev_5_Yr__Mo_End__USD"]),
                  value: this.getPassiveValueY(ele["Total_Ret_1_Yr__Mo_End__USD"], ele["Total_Ret_Annlzd_3_Yr__Mo_End__USD"], ele["Total_Ret_Annlzd_5_Yr__Mo_End__USD"]),
                  date: this.datePipe.transform(ele["Year"], 'dd-MM-yyyy'),
                  isBestPassive: true,
                  fundId: ele["id"],
                  normal: {
                    //       fill: this.allcolor[j.charAt(j.length-1)],
                    markerSize: 7,
                    type: "circle",
                    stroke: "3 #FFFFFF",
                  },
                  hovered: {
                    markerSize: 7,
                    stroke: "3 #FFFFFF",
                    type: "circle",
                    cursor: "pointer",
                  },
                  selected: {
                    fill: "#C71D39",
                    stroke: "3 #FFFFFF",
                    markerSize: 8,
                    type: "circle"
                  },
                  show: false,
                  color: "#C71D39",
                };
                this.fiveYearRiskRewNew.push(fiveYearObject);
              }
            })
          } else if (element.id == "5 YEAR-ILIM Indexed Name" && element.show) {
            Commons.bestPassiveTopFunds.forEach(ele => {
              if (ele.Type == "05") {
                fiveYearObject = {
                  name: ele["Name"],
                  x: this.getPassiveValueX(ele["Std_Dev_1_Yr__Mo_End__USD"], ele["Std_Dev_3_Yr__Mo_End__USD"], ele["Std_Dev_5_Yr__Mo_End__USD"]),
                  value: this.getPassiveValueY(ele["Total_Ret_1_Yr__Mo_End__USD"], ele["Total_Ret_Annlzd_3_Yr__Mo_End__USD"], ele["Total_Ret_Annlzd_5_Yr__Mo_End__USD"]),
                  date: this.datePipe.transform(ele["Year"], 'dd-MM-yyyy'),
                  isBestPassive: true,
                  fundId: ele["id"],
                  normal: {
                    //       fill: this.allcolor[j.charAt(j.length-1)],
                    markerSize: 7,
                    type: "circle",
                    stroke: "3 #FFFFFF",
                  },
                  hovered: {
                    markerSize: 7,
                    type: "circle",
                    cursor: "pointer",
                    stroke: "3 #FFFFFF",
                  },
                  selected: {
                    fill: '#FF5734',
                    stroke: "3 #FFFFFF",
                    markerSize: 8,
                    type: "circle"
                  },
                  show: false,
                  color: '#FF5734',
                };
                this.fiveYearRiskRewNew.push(fiveYearObject);
              }
            })
          }
        })
      }
    }
    this.topfunds = [];
    this.colorToPushNew = 0;

    if (this.shared.isShareClassOnn) {
      let i = 0, len = this.fiveYearRiskRewNew.length;
      while (i < len) {
        if (this.fiveYearRiskRewNew[i].isBestPassive == true) {
          // console.count('Calling loop');
          let j = this.colorToPushNew.toString();
          if (this.fiveYearRiskRewNew[i].isBestPassive == true || Commons.selectedScore.includes(this.fiveYearRiskRewNew[i].consistency)) {
            this.fiveYearRiskRewNew[i].color = Commons.allcolor[j.charAt(j.length - 1)],
              this.topfunds.push(this.fiveYearRiskRewNew[i]);
            this.colorToPushNew++;
            if (this.colorToPushNew == Commons.allcolor.length) {
              this.colorToPushNew = 0;
            }
          }
        }
        i++;
      }
      this.topfunds.sort(this.compare);
    } else {
      let i = 0, len = this.fiveYearRiskRewNew.length;
      while (i < len) {
        // console.count('Calling loop');
        let j = this.colorToPushNew.toString();
        if (this.fiveYearRiskRewNew[i].isBestPassive == true || Commons.selectedScore.includes(this.fiveYearRiskRewNew[i].consistency)) {
          this.fiveYearRiskRewNew[i].color = Commons.allcolor[j.charAt(j.length - 1)],
            this.topfunds.push(this.fiveYearRiskRewNew[i]);
          this.colorToPushNew++;
          if (this.colorToPushNew == Commons.allcolor.length) {
            this.colorToPushNew = 0;
          }
        }
        i++;
      }
    }
  }


  addTopFundsData(data) {
    const j = this.colorToPushNew.toString();
    const oneYearObject: any = [
      {
        name: data["name_value"],
        x: data["weighted_stdev"] * 100,
        value: data["weighted_total_ret"] * 100,
        fundId: data["id"],
        fundSize: data["monthendtna_value"] ? data["monthendtna_value"]?.toFixed(2) : '',
        date: this.datePipe.transform(data["Year"], 'dd-MM-yyyy'),
        isBestPassive: false,
        isShareClass: data["IsPrimaryShareClass"],
        shareClassId: data["ShareClass_Id"],
        ISIN: data["ISIN"],
        normal: {
          //          fill: this.allcolor[j.charAt(j.length-1)],
          markerSize: 6,
          type: "circle",
        },
        color: Commons.allcolor[j.charAt(j.length - 1)],
      },
    ];
    this.oneYearRiskRewNew.push(oneYearObject);
    const threeYearObject: any = [
      {
        name: data["name_value"],
        x: data["weighted_stdev"] * 100,
        value: data["weighted_total_ret"] * 100,
        shareClassId: data["ShareClass_Id"],
        fundId: data["id"],
        fundSize: data["monthendtna_value"] ? data["monthendtna_value"]?.toFixed(2) : '',
        isBestPassive: false,
        isShareClass: data["IsPrimaryShareClass"],
        ISIN: data["ISIN"],
        date: this.datePipe.transform(data["Year"], 'dd-MM-yyyy'),
        normal: {
          //         fill: this.allcolor[j.charAt(j.length-1)],
          markerSize: 6,
          type: "circle",
        },
        color: Commons.allcolor[j.charAt(j.length - 1)],
      },
    ];
    this.threeYearRiskRewNew.push(threeYearObject);
    let fiveYearObject: any;
    // if (this.common.selectedScore.includes(data["consistency"])){
    if (Commons.userDashboardshow) {
      fiveYearObject = {
        name: data["name_value"],
        x: data["weighted_stdev"] * 100,
        value: data["weighted_total_ret"] * 100,
        date: this.datePipe.transform(data["Year"], 'dd-MM-yyyy'),
        fundId: data["id"],
        fundSize: data["monthendtna_value"] ? data["monthendtna_value"]?.toFixed(2) : '',
        consistency: data["consistency"],
        isBestPassive: false,
        isShareClass: data["IsPrimaryShareClass"],
        ISIN: data["ISIN"],
        shareClassId: data["ShareClass_Id"],
        normal: {
          //       fill: this.allcolor[j.charAt(j.length-1)],
          markerSize: 6,
          type: "circle",
        },
        hovered: {
          markerSize: 6,
          type: "circle",
          cursor: "pointer",
        },
        selected: {
          fill: Commons.allcolor[j.charAt(j.length - 1)],
          // stroke: "3 white",
          markerSize: 8,
          type: "circle"
        },
        show: false,
        color: Commons.allcolor[j.charAt(j.length - 1)],
      };
    } else {
      fiveYearObject = {
        name: data["name_value"],
        x: data["weighted_stdev"] * 100,
        value: data["weighted_total_ret"] * 100,
        date: this.datePipe.transform(data["Year"], 'dd-MM-yyyy'),
        fundId: data["id"],
        fundSize: data["monthendtna_value"] ? data["monthendtna_value"]?.toFixed(2) : '',
        shareClassId: data["ShareClass_Id"],
        consistency: data["consistency"],
        isBestPassive: false,
        isShareClass: data["IsPrimaryShareClass"],
        ISIN: data["ISIN"],
        normal: {
          //       fill: this.allcolor[j.charAt(j.length-1)],
          markerSize: 6,
          type: "circle",
        },
        hovered: {
          markerSize: 6,
          type: "circle",
          cursor: "pointer",
        },
        selected: {
          fill: Commons.allcolor[j.charAt(j.length - 1)],
          stroke: "3 white",
          markerSize: 7,
          type: "circle"
        },
        show: false,
        color: Commons.allcolor[j.charAt(j.length - 1)],
      };
    }
    this.fiveYearRiskRewNew.push(fiveYearObject);
    this.colorToPushNew++;
    if (this.colorToPushNew == Commons.allcolor.length) {
      this.colorToPushNew = 0;
    }
    // }
    this.getTopFundConsistency();
  }
  // getTopFundConsistency = debounce(this.consistency.bind(this), 100, {isImmediate:false});

  async getTopFundConsistency() {
    this.topfunds = [];
    this.colorToPushNew = 0;
    //   this.fiveYearRiskRewNew.forEach((ele)=>{
    //   // console.count('Calling loop');
    //   let j = this.colorToPushNew.toString();
    //   if (this.common.selectedScore.includes(ele.consistency)){
    //     ele.color = this.allcolor[j.charAt(j.length - 1)],
    //     this.topfunds.push(ele);
    //     this.colorToPushNew++;
    //     if(this.colorToPushNew == this.allcolor.length){
    //       this.colorToPushNew = 0;
    //     }
    //   }
    // });
    let i = 0, len = this.fiveYearRiskRewNew.length;
    while (i < len) {
      // console.count('Calling loop');
      let j = this.colorToPushNew.toString();
      if (this.fiveYearRiskRewNew[i].isBestPassive == true || Commons.selectedScore.includes(this.fiveYearRiskRewNew[i].consistency)) {
        this.fiveYearRiskRewNew[i].color = Commons.allcolor[j.charAt(j.length - 1)],
          this.topfunds.push(this.fiveYearRiskRewNew[i]);
        this.colorToPushNew++;
        if (this.colorToPushNew == Commons.allcolor.length) {
          this.colorToPushNew = 0;
        }
      }
      i++;
    }
    if (this.topfunds.length > 0 && this.shared.isShareClassOnn) {
      this.topfunds.sort(this.compare);
    }
  }

  compare(item1, item2) {
    if (item1.ISIN == item2.ISIN) {
      if (item1.isShareClass == "TRUE") {
        return item1;
      } else if (item2.isShareClass == "TRUE") {
        return item2;
      } else if (item1.isShareClass == "FALSE" && item2.isShareClass == "FALSE") {
        if (item1["compounded_return_05"] > item2["compounded_return_05"]) {
          return item1;
        } else if (item1["compounded_return_05"] < item2["compounded_return_05"]) {
          return item2;
        } else {
        }
      }
    } else {
      return;
    }
  }

  //updateTopFundConsistency = debounce(this.updateTopFundConsistencyInRisk.bind(this), 100, { isImmediate: false });
  async updateTopFundConsistency() {
    if (Commons.userDashboardshow) {
      this.userDashboard = true;
    } else {
      this.userDashboard = false;
    }
    this.chart.removeAllSeries();
    this.chart.tooltip(false);
    const dataSet = anychart.data.set(this.shared.fiveYearRiskRewNew)
    const precentilesSeries = this.chart.marker(dataSet);
    precentilesSeries.tooltip(!Commons.userDashboardshow);
    precentilesSeries.legendItem(false);
    const tooltip = this.chart.getSeries(0).tooltip();
    tooltip.separator(false);
    tooltip.titleFormat("{%name}");
    tooltip.format("");
    let i = 0, len = this.topfunds.length;
    while (i < len) {
      const data = anychart.data.set([]);
      data.append(this.topfunds[i]);
      const series = data.mapAs({ x: "x", value: "value", color: "color", id: "id" });
      this.chart.addSeries(series);

      this.chart
        .getSeriesAt(this.chart.getSeriesCount() - 1)
        .name(this.topfunds[i].name)
        .color(this.topfunds[i].color ? this.topfunds[i].color : "#fff")
        .id(this.topfunds[i].fundId)
      i++;
    }
    const controller = this.chart.annotations();
    controller.removeAllAnnotations();
    const xMiddle = this.getMiddleForX(this.shared.weightTotalReturn, this.shared.weightSTD);
    const yMiddle = this.getMiddleForY(this.shared.weightTotalReturn, this.shared.weightSTD);
    const xline = controller.horizontalLine();
    const yline = controller.verticalLine();

    this.middleXValue = xMiddle * 3;
    this.middleYValues = yMiddle * 3;
    this.topfunds.forEach((ele, index, object) => {
      if (ele.x > this.middleXValue) {
        object.splice(index, 1);
      }
      else if (ele.value > this.middleYValues) {
        object.splice(index, 1);
      }
    });

    xline.valueAnchor(yMiddle);
    yline.xAnchor(xMiddle);

    yline.allowEdit(false);
    xline.allowEdit(false);

    yline.normal().stroke("#48EEE0", 0.5, "0 0");
    xline.normal().stroke("#48EEE0", 0.5, "0 0");
    yline.hovered().stroke("#48EEE0", 0.5, "0 0");
    xline.hovered().stroke("#48EEE0", 0.5, "0 0");
    yline.selected().stroke("#48EEE0", 0.5, "0 0");
    xline.selected().stroke("#48EEE0", 0.5, "0 0");
  }

  async updateTopFundsData() {
    if (Commons.userDashboardshow) {
      this.userDashboard = true;
    } else {
      this.userDashboard = false;
    }
    this.chart.removeAllSeries();
    this.chart.tooltip(false);
    const dataSet = anychart.data.set(this.shared.fiveYearRiskRewNew)
    const precentilesSeries = this.chart.marker(dataSet);
    precentilesSeries.tooltip(!Commons.userDashboardshow);
    precentilesSeries.legendItem(false);
    const tooltip = this.chart.getSeries(0).tooltip();
    tooltip.separator(false);
    tooltip.titleFormat("{%name}");
    tooltip.format("");
    if (this.topfunds.length > 0) {
      let i = 0, len = this.topfunds.length;
      while (i < len) {
        const data = anychart.data.set([]);
        data.append(this.topfunds[i]);
        const series = data.mapAs({ x: "x", value: "value", color: "color", id: "id" });
        this.chart.addSeries(series);

        this.chart
          .getSeriesAt(this.chart.getSeriesCount() - 1)
          .name(this.topfunds[i].name)
          .color(this.topfunds[i].color ? this.topfunds[i].color : "#fff")
          .id(this.topfunds[i].fundId)
        i++;
      }
      // await this.topfunds.forEach((element) => {
      //   var data = anychart.data.set([]);
      //   data.append(element);
      //   var series = data.mapAs({ x: "x", value: "value", color: "color", id: "id" });
      //   this.chart.addSeries(series);

      //   this.chart
      //     .getSeriesAt(this.chart.getSeriesCount() - 1)
      //     .name(element.name)
      //     .color(element.color ? element.color : "#fff")
      //     .id(element.fundId)
      // });
    }


    let minValueY = 0;
    let maxValueY = 0;
    let minValueX = 0;
    let maxValueX = 0;

    this.shared.weightSTD = this.shared.weightSTD.filter(function (el) { return el != null; });
    this.shared.weightTotalReturn = this.shared.weightTotalReturn.filter(function (el) { return el != null; });

    if (this.shared.fiveYearRiskRewNew.length > 0) {
      minValueY = Math.min.apply(null, this.shared.weightTotalReturn)
      maxValueY = Math.max.apply(null, this.shared.weightTotalReturn)
      minValueX = Math.min.apply(null, this.shared.weightSTD)
      maxValueX = Math.max.apply(null, this.shared.weightSTD)
    }

    this.chart
      .yScale()
      .minimum(
        this.common.setRiskChartYAxisMinMedian(
          this.category,
          minValueY < 0 ? minValueY + 0.0100 : minValueY - 0.000100,
          this.selectedCatYear
        )
      );
    this.chart
      .yScale()
      .maximum(
        this.common.setRiskChartYAxisMaxMedian(
          this.category,
          maxValueY + 0.0100,
          this.selectedCatYear
        )
      );
    this.chart
      .xScale()
      .minimum(
        this.common.setRiskChartXAxisMinMedian(
          this.category,
          minValueX < 0 ? minValueX + 0.0100 : minValueX - 0.000100,
          this.selectedCatYear
        )
      );
    this.chart
      .xScale()
      .maximum(
        this.common.setRiskChartXAxisMaxMedian(
          this.category,
          maxValueX + 0.0100,
          this.selectedCatYear
        )
      );

    if (!Commons.userDashboardshow) {
      this.chart.xAxis(0, { ticks: true, labels: true });
      this.chart.yAxis(0, { ticks: true, labels: true });
    }

    const controller = this.chart.annotations();
    controller.removeAllAnnotations();
    this.drawChartMiddleXYLines(controller, this.shared.weightTotalReturn, this.shared.weightSTD);
    // set interval Y axes
    // this.chart.yScale().ticks().interval(0.01);
    // set interval X axes
    // this.chart.xScale().ticks().interval(0.01);

    this.chart.dr;

    await this.generateCustomTooltips(this.chart);
  }

  async updateTopFunds() {
    var bestPassiveData: any = [];
    // const half = Math. ceil(this.common.fiveYearRiskRewNew.length / 2);
    // this.common.fiveYearRiskRewNew = this.common.fiveYearRiskRewNew.splice(0, half)

    if (Commons.userDashboardshow) {
      this.userDashboard = true;
    } else {
      this.userDashboard = false;
    }
    this.chart.tooltip(false);
    const dataSet = anychart.data.set(this.shared.fiveYearRiskRewNew)
    const precentilesSeries = this.chart.marker(dataSet);
    precentilesSeries.tooltip(!Commons.userDashboardshow);
    precentilesSeries.legendItem(false);
    const tooltip = this.chart.getSeries(0).tooltip();
    tooltip.separator(false);
    tooltip.titleFormat("{%name}");
    tooltip.format("");
    let minValueY = 0;
    let maxValueY = 0;
    let minValueX = 0;
    let maxValueX = 0;

    this.shared.weightSTD = this.shared.weightSTD.filter(function (el) { return el != null; });
    this.shared.weightTotalReturn = this.shared.weightTotalReturn.filter(function (el) { return el != null; });

    if (this.shared.fiveYearRiskRewNew.length > 0) {
      minValueY = Math.min.apply(null, this.shared.weightTotalReturn)
      maxValueY = Math.max.apply(null, this.shared.weightTotalReturn)
      minValueX = Math.min.apply(null, this.shared.weightSTD)
      maxValueX = Math.max.apply(null, this.shared.weightSTD)
    }


    this.chart
      .yScale()
      .minimum(
        this.common.setRiskChartYAxisMinMedian(
          this.category,
          minValueY < 0 ? minValueY + 0.0100 : minValueY - 0.000100,
          //          minValueY<0 ? minValueY+0.0100:minValueY-0.0100,
          //      minValueY<topMinValueY ? minValueY:topMinValueY,
          this.selectedCatYear
        )
      );
    this.chart
      .yScale()
      .maximum(
        this.common.setRiskChartYAxisMaxMedian(
          this.category,
          maxValueY + 0.0100,
          this.selectedCatYear
        )
      );
    this.chart
      .xScale()
      .minimum(
        this.common.setRiskChartXAxisMinMedian(
          this.category,
          minValueX < 0 ? minValueX + 0.0100 : minValueX - 0.000100,
          this.selectedCatYear
        )
      );
    this.chart
      .xScale()
      .maximum(
        this.common.setRiskChartXAxisMaxMedian(
          this.category,
          maxValueX + 0.0100,
          this.selectedCatYear
        )
      );

    if (!Commons.userDashboardshow) {
      this.chart.xAxis(0, { ticks: true, labels: true });
      this.chart.yAxis(0, { ticks: true, labels: true });
    }

    const controller = this.chart.annotations();
    controller.removeAllAnnotations();
    this.drawChartMiddleXYLines(controller, this.shared.weightTotalReturn, this.shared.weightSTD);
    const legend = this.chart.legend();
    legend.enabled(!Commons.userDashboardshow);
    legend.position("bottom");
    legend.align("center");
    legend.maxHeight("10%");
    legend.itemsLayout("verticalExpandable");
    legend.paginator().orientation("bottom");
    this.topfunds.forEach(ele => {
      if (ele.isBestPassive) {
        bestPassiveData.push(ele);
      }
    });
    legend.itemsFormatter(function (items) {
      items.forEach(element => {
        if (element.enabled && bestPassiveData.some((item) => item.name == element.text)) {
          element.iconStroke = "3 #FFFFFF"
        }
      });
      return items;
    });
    this.chart.dr;
    await this.generateCustomTooltips(this.chart);
  }

  chart: any;
  async drawRiskChart() {
    var bestPassiveData: any = [];
    if (this.riskchartcontainer) {
      this.riskchartcontainer.nativeElement.innerHTML = null;
    }
    this.chart = anychart.quadrant();
    this.chart.tooltip(false);
    const tool = this.chart.tooltip();
    if (Commons.userDashboardshow) {
      this.userDashboard = true;
    } else {
      this.userDashboard = false;
    }
    const dataSet = anychart.data.set(this.shared.fiveYearRiskRewNew)
    const precentilesSeries = this.chart.marker(dataSet);
    precentilesSeries.tooltip(!Commons.userDashboardshow);
    precentilesSeries.legendItem(false);
    const tooltip = this.chart.getSeries(0).tooltip();
    tooltip.separator(false);
    tooltip.titleFormat("{%name}");
    tooltip.format("");
    //Set series of top funds data
    await this.topfunds.forEach((element) => {
      if (element.isBestPassive) {
        bestPassiveData.push(element);
      }
      const data = anychart.data.set([]);
      data.append(element);
      const series = data.mapAs({ x: "x", value: "value", color: "color", id: "id" });
      this.chart.addSeries(series);

      this.chart
        .getSeriesAt(this.chart.getSeriesCount() - 1)
        .name(element.name)
        .color(element.color ? element.color : "#fff")
        .id(element.fundId)
    });

    let minValueY = 0;
    let maxValueY = 0;
    let minValueX = 0;
    let maxValueX = 0;
    this.shared.weightSTD = this.shared.weightSTD.filter(function (el) { return el != null; });
    this.shared.weightTotalReturn = this.shared.weightTotalReturn.filter(function (el) { return el != null; });
    if (this.shared.fiveYearRiskRewNew.length > 0) {
      minValueY = Math.min.apply(null, this.shared.weightTotalReturn);
      maxValueY = Math.max.apply(null, this.shared.weightTotalReturn);
      minValueX = Math.min.apply(null, this.shared.weightSTD);
      maxValueX = Math.max.apply(null, this.shared.weightSTD);
    }
    await this.chart
      .yScale()
      .minimum(
        this.common.setRiskChartYAxisMinMedian(
          this.category,
          minValueY < 0 ? minValueY + 0.0100 : minValueY - 0.000100,
          this.selectedCatYear
        )
      );
    await this.chart
      .yScale()
      .maximum(
        this.common.setRiskChartYAxisMaxMedian(
          this.category,
          maxValueY + 0.0100,
          this.selectedCatYear
        )
      );
    await this.chart
      .xScale()
      .minimum(
        this.common.setRiskChartXAxisMinMedian(
          this.category,
          minValueX < 0 ? minValueX + 0.0100 : minValueX - 0.000100,
          this.selectedCatYear
        )
      );
    await this.chart
      .xScale()
      .maximum(
        this.common.setRiskChartXAxisMaxMedian(
          this.category,
          maxValueX + 0.0100,
          this.selectedCatYear
        )
      );

    // configure axis
    if (!Commons.userDashboardshow) {
      this.chart.xAxis(0, { ticks: true, labels: true });
      this.chart.yAxis(0, { ticks: true, labels: true });
    }

    // set interval Y axes
    this.chart.yScale().ticks().interval(2);
    // set interval X axes
    this.chart.xScale().ticks().interval(4);
    //chart legends
    const legend = this.chart.legend();
    legend.enabled(!Commons.userDashboardshow);
    legend.position("bottom");
    legend.align("center");
    legend.maxHeight("10%");
    legend.itemsLayout("verticalExpandable");
    legend.paginator().orientation("bottom");


    legend.itemsFormatter(function (items) {
      items.forEach(element => {
        if (element.enabled && bestPassiveData.some((item) => item.name == element.text)) {
          element.iconStroke = "3 #FFFFFF"
        }
      });
      return items;
    });

    let yAxis = this.chart.yAxis(1);
    yAxis.orientation("left");
    this.chart.background().fill({
      color: "#000f19",
      angle: 130,
    });

    this.chart.xAxis(0).enabled(true).stroke("0");
    this.chart.xAxis(1).enabled(false);
    this.chart.yAxis(0).enabled(true).stroke("0");
    this.chart.yAxis(1).enabled(false);

    const controller = this.chart.annotations();

    await this.drawChartMiddleXYLines(controller, this.shared.weightTotalReturn, this.shared.weightSTD);

    // configure quarters
    this.chart.quarters({
      rightTop: {
        fill: "#fff 0",
        zIndex: 12,
        title: {
          text: "High Risk - High Return",
          align: "right",
          fontColor: "#48EEE0",
          fontSize: 10,
          padding: "20,20,20,20",
        },
      },
      rightBottom: {
        fill: "#fff 0",
        zIndex: 12,
        title: {
          text: "High Risk - Low Return",
          align: "right",
          fontColor: "#48EEE0",
          fontSize: 10,
          padding: "20,20,20,20",
        },
      },
      leftTop: {
        fill: "#fff 0",
        zIndex: 12,
        title: {
          text: "Low Risk - High Return",
          align: "left",
          fontColor: "#48EEE0",
          fontSize: 10,
          padding: "20,20,20,20",
        },
      },
      leftBottom: {
        fill: "#fff 0",
        zIndex: 12,
        title: {
          text: "Low Risk - Low Return",
          align: "left",
          fontColor: "#48EEE0",
          fontSize: 10,
          padding: "20,20,20,20",
        },
      },
    });

    this.chart
      .dataArea()
      .background()
      .enabled(true)
      .fill(["0.1 #321C29", "#0A262E"], 145);
    this.chart.padding(20)
    const labelLBottom2 = this.chart.quarters().leftBottom().label(1);
    labelLBottom2.text("");
    const yTitle = this.chart.yAxis().title();
    yTitle.enabled(true);
    yTitle.text("Reward (Annualised 1/3/5 yr Weighted Return)");
    yTitle.align("center");
    const xTitle = this.chart.xAxis().title();
    xTitle.enabled(true);
    xTitle.text("Risk (1/3/5 yr Weighted Standard Deviation)");
    xTitle.align("center");
    const labelLBottom1 = this.chart.quarters().rightBottom().label(1);
    labelLBottom1.text("");
    // set the chart title
    this.chart.title("");
    this.chart.crosshair(true);
    this.chart.crosshair().xLabel(false);
    this.chart.crosshair().yLabel(false);
    this.chart.crosshair().xStroke("grey", 0.5, "10 2");
    this.chart.crosshair().yStroke("grey", 0.5, "10 2");
    // "grey", 0.5, "10 2"
    // set the container id
    this.chart.container("riskreward");
    // initiate drawing the chart

    const test = this.chart.draw(true);
    Commons.riskChartLoader = false;
    Commons.drawRiskChart = test.me;
    this.chartDraw = "draw";

    await this.generateCustomTooltips(this.chart);
  }

  isBestPassive = false;
  generateCustomTooltips(chart) {
    // create a HTML tooltip

    let bestPassive = this.shared.isBestPassive;
    const tooltip = document.getElementById("custom-tooltip");


    // show the tooltip according to hovered label
    chart.listen("pointMouseOver", customTooltipMouseOver);

    // hide the tooltip
    chart.listen("pointMouseOut", customTooltipMouseOut);
    const topFundsData = this.topfunds;
    // to get series index : e.point.getSeries().getIndex() and using this we can get complete that of particular fund
    const self = this;
    // let topFund = this.topfunds;
    function customTooltipMouseOver(e) {
      // topFundsData = topFund;
      this.isBestPassive = false;
      self.chartHoverPointData = e;
      let dates;
      let x;
      // let isBestPassive = false;
      console.log('top fund', topFundsData);
      topFundsData.forEach((ele) => {
        if (e.point.getSeries().name() == ele.name) {
          dates = ele.date;
          x = parseFloat(ele.x).toFixed(2);
          if (ele.isBestPassive) {
            this.isBestPassive = true;
          }
        }
      });

      clearTimeout(timeout);
      // place the tooltip on the initial show
      positionTooltip(e);
      console.log(e);
      if (!this.isBestPassive && !bestPassive) {
        tooltip.innerHTML =
          `<div class="tooltip-content" style="   
      position: absolute;
      border-left: 2px solid ` +
          e.target.rU.sourceColor +
          `;
      border-bottom: 2px solid ` +
          e.target.rU.sourceColor +
          `;
      top: 0;
      transform: translate(5px) !important;
      transform: rotate(135deg) !important;
      right: 50%;
      height: 10px;
      width: 10px;
      z-index: 2;
      display: block;
      margin-top: -2.4%;
      background:#000f19;
    " ></div><div id='tooltip-buttons' style="margin-top: 12px;margin-left:5px;margin-right:5px;margin-bottom:10px;"><div class="tooltip-buttons-parent risk-reward-tooltip"><button class="button-tooltip" id="summary-card-topFund" type="button"
                onmouseover="this.style.borderColor='` +
          e.target.rU.sourceColor +
          `'" onmouseout="this.style.borderColor='#58677f'">SUMMARY CARD</button><br><div class="btn-flex"><button class="button-tooltip fact-btn" id="fact-sheet"
                onmouseover="this.style.borderColor='` +
          e.target.rU.sourceColor +
          `'" onmouseout="this.style.borderColor='#58677f'" id="fact-sheet">FACTSHEET</button><button class="button-tooltip" id="kiid"
                onmouseover="this.style.borderColor='` +
          e.target.rU.sourceColor +
          `'" onmouseout="this.style.borderColor='#58677f'" id="kid">KIID</button></div><br><div class="btn-flex" style="margin-top:-15px;"><button id="alerts-data" class="button-tooltip fact-btn"
                onmouseover="this.style.borderColor='` +
          e.target.rU.sourceColor +
          `'" onmouseout="this.style.borderColor='#58677f'">ALERTS</button><button class="button-tooltip" id="news-feeds"
                onmouseover="this.style.borderColor='` +
          e.target.rU.sourceColor +
          `'" onmouseout="this.style.borderColor='#58677f'">MARKET INSIGHTS</button></div></div>` +
          "<br/>" +
          '<span style="color:#617088;max-width:100%; white-space: normal;display: block;  margin-bottom: -18px;">' +
          e.point.getSeries().name() +
          "</span><br/>" +
          '<span style="color:#617088;max-width:100%; white-space: normal;display: block;  margin-bottom: -18px;">' + dates + '</span><br/>' +
          '<span style="color:' +
          e.target.rU.sourceColor +
          '">' +
          "1/3/5 yr Annualised Weighted Return" +
          ":" +
          e.point.getStat().value.toFixed(2) +
          "% (5Y)" +
          '</span><br/><span style="color:' +
          e.target.rU.sourceColor +
          '">' +
          "Weighted Standard Deviation" +
          ":" +
          x +
          "% (5Y)" +
          '</span></div><div id="news-in-tooltip" style="position: relative;"> </div>';
        if (e.point.getSeries().name() !== "Series 0" && !e.point.getSeries().name().includes("Series")) {
          tooltip.style.display = "block";
          tooltip.style.background = "#000F17";
          tooltip.style.border = "1px solid " + e.target.rU.sourceColor;
          tooltip.style.height = "auto";
          tooltip.style.width = "250px";
        }
        if (e.point.getSeries().name().includes("Series")) {
          tooltip.style.display = "none";
        }
      } else {
        tooltip.innerHTML =
          `<div class="tooltip-content" style="   
      position: absolute;
      border-left: 2px solid ` +
          e.target.rU.sourceColor +
          `;
      border-bottom: 2px solid ` +
          e.target.rU.sourceColor +
          `;
      top: 0;
      transform: translate(5px) !important;
      transform: rotate(135deg) !important;
      right: 50%;
      height: 10px;
      width: 10px;
      z-index: 2;
      display: block;
      margin-top: -2.4%;
      background:#000f19;
    " ></div>`+
          '<span style="color:#617088;max-width:100%; white-space: normal;display: block;  margin-bottom: -18px;">' +
          e.point.getSeries().name() +
          "</span><br/>" +
          '<span style="color:#617088;max-width:100%; white-space: normal;display: block;  margin-bottom: -18px;">' + dates + '</span><br/>' +
          '<span style="color:' +
          e.target.rU.sourceColor +
          '">' +
          "Total Weighted Value" +
          ":" +
          e.point.getStat().value.toFixed(2) +
          "% (5Y)" +
          '</span><br/></div><div id="news-in-tooltip" style="position: relative;"> </div>';
        if (e.point.getSeries().name() !== "Series 0" && !e.point.getSeries().name().includes("Series")) {
          tooltip.style.display = "block";
          tooltip.style.background = "#000F17";
          tooltip.style.border = "1px solid " + e.target.rU.sourceColor;
          tooltip.style.height = "auto";
          tooltip.style.width = "250px";
        }
        if (e.point.getSeries().name().includes("Series")) {
          tooltip.style.display = "none";
        }
      }

    }

    const hovering = document.getElementById("custom-tooltip");
    if (hovering) {
      hovering.addEventListener("mouseover", function (event) {
        //  this.chartData = "value";
        clearTimeout(timeout);
      });

      hovering.addEventListener("mouseout", function (event) {
        // hide the tooltip div
        customTooltipMouseOut();
      });
    }


    let timeout;
    function customTooltipMouseOut() {
      timeout = setTimeout(function () {
        self.chartHoverPointData = null;
        // hide the tooltip div
        tooltip.style.display = "none";
      }, 100);
    }


    function positionTooltip(e) {
      // get the chart container div
      const $container = chart.container().getStage().container();

      // get the the chart container div offsets
      const offsetTop = $container.offsetTop;
      const offsetLeft = $container.offsetLeft;

      // get the current page scroll position
      const scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      // calculate the tooltips div position
      const clientX = e.originalEvent["offsetX"] - offsetLeft;
      const clientY = e.originalEvent["offsetY"] - offsetTop + scrollPosition;
      // place the tooltip in the calculated position
      tooltip.style.left = clientX - 120 + "px";
      tooltip.style.top = clientY + 48 + "px";
    }
  }


  async drawChartMiddleXYLines(controller, weightTotalReturn, weightSTD) {
    const xMiddle = this.getMiddleForX(weightTotalReturn, weightSTD);
    const yMiddle = this.getMiddleForY(weightTotalReturn, weightSTD);

    this.middleXValue = xMiddle * 3;
    this.middleYValues = yMiddle * 3;
    this.topfunds.forEach((ele, index, object) => {
      if (ele.x > this.middleXValue) {
        object.splice(index, 1);
      }
      else if (ele.value > this.middleYValues) {
        object.splice(index, 1);
      }
    });
    this.shared.fiveYearRiskRewNew.forEach((ele, index, object) => {
      if (ele.x > this.middleXValue) {
        object.splice(index, 1);
      }
      else if (ele.value > this.middleYValues) {
        object.splice(index, 1);
      }
    });
    const xMinDifference = Math.abs(xMiddle - Commons.riskXAxisMin);
    const xMaxDifference = Math.abs(xMiddle - Commons.riskXAxisMax);
    const yMinDifference = Math.abs(yMiddle - Commons.riskYAxisMin);
    const yMaxDifference = Math.abs(yMiddle - Commons.riskYAxisMax);
    const yAxisDifference = yMinDifference - yMaxDifference;
    const xAxisDifference = xMinDifference - xMaxDifference;
    const xAxisValue = Math.abs(Number(xAxisDifference));
    const yAxisValue = Math.abs(Number(yAxisDifference));
    if (yMinDifference > yMaxDifference) {
      const value: number = +Commons.riskYAxisMax;
      const maxValue = value + yAxisValue;
      this.chart
        .yScale()
        .maximum(maxValue);
    }
    if (yMinDifference < yMaxDifference) {
      const value: number = +Commons.riskYAxisMin;
      const minValue = value - yAxisValue;
      this.chart
        .yScale()
        .minimum(minValue);
    }
    if (xMinDifference > xMaxDifference) {
      const value: number = +Commons.riskXAxisMax;
      const maxValue = value + xAxisValue;
      this.chart
        .xScale()
        .maximum(maxValue);
    }
    if (xMinDifference < xMaxDifference) {
      const value: number = +Commons.riskXAxisMin;
      const minValue = value - xAxisValue;
      this.chart
        .xScale()
        .minimum(minValue);
    }
    const chartScaleY = this.chart.getYScales();
    const chartScaleX = this.chart.getXScales();

    if (chartScaleY[0].min) {
      this.chart
        .xScale()
        .minimum(chartScaleX[0].min - 0.5);
      this.chart
        .xScale()
        .maximum(chartScaleX[0].max + 0.5);
      this.chart
        .yScale()
        .minimum(chartScaleY[0].min - 0.5);
      this.chart
        .yScale()
        .maximum(chartScaleY[0].max + 0.5);
    }

    const xline = controller.horizontalLine();
    const yline = controller.verticalLine();

    xline.valueAnchor(yMiddle);
    yline.xAnchor(xMiddle);

    yline.allowEdit(false);
    xline.allowEdit(false);

    yline.normal().stroke("#48EEE0", 0.5, "0 0");
    xline.normal().stroke("#48EEE0", 0.5, "0 0");
    yline.hovered().stroke("#48EEE0", 0.5, "0 0");
    xline.hovered().stroke("#48EEE0", 0.5, "0 0");
    yline.selected().stroke("#48EEE0", 0.5, "0 0");
    xline.selected().stroke("#48EEE0", 0.5, "0 0");
  }


  getMiddleForX(weightTotalReturn, weightSTD) {
    const median = this.median(weightSTD);
    return median;
  }

  median(values) {
    values.sort(function (a, b) {
      return a - b;
    });
    const half = Math.floor(values.length / 2);

    if (values.length % 2)
      return values[half];
    else
      return (values[half - 1] + values[half]) / 2.0;
  }

  getMiddleForY(weightTotalReturn, weightSTD) {
    const median = this.median(weightTotalReturn);
    return median;
  }


  drawLastTopFundLine(controller) {

  }

  getMaxXobject(data) {
    const m = [];
    for (let i = 0; i < data.length; i++) {
      m.push(data[i].x);
    }
    const maxM = m.reduce((max, p) => (p > max ? p : max), m[0]);
    return data.find((o) => o.x == maxM);
  }


  getMaxXobjectWeighted(values) {

    values.sort(function (a, b) {
      return a - b;
    });

    const half = Math.floor(values.length / 2);

    if (values.length % 2)
      return values[half];

    return (values[half - 1] + values[half]) / 2.0;
  }

  collectData(): any {
    this.selectedCatYear = this.shared.selectedYears;
    this.category = this.shared.selectedCategoryMarket;
    this.isNew = JSON.parse(localStorage.getItem('IsNew'));
    this.riskRewardData = this.shared.msRiskRewardData
    this.getChartData();
  }

  async getChartData() {
    this.shared.fiveYearRiskRewNew = null;
    this.shared.weightTotalReturn = null;
    this.shared.weightSTD = null;
    const weightTotalReturn = [];
    const weightSTD = [];
    const stData = [];
    this.shared.totalFiveYearRisk = [];
    // topfunds variable contains data of top funds to show in risk reward
    const fiveYearRiskRewNew = [];
    await this.riskRewardData.forEach((ele) => {
      weightTotalReturn.push(ele["Weighted_Total_Returns"] * 100);
      weightSTD.push(ele["Weighted_Standard_Deviation"] * 100);
      if (
        ele["RAR_5_year_percentile"] * 100 > 5 &&
        ele["RAR_5_year_percentile"] * 100 < 95
      ) {
        stData.push([
          {
            y: ele["Weighted_Total_Returns"] * 100,
            x: ele["Weighted_Standard_Deviation"] * 100,
            name: ele["Name"],
          },
        ]);
      }
    });

    for (let i = 0; i < stData.length; i++) {
      if (stData[i][0].name == "Fundsmith Equity R Acc") {
      }
      const fiveYearObject: any = {
        name: stData[i][0].name,
        x: stData[i][0].x,
        value: stData[i][0].y,
        isShow: false,
        normal: {
          fill: "#7BACC6 0.1",
          stroke: "#617089",
          markerSize: 3,
          type: "circle",
        },
        hovered: {
          fill: "#7BACC6 0.1",
          stroke: "2 #617089",
          markerSize: 5,
        },
        selected: {
          fill: "#7BACC6 0.1",
          stroke: "4 #617089",
          markerSize: 5,
        },
      };
      fiveYearRiskRewNew.push(fiveYearObject);
    }
    this.shared.totalFiveYearRisk = fiveYearRiskRewNew;
    this.shared.fiveYearRiskRewNew = fiveYearRiskRewNew;
    this.shared.weightTotalReturn = weightTotalReturn;
    this.shared.weightSTD = weightSTD;
    if (Commons.drawRiskChart == "quadrant" && this.chartDraw == "draw" && this.shared.riskChartDraw != "redraw") {
      this.updateTopFunds();
      this.updateTopFundsData();
      if (this.shared.riskChartDraw != "redraw") {
        this.drawRiskChart();
      }
      // return
    } else if (this.shared.riskChartDraw === "redraw") {
      if (this.shared.weightTotalReturn.length > 0) {
        this.drawRiskChart();
        this.shared.riskChartDraw = null;
      }
    }
    else {
      if (this.shared.weightTotalReturn.length > 0) {
        this.drawRiskChart();
      }
      // return
    }
  }

  showGraph() {
    if (this.showGraphdata) {
      this.showGraphdata = false;
    } else {
      setTimeout(() => {
        this.showGraphdata = !this.showGraphdata;
      }, 10);
    }
  }

  openAlertsForAlerts(event) {
    this.openRiskAlert.emit(event);
  }

  openAlertsForMarketInsight(event) {
    this.openAlertMarket.emit(event);
  }

  selectAlerts(event, item) {
    if (event == "like") {
      item.selectLike = !item.selectLike;
      item.selectDelete = false;
      item.selectClose = false;
    }
    if (event == "disLike") {
      item.selectLike = false;
      item.selectDelete = !item.selectDelete;
      item.selectClose = false;
    }
    if (event == "close") {
      item.selectLike = false;
      item.selectDelete = false;
      item.selectClose = !item.selectClose;
    }
  }


  onClick(event) {
    if (this.tooltipPopup != undefined) {
      if (!this.tooltipPopup.nativeElement.contains(event.target) && this.showGraphdata) {
        this.showGraphdata = false;
      }
    }
  }

  openPopup(event) {
    if (event.target.id == "summary-card-topFund") {
      let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": this.chartHoverPointData.target.Wd + "_summary_popup",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
      this.openSummary.emit(this.chartHoverPointData);

    }
    if (event.target.id == "fact-sheet") {
      let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": this.chartHoverPointData.target.Wd + "_factsheet_tooltip",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
      this.getFactsheet(this.chartHoverPointData);
    }
    if (event.target.id == "kiid") {
      let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": this.chartHoverPointData.target.Wd + "_kiid_tooltip",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
      this.getKiid(this.chartHoverPointData);
    }
    if (event.target.id == "alerts-data") {
      let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": this.chartHoverPointData.target.Wd + "_alerts_tooltip",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
      this.getAlertsData(this.chartData);
    }
    if (event.target.id == "news-feeds") {
      let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": this.chartHoverPointData.target.Wd + "_market_tooltip",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
      this.getNewsData(this.chartData);
    }
  }

  ClickedOut(event) {
    if (event.target.className === "pg-form-pages login-page") {
      document.getElementById("close-documentation").click();
    }
  }

  openDialog() {
    this.shared.popupTimer = 3;
    document.getElementById("openModalButton").click();
    const interval = setInterval(() => {
      if (this.shared.popupTimer > 0) {
        this.shared.popupTimer--;
      } else if (this.shared.popupTimer == 0) {
        document.getElementById("close-documentation").click();
        clearInterval(interval);
      }
    }, 1000);
  }

  getFactsheet(event) {
    if (this.shared.fundDocumentationData.length > 0) {
      this.shared.fundDocumentationData.forEach(element => {
        if (event.target.er == element.Name && element.Factsheet_Url != "null" && element.Factsheet_Url != undefined) {
          window.open(element.Factsheet_Url);
        } else {
          this.openDialog();
        }
      });
    } else {
      this.openDialog();
    }
  }

  getKiid(event) {
    if (this.shared.fundDocumentationData.length > 0) {
      this.shared.fundDocumentationData.forEach(element => {
        if (event.target.er == element.Name && element.KIID_Url != "null" && element.KIID_Url != undefined) {
          window.open(element.KIID_Url);
        } else {
          this.openDialog();
        }
      });
    } else {
      this.openDialog();
    }
  }

  getNewsData(event) {
    this.shared.newsFundDataRiskChart = this.chartHoverPointData;
    this.shared.newsLoadingRiskChart = true;
    this.dataNot = false;
    this.newsFeedData = [];
    this.newsFund.getNewsForFund(this.chartHoverPointData.point.getSeries().id()).subscribe((res) => {
      this.shared.newsLoadingRiskChart = false;
      if (res["data"]) {
        res["data"].forEach(element => {
          if (!this.newsFeedData.some((item) => item.Title == element.Title && item.Provider == element.Provider)) {
            this.newsFeedData.push(element);
          }
        });
      }
      else {
        this.dataNot = true;
      }
      this.getNewsDataMarket();
    }, (err) => {
      this.shared.newsLoadingRiskChart = false;
      this.dataNot = true;
    })
    document.getElementById("tooltip-buttons").style.display = "none";
    setTimeout(() => {
      document.getElementById("news-in-tooltip").innerHTML = this.newsComponent.nativeElement.innerHTML;
    }, 10);
  }

  getNewsDataMarket() {
    setTimeout(() => {
      document.getElementById("news-in-tooltip").style.display = "block";
      document.getElementById(
        "news-in-tooltip"
      ).innerHTML = this.newsComponent.nativeElement.innerHTML;
    }, 10)

    setTimeout(() => {
      document
        .getElementById("closeNewsRisk")
        .addEventListener("click", this.closeNewsActiveTooltip.bind(this));
    }, 10)
  }

  getAlertsData(event) {
    let data: any = [];
    this.shared.alertsDataRiskChart = this.chartHoverPointData;
    this.shared.alertLoadingRiskChart = true;
    this.shared.dataAvailableRiskChart = true;

    this.fundAlerts.getFundAlerts(this.chartHoverPointData.point.getSeries().id()).subscribe((res) => {
      this.shared.alertLoadingRiskChart = false;
      this.shared.fundAlertsDataRiskChart = [];
      if (res["length"] > 0) {
        data = res;
        data.forEach(element => {
          const test = element.event_value;
          //const da = test.replace(/None/g, '"None"').replace(/datetime/g, '"datetime').replace(')', ')"').replace(/Timestamp/g, '"Timestamp')
          const regex = /\s* :* *\T.*\)(|(?=\s+\}))/g;
          const subst = `""`;
          var d = test.replace(regex, subst);
          var obj = d.replace(/'/g, '"').replace(/None/g, '"None"');



          element.event_value = JSON.parse(obj);
          this.shared.fundAlertsDataRiskChart.push({
            occurrenceDate: element.effective_date,
            publishedData: element.date_event_noticed,
            newText: element.event_value.new_value != "None" ? element.event_value.new_value : "",
            name: element.Fund_Name, selectLike: false, fundId: element.ShareClass_Id,
            data: element.alert_type + ' : ' + element.event_type.replace(/_/g, " "),
            text: element.event_value.old_value != "None" ? element.event_value.old_value : "",
            selectDelete: false, selectClose: false, show: false
          });
        });
      } else {
        this.shared.dataAvailableRiskChart = false;
      }
      this.alertData();
    }, (err) => {
      this.shared.alertLoadingRiskChart = false;
      this.shared.dataAvailableRiskChart = false;
    });
    document.getElementById("tooltip-buttons").style.display = "none";
    document.getElementById("news-in-tooltip").style.display = "block";
    setTimeout(() => {
      document.getElementById("news-in-tooltip").innerHTML = this.alertComponent.nativeElement.innerHTML;
    }, 10);
  }

  alertData() {
    setTimeout(() => {
      document.getElementById("news-in-tooltip").style.display = "block";
      document.getElementById(
        "news-in-tooltip"
      ).innerHTML = this.alertComponent.nativeElement.innerHTML;
    }, 10)

    setTimeout(() => {
      document
        .getElementById("closeNewsRisk")
        .addEventListener("click", this.closeNewsActiveTooltip.bind(this));
    }, 10)
  }

  closeNewsActiveTooltip() {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "close_tooltip",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    document.getElementById("news-in-tooltip").style.display = "none";
    document.getElementById("tooltip-buttons").style.display = "block";
  }


  getSummeryState(event) {
    this.showPopupOfSummary = event.showState;
  }

  openAlertsNews() {
    this.shared.alertsTitle = AlertsAndNews.title;
    this.shared.filterTitle = AlertsAndNews.filterTitle;
    this.shared.chartFilterNews = AlertsAndNews.filterItems;
    this.shared.newsTableHeading = AlertsAndNews.newsTableHeadings;
    Commons.newdFeedsData = AlertsAndNews.alertNewsFeed;
  }

  openSummaryCardForfunds(event) {
    this.showPopupOfSummary = true;
    this.fundName = event;
  }

}
