// Components
import { AppComponent } from './app.component';
import { HomeComponent } from '../pages/home/home.component';
import { LoginComponent } from '../pages/login/login.component';
import { PrefrencesComponent } from '../components/prefrences/prefrences.component';
import { MethedologyComponent } from '../components/methedology/methedology.component';
import { UserDashboardComponent } from '../components/user-dashboard/user-dashboard.component';
import { ChartFilterComponent } from '../components/chart-filter/chart-filter.component';
import { TooltipAlertsComponent } from '../components/tooltip-alerts/tooltip-alerts.component';
import { NewsFeedsComponent } from '../components/news-feeds/news-feeds.component';
import { SummaryPopupComponent } from '../components/summary-popup/summary-popup.component';
import { ChangePasswordComponent } from 'src/pages/change-password/change-password.component';
import { ResetComponent } from '../pages/reset/reset.component';
import { VerifymailComponent } from '../pages/verifymail/verifymail.component'
import { MsGrowthViewComponent } from '../components/ms-growth-view/ms-growth-view.component';
import { ActivePassiveViewComponent } from '../components/active-passive-view/active-passive-view.component';
import { MyFundsComponent } from '../components/my-funds/my-funds.component';
import { HeaderComponent } from '../components/header/header.component';
import { MsRiskRewardComponent } from '../components/ms-risk-reward/ms-risk-reward.component';
import { TopFundComponent } from '../components/top-fund/top-fund.component';
import { RatingViewComponent } from '../components/rating-view/rating-view.component';
import { LoaderComponent } from '../components/loader/loader.component';
import { ActivePassiveComponent } from '../components/active-passive/active-passive.component';

// Modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MnFullpageModule } from 'ngx-fullpage';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { ChartAllModule, AccumulationChartAllModule, RangeNavigatorAllModule } from '@syncfusion/ej2-angular-charts';
import { ChartsModule } from 'ng2-charts';
// import { NgSelect2Module } from 'ng-select2';
import { NgSelectModule } from '@ng-select/ng-select';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Services
import { PrefrenceSettingsService } from '../services/PreferencesServices/prefrence-settings.service';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from '../services/CommonServices/common.service';
import { Commons, Shared } from 'src/utils/common';

//Pipes and directives
import { CategoryPipe } from '../components/rating-view/category.pipe';
import { OrderrByPipe } from '../components/rating-view/orderby.pipe';
import { VerificationCodeComponent } from 'src/pages/verification-code/verification-code.component';
import { ForgetPasswordComponent } from 'src/pages/forget-password/forget-password.component';
import { NgxDaterangepickerMd } from 'date-picker/src/daterangepicker/daterangepicker.module';
import { AngularResizedEventModule } from 'angular-resize-event';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import stock from 'highcharts/modules/stock.src';
import more from 'highcharts/highcharts-more.src';
import { MarketInsightComponent } from '../components/market-insight/market-insight.component';
import { FundDocumentationComponent } from '../components/fund-documentation/fund-documentation.component';
import { AlertsAndNotificationComponent } from '../components/alerts-and-notification/alerts-and-notification.component';
import { ProfileModalComponent } from '../components/profile-modal/profile-modal.component';
import { TermsConditionComponent } from 'src/components/terms-condition/terms-condition.component';
import { AlertModalComponent } from 'src/components/alert-modal/alert-modal.component';
import { ContactUsComponent } from '../components/contact-us/contact-us.component';
import { ChartHeadersComponent } from '../components/chart-headers/chart-headers.component';
import { TopFundChartComponent }   from 'src/components/top-fund-chart/top-fund-chart.component';
import { DecimalPipe } from '@angular/common';
import { SpinnerComponent } from 'src/components/spinner/spinner.component';
import { RiskRewardChartComponent } from 'src/components/risk-reward-chart/risk-reward-chart.component';
import { SecurityProfileComponent } from 'src/components/security-profile/security-profile.component';
import { UserProfileComponent } from 'src/components/user-profile/user-profile.component';
import { OrganizationComponent } from 'src/components/organization/organization.component';
import { DateRangeComponent } from 'src/components/date-range/date-range.component';
import { SafeHtmlPipe } from '../pipe/safe-html.pipe';
import { HighlightSearchPipe } from 'src/pipe/highlight/highlight-search.pipe';
import { GoogleAnalyticsService } from 'src/services/google-Analytics/google-analytics.service';
import { ToggleComponent } from 'src/components/toggle/toggle.component';
import { SearchGeoPip } from 'src/pipe/searchGeography/searchGeo.pipe';
export function highchartsModules() {
  // apply Highcharts Modules to this array
  return [stock, more];
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    PrefrencesComponent,
    MethedologyComponent,
    UserDashboardComponent,
    LoaderComponent,
    ActivePassiveComponent,
    TopFundComponent,
    RatingViewComponent,
    MsRiskRewardComponent,
    ResetComponent,
    VerifymailComponent,
    MsGrowthViewComponent,
    ActivePassiveViewComponent,
    MyFundsComponent,                   
    HeaderComponent,
    ToggleComponent,
    ChangePasswordComponent,
    ChartFilterComponent,
    TooltipAlertsComponent,
    NewsFeedsComponent,
    SummaryPopupComponent,
    CategoryPipe,
    SearchGeoPip,
    OrderrByPipe,
    VerificationCodeComponent,
    ForgetPasswordComponent,
    MarketInsightComponent,
    FundDocumentationComponent,
    AlertsAndNotificationComponent,
    TermsConditionComponent,
    ProfileModalComponent,
    AlertModalComponent,
    ContactUsComponent,
    TopFundChartComponent,
    ChartHeadersComponent,
    SpinnerComponent,
    RiskRewardChartComponent,
    SecurityProfileComponent,
    UserProfileComponent,
    OrganizationComponent,
    DateRangeComponent,
    SafeHtmlPipe,
    HighlightSearchPipe,
    ],

  imports:[
    FormsModule,
    ReactiveFormsModule ,
    ChartModule,
    // MatTabsModule,
    // MatTableModule,
    //NgScrollbarModule,
    ChartAllModule,
    AccumulationChartAllModule,
    RangeNavigatorAllModule,
    BrowserModule,
    // HighchartsChartModule,
    NgSelectModule,
    AngularResizedEventModule,
    AppRoutingModule,
    NoopAnimationsModule,
    ChartsModule,
    // DecimalPipe,
    // NgSelect2Module,
    HttpClientModule,       
    MnFullpageModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    DragDropModule,
  ],
  
  providers: [Commons, Shared, DecimalPipe,   CommonService , NgxDaterangepickerMd , CookieService, PrefrenceSettingsService, GoogleAnalyticsService, {provide: HIGHCHARTS_MODULES, useFactory: highchartsModules}],
  bootstrap: [AppComponent]
})
export class AppModule { }
