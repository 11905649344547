import { Component, OnInit, ViewChild } from '@angular/core';
import { DaterangepickerComponent } from 'date-picker/src/daterangepicker';
import * as moment from 'moment';
import { DatePipe } from "@angular/common";
import { currentDate } from 'src/utils/common-function';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent implements OnInit {
  @ViewChild('dateRangePicker') dateRangePicker: DaterangepickerComponent;
  selected: any;
  endDate: any;
  startDate: any;
  constructor(private datePipe: DatePipe) {
    this.startDate = localStorage.getItem('startDate');
    this.endDate = localStorage.getItem('endDate');
    this.selected = {
      startDate: moment(this.startDate),
      endDate: moment(this.endDate)
    }
    this.startDate = this.startDate;
    this.endDate = this.endDate;
   }

  ngOnInit(): void {
  }

  choosedDate(event) {
    if (this.endDate) {
      this.endDate = null;
      this.startDate = event.chosenLabel;
      this.selected = {
        startDate: moment(this.startDate),
        endDate: moment(this.endDate)
      }
      this.dateRangePicker.setStartDate(moment(this.startDate));
      this.dateRangePicker.setEndDate(moment(this.endDate));
      this.dateRangePicker.updateView();
      localStorage.setItem('startDate',this.startDate);
      localStorage.setItem('endDate',this.endDate);
    } else {
      this.endDate = event.chosenLabel;
      this.selected = {
        startDate: moment(this.startDate),
        endDate: moment(this.endDate)
      }
      this.dateRangePicker.setStartDate(moment(this.startDate));
      this.dateRangePicker.setEndDate(moment(this.endDate));
      this.dateRangePicker.updateView();
      localStorage.setItem('startDate',this.startDate);
      localStorage.setItem('endDate',this.endDate);
    }
    if(this.startDate > this.endDate){
      this.selected = {
        startDate: moment(this.endDate),
        endDate: moment(this.startDate)
      }
      this.dateRangePicker.setStartDate(moment(this.endDate));
      this.dateRangePicker.setEndDate(moment(this.startDate));
      this.dateRangePicker.updateView();
      localStorage.setItem('startDate',this.startDate);
      localStorage.setItem('endDate',this.endDate);
    }
    if(this.startDate < this.endDate){
      this.selected = {
        startDate: moment(this.startDate),
        endDate: moment(this.endDate)
      }
      this.dateRangePicker.setStartDate(moment(this.startDate));
      this.dateRangePicker.setEndDate(moment(this.endDate));
      this.dateRangePicker.updateView();
      localStorage.setItem('startDate',this.startDate);
      localStorage.setItem('endDate',this.endDate);
    }
  }

  reset(){
    const date = new Date();
    const latest_date = currentDate();
    this.startDate = latest_date;
    const nextDate = date.setDate(date.getDate() + 7);
    this.endDate = this.datePipe.transform(nextDate, 'MM/dd/yyyy');
    this.selected = {
      startDate: moment(this.startDate),
      endDate: moment(this.endDate)
    }
    localStorage.setItem('startDate',this.startDate);
    localStorage.setItem('endDate',this.endDate);
    this.dateRangePicker.setStartDate(moment(this.selected.startDate));
    this.dateRangePicker.setEndDate(null);
    this.dateRangePicker.updateView();
  }
  
}
