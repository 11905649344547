import { Component, Input, OnInit } from '@angular/core';
import { LoginService } from 'src/services/AuthServices/login.service';
import { ProfileService } from 'src/services/profile/profile.service';
import { decode } from 'string-encode-decode';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  public userName: any;
  public firstName: any;
  public surname: any;
  public primaryEmail: any;
  public secondaryEmail: any;
  public mobileNumber: any;
  public userDetailDataModal: any;
  public userDetails: any;
  public errMsg: any;
  public firstNameEdit:boolean;
  public lastNameEdit:boolean;
  @Input() userDetailsProfile: any;
  constructor(public userLoginService: LoginService, public profileService: ProfileService) { 

  }

  ngOnInit(): void {
    this.firstNameEdit = true;
    this.lastNameEdit = true;
    this.profileService.isPressed = false; 
    if(this.profileService.profileData){
      this.getData(this.profileService.profileData);
    }
    this.profileService.profileObservable.subscribe((res)=>{
      this.getData(res);
      this.userDetails = res;
    })
  }

  updateUserDetails() {
    this.profileService.isPressed = true;
    const modal = {
      "id": decode(localStorage.getItem("ID")),
      "Name": this.userName == null ? "" : this.userName,
      "FName": this.firstName == null ? "" : this.firstName,
      "LName": this.surname == null ? "" : this.surname,
      "PreEmail": this.primaryEmail == null ? "" : this.primaryEmail,
      "SecEmail": this.secondaryEmail == null ? "" : this.secondaryEmail,
      "Mobile": this.mobileNumber == null ? "" : this.mobileNumber,
    }
    this.userLoginService.userProfileUpdate(modal).subscribe((res) => {
      if(res["message"] == "Profile Update Successful!"){
        setTimeout(() =>{
          this.profileApi();
        }, 1000);
    }
    });
  }

  getData(res){
    if(res.data){
      const users = res.data;
      this.userName = users.Name;
      this.firstName = users.FName;
      this.surname = users.LName;
      this.primaryEmail = users.Email;
      this.secondaryEmail = users.EmailSecondary;
      this.mobileNumber = users.Mobile;
      if(!users.FName){
        this.firstNameEdit = false;
        this.lastNameEdit = false;
      } else{
        this.firstNameEdit = true;
        this.lastNameEdit = true;
      } 
    }
  }

  profileApi(){
    this.profileService.getProfile(decode(localStorage.getItem("ID")));
  }

  onPressFirstName(event) {
    this.firstName = event.target.value;
  }

  onPressSurname(event) {
    this.surname = event.target.value;
  }

  onPressSecondaryEmail(event) {
    this.secondaryEmail = event.target.value;
    this.userLoginService.secEmail = event.target.value;
  }

  onPressMobileNumber(event) {
    this.mobileNumber = event.target.value;
    this.userLoginService.mobNo = event.target.value;
  }

}
