import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  message:any;
  subject:any;
  email:any;
  lastname:any;
  firstname:any;
  error:any;
  constructor() { }

  ngOnInit(): void {
  }

  ClickedOut(event){
    if(event.target.className == "pg-form-pages login-page"){
      document.getElementById("close-contact-popup").click();
    }
  }

  submitContactDetails(event){

  }

}
