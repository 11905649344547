import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { worker } from 'cluster';
import { environment } from 'src/environments/environment';
import { Commons } from 'src/utils/common';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
declare let ga: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(public http: HttpClient,) {}
  // public eventEmitter(eventAction: string,
  //   eventLabel: string = null,
  //   Value: number = null) {
  //   ga('send', {
  //     hitType: 'event',
  //     eventCategory: 'general',
  //     eventAction: eventAction,
  //     eventLabel: eventLabel
  //   });
  //   // gtag('event', eventAction,{ 
  //   //   'event_Category': eventCategory, 
  //   //   'event_Label': eventLabel, 
  //   //   'value': Value
  //   // })
  // }
  webWorkerAddEvent(modal){
    if(window.Worker){
      // for QA/PROD release
      const myWorker = new Worker("0-es2015.23b14884378be85cafdb.worker.js");
      // for local
      //const myWorker = new Worker("0.worker.js");
      this.addEvent(modal);
      // myWorker.onmessage = (data) => {
      //   console.log(`page got message: ${data.data}`);
      // }
      myWorker.postMessage({});
    }
  }

  webWorkerCreateSession(modal){
    if(window.Worker){
      // for QA/PROD release
      const myWorker = new Worker("0-es2015.23b14884378be85cafdb.worker.js");
      // for local
      //const myWorker = new Worker("0.worker.js");
     this.createNewSession(modal);
    //  myWorker.onmessage = (data) => {
    //   console.log(`page got message: ${data.data}`);
    // }
      myWorker.postMessage({});
    }
  }

  webWorkerUpdateSession(modal){
    if(window.Worker){
      // for QA/PROD release
      const myWorker = new Worker("0-es2015.23b14884378be85cafdb.worker.js");
      // for local
      //const myWorker = new Worker("0.worker.js");
      this.updateSession(modal);
      // myWorker.onmessage = (data) => {
      //   console.log(`page got message: ${data.data}`);
      // }
      myWorker.postMessage({});
    }
  }
  
  addEvent(modal){
    return this.http.post(environment.baseUrl + 'addevent' , modal, httpOptions).subscribe(res => {
      //console.log(res);
    });
  }

  createNewSession(modal){
     this.http.post(environment.baseUrl + 'createSession' , modal, httpOptions).subscribe(res => {
      Commons.sessionId = res["data"].sessionId;
      sessionStorage.setItem("browserSession", Commons.sessionId);
    });
  }

  updateSession(modal){
    return this.http.post(environment.baseUrl + 'updateSession' , modal, httpOptions);
  }
}
