import { HttpHeaders } from '@angular/common/http';
import { decode } from 'string-encode-decode';
import * as moment from 'moment';

export function authorizeHeader(){
    const token = decode(localStorage.getItem("userToken"));
    const header = new HttpHeaders({
        'Content-Type' : 'application/json',
        // 'Authorization': `Bearer ${token}`
    })
    return header;
}

export function apiError(err:any){
    if(err.status == "401"){
        // localStorage.setItem('login', '0');
        // window.location.reload();
    } 
}

export function currentDate(){
    // let datePipe = new DatePipe();
    let latest_date:any;
    const date = new Date();
    const today_date = moment(date).format("L");
    const day = moment(today_date).format('dddd');
    // alert(day);
    if(day == "Saturday"){
        latest_date = moment(today_date).subtract(1, "days").format("L");
    }
    else if(day == "Sunday"){
        latest_date = moment(today_date).subtract(2, "days").format("L");
    }else{
        latest_date = today_date;
    }
    // alert(latest_date);
    localStorage.setItem("date", latest_date);
    localStorage.setItem("endDate", latest_date);
    return latest_date;
}

export function esgIncludeValue(id, value){
    let data:any;
    switch(id){
        case 'Alternative Energy ':
            if(value == '0'){
                data = 'x';
            } else if(value == '1'){
                data = '<span style="font-size: 20px;">✔</span>';
            } 
            return data;
            break;
        case 'Energy Efficient ':
            if(value == '0'){
                data = 'x';
            } else if(value == '1'){
                data = '<span style="font-size: 20px;">✔</span>';
            }
            return data;
            break;
        case 'Board Independence Percent ':
            if(value != null){
                data = value;
            }
            return data;
            break;
        case 'Customer Controversies ':
            if(value != null){
                data = value;
            }
            return data;
            break;
        default:
            return '--';
    }
}

export function esgExcludeValue(id, value){
    let data:any;
    switch(id){
        // case 'Severe Labor Controversies ':
        //     data = value;
        //     return data;
        //     break;
        case 'Severe Human Rights and Community Controversies ':
            data = value;
            return data;
            break;
        case 'UN Global Compact Compliance ':
            if(value != null){
                data = value;
            }
            return data;
            break;
        case 'Human Rights Compliance ':
            if(value != null){
                data = value;
            }
            return data;
            break;
        case 'Adult Entertainment Ties ':
            if(value == 'NA' || value == null){
                data = '--';
            } else if(value == 'TRUE' || value == 'T'){
                data = '<span style="font-size: 20px;">✔</span>';
            }
            return data;
            break;
        case 'Alcohol Ties ':
            if(value == 'NA' || value == null){
                data = '--';
            } else if(value == 'TRUE' || value == 'T'){
                data = '<span style="font-size: 20px;">✔</span>';
            }
            return data;
            break;
        case 'Gambling Ties':
            if(value == 'NA' || value == null){
                data = '--';
            } else if(value == 'TRUE' || value == 'T'){
                data = '<span style="font-size: 20px;">✔</span>';
            }
            return data;
            break;
        case 'GMO Ties ':
            if(value == 'NA' || value == null){
                data = '--';
            } else if(value == 'TRUE' || value == 'T'){
                data = '<span style="font-size: 20px;">✔</span>';
            }
            return data;
            break;
        case 'Nuclear Ties ':
            if(value == 'NA' || value == null){
                data = '--';
            } else if(value == 'TRUE' || value == 'T'){
                data = '<span style="font-size: 20px;">✔</span>';
            }
            return data;
            break;
        case 'Predatory Lending ':
            if(value == 'NA' || value == null){
                data = '--';
            } else if(value == 'TRUE' || value == 'T'){
                data = '<span style="font-size: 20px;">✔</span>';
            }
            return data;
            break;
        case 'Tobacco Ties ':
            if(value == 'NA' || value == null){
                data = '--';
            } else if(value == 'TRUE' || value == 'T'){
                data = '<span style="font-size: 20px;">✔</span>';
            }
            return data;
            break;
        case 'Controversial Weapons Ties ':
            if(value == 'NA' || value == null){
                data = '--';
            } else if(value == 'TRUE' || value == 'T'){
                data = '<span style="font-size: 20px;">✔</span>';
            }
            return data;
            break;
        case 'Weapons Manufacture Ties':
            if(value == 'NA' || value == null){
                data = '--';
            } else if(value == 'TRUE' || value == 'T'){
                data = '<span style="font-size: 20px;">✔</span>';
            }
            return data;
            break;
        case 'Fossil Fuel Reserve Links':
            if(value == 'NA' || value == null){
                data = '--';
            } else if(value == 'TRUE' || value == 'T'){
                data = '<span style="font-size: 20px;">✔</span>';
            }
            return data;
            break;
        case 'Female Directors ':
            if(value == '1'){
                data = 'x';
            } else if(value == '0'){
                data = '<span style="font-size: 20px;">✔</span>';
            }
            return data;
            break;
        case 'Carbon Emissions Score (0-10)':
            if(value != null){
               data = value;
            }
            return data;
            break;
        case 'Social Controversy Score (0-10) ':
            if(value != null){
                data = value;
            }
            return data;
            break;
        case 'Customer Controversies ':
            if(value != null){
                data = value;
            }
            return data;
            break;
        default:
            return '--';
    }
}

export function consistencyUpdate(){
}
