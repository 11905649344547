import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Cacheable } from 'ngx-cacheable';
import { Subject } from "rxjs";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class TopFundsService {
//public chartScore:any[]=[];
  constructor(public http: HttpClient) { 
    // this.chartScore = [
    //   {name:'5', select: true, showInfo:true, info:"Consistency Score of 1/3/5 years vs. Passive: 5 = Top 5% Sharpe Ratio of Active Funds (FUNDSaiQ Recommended)"},
    //   {name:'4', select: false, showInfo:false, info:"Consistency Score of 1/3/5 years vs. Passive: 4 = Top 10% Sharpe Ratio of Active Funds (FUNDSaiQ Recommended)"},
    //   {name:'3', select: false, showInfo:false, info:"Consistency Score of 1/3/5 years vs. Passive: 3 = Top 15% Sharpe Ratio of Active Funds (FUNDSaiQ Recommended)"},
    //   {name:'2', select: false, showInfo:false, info:"Consistency Score of 1/3/5 years vs. Passive: 2 = Top 20% Sharpe Ratio of Active Funds (FUNDSaiQ Recommended)"},
    //   {name:'1', select: false, showInfo:false, info:"Consistency Score of 1/3/5 years vs. Passive: 1 = Top 25% Sharpe Ratio of Active Funds (FUNDSaiQ Recommended)"}]
  }

}
