import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { decode } from 'string-encode-decode';
import { Router } from "@angular/router";
import { HttpHeaders } from "@angular/common/http";
import { CommonService } from "src/services/CommonServices/common.service";
import { MarketNews } from "../../utils/constants";
import { AlertsAndNews } from "../../utils/constants";
import { DatePipe } from "@angular/common";
import { MarketInsightService } from "src/services/market-insight/market-insight.service";
import { AlertsNotificationsService } from "src/services/alerts-notifications/alerts-notifications.service";
import { GoogleAnalyticsService } from "src/services/google-Analytics/google-analytics.service";
import { Commons, Shared } from "src/utils/common";


const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
@Component({
  selector: "app-news-feeds",
  templateUrl: "./news-feeds.component.html",
  styleUrls: ["./news-feeds.component.scss"],
  host: {
    "(document:click)": "onClick($event)",
  },
})
export class NewsFeedsComponent implements OnInit {
  @Input() pageX: any;
  @Input() pageY: any;
  @ViewChild("menu") menu: ElementRef;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @Input() showAlertInbox = false;
  @Output() sendState: EventEmitter<any> = new EventEmitter();
  @Input() newsTitle: any;
  @Output("closeAlertPopup")
  closeAlertPopup: EventEmitter<any> = new EventEmitter();
  showAnimation: boolean;
  loadMoreData:boolean;
  showDropDownAddSetting: boolean;
  selectedItems: any = [];
  MarketData: any;
  paging:any;
  loadMoreDataAlerts:boolean;
  scrolled: boolean;

  constructor(
    public router: Router,
    private eRef: ElementRef,
    public common: CommonService,
    public datePipe:DatePipe,
    public marketInsightService:MarketInsightService,
    public alertsAndNotificationService:AlertsNotificationsService,
    public googleAnalyticsEventsService: GoogleAnalyticsService,
    public shared: Shared
  ) {
    this.shared.isFirstLogin = true;
  }

  ngOnInit(): void {
   }

  searchFunds(event) {
    this.MarketData = event;
    if (
      this.MarketData != "" &&
      this.MarketData != undefined &&
      this.MarketData != null
    ) {
      
      if(this.shared.alertsTitle == "Alerts"){
        this.shared.newsfeedDataAlerts = this.shared.alertsAndNotificationData.filter((item) => {
          return item.alert_type.toLowerCase().indexOf(event.toLowerCase()) > -1 ||
                 item.Fund_Name.toLowerCase().indexOf(event.toLowerCase()) > -1  || 
                 item.event_type.toLowerCase().indexOf(event.toLowerCase()) > -1;
        });
      }
      if(this.shared.alertsTitle == "Market Insights"){
        this.shared.newsFeedsData = this.shared.marketInsightData.filter((item) => {
          return item.Description.toLowerCase().indexOf(event.toLowerCase()) > -1 ||
          item.Tags.toLowerCase().indexOf(event.toLowerCase()) > -1 || 
          item.Title.toLowerCase().indexOf(event.toLowerCase()) > -1 || 
          item.Provider.toLowerCase().indexOf(event.toLowerCase()) > -1 ||
          item.Source.toLowerCase().indexOf(event.toLowerCase()) > -1;
        });
      }
    }
    if (this.MarketData == "") {
      if(this.shared.alertsTitle == "Alerts"){
        this.shared.newsfeedDataAlerts = this.shared.alertsAndNotificationData;
      } 
      if(this.shared.alertsTitle == "Market Insights"){
        this.shared.newsFeedsData = this.shared.marketInsightData;
      }
    }
  }

  removeUnderscore(item) {
    if (item) {
      const value = item.replace(/_/g, " ");
     return value;
    }
  }

  onScroll() {
    const element = this.myScrollContainer.nativeElement
    const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;

    if (this.scrolled && atBottom) {
      this.marketInsightService.paging++;
      this.scrolled = false;
      this.loadMoreData = true;
      const startDate = this.datePipe.transform(localStorage.getItem('startDate'), 'yyyy-MM-dd');
        const endDate = this.datePipe.transform(localStorage.getItem('endDate'), 'yyyy-MM-dd');
        const modal = {
          "page": this.marketInsightService.paging,
          "startDate": startDate,
          "endDate": endDate,
          "type": this.shared.selectedFilter,

        }
      this.marketInsightService.getMarketInsight(modal).subscribe((data:any[])=>{

        if(data){
          data.forEach((ele)=>{
            const newDataDescription = ele.Description.replace("<p>", "").replace("<\/p>", "").replace("↵", "");
            ele.Description = newDataDescription;
            this.shared.newsFeedsData.push(ele);
            this.shared.marketInsightData.push(ele);
          })
        }
        this.loadMoreData = false;
      }, (err)=>{
        this.loadMoreData = false;
      })
    } else {
      this.scrolled = true;
    }
  }

  onScrollAlerts(){
    const element = this.myScrollContainer.nativeElement
    const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;

    if (this.scrolled && atBottom) {
      this.scrolled = false;
      this.loadMoreDataAlerts = true;

      this.alertsAndNotificationService.getALertsNotificationsScroll(decode(localStorage.getItem("ID")), this.paging++).subscribe((data:any[])=>{

        this.loadMoreDataAlerts = false;
        if(data){
          data.forEach(element => {
            const date = this.datePipe.transform(element.date_event_noticed, 'MM/dd/yyyy');
            element.date_event_noticed = date;
            this.shared.alertsAndNotificationData.push(element);
            this.shared.newsfeedDataAlerts.push(element);
          });
        }
        
      }, (err)=>{
        this.loadMoreDataAlerts = false;
      })
    } else {
      this.scrolled = true;
    }
  }

  validateContent(content, show, container){
    if(content){
      if(!show)
      return container.offsetWidth<500 ? content.replace(/(([^\s]+\s\s*){15})(.*)/,"$1"):content.replace(/(([^\s]+\s\s*){35})(.*)/,"$1")//contentarray.splice(0,13).join(' ')
      else
      return content
    }
  }

  validateAlertsContent(content, container){
    const value  = content;
    if(value){
      return container.offsetWidth<500 ? value.replace(/(([^\s]+\s\s*){15})(.*)/,"$1").replace(/_/g, " "):value.replace(/(([^\s]+\s\s*){35})(.*)/,"$1").replace(/_/g, " ")
    }else
    return value
  }
  
  getChartFilter(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.name,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    event.select = !event.select;
    if(this.shared.alertsTitle=="Alerts")
    Commons.newdFeedsData = AlertsAndNews.alertNewsFeed;
    else
    Commons.newdFeedsData = MarketNews.marketNewsData;
     if (event.select) {
      this.selectedItems.push(event.name);
      Commons.newdFeedsData = Commons.newdFeedsData.filter(item => {
        if(this.selectedItems.includes(item.name)){
          return item;
        }
     })
    }
     else {
       const indexx = this.selectedItems.indexOf(event.name);
       this.selectedItems.splice(indexx, 1);
       Commons.newdFeedsData = Commons.newdFeedsData.filter(item => {
        if(this.selectedItems.includes(item.name)){
          return item;
        }
     })
  }
  if(this.selectedItems.length===0)
  {
    if(this.shared.alertsTitle== "Alerts")
    Commons.newdFeedsData = AlertsAndNews.alertNewsFeed;
    else
    Commons.newdFeedsData = MarketNews.marketNewsData;

  }
}

  ClickedOut(event) {
    if (event.target.className == "pg-form-pages login-page") {
      document.getElementById("close-news").click();
    }
  }

  onClick(event) {
    if (this.menu != undefined) {
      if (
        !this.menu.nativeElement.contains(event.target) &&
        this.showDropDownAddSetting
      ) {
        this.showAnimation = false;
        setTimeout(() => {
          this.showDropDownAddSetting = false;
        }, 500);
      }
    }
  }

  showDropDown() {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "newsfeed_filter",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if (!this.showAnimation) {
      setTimeout(() => {
        this.showDropDownAddSetting = true;
        setTimeout(() => {
          this.showAnimation = true;
        }, 500);
      }, 10);
    } else if (this.showAnimation) {
      this.showAnimation = false;
      setTimeout(() => {
        this.showDropDownAddSetting = false;
      }, 500);
    }
  }

  getMarketNewsFilter(event){
    let createdTime = new Date();
    let model = {
      "createdAt": createdTime,
      "eventName": event.name,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.addEvent(model);
    this.shared.newsFeedDataAvailable = true;
    this.shared.newsFeedLoading = true;
    event.select = !event.select;
    if(event.select){
      this.shared.selectedFilter.push(event.value);
    } else{
      //const index = this.shared.selectedFilter.findIndex(item => item  === event.value);
      this.shared.selectedFilter.forEach((ele, index)=> {
        if(ele == event.value){
          this.shared.selectedFilter.splice(index, 1); 
        }
      }) 
      //this.shared.selectedFilter.splice(index, 1); 
    }
    const startDate = this.datePipe.transform(localStorage.getItem('startDate'), 'yyyy-MM-dd');
    const endDate = this.datePipe.transform(localStorage.getItem('endDate'), 'yyyy-MM-dd');

    const modal = {
      "page": this.marketInsightService.paging,
      "startDate": startDate,
      "endDate": endDate,
      "type": this.shared.selectedFilter,
    }
    this.marketInsightService.getMarketInsight(modal).subscribe((data:any[])=>{
      this.shared.newsFeedLoading = false;
      if(data.length > 0){
        this.shared.newsFeedDataAvailable = true;
        data.forEach((ele)=>{
          ele.show = false;
          let newData;
          if(ele.Content){
             newData = ele.Content.replace("<p>", "").replace("<\/p>", "").replace("↵", "");
             ele.Content = newData;
          }
          let newDataDescription;
          if(ele.Description){
            newDataDescription = ele.Description.replace("<p>", "").replace("<\/p>", "").replace("↵", "");
            ele.Description = newDataDescription;
          }
        })
        this.shared.marketInsightData = data;
        this.shared.newsFeedsData = data;
      }
      else{
        this.shared.newsFeedDataAvailable = false;

      }
    },(err)=>{
      this.shared.newsFeedDataAvailable = false;
      this.shared.newsFeedLoading = false;

    })
  }

  close() {
    document.getElementById("close-news").click();
  }

  openDropDown() {
    document.getElementById("myDropdown").classList.toggle("show");
    document.getElementById("myDropdown");
  }

}
