import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AlertsData } from "src/utils/constants";
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { decode } from 'string-encode-decode';
import { CommonService } from 'src/services/CommonServices/common.service';
import { AlertsServiceService } from 'src/services/alerts-service/alerts-service.service';
import { FundsDataService } from 'src/services/ChartServices/funds-data.service';
import { PrefrenceSettingsService } from 'src/services/PreferencesServices/prefrence-settings.service';
import { AlertsNotificationsService } from 'src/services/alerts-notifications/alerts-notifications.service';
import { DatePipe } from '@angular/common';
import { apiError } from 'src/utils/common-function';
import { GoogleAnalyticsService } from 'src/services/google-Analytics/google-analytics.service';
import { NavigationEnd, Router } from '@angular/router';
import { Commons, Shared } from 'src/utils/common';

declare let gtag: Function;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class AlertModalComponent implements OnInit {
  @ViewChild('menu') menu: ElementRef;
  public default: boolean;
  public selectedOption: any;
  public marketData: any = [];
  public channelData: any = [];
  public marketUniverseDrop: any = [];
  public showAnimation: boolean;
  public showDropDown: boolean;
  public fundName: boolean;
  public category: any = [];
  public fundLoading: boolean;
  public myFundLoading: boolean;
  public isPressed: boolean;
  public savedAlertsData: any[] = [];
  public fundsData: any = { topFund: [], myFund: [], topfundAlerts: [], myFundAlerts: [], selectedTopFund: null, selectedMyFund: null };
  public newsData: any = { topFund: [], myFund: [], topfundAlerts: [], myFundAlerts: [], selectedTopFund: null, selectedMyFund: null };
  public selectedFundTopFund: any[] = [];
  public selectedFundMyFund: any[] = [];
  public selectedNewsTopFund: any[] = [];
  public selectedNewsMyFund: any[] = [];
  public selectedFundsAlerts: any[] = [];
  public selectedNewsAlerts: any[] = [];
  public topFundDataAvailable: boolean;
  public myFundDataAvailable: boolean;
  public alertedFunds: any = [];
  public fromAlerts: boolean;
  public isMyAlertsAvailable: boolean;
  public isMyAlertsDataAvailable: boolean;
  public myAlerts = {
    checked: false,
    isBestPassiveAvailable: false,
    isDataAvailable: false,
    isNew: true,
    name: "My Alerts"
  };
  userid: any;
  getAlertData: any;
  selectedCategory;
  fundId: any;
  marketName: any[] = [];
  channelName: any;
  mobileNumber: any;
  selectMarket: any;
  marketResName: any;
  cate: any;
  channel: any;
  channelSelect: any;
  phNo: any;
  selectedName: any;

  constructor(public http: HttpClient, public common: CommonService,
    public datePipe:DatePipe,
    public preferenceService: PrefrenceSettingsService, public alertsAndNotificationService: AlertsNotificationsService,
    public alertService: AlertsServiceService, public fundsService: FundsDataService ,
    public router: Router,
    public googleAnalyticsEventsService: GoogleAnalyticsService,
    public shared: Shared) {
    this.userid = decode(localStorage.getItem("ID"));
    this.isPressed = false;
    
  }


  topFundApi() {
    this.topFundDataAvailable = true;
    this.fundLoading = true;
    this.fromAlerts = false;
    // this.fundName = false;
    this.alertService.topFund(this.shared.selectCategory, this.datePipe.transform(localStorage.getItem('date'), 'yyyy-MM-dd')).subscribe(res => {
      this.fundsData.topFund = [];
      this.newsData.topFund = [];
      this.fundsData.topfundAlerts = [];
      this.fundsData.selectedTopFund = null;
      this.newsData.topfundAlerts = [];
      this.newsData.selectedTopFund = null;
      this.shared.topFunds = res.data.topData;
      // if(this.topFunds != null && this.topFunds != undefined){
      // this.onLoad();
      this.fundLoading = false;
      if (res.data.topData.length > 0) {
        this.topFundDataAvailable = true;
        // this.fundName = true;
        res.data.topData.forEach(element => {
          this.fundsData.topFund.push({
            Name: element.name_value, SecId: element.id, consistency: element.consistency, checked: false, alerts: [
              { name: "Fund Closure", select: false, disable: true },
              { name: "Fund Manager Change", select: false, disable: false, value: "['manager_added','historic_manager_change','manager_change']" },
              { name: "Fund Flows", select: false, disable: true, },
              { name: "Fund Name Change", select: false, disable: false, value: "fund_name_change" },
              // { name: "Changes in Funds Composition", select: false },
              { name: "Holdings Change", select: false, disable: false, value: "holdings_value_change_A" },
              { name: "Liquidation", select: false, disable: false, value: "liquidation" },
              { name: "Mergers", select: false, disable: false, value: "merger" },
              { name: "Re-classification of Funds", select: false, disable: true },
              // { name: "ESG News", select: false },
              { name: "Repayments", select: false, disable: false, value: "repayment" },
              { name: "Share Splits", select: false, disable: false, value: "share_split" },
              { name:"Top Holdings Change", select:false, disable:false, value:"top_10_holdings_change"}
            ], savedAlerts: []
          });
          this.newsData.topFund.push({
            Name: element.name_value, SecId: element.id, consistency: element.consistency, checked: false, alerts: [
              // { name: "General Fund News", select: false },
              // // { name: "General News", select: false },
              // // { name: "Fund Manager News", select: false },
              // {name:"FUNDSaiQ Curated News", select:false},
              // { name: "ESG News", select: false },
              // { name: "Social Media", select: false },
              { name: "ESG News", select: false },
              { name: "FUNDSaiQ Curated News", select: false },
              { name: "General Fund News", select: false },
              { name: "Social Media", select: false },
            ], savedAlerts: []
          });
        });
        setTimeout(() => {
          this.defaultValues();
        }, 200)

        this.getAlertApi();
        // this.selectValueFromMarketDrop(this.fundsData.topFund[0]);
        // this.selectNewsTopFund(this.newsData.topFund[0]);
        // this.fundsData.topFund = res.data.topData;
        // this.newsData.topFund = res.data.topData;
      } else {
        this.topFundDataAvailable = false;
      }
      // }
      // this.getAlertApi();
    }, (err) => {
      this.fundLoading = false;
      apiError(err);
    });
  }
  defSelectedCons:boolean;
  fiveConsitencyFunds = false;
  fourConsitencyFunds = false;
  threeConsitencyFunds = false;
  twoConsitencyFunds = false;
  oneConsitencyFunds = false;
  openConsistency(event){
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if(event == "5 Consistency"){
      if(this.fiveConsitencyFunds == false){
        this.fiveConsitencyFunds = true;
      }else{
        this.fiveConsitencyFunds = false;
        this.defSelectedCons = false;
      }
    }
    if(event == "4 Consistency"){
      if(this.fourConsitencyFunds == false){
        this.fourConsitencyFunds = true;
      }else{
        this.fourConsitencyFunds = false;
      }
    }
    if(event == "3 Consistency"){
      if(this.threeConsitencyFunds == false){
        this.threeConsitencyFunds = true;
      }else{
        this.threeConsitencyFunds = false;
      }
    }
    if(event == "2 Consistency"){
      if(this.twoConsitencyFunds == false){
        this.twoConsitencyFunds = true;
      }else{
        this.twoConsitencyFunds = false;
      }
    }
    if(event == "1 Consistency"){
      if(this.oneConsitencyFunds == false){
        this.oneConsitencyFunds = true;
      }else{
        this.oneConsitencyFunds = false;
      }
    }
    
  }
  defFiveCons:boolean;
  fiveConsFunds = false;
  fourConsFunds = false;
  threeConsFunds = false;
  twoConsFunds = false;
  oneConsFunds = false;
  openNewsConsistency(event){
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if(event == "5 Consistency"){
      if(this.fiveConsFunds == false){
        this.fiveConsFunds = true;
      }else{
        this.fiveConsFunds = false;
        this.defFiveCons = false;
      }
    }
    if(event == "4 Consistency"){
      if(this.fourConsFunds == false){
        this.fourConsFunds = true;
      }else{
        this.fourConsFunds = false;
      }
    }
    if(event == "3 Consistency"){
      if(this.threeConsFunds == false){
        this.threeConsFunds = true;
      }else{
        this.threeConsFunds = false;
      }
    }
    if(event == "2 Consistency"){
      if(this.twoConsFunds == false){
        this.twoConsFunds = true;
      }else{
        this.twoConsFunds = false;
      }
    }
    if(event == "1 Consistency"){
      if(this.oneConsFunds == false){
        this.oneConsFunds = true;
      }else{
        this.oneConsFunds = false;
      }
    }
    
  }

  onLoad() {
    this.shared.topFunds.forEach(element => {
      element.Index_Fund = false;
      this.shared.topFunds[2].Index_Fund = true;
    });
  }

  defaultValues() {
    this.marketName = [];
    if (this.fundsData.topFund.length > 0) {
      this.fundsData.selectedTopFund = this.fundsData.topFund[0].Name;
      this.fundsData.topfundAlerts = this.fundsData.topFund[0].alerts;
      this.newsData.selectedTopFund = this.newsData.topFund[0].Name;
      this.newsData.topfundAlerts = this.newsData.topFund[0].alerts;
      this.fundsData.topFund[0].checked = true;
      this.newsData.topFund[0].checked = true;
      this.selectedFundTopFund.push({ "FundId": this.fundsData.topFund[0].SecId, "FundName": this.fundsData.topFund[0].Name, "Alerts": this.fundsData.topFund[0].savedAlerts });
      this.selectedNewsTopFund.push({ "FundId": this.newsData.topFund[0].SecId, "FundName": this.newsData.topFund[0].Name, "Alerts": this.newsData.topFund[0].savedAlerts });
      this.marketData[0].select = true;
      this.marketName.push({ "Name": this.marketData[0].name });
    }
    if (this.fundsData.myFund.length > 0) {
      this.fundsData.selectedMyFund = this.fundsData.myFund[0].Name;
      this.fundsData.myFundAlerts = this.fundsData.myFund[0].alerts;
      this.newsData.myFundAlerts = this.newsData.myFund[0].alerts;
      this.newsData.selectedMyFund = this.newsData.myFund[0].Name;
      this.newsData.myFund[0].checked = true;
      this.fundsData.myFund[0].checked = true;
      this.selectedFundMyFund.push({ "FundId": this.fundsData.myFund[0].SecId, "FundName": this.fundsData.myFund[0].Name, "Alerts": this.fundsData.myFund[0].savedAlerts });
      this.selectedNewsMyFund.push({ "FundId": this.newsData.myFund[0].SecId, "FundName": this.newsData.myFund[0].Name, "Alerts": this.newsData.myFund[0].savedAlerts });
    }
  }
  preSettingId:any;
  preSettingName:any;
  saveAlertApi() {
    this.isPressed = true;
    this.selectedFundsAlerts = [];
    this.selectedNewsAlerts = [];
    this.fundsData.topFund.forEach((element) => {
      let ind = this.selectedFundTopFund.findIndex(item => item.FundId == element.SecId && element.checked == false)
        if(ind != -1){
          this.selectedFundTopFund.splice(ind, 1);
        }
    });
    this.selectedFundTopFund.forEach((ele) => {
      if (ele.Alerts.length > 0) {
        this.selectedFundsAlerts.push(ele)
      }
    })
    this.selectedFundMyFund.forEach((ele) => {
      if (ele.Alerts.length > 0) {
        this.selectedFundsAlerts.push(ele)
      }
    })
    this.selectedNewsTopFund.forEach((ele) => {
      if (ele.Alerts.length > 0) {
        this.selectedNewsAlerts.push(ele)
      }
    })
    this.selectedNewsMyFund.forEach((ele) => {
      if (ele.Alerts.length > 0) {
        this.selectedNewsAlerts.push(ele)
      }
    })
    
    if(this.shared.savedSettings){
       this.shared.savedSettings.forEach(ele => {
         if(this.shared.selectedPreSetting == ele.name){
           this.preSettingId = ele.id;
           this.preSettingName = ele.name;
         }
       })
    }else{
      this.preSettingId = "";
      this.preSettingName = "";
    }

    const details =
    {
      "UserId": decode(localStorage.getItem("ID")),
      "Category": this.selectedCategory,
      "Funds": this.selectedFundsAlerts,
      "News": this.selectedNewsAlerts,
      "Marketing": this.marketName,

      "Channel": [
        {
          "Name": this.channelName,
          "PhoneNumber": this.mobileNumber == null ? "" : this.mobileNumber
        }
      ],
      "PreferencesId": this.preSettingId,
      "PreferencesName": this.preSettingName
    }
    this.alertService.saveAlertSetting(details).subscribe(res => {
      this.isPressed = false;
      this.selectedFundsAlerts = [];
      this.selectedNewsAlerts = [];
      if (res != null || res != undefined) {
        this.getAlertApi();
        // getAlertsNotifications
        this.alertsAndNotificationService.getAlertsNotifications(decode(localStorage.getItem("ID")), 0);
      }
      document.getElementById("close-alert-modal").click();
    }, (err) => {
      this.isPressed = false;
      apiError(err);
    });
  }


  getAlertApi() {
    this.isMyAlertsAvailable = true;
    if(this.shared.savedSettings){
      this.shared.savedSettings.forEach(ele => {
        if(this.shared.selectedPreSetting == ele.name){
          this.preSettingId = ele.id;
        }
      })
   }else{
     this.preSettingId = "";
   }
    this.alertService.getAlertSetting(this.userid, this.selectedCategory, this.preSettingId).subscribe((res:any) => {
      this.savedAlertsData = [];
      this.getAlertData = res;
      const data = res["data"];
      this.isMyAlertsAvailable = false;
      if (data) {
        this.isMyAlertsDataAvailable = true;
        this.savedAlertsData = data;
         data.funds.forEach(element => {
          this.alertedFunds.push({checked: false, data: element});
         });
        if(this.fundsData.topFund.length > 0){
          this.selectFundsAlert(this.fundsData.topFund[0]);
        }
        if(this.fundsData.myFund.length > 0){
          this.selectFundsAlert(this.fundsData.myFund[0]);
        }
        if(this.newsData.topFund.length > 0){
          this.selectedNewsAlertsData(this.newsData.topFund[0]);
        }
        if(this.newsData.myFund.length > 0){
          this.selectedNewsAlertsData(this.newsData.myFund[0]);
        }
      } else {
        this.isMyAlertsDataAvailable = false;
        if(this.fundsData.topFund.length > 0){
          this.fundsData.topFund[0].checked = true;
          this.fundsData.selectedTopFund = this.fundsData.topFund[0].Name;
        }
        if(this.fundsData.myFund.length > 0){
          this.fundsData.myFund[0].checked = true;
          this.fundsData.selectedMyFund = this.fundsData.myFund[0].Name;
        }
        
        if(this.newsData.topFund.length > 0){
          this.newsData.topFund[0].checked = true;
          this.newsData.selectedTopFund = this.newsData.topFund[0].Name;
        }
        if(this.newsData.myFund.length > 0){
          this.newsData.myFund[0].checked = true;
          this.newsData.selectedMyFund = this.newsData.myFund[0].Name;
        }
      }
    }, (err) => {
      apiError(err);
    });
  }

  getMyFunds(res) {
    this.myFundDataAvailable = true;
    this.myFundLoading = true;
    this.fundsData.myFund = [];
    this.newsData.myFund = [];
    this.fundsData.myFundAlerts = [];
    this.fundsData.selectedMyFund = null;
    this.newsData.myFundAlerts = [];
    this.newsData.selectedMyFund = null;
    // this.fundsService.allFundsData(this.userid).subscribe((res) => {
      this.myFundLoading = false;
      const data = res["data"];
      if (data) {
        data.forEach(element => {
          this.myFundDataAvailable = true;
          this.fundsData.myFund.push({
            Name: element.Name, SecId: element.Id, checked: false, alerts: [
              { name: "Fund Closure", select: false, disable: true },
              { name: "Fund Manager Change", select: false, disable: false, value: "['manager_added','historic_manager_change','manager_change']" },
              { name: "Fund Flows", select: false, disable: true, },
              { name: "Fund Name Change", select: false, disable: false, value: "fund_name_change" },
              // { name: "Changes in Funds Composition", select: false },
              { name: "Holdings Change", select: false, disable: false, value: "holdings_value_change_A" },
              { name: "Liquidation", select: false, disable: false, value: "liquidation" },
              { name: "Mergers", select: false, disable: false, value: "merger" },
              { name: "Re-classification of Funds", select: false, disable: true },
              // { name: "ESG News", select: false },
              { name: "Repayments", select: false, disable: false, value: "repayment" },
              { name: "Share Splits", select: false, disable: false, value: "share_split" },
              { name:"Top Holdings Change", select:false, disable:false, value:"top_10_holdings_change"}
            ], savedAlerts: []
          });
          this.newsData.myFund.push({
            Name: element.Name, SecId: element.Id, checked: false, alerts: [
              { name: "ESG News", select: false },
              { name: "FUNDSaiQ Curated News", select: false },
              { name: "General Fund News", select: false },
              { name: "Social Media", select: false },
            ], savedAlerts: []
          });
        });
        setTimeout(() => {
          this.defaultValues();
        }, 200)
      } else {
        this.myFundDataAvailable = false;
      }
  }

  ngOnInit(): void {
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     gtag('config', 'UA-211001570-1', 
    //                {
    //                  'page_path': event.urlAfterRedirects + '#alertModal'
    //                }
    //               );
    //   }
    // });
    this.defSelectedCons = true;
    this.defFiveCons = true;
   
    this.shared.alertsSideOptions = AlertsData.sideOPtions;
    this.marketData = AlertsData.marketData;
    this.channelData = AlertsData.ChannelData;
    this.shared.alertsSideOptions[0].select = true;
    this.getCatgories();
    this.category = AlertsData.category;
    this.shared.changeCategory.subscribe(() => {
      this.getCatgories();
    })
    this.reset();
    this.fundName = false;
    this.selectChannel(this.channelData[1]);
    this.selectMarketData(this.marketData[0]);
    this.shared.alertSetupObservable.subscribe(()=>{
      const cate = localStorage.getItem("chartCategory");
      if(cate && cate != "undefined"){
        this.shared.selectCategory = localStorage.getItem("chartCategory");
        this.selectedCategory = localStorage.getItem("chartCategory");
        this.getAlertApi();
        this.topFundApi();
      } else{
        this.shared.selectCategory = "Market Universe";
      }
    })
    this.shared.myFundsDataObservable.subscribe((res)=>{
      this.getMyFunds(res);
    });
    }

  getCatgories() {
    this.marketUniverseDrop = this.shared.marketUniverData.sort((a, b) => (a.name < b.name ? -1 : 1));
    // let value = {
    //   checked: false,
    //   isBestPassiveAvailable: false,
    //   isDataAvailable: false,
    //   isNew: true,
    //   name: "My Alerts"
    // };
    //this.marketUniverseDrop.splice(0, 0, value);
    this.marketUniverseDrop.forEach(element => {
      if (element.name == this.shared.selectCategory) {
        this.shared.selectCategory = element.name;
        element.checked = true;
      } else {
        element.checked = false;
      }
    });
  }

  Apply() {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "alert_setup_apply",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.saveAlertApi();
  }

  ClickedOut(event) {
    if (event.target.className == "pg-form-pages login-page") {
      document.getElementById("close-alerts-popup").click();
    }
  }

  removeUnderscore(item) {
    if (item) {
      const value = item.replace(/_/g, " ");
      return value;
    }
  }


  selectOption(event) {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": event.name,
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if (event.name == "Fund" || event.name == "News") {
      this.fundName = true;
    }
    else {
      this.fundName = false;
    }
    this.selectedOption = event.name;
    this.default = false;
    this.shared.alertsSideOptions.forEach(element => {
      if (event.name == element.name) {
        element.select = true;
      } else {
        element.select = false;
      }
    });
  }

  showDrop() {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "show_market_universe",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if (!this.showAnimation) {
      setTimeout(() => {
        this.showDropDown = true;
        setTimeout(() => {
          this.showAnimation = true;
        }, 500);
        if(this.myAlerts.checked == false){
        this.marketUniverseDrop.forEach(element => {
          if (this.shared.selectCategory == element.name) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        });
        }
        // this.topFundApi();
      }, 10);
    } else if (this.showAnimation) {
      this.showAnimation = false;
      setTimeout(() => {
        this.showDropDown = false;
      }, 500);
    }
  }

  selectMyAlertedFund(event){
    event.checked = !event.checked;
    if(!event.checked){
      this.fundsData.topFund.forEach((element) => {
        if(event.SecId == element.SecId){
          element.alerts.forEach(ele => {
            ele.select = false;
          });
        }
      });
    }else{
      this.fundsData.topFund.forEach((element) => {
        if(event.SecId == element.SecId){
          element.alerts.forEach(ele => {
            if(element.savedAlerts.some(item => item.Name == ele.value)){
              ele.select = true;
            }
          });
        }
      });
    }
    if (this.savedAlertsData) {
      this.selectFundsAlert(event);
    }
    if(event.checked && event.savedAlerts.length > 0){
      if (!this.selectedFundTopFund.some((item) => item.FundName == event.Name)) {
        this.selectedFundTopFund.push({ "FundId": event.SecId, "FundName": event.Name, "Alerts": event.savedAlerts });
      }
    }else if(!event.checked){
      event.savedAlerts = [];
      event.alerts.forEach(element => {
        if(element.select){
          element.select = false;
        }
      });
    }
  }

  selectedAlerts:any = [];
  selectedTopFunds(event){
    if(this.fundsData.topFund.some(item => item.SecId == event.SecId)){
      this.fundsData.selectedTopFund = event.Name;
      this.savedAlertsData["funds"].forEach(element => {
        this.fundsData.topFund.forEach(ele => {
          if(element.Fund_Id == ele.SecId){
           ele.alerts.forEach(data => {
             if(element.Alert_Name == data.value){
               data.select = true;
             }
           });
          }
        });
      });
    }else if(this.fundsData.myFund.some(item => item.SecId == event.SecId)){
      this.fundsData.selectedMyFund = event.Name;
      this.fundsData.myFund.forEach(element => {
        if(event.SecId == element.SecId){
          element.alerts.forEach(ele => {
            if(element.savedAlerts.some(item => item.Name == ele.value)){
              ele.select = true;
            }
          });
        }
      });
    }
   }

  selectValueFromMarketDrop(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.name,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.selectCategory = event.name == "My Alerts" ? "Austria_Mid_Blend" : event.name;
    this.selectedCategory = event.name;
    event.checked = true;
    this.showAnimation = false;
    setTimeout(() => {
      this.showDropDown = false;
    }, 500);
    if(event.name == "My Alerts"){
      this.selectedName = event.name;
      let selectedIds: any = [];
      this.selectedAlerts = [];
      this.alertedFunds = [];
      this.fromAlerts = true;
      this.getAlertApi();
      this.marketUniverseDrop.forEach(element => {
        element.checked = false;
      });
      this.savedAlertsData["funds"].forEach(element => {
        if(selectedIds.length > 0 && selectedIds.includes(element.Fund_Id)){
        }else{
          selectedIds.push(element.Fund_Id);
        }
      });
        this.fundsData.topFund.forEach(ele => {
          if(selectedIds.includes(ele.SecId)){
            let checked = selectedIds.includes(ele.SecId);
            this.selectedAlerts = ele.alerts;
            this.alertedFunds.push({ Name: ele.Name, SecId: ele.SecId, checked: checked, alerts: ele.alerts });
          }
        });
        this.fundsData.myFund.forEach(ele => {
          if(selectedIds.includes(ele.SecId)){
            let checked = selectedIds.includes(ele.SecId);
            this.selectedAlerts = ele.alerts;
            this.alertedFunds.push({ Name: ele.Name, SecId: ele.SecId,  checked: checked, alerts: ele.alerts });
          }
        });
    }else{
      this.fromAlerts = false;
      this.myAlerts.checked = false;
      this.marketUniverseDrop.forEach(element => {
        if (event.name == element.name) {
          element.checked = true;
          this.selectedCategory = element.name;
          this.topFundApi();
        } else {
          element.checked = false;
        }
      });
    }
    this.cate = this.getAlertData.data.category;
    this.marketResName = this.getAlertData.data.market[0]?.Name;
    this.channel = this.getAlertData.data.channel[0].Name;
    this.phNo = this.getAlertData.data.channel[0].Phone_Number;
    if (event.checked == true) {
      if (this.shared.selectCategory == this.cate) {
        this.selectMarket = true;
        this.channelSelect = true;
        this.mobileNumber = this.phNo;
        this.marketData.forEach(ele => {
          if (ele.name == this.marketResName) {
            ele.checked = this.selectMarket;
          }
        });
        this.channelData.forEach(ele => {
          if (ele.name == this.channel) {
            ele.checked = this.channelSelect;
          }
        });
      }
    }
  }


  selectFundTopFund(event) {
    event.checked = !event.checked;
    if (this.savedAlertsData) {
    this.selectFundsAlert(event);
    }
    if(event.checked && event.savedAlerts.length > 0){
      if (!this.selectedFundTopFund.some((item) => item.FundName == event.Name)) {
        this.selectedFundTopFund.push({ "FundId": event.SecId, "FundName": event.Name, "Alerts": event.savedAlerts });
      }
    }
    this.fundsData.topfundAlerts = event.alerts;
    if(!event.checked){
      this.fundsData.selectedTopFund = "";
      this.fundsData.topFund.forEach((element) => {
        if(event.SecId == element.SecId){
          element.alerts.forEach(ele => {
            ele.select = false;
          });
        }
      });
    }else{
      this.fundsData.selectedTopFund = event.Name;
      this.fundsData.topFund.forEach((element) => {
        if(event.SecId == element.SecId){
          element.alerts.forEach(ele => {
            if(element.savedAlerts.some(item => item.Name == ele.value)){
              ele.select = true;
            }
          });
        }
      });
    }
    // this.fundsData.topFund.forEach(element => {
    //   if (element.Name == event.Name) {
    //     element.checked = true;
    //   } else {
    //     element.checked = false;
    //   }
    // });
  }


  selectFundMyFund(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.Name,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if (this.savedAlertsData) {
    this.selectFundsAlert(event);
    }

    if (!this.selectedFundMyFund.some((item) => item.FundName == event.Name)) {
      this.selectedFundMyFund.push({ "FundId": event.SecId, "FundName": event.Name, "Alerts": event.savedAlerts });
    }

    this.fundsData.selectedMyFund = event.Name;
    this.fundsData.myFundAlerts = event.alerts;
    this.fundsData.myFund.forEach(element => {
      if (element.Name == event.Name) {
        element.checked = true;
      } else {
        element.checked = false;
      }
    });
  }

  selectFundsAlert(event) {
    // event.alerts.map(a => a.select = false);

    // this.fundName = true;
    const saved: any[] = [];
    if (this.savedAlertsData) {
      // let fundsData = this.savedAlertsData["funds"]
      const fundsData = this.savedAlertsData["funds"];
      if(fundsData && fundsData.length > 0){
        fundsData.forEach((ele) => {
        if (ele.Fund_Id == event.SecId) {
          saved.push(ele.Alert_Name);
        }
      })

      event.alerts.forEach(element => {

        let data: any[] = [];
        if (element.value) {
          data = [];
          let use: any;
          const json = element.value.replace(/'/g, '"');
          if (json.includes("[")) {
            use = JSON.parse(json);
            data.push(use);
            use.forEach(ele => {
              const value = saved.includes(ele);
              if (value) {
                element.select = true;
              }
            });
          } else {
            data.push(json);
            const value = saved.includes(element.value);
            if (value) {
              element.select = true;
            }
          }

        }
      });
      }
    }
  }

  selectNewsTopFund(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.Name,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.selectedNewsAlertsData(event);
    this.newsData.selectedTopFund = event.Name;
    if (!this.selectedNewsTopFund.some((item) => item.FundName == event.Name)) {
      this.selectedNewsTopFund.push({ "FundId": event.SecId, "FundName": event.Name, "Alerts": event.savedAlerts });
    }
    this.newsData.topfundAlerts = event.alerts;
    this.newsData.topFund.forEach(element => {
      if (element.Name == event.Name) {
        element.checked = true;
      } else {
        element.checked = false;
      }
    });
  }

  selectNewsMyFund(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.Name,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.selectedNewsAlertsData(event);
    this.newsData.selectedMyFund = event.Name;
    if (!this.selectedNewsMyFund.some((item) => item.FundName == event.Name)) {
      this.selectedNewsMyFund.push({ "FundId": event.SecId, "FundName": event.Name, "Alerts": event.savedAlerts });
    }
    this.newsData.myFundAlerts = event.alerts;
    this.newsData.myFund.forEach(element => {
      if (element.Name == event.Name) {
        element.checked = true;
      } else {
        element.checked = false;
      }
    });
  }

  selectedNewsAlertsData(event) {
    const saved: any[] = [];
    if (this.savedAlertsData) {
        const newsData = this.savedAlertsData["news"];
        if(newsData && newsData.length > 0){
        newsData.forEach((ele) => {
        if (ele.Fund_Id == event.SecId) {
          saved.push(ele.Alert_Name);
        }
      })

      event.alerts.forEach(element => {
        const value = saved.includes(element.name);
        if (value) {
          element.select = true;
        }
      });
    }
  }
  }

  selectFundTopFundAlerts(event, fundName) {

let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.name,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    event.select = !event.select;
    if (event.select) {
      this.fundsData.topFund.forEach(element => {
        if (element.Name == fundName) {
          element.savedAlerts.push({ "Name": event.value });
        }
      });
    }
    if (!event.select) {
      this.fundsData.topFund.forEach((element) => {
        if (element.Name == fundName) {
          const index = element.savedAlerts.map(function (e) { return e.Name; }).indexOf(event.name);
          if (index !== -1) {
            element.savedAlerts.splice(index, 1);
          }
        }
      });
    }
  }

  selectFundMyFundAlert(event, fundName) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.name,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    event.select = !event.select;
    if (event.select) {
      this.fundsData.myFund.forEach(element => {
        if (element.Name == fundName) {
          element.savedAlerts.push({ "Name": event.value });
        }
      });
    }
    if (!event.select) {
      this.fundsData.myFund.forEach((element) => {
        if (element.Name == fundName) {
          const index = element.savedAlerts.map(function (e) { return e.Name; }).indexOf(event.name);

          if (index !== -1) {
            element.savedAlerts.splice(index, 1);
          }
        }
      });
    }
  }

  selectNewsTopFundAlerts(event, fundName) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.name,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    event.select = !event.select;
    if (event.select) {
      this.newsData.topFund.forEach(element => {
        if (element.Name == fundName) {
          element.savedAlerts.push({ "Name": event.name });
        }
      });
    }
    if (!event.select) {
      this.newsData.topFund.forEach((element) => {
        if (element.Name == fundName) {
          const index = element.savedAlerts.map(function (e) { return e.Name; }).indexOf(event.name);
  
          if (index !== -1) {
            element.savedAlerts.splice(index, 1);
          }
        }
      });
    }
  }

  selectNewsMyFundAlerts(event, fundName) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.name,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    event.select = !event.select;
    if (event.select) {
      this.newsData.myFund.forEach(element => {
        if (element.Name == fundName) {
          element.savedAlerts.push({ "Name": event.name });
        }
      });
    }
    if (!event.select) {
      this.newsData.myFund.forEach((element) => {
        if (element.Name == fundName) {
          const index = element.savedAlerts.map(function (e) { return e.Name; }).indexOf(event.name);

          if (index !== -1) {
            element.savedAlerts.splice(index, 1);
          }
        }
      });
    }
  }


  selectChannel(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.name,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    event.select = !event.select;
    this.channelName = event.name;

  }

  selectMarketData(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.name,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    event.select = !event.select;
    if (event.select == true) {
      if (!this.marketName.some((item) => item.Name == event.name)) {
        this.marketName.push({ "Name": event.name });
      }
    }
    if (!event.select) {
      const index = this.marketName.map(function (e) { return e.Name; }).indexOf(event.name);
      if (index !== -1) {
        this.marketName.splice(index, 1);
      }
    }
  
  }


  onClick(event) {
    if (this.menu != undefined) {
      if (!this.menu.nativeElement.contains(event.target) && this.showDropDown) {
        this.showAnimation = false;
        setTimeout(() => {
          this.showDropDown = false;
        }, 500)
      }
    }
  }

  reset() {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "alert_setup_clear_all_filter",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if(!Commons.settingCategory){
     this.marketUniverseDrop.forEach(element => {
       element.checked = false;
     });
    }else{
      this.marketUniverseDrop.forEach(element => {
        if(element.name == Commons.settingCategory){
          element.checked = true;
          this.shared.selectCategory = Commons.settingCategory;
          this.selectedCategory = Commons.settingCategory;
          this.getAlertApi();
        }else{
          element.checked = false;
        }
      });
    }
    if(this.getAlertData?.message == "Data Not Found!"){
      if(this.fundsData.topFund.length > 0){
        this.fundsData.topFund[0].checked = true;
        this.fundsData.selectedTopFund = this.fundsData.topFund[0].Name;
      }
      if(this.fundsData.myFund.length > 0){
        this.fundsData.myFund.forEach((element, Index) => {
          if(Index == 0){
            element.checked = true;
            this.fundsData.selectedMyFund = element.Name;
          }else{
            element.checked = false;
          }
        });
      }
      if(this.fundsData.myFundAlerts.length > 0){
        this.fundsData.myFundAlerts.forEach(element => {
          element.select = false;
        });
      }
      if(this.newsData.topFund.length > 0){
        this.newsData.topFund[0].checked = true;
        this.newsData.selectedTopFund = this.newsData.topFund[0].Name;
      }
      if(this.newsData.myFund.length > 0){
        this.newsData.myFund.forEach((element, index) => {
          if(index == 0){
            element.checked = true;
            this.newsData.selectedMyFund = element.Name;
          }else{
            element.checked = false;
          }
        });
      }
      if(this.newsData.myFundAlerts.length > 0){
        this.newsData.myFundAlerts.forEach(element => {
          element.select = false;
        });
      }
      this.marketData.forEach(element => {
        if(element.name == "Marketing"){
          element.select = true;
        }else{
          element.select = false;
        } 
      });
      this.channelData[1].select = true;
      this.channelName = this.channelData[1].name;
    }else{
    }
    this.selectedOption = "";
    this.default = true;
    this.fundName = false;
    this.shared.alertsSideOptions.forEach(element => {
      element.select = false;
    });
  }


  cancel() {

  }
}
