import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ForgetPasswordService } from 'src/services/AuthServices/forget-password.service';
import { MnFullpageService } from 'ngx-fullpage';

@Component({
    selector: 'app-reset',
    templateUrl: './reset.component.html',
    styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {
    password: any;
    confirmPassword: any;
    id: any;
    errorMsg: any;
    isPressed: boolean;
    valid = false;
    loginPress:boolean;
    passwordValid = true;
    confirmPasswordValid = true;
    errorMsgPassword:any;
    constructor(
            private route: ActivatedRoute,
            private router: Router,
            public fullpageService: MnFullpageService,
            public resetPwd: ForgetPasswordService
    ) {
    }

    ngOnInit() {
        document.getElementById("showBlock").click();
        const data = this.route
            .queryParams
            .subscribe(params => {
                this.id = params.ID;
            });
    }

    onPasswordInput(event) { 
        this.errorMsg = "";
        this.errorMsgPassword = "";      
        this.passwordValid = true;
    }

    goToLogin(){
        this.router.navigateByUrl('/login');
    }

    onConfirmPasswordInput(event) {
        this.errorMsg = "";
        this.errorMsgPassword = "";      
        this.confirmPasswordValid = true;
    }

    resetPassword() {
        this.isPressed = true;
        const user = {
            "NewPassword": this.password,
            "ConfirmPassword": this.confirmPassword,
            "id": this.id
        }
        if (this.password == undefined || this.password == "") {
            this.passwordValid = false;
            this.isPressed = false;
            this.errorMsgPassword = "Please enter password";
            return
          }
          if (this.confirmPassword == undefined || this.confirmPassword == "") {
            this.confirmPasswordValid = false;
            this.isPressed = false;
            this.errorMsg = "Please enter confirm password";
            return
          }
          else{
              if(this.password != this.confirmPassword){
                this.passwordValid = false;
                this.confirmPasswordValid = false;
                this.isPressed = false;
                this.errorMsg = "Password not match";
              } else{
                this.resetPwd.resetPassword(user).subscribe((res) => {
            this.isPressed = false;
            if(res['message'] == "Password Reset Successfully!"){
                this.router.navigateByUrl('/user-dashboard');
            }
        }, (err) => {
            this.isPressed = false;
        })
              }
          }

    }


}
