import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Commons, Shared } from 'src/utils/common';
import { apiError } from 'src/utils/common-function';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class FundDocumentaionService {

  constructor(public http: HttpClient, public shared: Shared) { }

  getFundDocs(category){
   if(category != null && category != "undefined"){
      this.shared.fundDocumentLoading = true;
      this.shared.isDataAvailable = true;
      return  this.http.post(environment.baseUrl + 'getFundsDocument/' + category, Commons.topFundSecId, httpOptions).subscribe((res) => {
        if(res["data"]){
          this.shared.apiData = res["data"];
          this.shared.isDataAvailable = true;
          this.getConsistencyData();
        }else{
          this.shared.isDataAvailable = false;
        }
        this.shared.fundDocumentLoading = false;
      }, error =>{
        this.shared.fundDocumentLoading = false;
        this.shared.isDataAvailable = false;
        apiError(error);
      });
    }else{
    }
  }

  getConsistencyData(){
    this.shared.isDataAvailable = true;
    this.shared.fundDocumentationData = [];
    this.shared.apiData.forEach((ele)=>{
      if(Commons.consistencyFundId.includes(ele.Id)){
        this.shared.fundDocumentationData.push(ele);
      }
    })
    if(this.shared.fundDocumentationData.length == 0){
      this.shared.isDataAvailable = false;
    }
  }

}
