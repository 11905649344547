import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FundAlertsService {

  constructor(public http: HttpClient) { }

  getFundAlerts(fundId){
    return  this.http.get(environment.baseUrl + 'getFundAlerts/' + fundId);
}
}
