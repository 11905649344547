import { Component, OnInit, ViewChild } from '@angular/core';
import { ProfileData } from "src/utils/constants";
import { FormBuilder } from '@angular/forms';
import { ForgetPasswordService } from 'src/services/AuthServices/forget-password.service';
import { PrefrenceSettingsService } from 'src/services/PreferencesServices/prefrence-settings.service';
import { decode } from 'string-encode-decode';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { OrganizationComponent } from '../organization/organization.component';
import { ProfileService } from 'src/services/profile/profile.service';
import { CommonService } from 'src/services/CommonServices/common.service';
import { GoogleAnalyticsService } from 'src/services/google-Analytics/google-analytics.service';
import { Commons, Shared } from 'src/utils/common';

@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss']
})
export class ProfileModalComponent implements OnInit {
  @ViewChild('organisationModal') organisationModal: OrganizationComponent;
  @ViewChild('userDetail') userDetail: UserProfileComponent;
  userId: any;
  userEmail:any;
  userDetails:any;
  constructor(public fb: FormBuilder, public forgetPass: ForgetPasswordService,
    public profileService:ProfileService,
    public prefrenceSetting: PrefrenceSettingsService,
    public common: CommonService,
    public googleAnalyticsEventsService: GoogleAnalyticsService,
    public shared: Shared) {
  }

  ngOnInit(): void {
    this.profileService.isPressed = false; 
    this.userId = decode(localStorage.getItem("ID"));
    this.shared.profileSideOptions = ProfileData.sideOptions;
    if(this.shared.showOrganisation){
    this.shared.profileSideOptions[2].select = true;
    this.shared.selectedOption = this.shared.profileSideOptions[2].name;
    }else{
      this.shared.profileSideOptions[0].select = true;
      this.shared.selectedOption = this.shared.profileSideOptions[0].name;
    }
    this.shared.userOptions = ProfileData.userOptions;
    this.shared.securityOptions = ProfileData.security;
    this.shared.organizationOptions = ProfileData.organization;

  }

  ClickedOut(event) {
    if (event.target.className == "pg-form-pages login-page") {
      document.getElementById("close-profile-popup").click();
    }
  }

  updateProfile() {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "update",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.profileSideOptions.forEach(ele => {
      if (ele.name == "User" && ele.select == true) {
        this.userDetail.updateUserDetails();
      }
      if (ele.name == "Organisation" && ele.select == true) {
        this.organisationModal.updateOrganisationModal();
      }
    
    });
  }

  changePassword() {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "change_password",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.resetLoader = true;
    this.shared.sendResetLink = false;
    this.userEmail = localStorage.getItem("userName");
    if (this.userEmail != null && this.userEmail != undefined) {
      this.forgetPass.sendRequestForResetPassword(this.userEmail).subscribe((res) => {
        this.shared.resetLoader = false;
        document.getElementById("reset-link").click();
        setTimeout(() => {
          document.getElementById("close").click();
        }, 5000);
      }, (err) => {
        this.shared.resetLoader = false;
      })
    }
  }

  selectSideOption(item) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": item.name,
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.selectedOption = item.name;
    this.shared.profileSideOptions.forEach(element => {
      if (element.name === item.name) {
        element.select = true;
      } else {
        element.select = false;
      }
    });
  }

  reset() {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "alert_setup_clear_all_filter",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.clear();
  }

  cancel() {
    this.clear();
  }

  clear() {
  }

  ClickedOutSide(event) {
    if (event.target.className === "pg-form-pages login-page") {
      document.getElementById("close").click();
    }
  }

}
