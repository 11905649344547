import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "src/services/CommonServices/common.service";
import { encode } from "string-encode-decode";
import { UUID } from "angular2-uuid";
import { CookieService } from "ngx-cookie-service";
import { LoginService } from "src/services/AuthServices/login.service";
import { environment } from "src/environments/environment";
import { Shared } from "src/utils/common";

declare let ga: Function;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {
  userEmail: any;
  Password: string;
  termCond: boolean;
  isPressed = false;
  errorMsg: any;
  emailValid = true;
  passwordValid = true;
  acceptConditionValue = false;
  termsAccepted = true;
  uuidValue: any;
  termsAgree = true;
  loginPress = true;
  showPassword: boolean;
  platform: any;
  constructor(
    public http: HttpClient,
    public cookieService: CookieService,
    public userLoginService: LoginService,
    public router: Router,
    public common: CommonService,
    public shared: Shared
  ) {
    this.loginPress = true;
    const check = cookieService.check("browserToken");
    if (check == false) {
      this.uuidValue = UUID.UUID();
      this.cookieService.set("browserToken", this.uuidValue);
    } else {
      this.uuidValue = this.cookieService.get("browserToken");
    }
    this.showPassword = false;
    this.isPressed = false;
    const user = localStorage.getItem("activeRaoter");
    // localStorage.setItem("login", "0");
    localStorage.removeItem("userId");
    // localStorage.setItem('userId', '123');
    localStorage.removeItem("ID");
  }

  loginChange() {
    this.router.navigateByUrl("/changePassword");
  }


  reset() {
    this.router.navigateByUrl('/reset-component');
  }

  onEmailInput(event) {
    this.userEmail = event.target.value;
    this.emailValid = true;
    this.errorMsg = null;
  }

  showPasswords() {
    this.showPassword = true;
  }

  hidePassword() {
    this.showPassword = false;
  }


  onPasswordInput(event) {
    this.Password = event.target.value;
    this.passwordValid = true;
    this.errorMsg = null;
  }


  login() {
    this.isPressed = true;
    this.passwordValid = true;
    this.emailValid = true;
    const userDetail: any = {
      email: this.userEmail,
      password: this.Password,
      token: this.uuidValue,
    };
    if (this.userEmail) {
      this.emailValid = true;
    } else {
      this.emailValid = false;
      this.isPressed = false;
    }

    if (this.Password) {
      this.passwordValid = true;
    } else {
      this.passwordValid = false;
      this.isPressed = false;
    }

    if (this.userEmail && this.Password) {
      localStorage.setItem("userName", this.userEmail);
      localStorage.setItem("userPassword", this.Password);
      localStorage.setItem("uuidValue", this.uuidValue);
      if (!this.termsAgree) {
        if (!this.acceptConditionValue) {
          this.isPressed = false;
          this.termsAccepted = false;
        } else {
          this.agreeTermsApi();

        }
      }
      else if (this.termsAgree) {
        this.userLogin(userDetail);
      }
    }
  }

  userLogin(userDetail) {
    this.userLoginService.userLogin(userDetail).subscribe((res: any) => {
      // localStorage(token)
      // localStorage.setItem("userToken", encode(res["data"].token));
      if(res){
      }else{
        localStorage.removeItem("userId");
      }
      this.apiRes(res);
      localStorage.setItem("currentUserDetail", JSON.stringify(res["data"]));
      if(environment.baseUrl == "https://qapi.fundsaiq.com/" ){
        this.platform = "QA";
      }else{
        this.platform = "PROD";
      }
      localStorage.setItem("platform", this.platform);
    }, (err) => {
      this.isPressed = false;
    });
  }

  agreeTermsApi() {
    const data = {
      "userid": this.shared.userId,
      "timestamp": Date.now(),
      "email": localStorage.getItem("userName")
    }
    this.userLoginService
      .acceptTerms(data)
      .subscribe((res: any) => {
        this.apiRes(res);
        localStorage.setItem("currentUserDetail", JSON.stringify(res["data"]));
      }, (err) => {
        this.isPressed = false;
      });
  }

  goToCodeVerificationAfterAcceptTerms(res) {
    // console.log('res["data"].userID',res["data"].userID);
    this.isPressed = false;
    if (res["data"].browser == true) {
      // localStorage.setItem("login", "1");
      this.loginPress = false;
      localStorage.setItem('userId', res["data"].userID);
      this.shared.isLogin = true;
      this.shared.userId = res["data"].userID;
      localStorage.setItem("ID", encode(res["data"].userID));
      localStorage.setItem("currentUserDetail", JSON.stringify(res["data"]));
      this.router.navigateByUrl("/user-dashboard");
      ga('create', 'UA-211001570-1', {
        'storage': 'none',
        'clientId': this.shared.userId
      });
      ga('send', 'pageview');
    } else {
      document.getElementById("openVerfication").click();
    }
    // console.log('decode(localStorage.getItem("ID"))',decode(localStorage.getItem("ID")));
  }

  goToCodeVerificationAfterLogin(res) {
    this.isPressed = false;
    this.shared.userId = res["data"].userID;
    if (res["data"].IsTermsAgreed == true) {
      this.termsAgree = res["data"].IsTermsAgreed;
      this.goToCodeVerificationAfterAcceptTerms(res)
    } else if (res["data"].IsTermsAgreed == false || res["data"].IsTermsAgreed == null) {
      this.termsAgree = res["data"].IsTermsAgreed;
      ga('create', 'UA-211001570-1', {
        'storage': 'none',
        'clientId': this.shared.userId
      });
      ga('send', 'pageview');
    }
  }

  apiRes(res) {
    if (
      res.message == "User Not Found, Please check your username/password"
    ) {
      this.emailValid = false;
      this.passwordValid = false;
      this.isPressed = false;
      this.errorMsg = "Please enter valid email and password";
    }
    else if (res.message == "Password Incorrect!") {
      // this.emailValid = false;
      this.passwordValid = false;
      this.isPressed = false;
      this.errorMsg = "Please enter valid password";
    }
    else if (
      res.message ==
      "Email address is not verified, please check your mail to verify."
    ) {
      this.isPressed = false;
      alert(res.message);
    }
    else if (res.message == "Code Send to Mail") {
      this.goToCodeVerificationAfterLogin(res);
    } else if (res.message == "User Agreed with Terms and Conditions Successfully!") {
      this.isPressed = false;
      if (res["data"]) {
        this.goToCodeVerificationAfterAcceptTerms(res);
      }
      else {
        document.getElementById("openVerfication").click();
      }
    }
  }

  ngOnInit() {
    localStorage.setItem("activeRaoter", "home");
  }

  acceptCondition(value) {
    this.termsAccepted = true;
    this.acceptConditionValue = value;
  }

}
