import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class Commons {
  static currentPass: any;
  static newPass: any;
  static userName:any;
  static firstName:any;
  static surname:any;
  static primaryEmail:any;
  static secondaryEmail:any;
  static mobile:any;
  static companyName:any;
  static role:any;
  static location:any;
  static investorType:any;
  static investmentPref:any;
  static investStyle:any;
  static confirmPass: any;
  static selectedTab:any;
  static sessionId: any;
  static userDetails: any;
  static platform: any;
  static date:any;
  static actDate:any;
  static settingCategory:any;
  static selectedTotalYear:any;
  static selectedHiddenGem:any;
  static riskYAxisMin:any;
  static riskYAxisMax:any;
  static EsgRattingData:any;
  static RefESGRattingData:any;
  static riskXAxisMin:any;
  static riskXAxisMax:any;
  static selectedDate: any;
  static myFundsDataApis: any;
  static chartHeightCollapseView: any;
  static drawRiskChart: any;
  static newdFeedsData: any[] = [];
  static ESGDataIncludeExclude:any[]=[];
  static selectedFundSize: any[] = [];
  static bestPassiveTopFunds: any[] = [];
  static ESGRatting:any[]=[];
  static preferenceSelectedDataESG:any[]=[];
  static consistencyData:any = [];
  static topFundSecId:any[]=[];
  static bestPassiveData:any[]=[];
  static bestPassiveIds:any[]=[];
  static consistencyFundId:any[]=[];
  static myFund:any[]=[];
  static fundsShareClassId: any = [];
  static geographyData: any = [];
  static geographyFunds: any = [];
  static geographyCountries: any = [];
  static tempData: any = [];
  static selectedScore :any = [5];
  static consistencyTopFund:any = [];
  static selectedConsistency:any = [];
  static fundIds:any = [];
  static previousDateChange: boolean;
  static userDashboardshow:boolean;
  static addViewEnable:boolean;
  static bestPassiveApplied:boolean;
  static riskChartLoading:boolean;
  static riskChartLoader: boolean;
  static isShareClassData:any = { topData: []};
  static resizeCall:any=0;
  static topFundsCallTime = 0;
  static activePassiveCallTime = 0;
  static consistencyObTopFund:any=0;
  static consistencyObActivePassive:any=0;
  static consistencyObRiskReward:any=0;
  static consistencyObRatingView:any=0;
  static bestPassivesData = 0;
  static previousDates = 1;
  static count = 0;
  static allcolor = [
    "#AB036E",
    "#C71D39",
    "#FF5734",
    "#FFC302",
    "#99FF66",
    "#66CCCC",
    "#1F639B",
    "#1F639B",
    "#173F5F",
  ];
  
  static consistencyScoreChange: Subject<any> = new Subject<any>();
  static consistencyChange: Subject<any> = new Subject<any>();
  static changeSettingObs: Subject<any> = new Subject<any>();
  static toggleShareClass: Subject<any> = new Subject<any>();
  static changeBestPassives: Subject<any> = new Subject<any>();
  static myFundsChart: Subject<any> = new Subject<any>();
  static bestPassiveObeservable: Subject<any> = new Subject<any>();
}

@Injectable()
export class Shared {
   // any type
   public alertsTitle: any;
   public chartFilterNews: any;
   public newsTableHeading: any;
   public filterTitle: any;
   public placeHolder: any;
   public legendOneYear: any;
   public legendThreeYear: any;
   public legenedFiveYear: any;
   public chartWidthCollapseView: any;
   public paging: any;
   public riskChartGetData:any;
   public topFundChartHeight: any;
   public topFundChartWidth: any;
   public userId: any;
   public selectedYears: any;
   public selectedCategoryMarket: any;
   public colorToPushIndex: any;
   public topFundCategoryData: any;
   public fiveYearRiskRewNew: any;
   public weightTotalReturn: any;
   public weightSTD: any;
   public getCurrency:any;
   public getGraphDivHeight: any;
   public getGraphDivWidth: any;
   public riskGraphHeight: any;
   public riskGraphWidth: any;
   public activePassiveGraphHeight: any;
   public fundName: any;
   public topFundzIndex: any;
   public graphHeighthData: any;
   public graphWidthData: any;
   public navHeight: any;
   public topFunddChartHeight: any;
   public topFunddChartWidth: any;
   public tableViewName: any;
   public fundSize:any;
   public isObservableCall:any;
   public savedCurrency:any;
   public xAxisData:any;
   public alterSettingId:any;
   public settingId: any;
   public selectCategory:any;
   public selectedPreSetting: any;
   public preferenceSettings:any;
   public diamonds: any;
   public chartTest: any;
   public alertsDataTopFund: any;
   public colorTopFundPushIndex:any;
   public selectedCategoryTopFund: any;
   public alertsDataRiskChart: any;
   public newsFundDataRiskChart: any;
   public totalFiveYearRisk: any;
   public selectedOption: any;
   public organisationDetails: any;
   public newsFundDataGrowthView:any;
   public selectedCategoryGrowthView: any;
   public timePeriod: any;
   public fundNameGrowthView:any;
   public alertsDataGrowthView:any;
   public selectedNewsDate: any;
   public selectedDropDown: any;
   public itemSetting: any;
   public selectedOptPreferences: any;
   public selectedSettingData:any;
   public filterGeographyInput:any;
   public selectedSettingId:any;
   public changeRebatesNumber: any;
   public tempSelectedSetting:any;
   public filterInput: any;
   public searchFilter: any;
   public activeSubCat: any;
   public addNewSetting: any;
   public rebatesSelectedIndex: any;
   public previousSavedSetting: any;
   public esgAllData:any;
   public selectedOpt: any;
   public searchMarketData: any;
   public selectedDates: any;
   public selectedIncludeESGChartFilter: any;
   public selectedExcludeESGChartFilter: any;
   public displayFundSize: any;
   public selectedDateChartFilter: any;
   public newsFundDataTopFund: any;
   public view: any;
   public regionOfSale = 'Europe';

   // variables with predefined values
   public selectedItems = "Austria_Mid_Blend";
   public searchMyFund = "";
   public selectedCurrency:any = "EUR";
   public selectedAssteClass:any = "Equity";
   public searchMyFundPreferences = "";
   public popupTimer: number = 3;

   // null type
   public riskChartDraw: any = null;

   // array type
   public topFundNewsFeedData: any[] = [];
   public marketboxDataChartFilter = [];
   public esgDataChartFilter: any[] = [];
   public marketUniverses: any[] = [];
   public selectedDataChartFilter: any = [];
   public selectedBestPassiveChartFilter: any = [];
   public selectedExcludeESG: any[] = [];
   public selectedIncludeESG: any[] = [];
   public selectedOptionData: any[] = [];
   public prefrenceSettings: any[] = [];
   public selectedFunds: any = [];
   public fundsSecIds: any[] = [];
   public topFundData:any [] = [];
   public ratingViewArray: any = [];
   public activePassive_Y_yearly1: any[] = [];
   public activePassive_Y_yearly3: any[] = [];
   public activePassive_Y_yearly5: any[] = [];
   public activePassive_Y_percenties_1yr: any[] = [];
   public activePassive_Y_percenties_3yr: any[] = [];
   public activePassive_Y_percenties_5yr: any[] = [];
   public msRiskRewardData = [];
   public rar3items: any[];
   public rar5items: any[];
   public activePassivehighchartDataFor1Yr: any = [];
   public activePassivehighchartDataFor3Yr: any = [];
   public activePassivehighchartDataFor5Yr: any = [];
   public activePassiveHighchartDataFor_yearly1: any = [];
   public activePassiveHighchartDataFor_yearly3: any = [];
   public activePassiveHighchartDataFor_yearly5: any = [];
   public esgSelectedData: any[] = [];
   public selectedSelectColum: any[] = [];
   public selectedBestPassive: any[] = [];
   public rar1items = [];
   //public bestPassive: any[] = [];
   public fundDetails: any[] = [];
   public selectedDataDropDown: any = [];
   public dropDownData: any = [];
   public clickEvents: any = [];
   public tableViewData: any = [];
   public tableData: any = [];
   public bestPassiveData: any[] = [];
   public fundNameData: any[] = [];
   public bestPassives: any[] = [];
   public selectedTableData: any = [];
   public firstColumnData: any = [];
   public swappedData: any = [];
   public tempData: any = [];
   public selectedColumns: any = [];
   public ESGValue: any[] = [];
   public ratingViewHeaders: any[] = [];
   public marketUniverData:any[]=[];
   public geographySale:any[]=[];
   public marketUniverseDataChartFilter:any[]=[];
   public getAllSettingsData:any[]=[];
   public selectedFilter:any[]=[];
   public marketInsightData:any[]=[];
   public newsFeedsData:any[]=[];
   public marketInsightTopFund:any[]=[];
   public listItems: any[];
   public listData:any[]=[];
   public fundSearchValues:any[]=[];
   public yAxisData:any[] = [];
   public chartData:any= [];
   public summaryChartData:any = [];
   public chartsObject:any=[];
   public growthChartData:any[]=[];
   public summaryPopupchart:any[]=[];
   public myFundIds:any = [];
   public bestPassiveGrowthService:any[]=[];
   public fundDocumentationData:any = [];
   public apiData:any[]=[];
   public myFundChartData:any[]=[];
   public savedSettings:any[] = [];
   public alertsAndNotificationData:any[]=[];
   public newsfeedDataAlerts:any[]=[];
   public topFundGlobalEqualityAlertsX:any[]=[];
   public myFunds:any[]=[];
   public TopFundGlobalEqualityY: any[] = [];
   public fundSizeTopFund: any[] = [];
   public TopFundGlobalEqualityX: any[] = [];
   public chartYValue: any[] = [];
   public fundAlertsDataTopFund: any = [];
   public fundAlertsDataRiskChart: any = [];
   public profileSideOptions: any = [];
   public securityOptions: any = [];
   public organizationOptions: any = [];
   public userOptions: any = [];
   public growthData: any[] = [];
   public fundAlertsDataGrowthView:any = [];
   public marketInsightFilerData: any = [];
   public selectedDataPreferences: any= [];
   public tempSetting: any[] = [];
   public dropdownList = [];
   public myAllFundIs = [];
   public esgDataPreferences: any = [] = [];
   public rebatesData:any = [];
   public preferenceSaveSettings: any[] = [];
   public preferenceOptions: any = [];
   public hiddenGems: any = [];
   public selectedFundSize: any[] = [];
   public alertsSideOptions: any = [];
   public topFunds: any = [];

   // boolean type
   public isBestPassiveAvailable:boolean;
   public activePassiveApiError:boolean;
   public fundsAvailable: boolean;
   public showDashboard = true;
   public showInformation = false;
   public showOrganisation = false;
   public topFundErrMsg:boolean;
   public isBestPassive = false;
   public marketInsightLoading: boolean;
   public marketInsightDataIsAvailable:boolean;
   public blinks: boolean;
   public isLogin: boolean;
   public isShowLoading: boolean;
   public isShowTopFundLoading: boolean;
   public isDataTopFundNotAvailable: boolean;
   public isFirstLogin: boolean;
   public isActivePassiveDataNotAvailable: boolean = false;
   public isShareClassOnn:boolean = false;
   public addView:boolean = false;
   public addViewSelected: boolean;
   public showGraphdata = false;
   public userDashboard = true;
   public istopFunds: boolean;
   public isactivePassive: boolean;
   public isHome: boolean;
   public isratingView: boolean;
   public ismsGrowthView: boolean;
   public ismsRiskReward: boolean;
   public showmarketInsight = true;
   public addViewEnable: boolean;
   public ismyFunds: boolean;
   public bottomView = true;
   public showPopupOfSummary = false;
   public showTooltipOnActive = false;
   public isAddView: boolean;
   public animation = false;
   public topfundAnimation = false;
   public activePassiveAnimation = false;
   public tableViewAnimation = false;
   public MsGrowthAnimation = false;
   public MsRiskRewardAnimation = false;
   public selectedTopFund = false;
   public selectedActivePassive = false;
   public selectedTableView = false;
   public selectedGrowth = false;
   public selectedRiskReward = false;
   public selectedMyFunds = false;
   public showFundSize = false;
   public showPopupSummaryCard: boolean;
   public summaryCardDataLoading: boolean;
   public performanceToggle:boolean;
   public showPopupOfSummaryAlerts:boolean;
   public alertsAndNotificationSummary:boolean;
   public marketUniverseLoading:boolean;
   public newsFeedDataAvailable:boolean;
   public newsFeedLoading:boolean;
   public toggleMarket:boolean;
   public growthChartLoading:boolean;
   public growthChartDataNotAvailable:boolean;
   public dateSelected:boolean;
   public filterTimeSelected:boolean;
   public growthChartApiErr:boolean;
   public myFundsChart:boolean = false;
   public isDataAvailable = false;
   public fundDocumentLoading = false;
   public myFundLoading:boolean;
   public myFundDataAvailable = false;
   public myFundSpinner:boolean;
   public checkMyFunds:boolean = false;
   public alertsAndNotificationLoading:boolean;
   public alertsAndNotificationDataAvailable:boolean;
   public showToolTip:boolean;
   public checkFundName:boolean;
   public showPopupSummary = false;
   public dataNot: boolean;
   public newsLoadingTopFund: boolean;
   public fundActive: boolean;
   public alertLoadingTopFund: boolean;
   public dataAvailableTopFund: boolean;
   public alertLoadingRiskChart: boolean;
   public dataAvailableRiskChart: boolean;
   public newsLoadingRiskChart: boolean;
   public sendResetLink: boolean;
   public resetLoader: boolean;
   public newsLoadingGrowthView:boolean;
   public growthViewChartLoading: boolean;
   public growthViewSummaryPopup: boolean;
   public growthDropDown: boolean;
   public alertLoadingGrowthView:boolean;
   public defaultMarketInsight:boolean;
   public moreLoadData:boolean = false;
   public isLoading:boolean;
   public showAnimationPreferences = false;
   public defaultPreferences: boolean;
   public enableAdd = false;
   public enableEditRebates = false;
   public showDropDownPreferences = false;
   public isNewCategoryPreferences: boolean;
   public defaultChartFilter: boolean;
   public isNewCategoryChartFilter: boolean = true;
   public showModel: boolean = false;
   

   // String type
   public fundDeleteResponse: string;

   // observables
   public marketInsightObservable: Subject<any> = new Subject<any>();
   public topFundDataObservable: Subject<any> = new Subject<any>();
   public summaryPopupGrowth: Subject<any> = new Subject<any>();
   public selectedCategory: Subject<any> = new Subject<any>();
   public riskRewardPercenties: Subject<any> = new Subject<any>();
   public commonTopFundPrecentileOb: Subject<any> = new Subject<any>();
   public ratingServiceObservable: Subject<any> = new Subject<any>();
   public tableViewChangeObservable: Subject<any> = new Subject<any>();
   public summaryChartObservable: Subject<any> = new Subject<any>();
   public changeDataChartFilter:Subject<any> = new Subject<any>();
   public changeCategory:Subject<any> = new Subject<any>();
   public allSettingObservable:Subject<any> = new Subject<any>();
   public getSettingByIdObservable:Subject<any> = new Subject<any>();
   public growthChartObservable:Subject<any> = new Subject<any>();
   public getAllfund: Subject<any> = new Subject<any>();
   public myFundsDataObservable: Subject<any> = new Subject<any>();
   public myFundsPreferencesObservable: Subject<any> = new Subject<any>();
   public alertSetupObservable:Subject<any> = new Subject<any>();
   public alertsAndNotificationObservable: Subject<any> = new Subject<any>();

   // array with objects
   public bestPassive = [
    { head: "Select a Best Passive" },
    { id: "1 YEAR-ILIM Indexed Name", show: false, value: "" },
    { id: "3 YEAR-VANGAURD Name", show: false, value: "" },
    { id: "5 YEAR-ILIM Indexed Name", show: false, value: "" },
  ];

   public consistencyScore = [
    {name:'5', select: true, value:5, showInfo:true, info:"Consistency Score of 1/3/5 years vs. Passive: 5 = Top 5% Sharpe Ratio of Active Funds (FUNDSaiQ Recommended)"},
    {name:'4', select: false, value:4, showInfo:false, info:"Consistency Score of 1/3/5 years vs. Passive: 4 = Top 10% Sharpe Ratio of Active Funds (FUNDSaiQ Recommended)"},
    {name:'3', select: false, value:3, showInfo:false, info:"Consistency Score of 1/3/5 years vs. Passive: 3 = Top 15% Sharpe Ratio of Active Funds (FUNDSaiQ Recommended)"},
    {name:'2', select: false, value:2, showInfo:false, info:"Consistency Score of 1/3/5 years vs. Passive: 2 = Top 20% Sharpe Ratio of Active Funds (FUNDSaiQ Recommended)"},
    {name:'1', select: false, value:1, showInfo:false, info:"Consistency Score of 1/3/5 years vs. Passive: 1 = Top 25% Sharpe Ratio of Active Funds (FUNDSaiQ Recommended)"}];

    filterCheckBoxes = [
      { name: "Curated Content", select: false, value:"FUNDSaiQ Curated", disable:false, filter:"FUNDSaiQ Curated" },
      { name: "News Feed", select: false, value:"General fund news", disable:false, filter:"General Fund News" },
      { name: "Social Media", select: false , disable:true, filter:"Social Media"},
    ];
    public hiddenGemRange = [
      { head: "Size", show: false },
      { id: "100m-500m", show: false },
    ];
    public weightedValue = [
      { head: "Weighted Tenure", show: false, disable: true },
      {
        id: "5-Yr", show: false, disable: false, subCate: [{ name: "Tenure weightings" },
        { id: "1-Yr", value: "20%" },
        { id: "3-Yr", value: "50%" },
        { id: "5-Yr", value: "30%" },]
      },
      { id: "7-Yr", show: false, disable: true },
      // { id: "10-Yr", show: false, disable: true },
    ];
    public fundSizeChartFilter = [
      { head: "Filter By", },
      { id: "0-50M", show: false },
      { id: "50-100M", show: false },
      { id: "100-500M", show: false },
      { id: "500M+", show: false },
    ];
    public currencySigns = [
      {  "CURRENCY": "HUF", "Sign": "Ft" },
      {  "CURRENCY": "ISK", "Sign": "kr" },
      {  "CURRENCY": "JPY", "Sign": "¥" },
      {  "CURRENCY": "KRW", "Sign": "₩" },
      {  "CURRENCY": "CZK", "Sign": "Kč" },
      {  "CURRENCY": "AUD", "Sign": "$" },
      {  "CURRENCY": "BGN", "Sign": "лв" },
      {  "CURRENCY": "BRL", "Sign": "R$" },
      {  "CURRENCY": "CAD", "Sign": "$" } ,
      {  "CURRENCY": "CHF", "Sign": "CHF" },
      {  "CURRENCY": "CNY", "Sign": "¥" },
      {  "CURRENCY": "DKK", "Sign": "kr" }, 
      {  "CURRENCY": "HKD", "Sign": "$" },
      {  "CURRENCY": "ILS", "Sign": "₪" },
      {  "CURRENCY": "INR", "Sign": "₹" }, 
      {  "CURRENCY": "NOK", "Sign": "kr" }, 
      {  "CURRENCY": "NZD", "Sign": "$" }, 
      { "CURRENCY": "PLN", "Sign": "zł" },
      { "CURRENCY": "RON", "Sign": "lei" },
      { "CURRENCY": "SEK", "Sign": "kr" },
      { "CURRENCY": "SGD", "Sign": "$" },
      { "CURRENCY": "TRY", "Sign": "₺" },
      { "CURRENCY": "USD", "Sign": "$" },
      { "CURRENCY": "ZAR", "Sign": "R" },
      { "CURRENCY": "GBP", "Sign": "£" },
      { "CURRENCY": "RUB", "Sign": "₽" },
      { "CURRENCY": "EUR", "Sign": "€" },
      { "CURRENCY": "CNH", "Sign": "¥" },
      { "CURRENCY": "GBX", "Sign": "p"},
    ]
  
}
