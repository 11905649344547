import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { AlertsNotificationsService } from 'src/services/alerts-notifications/alerts-notifications.service';
import { CommonService } from 'src/services/CommonServices/common.service';
import { decode } from 'string-encode-decode';
import { AlertsAndNews } from "../../utils/constants";
import { DatePipe } from "@angular/common";
import { SummaryPopupService } from 'src/services/summary-popup/summary-popup.service';
import { Commons, Shared } from 'src/utils/common';
import { GoogleAnalyticsService } from 'src/services/google-Analytics/google-analytics.service';
@Component({
  selector: 'app-alerts-and-notification',
  templateUrl: './alerts-and-notification.component.html',
  styleUrls: ['./alerts-and-notification.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class AlertsAndNotificationComponent implements OnInit {
  @ViewChild('tooltipPopup') tooltipPopup: ElementRef;
  @Output("openAlertsFromRisk") openAlertsFromRisk: EventEmitter<any> = new EventEmitter();
  @Output() summaryPopupTooltipFund = new EventEmitter<string>();
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  //topFundGlobalEqualityAlertsX: any[] = [];
  showGraphdata = false;
  showPopupOfSummary = false;
  public title: string;
  public userID: any;
  paging: any;
  scrolled: boolean;
  showBottomLoading: boolean;
  

  constructor(public common: CommonService, public alertsAndNotificationService: AlertsNotificationsService, 
    public datePipe: DatePipe, public summaryPopupService: SummaryPopupService, public googleAnalyticsEventsService: GoogleAnalyticsService, public shared: Shared) {
    this.userID = decode(localStorage.getItem("ID"));
  }

  ngOnInit(): void {
  }

  removeUnderscore(item) {
    if (item) {
      const value = item.replace(/_/g, " ");
      return value;
    }
  }


  onScroll() {
    const element = this.myScrollContainer.nativeElement
    const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
    if (this.scrolled && atBottom) {
      this.scrolled = false;
      this.paging++;
      this.showBottomLoading = true;
      this.alertsAndNotificationService.getALertsNotificationsScroll(this.userID, this.paging).subscribe(res => {
        this.showBottomLoading = false;
        if (res['data'].length > 0) {
          res['data'].forEach(element => {
            let a = element.event_value.replace(/'/g, '"')
            let x = a.replace('"{', "'{")
            let y = x.replace("{", "'{")
            let z = y.replace("}:", "}'")
            let b = z.replace('"{', "{").replace("{", "'{").replace("}:", "}'").replace(/'/g, "")
            let da = b.replace(/None/g, '"None"').replace(/datetime/g, '"datetime').replace(')', ')"').replace(/Timestamp/g, '"Timestamp')
            element.event_value = JSON.parse(da);
            this.shared.topFundGlobalEqualityAlertsX.push({
              occurrenceDate:element.effective_date,
              publishedData:element.date_event_noticed,
              name: element.name, selectLike: false, fundId: element.id,
              data: element.alert_type + ' : ' + element.event_type,
              text: element.event_value.old_value != "None" ? element.event_value.old_value : "",
              newText: element.event_value.new_value != "None" ? element.event_value.new_value : "",
              selectDelete: false, selectClose: false, show: false
            })
          });
        }
      }, (err)=>{
        this.showBottomLoading = false;
      });
    } else {
      this.scrolled = true;
    }
  }

  showGraph() {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "alerts_notification_tooltip",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if (this.showGraphdata) {
      this.showGraphdata = false;
    } else {
      setTimeout(() => {
        this.showGraphdata = !this.showGraphdata;
        this.summaryPopupService.getAlertsDataTooltip(this.shared.topFundGlobalEqualityAlertsX[0]);
      }, 10)
    }
    // this.summaryDetails();
  }

  onClick(event) {
    if (!this.tooltipPopup.nativeElement.contains(event.target) && this.showGraphdata) {
      this.showGraphdata = false;
    }
  }

  openAlertsForAlerts(event) {
    this.openAlertsFromRisk.emit(event);
  }

  alertSetup(){
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "alerts_notification_expand",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
  }

  openAlertsNews() {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "alerts_notification_expand",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
      this.shared.alertsTitle = AlertsAndNews.title;
      this.shared.placeHolder = AlertsAndNews.placeHolder;
    this.shared.filterTitle = AlertsAndNews.filterTitle;
    this.shared.chartFilterNews = AlertsAndNews.filterItems;
    this.shared.newsTableHeading = AlertsAndNews.newsTableHeadings;
    Commons.newdFeedsData = AlertsAndNews.alertNewsFeed;
    console.log("Alerts",this.shared.newsfeedDataAlerts)
  }

  eventData:any;
  openSummary(event) {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "alerts_notification_summery_popup",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.eventData = event;
    this.summaryPopupService.summaryCardAlertsAndNotification(event);
  }

  getSummeryState(event) {
    this.showPopupOfSummary = event.showState;
  }

  selectAlerts(event, item, i) {
    if (event == "like") {
      let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "alerts_notification_like",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
      item.selectLike = !item.selectLike;
      item.selectDelete = false;
      item.selectClose = false;
    }
    if (event == "disLike") {
      let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "alerts_notification_dislike",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
      item.selectLike = false;
      item.selectDelete = !item.selectDelete;
      item.selectClose = false;
    }
    if (event == "close") {
      let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "alerts_notification_close",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
      item.selectLike = false;
      item.selectDelete = false;
      item.selectClose = !item.selectClose;
      this.shared.topFundGlobalEqualityAlertsX.forEach(ele => {
        if (item.name == ele.name && item.selectClose == true) {
          this.shared.topFundGlobalEqualityAlertsX.splice(i, 1);
          item.selectClose = false;
        }
      });
    }
  }

  clickedOut(event) {
  }

  validateContent(content, show, container) {
    if (!show)
      // return container.offsetWidth<1000 ? content.replace(/(([^\s]+\s\s*){5})(.*)/,"$1"):content.replace(/(([^\s]+\s\s*){35})(.*)/,"$1")//contentarray.splice(0,13).join(' ')
      return container.offsetHeight < 100 ? content.replace(/(([^\s]+\s\s*){5})(.*)/, "$1") : content.replace(/(([^\s]+\s\s*){35})(.*)/, "$1")
    else
      return content
  }


  showItems(item) {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": "alerts_notification_show_more/less",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
      this.shared.blinks = false;
    item.show = !item.show;
  }

  stop() {
    this.shared.blinks = false;
  }
}
