import { Component, OnInit , Output , EventEmitter, Input,} from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {
  @Input() commonFund = false;
  @Output() onPickRemember: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  commonFunds(event){
    this.onPickRemember.emit(event);
  }

}
