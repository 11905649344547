import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject } from "rxjs";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AlertsServiceService {

  constructor(public http: HttpClient) { }

  saveAlertSetting(alertDetails){
    return this.http.post(environment.baseUrl + "saveAlertSettings",alertDetails, httpOptions);
  } 

  getAlertSetting(userid, category, preferencesId){
    return this.http.get(environment.baseUrl + 'getAlertSettings/'+userid+"?category=" +category+ "&preferencesId=" +preferencesId);
  }


  topFund(category, date){
    const esgData = []
    return this.http.post<any>(  environment.baseUrl +"topfunds/" +category +  "?date=" + date  +"&year=" +
    localStorage.getItem('year') +
    "&totalyear=" +
    '5'+ "&isNew=" + 'true', esgData, httpOptions);
  }
  getMyFundsData(){

  }
  
}
