import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/services/google-Analytics/google-analytics.service';

declare let gtag: Function;
declare let ga: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'FundsaiQ';
  componentName: any;
  constructor(public router: Router, public googleAnalyticsEventsService: GoogleAnalyticsService) {
    let client = localStorage.getItem('userId');
    if (client) {
      ga('create', 'UA-211001570-1', {
        'storage': 'none',
        'clientId': client
      });
      ga('send', 'pageview');
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga('send', {
          hitType: 'pageview',
          page: event.urlAfterRedirects
        });
      }
    });
    if (typeof Worker !== 'undefined') {
      // Create a new
      const worker = new Worker('./app.worker', { type: 'module' });
      worker.onmessage = ({ data }) => {
        console.log(`page got message: ${data}`);
      };
      worker.postMessage('hello');
    } else {
      // Web Workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
    }
  }
}