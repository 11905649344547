import { Component, EventEmitter, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from 'src/services/CommonServices/common.service';
import { NewsFundsService } from 'src/services/news-for-funds/news-funds.service';
import { SummaryPopupService } from 'src/services/summary-popup/summary-popup.service';
import { Commons, Shared } from '../../utils/common';
import { DatePipe } from "@angular/common";
import { Subscription } from 'rxjs';
import { FundDocumentaionService } from 'src/services/fund-documentation/fund-documentaion.service';
import { FundAlertsService } from 'src/services/fund-Alerts/fund-alerts.service';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/services/google-Analytics/google-analytics.service';
var Highcharts = require('highcharts/highstock');

declare let ga: Function;

@Component({
  selector: 'app-top-fund-chart',
  templateUrl: './top-fund-chart.component.html',
  styleUrls: ['./top-fund-chart.component.scss']
})
export class TopFundChartComponent implements OnInit {
  @Input() topFundChartHeight: any;
  @Input() topFundChartWidth: any;
  @Input() userDashboard: boolean;
  @Input() dataNotAvailable: boolean;
  @Input() topFundChartHeightDashboard: any;
  @Input() topFundChartWidthDashboard: any;
  @Output() summaryPopupTooltip = new EventEmitter<string>();
  @ViewChild('newsComponentTopFund') newsComponentTopFund: ElementRef;
  @ViewChild('alertComponentTopFund') alertComponentTopFund: ElementRef;
  topFundObservable: Subscription;
  consistencyObs: Subscription;
  toggleShareClasses: Subscription;
  newsFundData: Subscription;
  
  constructor(public common: CommonService, public newsFund: NewsFundsService,
    public summaryPopup: SummaryPopupService, public datePipe: DatePipe,
    public fundService: FundDocumentaionService, public fundAlerts: FundAlertsService,
    public router: Router, public googleAnalyticsEventsService: GoogleAnalyticsService,
    public shared: Shared) {
    this.shared.chartTest = Highcharts.chart;
  }

  ngOnInit(): void {
    if(Commons.userDashboardshow){
      this.router.config.forEach(event => {
        if (event.path == 'user-dashboard') {
          // gtag('config', 'UA-211001570-1', 
          //            {
          //              'page_path': event.path              
          //            }
          //           );
                    ga('send', {
                      hitType: 'pageview',
                      page: event.path
                    });
        }
      });
    }else{
      this.router.config.forEach(event => {
        if (event.path == "topFunds") {
          // gtag('config', 'UA-211001570-1', 
          //            {
          //              'page_path': event.path          
          //            }
          //           );
                    ga('send', {
                      hitType: 'pageview',
                      page: event.path
                    });
        }
      });
    }
    this.consistencyObs = Commons.consistencyScoreChange.subscribe(() => {
      if (Commons.consistencyObTopFund == 0) {
        if (this.shared.topFundCategoryData.topData.length > 0) {
          this.getTopFundDataXY(this.shared.topFundCategoryData, Commons.selectedTotalYear, Commons.selectedHiddenGem);
        }
      }
      Commons.consistencyObTopFund++;
    });
    this.toggleShareClasses = Commons.toggleShareClass.subscribe((res) => {
      // console.log('res test')
       if(this.shared.isShareClassOnn){
         if(res != undefined){
          this.getTopFundDataXY(res.data, res.year, res.hiddenGem);
         }else{
           if(Commons.isShareClassData.topData.length > 0){
            this.getTopFundDataXY(Commons.isShareClassData, Commons.selectedTotalYear, Commons.selectedHiddenGem);
           }else{
            this.getTopFundDataXY(this.shared.topFundCategoryData, Commons.selectedTotalYear, Commons.selectedHiddenGem);
           }
        }
       }else{
        this.getTopFundDataXY(this.shared.topFundCategoryData, Commons.selectedTotalYear, Commons.selectedHiddenGem);
       }
    });
    this.shared.selectedCategory.subscribe((res) => {
      if (res && res != "undefined") {
        this.shared.selectedCategoryTopFund = res;
      }
    });
    const selectCate = localStorage.getItem("chartCategory");
    if (selectCate && selectCate != "undefined") {
      this.shared.selectedCategoryTopFund = localStorage.getItem("chartCategory");
    }

    this.topFundObservable = this.shared.topFundDataObservable.subscribe((res) => {
      if (res.data.topData.length > 0) {
        this.shared.isDataTopFundNotAvailable = false;
        this.getTopFundDataXY(res.data, res.year, res.hiddenGem);
      } else if (res.data.bestPassive.length > 0) {
        this.getBestPassiveData(res.data.bestPassive);
      }
      else {
        this.shared.isDataTopFundNotAvailable = true;
      }
    })
    // this.common.riskRewardPercenties.subscribe((res) => {
    // })
    // this.common.bestPassiveObeservable.subscribe((rs) => {
    // })
  }

  ngOnDestroy() {
    if (this.topFundObservable) {
      this.topFundObservable.unsubscribe();
    }
    if (this.consistencyObs) {
      // this.consistencyObs.unsubscribe();
    }
    if(this.toggleShareClasses){
      this.toggleShareClasses.unsubscribe();
    }
    if(Commons.userDashboardshow){
      this.router.config.forEach(event => {
        if (event.path == 'user-dashboard') {
          // gtag('config', 'UA-211001570-1', 
          //            {
          //              'page_path': event.path              
          //            }
          //           );
                    ga('send', {
                      hitType: 'pageview',
                      page: event.path
                    });
        }
      });
    }
  }

  topFundchart() {
    const max = Math.max(...this.shared.chartYValue);
    const chartMax = max ? max + 10 : undefined;
    const gems = this.shared.diamonds;
    let bestPassiveTooltip : any;
    let chartTitle: any;
    let checkBestPassive: boolean;
    let currencyData: any = [];
    if (this.shared.TopFundGlobalEqualityY.length == 0) {
      this.shared.fundsAvailable = true;
    } else {
      this.shared.fundsAvailable = false;
    }
    checkBestPassive = this.shared.isBestPassive;
    if (this.shared.isBestPassive) {
      chartTitle = "*WEIGHTED% 5 YEAR RETURN OF BEST PASSIVE";
      bestPassiveTooltip = "This fund is not sold in your selected geographies.";
    } else {
      chartTitle = "Weighted 5 year Excess Return over Passives";
    }

    if (this.shared.TopFundGlobalEqualityX.length) {
      let graphHeight;
      let graphWidth;
      let graphLabel;
      let chartScroll;
      let xAxisData;
      let showFundSize;
      let chartData;
      let currencySign;
      let geographyFund: any = [];
      let geographySale: any = [];
      let geographyCountries: any = [];
      let count;
      geographyFund = Commons.geographyFunds;
      geographySale = Commons.geographyData
      geographyCountries = Commons.geographyCountries;
      currencyData = this.shared.currencySigns;
      if (Commons.userDashboardshow) {
        this.userDashboard = true;
        // graphHeight = this.topFundChartHeight - 120;
        // graphWidth = this.topFundChartWidth - 20;
        graphHeight = Commons.chartHeightCollapseView - 120;
        graphWidth = this.shared.chartWidthCollapseView - 20;
        graphLabel = false;
        chartScroll = false;
      }
      else if (!Commons.userDashboardshow) {
        count = 0;
        this.userDashboard = false;
        graphHeight = this.shared.topFundChartHeight - 80;
        graphWidth = this.shared.topFundChartWidth - 100;
        graphLabel = true;
        showFundSize = this.shared.fundActive;
        xAxisData = this.shared.TopFundGlobalEqualityX.length;
        if (this.shared.TopFundGlobalEqualityY.length > 6) {
          chartScroll = true;
        }
        else {
          chartScroll = false;
        }
      }
      // categories.sort((a, b) => (a < b ? -1 : 1));
      chartData = this.shared.TopFundGlobalEqualityY;
      // this.chartTest = Highcharts.stockChart.chart(
      // let myChart = Highcharts.stockChart("stockContainer", {
      this.shared.chartTest = Highcharts.chart(
        "topFundContainers",
        {
          chart: {
            backgroundColor: "#000f19",
            color: "#fffff",
            // display: 'flex',
            height: graphHeight,
            width: graphWidth,
            //   scrollablePlotArea: {
            //     minWidth: 1600,
            //     scrollPositionX: 0
            // },
            style: {
              fontFamily: "sans-serif",
            },
          },

          title: {
            text: "",
          },
          subtitle: {
            text: "",
          },
          yAxis: [{
            max: chartMax,
            stackLabels: {
              style: {
                rotation: 0,
                color: "#48EEE0",
                align: "center",
                position: "relative"
              },
              enabled: true,
              align: "center",
              useHTML: true,
              overflow: 'none',
              crop: false,
              formatter: function () {
                let val;
                let redLeafImage;
                let greenLeaf;
                let diamondIcon;
                let geographyIcon;
                if (chartData && !Commons.userDashboardshow) {
                  const data = chartData[this.x];
                  let countries: any = [];
                  if (data) {
                    if (gems == "100m-500m") {
                      if (data.name > 100000000 && data.name < 500000000 && data.name != "") {
                        diamondIcon = '<img src="../../../assets/images/diamond.png" style="min-width: 20px;">';
                      } else {
                        diamondIcon = "";
                      }
                    } else {
                      diamondIcon = "";
                    }
                    if (data.esgInclude) {
                      greenLeaf = '<img src="../../../assets/images/green-leaf.svg" style="min-width: 20px;">';
                    } else {
                      greenLeaf = "";
                    }
                    if (data.esgExclude) {
                      redLeafImage = '<img src="../../../assets/images/red-leaf.svg" style="min-width: 20px;">';
                    } else {
                      redLeafImage = "";
                    }
                    if(geographySale.length > 0){
                    if(geographyFund.includes(data.fundId)){
                      geographyCountries.forEach(element => {
                        if(element.ShareClass_Id == data.fundId){                        
                        element.selectedCountries.forEach(element_c => {
                              countries.push(element_c);
                        });
                      }
                      });
                      geographyIcon = `<div class="photo-container canapes" style="position: relative;"><a class="photo-container" href="#">`+
                        `<img src="../../../assets/images/earth.svg" style="min-width: 20px;" alt="Monarch"><span class="tabs-popover theme-scroll" style="text-align: justify; height: auto; width: auto; left: 0 !important; top: 0 !important;">` + '<p style="font-size: 14px">' + "Geographies of Sale:" + '</p>' 
                         if(countries.length > 0){
                          for(let i=0; i < countries.length;  i++){
                            geographyIcon  += '<p style="font-size: 12px; margin-top: -10px">' +  countries[i] + '</p>'
                            }
                         }else if(checkBestPassive && countries.length == 0){
                          geographyIcon  += '<p style="font-size: 12px; margin-top: -10px">' +  bestPassiveTooltip + '</p>'
                         }
                          geographyIcon += "</span></a></div>"
                    }else if(checkBestPassive && countries.length == 0){
                      geographyIcon = `<div class="photo-container canapes" style="position: relative;"><a class="photo-container" href="#">`+
                        `<img src="../../../assets/images/earth.svg" style="min-width: 20px;" alt="Monarch"><span class="tabs-popover theme-scroll" style="text-align: justify; height: auto; width: auto; left: 0 !important; top: 0 !important;">` + '<p style="font-size: 14px">' + "Geographies of Sale:" + '</p>' 
                          geographyIcon  += '<p style="font-size: 14px; color: red; margin-top: -10px">' +  bestPassiveTooltip + '</p>'
                          geographyIcon += "</span></a></div>"
                    }
                  }else{
                    geographyIcon = "";
                  }
                  } else {
                    geographyIcon = "";
                    redLeafImage = "";
                    greenLeaf = "";
                    diamondIcon = "";
                  }
                } else {
                  geographyIcon = "";
                  redLeafImage = "";
                  greenLeaf = "";
                  diamondIcon = "";
                }

                if (Number.isInteger(this.total)) {
                  val = this.total + '%';
                } else {
                  val = this.total + '%';
                }
                return '<div style="display: flex;align-items: center;   justify-content: center;"><span style="">' + greenLeaf + '</span>' + '<span style="">' + diamondIcon + '</span>' + '<span>' + redLeafImage + '</span>' + '<span >' + geographyIcon + '</span></div>' + val;
              }
            },
            labels: {
              style: {
                fontSize: "14px",
              },
            },
            gridLineWidth: 0.5,
            title: {
              text: chartTitle,
              style: { color: "#606f88", fontSize: "12px" },
            },
          }],
          xAxis: {
            min: xAxisData > 6 ? 0 : undefined,
            max: xAxisData > 6 ? 5 : undefined,
            tickLength: 0,
            scrollbar: {
              enabled: chartScroll,
              barBackgroundColor: '#48eee0',
              barBorderRadius: 7,
              barBorderWidth: 0,
              buttonBackgroundColor: 'transparent',
              buttonBorderWidth: 0,
              buttonArrowColor: 'transparent',
              buttonBorderRadius: 7,
              rifleColor: '#48eee0',
              trackBackgroundColor: 'transparent',
              trackBorderWidth: 0,
              trackBorderColor: '#48eee0',
              trackBorderRadius: 7,
              height: 10,
              liveRedraw: true
            },
            categories: this.shared.TopFundGlobalEqualityX,
            labels: {
              enabled: graphLabel,
              style: {
                color: "#606f88",
                fontSize: "13px",
              },
            },
          },
          tooltip: {
            // positioner: function(labelWidth, _, point) {
              
            //   var plotX = point.plotX;
            //   return {
            //     x: plotX - labelWidth + 30 > 0 ? plotX - labelWidth + 10 : plotX + 70,
            //     y: point.plotY,
                
            //   };
              
            // },
            // positioner: function (this) {
            //   console.log(this);
            //   //return { x: this, y: this / 2 };
            // },
            enabled: graphLabel,
            useHTML: true,
            borderRadius: 10,
            stickOnContact: true,
            split: false,
            padding: 18,
            paddingLeft: 3,
            height: '140px',
            backgroundColor: "#000f19",
            formatter: function () {
              if (this.point.tooltip) {
                let redLeafImage: any;
                let gaographyIcon: any;
                let greenLeaf: any;
                let diamond: any;
                let newVal:any;
                if (this.point.name != null) {
                  newVal = Math.abs(Number(this.point.name)) >= 1.0e+9

                    ? Math.round((Math.abs(Number(this.point.name)) / 1.0e+9)) + "B"
                    : Math.abs(Number(this.point.name)) >= 1.0e+6

                      ? Math.round((Math.abs(Number(this.point.name)) / 1.0e+6)) + "M"
                      // Three Zeroes for Thousands
                      : Math.abs(Number(this.point.name)) >= 1.0e+3
                        ?
                        Math.round((Math.abs(Number(this.point.name)) / 1.0e+3)) + "K"
                        : Math.abs(Number(this.point.name));
                }
                if (this.point.esgExclude) {
                  redLeafImage = `<img alt="" src="../../../assets/images/red-leaf.svg" style="height:15px;width:15px;">`;
                } else {
                  redLeafImage = "";
                }
                if (this.point.esgInclude) {
                  greenLeaf = `<img src="../../../assets/images/green-leaf.svg" alt="" style="height:15px;width:15px;">`;

                } else {
                  greenLeaf = "";
                }
                if (gems == "100m-500m") {
                  if (this.point.name > 100000000 && this.point.name < 500000000 && this.point.name != "") {
                    diamond = `<img alt="" src="../../../assets/images/diamond.png" style="height:15px;width:15px;">`
                  } else {
                    diamond = "";
                  }
                } else {
                  diamond = "";
                }
                // if(this.common.geographySaleFunds.length > 0){
                //   this.common.geographySaleFunds.forEach(element => {
                //     if (this.point) {
                //       gaographyIcon = `<img src="../../../assets/images/earth.svg" alt="" style="height:15px;width:15px;">`;
    
                //     } else {
                //       gaographyIcon = "";
                //     }
                //   });
                // }else{
                //   gaographyIcon = "";
                // }
                
                return (
                  `<div id='tooltip-buttons' style="background: #000f19;"><div class="tooltip-buttons-parent"><button class="button-tooltip" id="summary-card-topFund" type="button"
                  onmouseover="this.style.borderColor='`+ this.point.color + `'" onmouseout="this.style.borderColor='#58677f'">SUMMARY CARD</button><br><div class="btn-flex"><button class="button-tooltip fact-btn" id="fact-sheet"
                  onmouseover="this.style.borderColor='`+ this.point.color + `'" onmouseout="this.style.borderColor='#58677f'">FACTSHEET</button><button class="button-tooltip" id="kiid"
                  onmouseover="this.style.borderColor='`+ this.point.color + `'" onmouseout="this.style.borderColor='#58677f'">KIID</button></div><br><div class="btn-flex" style="margin-top:-15px;"><button id="alerts-data" class="button-tooltip fact-btn"
                  onmouseover="this.style.borderColor='`+ this.point.color + `'" onmouseout="this.style.borderColor='#58677f'">ALERTS</button><button class="button-tooltip" id="news-feeds"
                  onmouseover="this.style.borderColor='`+ this.point.color + `'" onmouseout="this.style.borderColor='#58677f'">MARKET INSIGHTS</button></div></div>` +
                  "<br/>" +
                  '<span style="color:#617088;max-width:100%; white-space: normal;display: block;  margin-bottom: -8px;">' +
                  this.point.category +
                  greenLeaf + redLeafImage + diamond + `</span><br/>` +
                  '<span style="color:#617088;max-width:100%; white-space: normal;display: block;  margin-bottom: -8px;">' + this.point.date + '</span>' + '<br>' +
                  '<span style="color:' +
                  this.point.color +
                  '">' +
                  "Total Weighted Value" +
                  ": " +
                  this.point.y.toFixed(2) + '% (5Y)' +
                  '</span><br/></div><div id="newsFeedDataOnTooltip" style="position: relative;"> </div><span style="color:white;max-width:100%; white-space: normal;display: block;  margin-bottom: -8px;">'
                  + "Size = €"+(newVal != undefined ? newVal : 0)+" Consistency = 5 "+'</span>'
                );
              } else {
                return false;
              }
            },
          },
          plotOptions: {
            column: {
              stacking: 'normal',
            },
            series: {
              point: {
                events: {
                  mouseOver: function (event) {
                    setTimeout(() => {
                      if (document.getElementById("news-feeds")) {
                        document
                          .getElementById("news-feeds")
                          .addEventListener("click", this.getNewsData.bind(this, event));
                      }
                    }, 100);
                    setTimeout(() => {
                      if (document.getElementById("fact-sheet")) {
                        document
                          .getElementById("fact-sheet")
                          .addEventListener("click", this.getFactsheet.bind(this, event));
                      }
                    }, 100);
                    setTimeout(() => {
                      if (document.getElementById("kiid")) {
                        document
                          .getElementById("kiid")
                          .addEventListener("click", this.getKiid.bind(this, event));
                      }
                    }, 100);
                    setTimeout(() => {
                      if (document.getElementById("summary-card-topFund")) {
                        document.getElementById("summary-card-topFund").addEventListener(
                          "click",
                          this.openSummaryCard.bind(this, event)
                        );
                      }
                    }, 100);
                    setTimeout(() => {
                      if (document.getElementById("alerts-data")) {
                        document.getElementById("alerts-data")
                          .addEventListener("click", this.getAlertsData.bind(this, event));
                      }
                    }, 100);
                  }.bind(this),
                },
              },
            },
          },
          series: [
            {
              type: "column",
              borderWidth: 0,
              colorByPoint: true,
              stickOnContact: true,
              data: this.shared.TopFundGlobalEqualityY,
              dataLabels: {
                enabled: graphLabel,
                useHTML: true,
                rotation: 0,
                borderColor: 'transparent',
                color: "white",
                align: "center",
                textOutline: 0,
                formatter: function () {
                  let cons;
                  let fundSize = '';
                  let val;
                  let temp;
                  if (!this.point.bestPassive) {
                    // console.log('top fund');
                    graphHeight
                    graphWidth
                    cons = 'C' + this.point.consistency;
                    // if(this.point.pointWidth < 81){
                    //   cons = 'C = ' + this.point.consistency;
                    // }else{
                    //   cons = 'Consistency = ' + this.point.consistency;
                    // }
                    if (this.point.name == 0) {
                      fundSize = '--';
                    }
                    if (this.point.name == null) {
                      fundSize = '--';
                    }
                    if (this.point.name != null) {
                      temp = Math.abs(Number(this.point.name)) >= 1.0e+9

                        ? Math.round((Math.abs(Number(this.point.name)) / 1.0e+9)) + "B"
                        : Math.abs(Number(this.point.name)) >= 1.0e+6

                          ? Math.round((Math.abs(Number(this.point.name)) / 1.0e+6)) + "M"
                          // Three Zeroes for Thousands
                          : Math.abs(Number(this.point.name)) >= 1.0e+3
                            ?
                            Math.round((Math.abs(Number(this.point.name)) / 1.0e+3)) + "K"
                            : Math.abs(Number(this.point.name));
                      // currencyData.forEach(ele => {
                      //  if(ele.CURRENCY == this.point.currency){
                      //   currencySign = ele.Sign;
                      //  }
                      // });
                      fundSize = '€' + temp;
                    }
                    if (!showFundSize) {
                      val = cons + '<br><div style="text-align: center">' + fundSize + '</di>';
                      // if(this.point.pointWidth < 81){
                      //   val = cons + '<br><div style="margin-left:0px;">' + fundSize + '</di>';
                      // }else{
                      //   val = cons + '<br><div style="margin-left:20px;">' + fundSize + '</di>';
                      // }
                      // val = cons;
                    }
                    else {
                      val = cons;
                    }
                    val = cons + '<br><div style="text-align: center">' + fundSize + '</di>';
                    // console.log('Vau',val);
                    return val;
                  }
                  if (this.point.bestPassive) {
                    // console.log('Best passive');
                    if (this.point.name == 0) {
                      fundSize = '--';
                    }
                    if (this.point.name == null) {
                      fundSize = '--';
                    }
                    if (this.point.name != null) {
                      temp = Math.abs(Number(this.point.name)) >= 1.0e+9

                        ? Math.round((Math.abs(Number(this.point.name)) / 1.0e+9)) + "B"
                        // Six Zeroes for Millions 
                        : Math.abs(Number(this.point.name)) >= 1.0e+6

                          ? Math.round((Math.abs(Number(this.point.name)) / 1.0e+6)) + "M"
                          // Three Zeroes for Thousands
                          : Math.abs(Number(this.point.name)) >= 1.0e+3
                            ?
                            Math.round((Math.abs(Number(this.point.name)) / 1.0e+3)) + "K"
                            : Math.abs(Number(this.point.name));
                      // currencyData.forEach(ele => {
                      //   if(ele.CURRENCY == this.point.currency){
                      //    currencySign = ele.Sign;
                      //   }
                      //  });
                      fundSize = '€' + temp;
                    }
                    if (showFundSize) {
                      val = '<div style="text-align: center">' + fundSize + '</di>';
                    }
                    val = '<div style="text-align: center">' + fundSize + '</di>';
                    return val;
                  }
                },
                 // 10 pixels down from the top
                style: {
                  fontWeight: '100',
                  fontSize: "10px",
                  fontFamily: "Verdana, sans-serif",
                  textOutline: 0,
                },
              },
              marker: {
                enabled: false,
              },
              showInLegend: false
            },
          ],
        },
      );
      const appBanners = document.getElementsByClassName(
        "highcharts-credits"
      ) as HTMLCollectionOf<HTMLElement>;

      for (let i = 0; i < appBanners.length; i++) {
        appBanners[i].style.display = "none";
      }
    }
  }
  
  updateChartHeight(width, height){
    const chart = this.shared.chartTest;
    chart.setSize(width-20, 
    height-120);
  }

  getNewValue(value) {
    if (Commons.bestPassiveTopFunds.length > 0) {
      if (Commons.bestPassiveTopFunds[0].Name == Commons.bestPassiveTopFunds[1].Name) {
        const data = Commons.bestPassiveTopFunds[0].Compounded_Return_5_Year ? Commons.bestPassiveTopFunds[0].Compounded_Return_5_Year : 0;
        return data;
      }
      else {
        const data = Commons.bestPassiveTopFunds[2].Compounded_Return_5_Year;
        return data;
      }
    }
    else {
      return (value * 100).toFixed(2);
    }
  }

  getBestPassiveData(bestPassive) {
    this.shared.TopFundGlobalEqualityX = [];
    this.shared.TopFundGlobalEqualityY = [];
    this.shared.chartYValue = [];
    this.shared.fundActive = false;
    const fundSizeDisplay = localStorage.getItem('fundSizesDisplay');
    if (fundSizeDisplay == "On") {
      this.shared.fundActive = true;
    }
    if (bestPassive[0].Name == bestPassive[1].Name) {
      this.shared.TopFundGlobalEqualityX.push(bestPassive[0].Name);
      const val = (bestPassive[0].Compounded_Return_5_Year * 100).toFixed(2);
      this.shared.TopFundGlobalEqualityY.push({
        y: parseFloat(val),
        color: "#AB036E",
        name: bestPassive[0].monthendtna_value,
        fundName: bestPassive[0].Name,
        tooltip: true,
        date: this.datePipe.transform(bestPassive[0].Year, 'dd-MM-yyyy'),
        bestPassive: true,
        fundId: bestPassive[0].id,
        currency: bestPassive[0].currency,
      })
    } else {
      this.shared.TopFundGlobalEqualityX.push(bestPassive[2].Name);
      const val = (bestPassive[2].Compounded_Return_5_Year * 100).toFixed(2);
      this.shared.TopFundGlobalEqualityY.push({
        y: parseFloat(val),
        color: "#AB036E",
        name: bestPassive[2].monthendtna_value,
        fundName: bestPassive[2].Name,
        bestPassive: true,
        tooltip: true,
        date: this.datePipe.transform(bestPassive[2].Year, 'dd-MM-yyyy'),
        fundId: bestPassive[2].id,
        currency: bestPassive[2].currency,
      })
    }
    if (this.shared.TopFundGlobalEqualityY) {
      this.shared.chartYValue.push(this.shared.TopFundGlobalEqualityY[0].y);
    }
    this.topFundchart();
  }

  topFundChartSeriesData(data){
    const j = this.shared.colorTopFundPushIndex.toString();
    const value = (data["compounded_return_05"] * 100).toFixed(2);
    this.shared.TopFundGlobalEqualityY.push({
      y: parseFloat(value),
      color: Commons.allcolor[j.charAt(j.length - 1)],
      name: data["monthendtna_value"] ? data["monthendtna_value"].toFixed(2):'',
      fundId: data["id"],
      fundName: data["name_value"],
      tooltip: true,
      date: this.datePipe.transform(data["Year"], 'dd-MM-yyyy'),
      esgInclude: data["Included"] ? data["Included"] : false,
      esgExclude: data["Excluded"] ? data["Excluded"] : false,
      consistency: data["consistency"],
      currency: data["currency"],
    });
    this.shared.colorTopFundPushIndex++;
  }

 async getTopFundDataXY(res, totalyear, diamond) {
    this.shared.fundsAvailable = false;
    let fundSize: any;
    this.shared.fundActive = false;
    Commons.consistencyData = [];
    Commons.consistencyFundId = [];
    let fundSizeDisplay = localStorage.getItem('fundSizesDisplay');
    if (fundSizeDisplay == "On") {
      this.shared.fundActive = true;
    }
    if (localStorage.getItem("fundSizes")) {
      fundSize = JSON.parse(localStorage.getItem("fundSizes"));
    }
    this.shared.diamonds = diamond;
    this.shared.TopFundGlobalEqualityX = [];
    this.shared.TopFundGlobalEqualityY = [];
    let colorToPushIndex = 0;
    let colorToPushIndexc = 0;
    this.shared.colorTopFundPushIndex = 0;
  await  res.topData.forEach((data) => {
        if (data["monthendtna_value"] == "NULL") {
          data["monthendtna_value"] = "0";
        }
  
        if (Commons.selectedScore.includes(data["consistency"])) {
          Commons.consistencyData.push(data["id"]);
          Commons.consistencyFundId.push(data["id"]);
        }
        let c = colorToPushIndex.toString();
        if (totalyear == "5") {
          if (Commons.selectedScore.includes(data["consistency"])) {
            this.shared.fundsSecIds.push(data["SecId"]);
            let j = colorToPushIndex.toString();
            if (fundSize?.length > 0) {
                fundSize.forEach(element => {
                if (element.id == "0-50M") {
                  if (data["monthendtna_value"] >= 0 && data["monthendtna_value"] < 50000000 && data["monthendtna_value"] != "") {
                    this.topFundChartSeriesData(data);
                  }
                }
                if (element.id == "50-100M") {
                  if (data["monthendtna_value"] > 50000000 && data["monthendtna_value"] < 100000000 && data["monthendtna_value"] != "") {
                    this.topFundChartSeriesData(data);
                  }
                }
                if (element.id == "100-500M") {
                  if (data["monthendtna_value"] > 100000000 && data["monthendtna_value"] < 500000000 && data["monthendtna_value"] != "") {
                    this.topFundChartSeriesData(data);
                  }
                }
                if (element.id == "500M+") {
                  if (data["monthendtna_value"] > 500000000 && data["monthendtna_value"] != "") {
                    this.topFundChartSeriesData(data);
                  }
                }
                else {
                }
              });
            } else {
              this.topFundChartSeriesData(data);
            }
            colorToPushIndex++;
            if(colorToPushIndex == Commons.allcolor.length){
              colorToPushIndex = 0;
            }
          }
        } else if (totalyear == "3") {
          if (Commons.selectedScore.includes(data["consistency"])) {
            this.topFundChartSeriesData(data);
          }
        } else {
          if (Commons.selectedScore.includes(data["consistency"])) {
            this.topFundChartSeriesData(data);
          }
        }
        let FundSizeAndNames = {
          name: data["name_value"],
          fSize: data["monthendtna_value"]
        };
        this.shared.fundSizeTopFund.push(FundSizeAndNames);
        colorToPushIndexc++;
      
    });

  await  this.shared.TopFundGlobalEqualityY.sort(function (a, b) {
      return b.consistency - a.consistency;
    });

    this.shared.TopFundGlobalEqualityX = [];
    this.shared.chartYValue = [];
    let i = 0, len = this.shared.TopFundGlobalEqualityY.length;
    while (i < len){
      this.shared.chartYValue.push(this.shared.TopFundGlobalEqualityY[i].y);
      this.shared.TopFundGlobalEqualityX.push(this.shared.TopFundGlobalEqualityY[i].fundName);
      i++;
    }
  // this.shared.TopFundGlobalEqualityY.forEach(element => {
  //   console.count('Calling Api');
  //     this.chartYValue.push(element.y);
  //     this.shared.TopFundGlobalEqualityX.push(element.fundName);
  //   });
    this.topFundchart();
  }

  openSummaryCard(event) {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": event.target.fundId +"_summary_popup",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.summaryPopup.openSummaryCardTopFund(event);
  }

  getSummeryState(event) {
    this.shared.showPopupSummary = event.showState
  }


  getNewsData(event) {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": event.target.fundId +"_marketInsight_tooltip",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
      if(this.newsFundData){
        this.newsFundData.unsubscribe();
      }
    this.shared.newsFundDataTopFund = event;
    this.shared.newsLoadingTopFund = true;
    this.shared.dataNot = false;
    this.shared.topFundNewsFeedData = [];
    this.newsFundData = this.newsFund.getNewsForFund(event.target.fundId).subscribe((res) => {
      this.shared.newsLoadingTopFund = false;
      if (res["data"]) {
        res["data"].forEach(element => {
          if(!this.shared.topFundNewsFeedData.some((item) => item.Title == element.Title && item.Provider == element.Provider)){
            this.shared.topFundNewsFeedData.push(element);
          }
        });
      }
      else {
        this.shared.dataNot = true;
      }
      this.newsData();
    }, (err) => {
      this.shared.newsLoadingTopFund = false;
      this.shared.dataNot = true;
    })
    document.getElementById("tooltip-buttons").style.display = "none";
    document.getElementById("newsFeedDataOnTooltip").style.display = "block";
    setTimeout(() => {
      document.getElementById(
        "newsFeedDataOnTooltip"
      ).innerHTML = this.newsComponentTopFund ? this.newsComponentTopFund.nativeElement.innerHTML : `Data not Available`;
    }, 10)
  }

  ClickedOut(event) {
    if(event.target.className === "pg-form-pages login-page") {
      document.getElementById("close-documentation").click();
    }
  }

  getFactsheet(event) {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": event.target.fundId +"_factsheet_tooltip",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if (this.shared.fundDocumentationData.length > 0) {
      this.shared.fundDocumentationData.forEach(element => {
        if (event.target.fundName == element.Name && element.Factsheet_Url != "null" && element.Factsheet_Url != undefined) {
          window.open(element.Factsheet_Url);
        }else {
          this.openDialog();
        }
      });
    }else{
      this.openDialog();
    }
  }

  openDialog() {
    this.shared.popupTimer = 3;
    document.getElementById("openModalButton").click();
    const interval = setInterval(() => {
        if(this.shared.popupTimer > 0) {
          this.shared.popupTimer--;
        }else if(this.shared.popupTimer == 0) {
          document.getElementById("close-documentation").click();
          clearInterval(interval);
        }
    },1000);
  }

  getKiid(event) {
    let createdTime = new Date();
      let modal = {
        "createdAt": createdTime,
        "eventName": event.target.fundId +"_kiid_tooltip",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if (this.shared.fundDocumentationData.length > 0) {
      this.shared.fundDocumentationData.forEach(element => {
        if (event.target.fundName == element.Name && element.KIID_Url != "null" && element.KIID_Url != undefined) {
          window.open(element.KIID_Url);
        }else{
          this.openDialog();
        }
      });
    } else {
      this.openDialog();
    }
  }
  newsData() {
    setTimeout(() => {
      document.getElementById("newsFeedDataOnTooltip").style.display = "block";
      document.getElementById(
        "newsFeedDataOnTooltip"
      ).innerHTML = this.newsComponentTopFund.nativeElement.innerHTML;
    }, 5)
    // }

    setTimeout(() => {
      document
        .getElementById("closeTopFundNew")
        .addEventListener("click", this.closeNewsForTopFund.bind(this));
    }, 10)
  }                                                                                     

  getAlertsData(event) {
    let createdTime = new Date();
    let data:any =[];
      let modal = {
        "createdAt": createdTime,
        "eventName": event.target.fundId +"_alerts",
        "sessionId": Commons.sessionId,
        "userId": Commons.userDetails.userID,
        "userEmail": Commons.userDetails.Email,
        "userName": Commons.userDetails.Name,
        "platform": Commons.platform
      }
      this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.alertsDataTopFund = event;
    this.shared.alertLoadingTopFund = true;
    this.shared.dataAvailableTopFund = true;
    this.fundAlerts.getFundAlerts(event.target.fundId).subscribe((res) => {
      this.shared.alertLoadingTopFund = false;
      this.shared.fundAlertsDataTopFund = [];
      if (res["length"] > 0) {
         data = res;
        data.forEach(element => {
          const a = element.event_value;
          const regex = /\s* :* *\T.*\)(|(?=\s+\}))/g;
          const subst = `""`;
          var d = a.replace(regex, subst);
          var obj = d.replace(/'/g, '"').replace(/None/g, '"None"');
          

          element.event_value = JSON.parse(obj);
        this.shared.fundAlertsDataTopFund.push({
          occurrenceDate: element.effective_date,
          publishedData: element.date_event_noticed,
          newText: element.event_value.new_value != "None" ? element.event_value.new_value : "",
          name: element.Fund_Name, selectLike: false, fundId: element.ShareClass_Id,
          data: element.alert_type + ' : ' + element.event_type.replace(/_/g, " "),
          text: element.event_value.old_value != "None" ? element.event_value.old_value : "",
          selectDelete: false, selectClose: false, show: false
        });
      })
      } else {
        this.shared.dataAvailableTopFund = false;
      }
      this.alertData();
    }, (err) => {
      this.shared.alertLoadingTopFund = false;
      this.shared.dataAvailableTopFund = false;
    });
    document.getElementById("tooltip-buttons").style.display = "none";
    document.getElementById("newsFeedDataOnTooltip").style.display = "block";
    setTimeout(() => {
      document.getElementById(
        "newsFeedDataOnTooltip"
      ).innerHTML = this.alertComponentTopFund ? this.alertComponentTopFund.nativeElement.innerHTML : `Data not Available`;
    }, 10)
  }

  alertData() {
    setTimeout(() => {
      document.getElementById("newsFeedDataOnTooltip").style.display = "block";
      document.getElementById(
        "newsFeedDataOnTooltip"
      ).innerHTML = this.alertComponentTopFund.nativeElement.innerHTML;
    }, 5)

    setTimeout(() => {
      document
        .getElementById("closeTopFundNew")
        .addEventListener("click", this.closeNewsForTopFund.bind(this));
    }, 10)
  }

  closeNewsForTopFund() {
    this.shared.topFundNewsFeedData = [];
    this.shared.newsFundDataTopFund = null;
    document.getElementById("newsFeedDataOnTooltip").style.display = "none";
    document.getElementById("tooltip-buttons").style.display = "block";
  }


}
