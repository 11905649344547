import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'searchGeo'
})
export class SearchGeoPip implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value: any, args: any): any {
    
    if(args){
        return value.filter(snapshot => {
            return snapshot.name.toLowerCase().includes(args.toLowerCase());
          });
    }

    return value
    
  }
//   transform(value: any, args: any): any {
//     if (!args) {return value;}
//     var re = new RegExp(args, 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
//     return value.replace(re, "<mark>$&</mark>");
// }
}