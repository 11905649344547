import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ForgetPasswordService } from 'src/services/AuthServices/forget-password.service';
import { MnFullpageService } from 'ngx-fullpage';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  temPassword: any;
  password: any;
  confirmPassword: any;
  id: any;
  loginPress = false;
  errorMsg: any;
  isPressed: boolean;
  valid = false;
  tempPasswordValid=true;
  newPassword=true;
  confirmNewPassword=true;
  errMsg:any;
  constructor(
    public http: HttpClient, 
    private route: ActivatedRoute,
    private router: Router,
    public fullpageService: MnFullpageService,
    public resetPwd: ForgetPasswordService,
  ) { }

  ngOnInit(): void {
    document.getElementById("showBlock").click();
    const data = this.route
      .queryParams
      .subscribe(params => {
        this.id = params.ID;
      });
  }

  goToLogin(){
    this.router.navigateByUrl('/login');
  }

  goToHome(){
    this.router.navigateByUrl('/home');
  }


  onTempPasswordInput(event) {
    this.temPassword = event.target.value;
    this.tempPasswordValid =  true;
  }

  onPasswordInput(event) {
    this.password = event.target.value;
    this.newPassword = true;
  }

  onConfirmPasswordInput(event) {
    this.confirmPassword = event.target.value;
    this.confirmNewPassword = true;
  }

  changePassword() {
    this.isPressed = true;
    if(this.temPassword == undefined && this.temPassword == null){
      this.tempPasswordValid = false;
      this.isPressed = false;
    }
    if(this.password == undefined && this.password == null){
      this.newPassword = false;
      this.isPressed = false;
    }
    if(this.confirmPassword == undefined && this.confirmPassword == null){
      this.confirmNewPassword = false;
      this.isPressed = false;
    }
    if(this.confirmPassword != null){
      if(this.password != this.confirmPassword){
        this.confirmNewPassword = false;
        this.newPassword = false;
        this.isPressed = false;
        this.errMsg = "Password not match";
        setTimeout(() => {
          this.errMsg = ""
        }, 2000);
      } else{

        const userDetail = {
          "password": this.temPassword,
          "newpassword": this.password,
          "id": this.id
      }
      this.http.post(environment.baseUrl + "changepassword", userDetail, httpOptions).subscribe((res) => {
        if(res["status"] === 200){
          this.router.navigateByUrl('/login');
        } else{
          this.errMsg = res["message"];
          setTimeout(() => {
            this.errMsg = "";
          }, 2000);
        }
        this.isPressed = false;
      }, (err) => {
        this.isPressed = false;
        console.log(err);
      })
      }
    }

  }

}
