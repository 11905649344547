import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject, Subscription } from "rxjs";
import { Commons, Shared } from 'src/utils/common';
import { decode } from 'string-encode-decode';
import { apiError, authorizeHeader } from 'src/utils/common-function';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class GrowthViewService {
  fundName:any;
  checkGrowthApiRequest:Subscription;
  
  constructor(public http: HttpClient, public shared: Shared) { }

  getGrowthData(userId, category, date){
    if(this.checkGrowthApiRequest){
      this.checkGrowthApiRequest.unsubscribe();
      this.shared.growthChartLoading = false;
      this.shared.growthChartDataNotAvailable = true;
      this.shared.growthChartApiErr = true;
    }
    this.shared.growthChartLoading = true;
    this.shared.growthChartDataNotAvailable = false;
    this.shared.growthChartApiErr = false;
    this.shared.yAxisData = [];
    // alert('test')
    // let startDate = localStorage.getItem('startDate');
    // let endDate = localStorage.getItem('endDate');
    this.checkGrowthApiRequest = this.http.get(environment.baseUrl + 'gettopfundgrowth/' + userId + '?category=' + category + '&date=' + date + '&time_period=' + localStorage.getItem('time')).subscribe((res)=>{
      this.shared.growthChartLoading = false;
       if(res["data"]){
        this.shared.growthChartDataNotAvailable = false;
        this.createData(res["data"]);
        this.shared.growthChartData = res["data"];
        // this.summaryPopupchart = res["data"];
        this.shared.growthChartObservable.next(res);
       } else{
        this.shared.growthChartDataNotAvailable = true;
       }
     }, (err) =>{
      this.shared.growthChartLoading = false;
      this.shared.growthChartDataNotAvailable = true;
      this.shared.growthChartApiErr = true;
      apiError(err);
     })
  }

  getBestPassive(userId, category, id, date){
    if(this.checkGrowthApiRequest){
      this.checkGrowthApiRequest.unsubscribe();
      if(this.shared.myFundsChart){
      }else{
        this.shared.growthChartLoading = false;
        this.shared.growthChartDataNotAvailable = true;
        this.shared.growthChartApiErr = true;
      }
    }
    // alert('bets passive');
    if(this.shared.myFundsChart){
    }else if(!this.shared.myFundsChart){
    this.shared.growthChartLoading = true;
    this.shared.growthChartDataNotAvailable = false;
    this.shared.growthChartApiErr = false;
    }
    this.shared.yAxisData = [];
    // let startDate = localStorage.getItem('startDate');
    // let endDate = localStorage.getItem('endDate');
    
    this.checkGrowthApiRequest = this.http.post(environment.baseUrl + 'gettopfundgrowthwithbestpassive/' + userId + '?category=' + category + '&date=' + date + '&time_period=' + localStorage.getItem('time') ,id, {headers:authorizeHeader() }).subscribe((res)=>{
      this.shared.growthChartLoading = false;
       if(res["data"]){
        this.shared.growthChartDataNotAvailable = false;
        const data = res["data"];
        this.createData(res["data"]);
        this.shared.growthChartData = res["data"];
        // this.summaryPopupchart = res["data"];
        this.shared.growthChartObservable.next(res);
       } else{
        this.shared.growthChartDataNotAvailable = true;
       }
     }, (err) =>{
      this.shared.growthChartLoading = false;
      this.shared.growthChartApiErr = true;
      apiError(err);
     })
  }

  getGrowthDataDates(userId, category, date){
    if(this.checkGrowthApiRequest){
      this.checkGrowthApiRequest.unsubscribe();
      this.shared.growthChartLoading = false;
      this.shared.growthChartDataNotAvailable = true;
      this.shared.growthChartApiErr = true;
    }
    this.shared.growthChartLoading = true;
    this.shared.growthChartDataNotAvailable = false;
    this.shared.growthChartApiErr = false;
    this.shared.yAxisData = [];
    const start_date = localStorage.getItem('startDate');
    const end_date = localStorage.getItem('endDate');
    this.checkGrowthApiRequest = this.http.get(environment.baseUrl + 'gettopfundgrowth/' + userId + '?category=' + category + '&date=' + date + 'start_date=' + start_date + '&end_date=' + end_date).subscribe((res)=>{
      this.shared.growthChartLoading = false;
       if(res["data"]){
        this.shared.growthChartDataNotAvailable = false;
        // this.summaryCard = data.funds;
        this.createData(res["data"]);
        this.shared.growthChartData = res["data"];
        this.shared.growthChartObservable.next(res);
       } else{
        this.shared.growthChartDataNotAvailable = true;
       }
     }, (err) =>{
      this.shared.growthChartLoading = false;
      this.shared.growthChartDataNotAvailable = true;
      this.shared.growthChartApiErr = true;
      apiError(err);
     })
  }

  topFundSummary(name){
      const index = this.shared.chartData.findIndex(ele => ele.name === name);
      this.shared.summaryPopupchart = this.shared.chartData[index];
  }

  async createData(data){
    // this.chartData = [];
    this.shared.chartsObject = [];
    let colorToPushIndex = 0;
      await data.funds.forEach((element, indexes) => {
      // if(Commons.consistencyFundId.includes(element.id)){
        const j = colorToPushIndex.toString();
        let fundSize:any = null;
          let fundDetail = this.shared.topFundData.filter((x) => { return x.id == element.id});
          if(fundDetail && fundDetail.length > 0){
            fundSize = fundDetail[0]["monthendtna_value"] ? fundDetail[0]["monthendtna_value"]?.toFixed(2):'';
          }
          // console.log('fundSize', fundSize);
        this.shared.chartsObject.push({name:element.name, 
          data:[], id:element.id, fundId:element.id,
          isSearched: this.shared.fundSearchValues.includes(element.name) ? true : false,
          dashStyle:Commons.bestPassiveIds.includes(element.id) ? 'DashDot' : 'Solid',
          isBestPassive:Commons.bestPassiveIds.includes(element.id) ? true : false,
          isTopFund:Commons.topFundSecId.includes(element.id) ?  true : false, 
          fundSize: Number(fundSize),
          color: Commons.allcolor[j.charAt(j.length - 1)]});
           data.index.forEach((ele, i) => {
          // this.chartData[indexes].date.push(data.index[i])
          if(element.values[element.values.length - 1] == 0){
            element.values.splice(element.values.length - 1, 1);
          }else{
            if(element.values[i]){
              // this.shared.chartsObject[indexes].data.push([(new Date(data.index[i].replace('-','/'))).getTime(), element.values[i]])
              const date = data.index[i].split('-');
              this.shared.chartsObject[indexes].data.push([Date.UTC(date[0], date[1]-1, date[2]), element.values[i]]);
            }
          }
          });
      colorToPushIndex++;
      if(colorToPushIndex == Commons.allcolor.length){
        colorToPushIndex = 0;
      }
      // }
    }); 
    this.getChartData();
  }

  getSearchedData(data){
    let colorToPushIndex = 0;
    data.funds.forEach((element) => {
        if(!this.shared.chartsObject.some((item) => item.name === element.name)){
          const j = colorToPushIndex.toString();
          this.shared.chartsObject.push({name:element.name, 
            data:[], id:element.id, fundId:element.id,
            isSearched:true,
            dashStyle:Commons.bestPassiveIds.includes(element.id) ? 'DashDot' : 'Solid',
            isBestPassive:Commons.bestPassiveIds.includes(element.id) ? true : false,
            isTopFund:Commons.topFundSecId.includes(element.id) ?  true : false, 
            color: Commons.allcolor[j.charAt(j.length - 1)]});
            const index = this.shared.chartsObject.findIndex(item => item.name === element.name);
             data.index.forEach((ele, i) => {
            if(element.values[element.values.length - 1] == 0){
              element.values.splice(element.values.length - 1, 1);
            }else{
              if(element.values[i]){
                const date = data.index[i].split('-');
                this.shared.chartsObject[index].data.push([Date.UTC(date[0], date[1]-1, date[2]), element.values[i]]);
              }
            }
            });
        colorToPushIndex++;
        if(colorToPushIndex == Commons.allcolor.length){
          colorToPushIndex = 0;
        }
        }
    }); 
    this.getChartData();
  }

 async getChartData(){
   if(this.shared.myFundsChart){
    this.shared.summaryChartData = [];
   }else{
    this.shared.chartData = [];
   }
  let colorToPushIndex = 0;
    if(this.shared.myFundsChart){
      let i = 0, len = this.shared.chartsObject.length;
      while (i < len) {
        // console.count('i0');
        const j = colorToPushIndex.toString();
        this.shared.chartsObject[i].color = Commons.allcolor[j.charAt(j.length - 1)];
        this.shared.summaryChartData.push(this.shared.chartsObject[i]);
        colorToPushIndex++;
        if(colorToPushIndex == Commons.allcolor.length){
          colorToPushIndex = 0;
        }
        
        i++;
      }
    }else{
      let i = 0, len = this.shared.chartsObject.length;
      while (i < len) {
        // console.count('i0');
        const j = colorToPushIndex.toString();
        if(Commons.consistencyFundId.includes(this.shared.chartsObject[i].id)){
          this.shared.chartsObject[i].color = Commons.allcolor[j.charAt(j.length - 1)];
          this.shared.chartData.push(this.shared.chartsObject[i]);
          colorToPushIndex++;
          if(colorToPushIndex == Commons.allcolor.length){
            colorToPushIndex = 0;
          }
        }
        if(this.shared.chartsObject[i].isSearched){
          this.shared.chartData.push(this.shared.chartsObject[i]);
          this.shared.chartsObject[i].color = Commons.allcolor[j.charAt(j.length - 1)];
          colorToPushIndex++;
          if(colorToPushIndex == Commons.allcolor.length){
            colorToPushIndex = 0;
          }
        }
        if(this.shared.chartsObject[i].isBestPassive){
          this.shared.chartData.push(this.shared.chartsObject[i]);
          colorToPushIndex++;
          if(colorToPushIndex == Commons.allcolor.length){
            colorToPushIndex = 0;
          }
        }
          i++;
      }
    }
  }

  searchFundGrowth(name, page){
   return this.http.get(environment.baseUrl + 'searchGrowthFunds/?name=' + name + '&offset=10' + '&page=' + page);
  }

  checkFundGrowth(id, start_date, end_date){
    if(this.checkGrowthApiRequest){
      this.checkGrowthApiRequest.unsubscribe();
      this.shared.growthChartLoading = false;
      this.shared.growthChartDataNotAvailable = true;
      this.shared.growthChartApiErr = true;
    }
    this.shared.growthChartLoading = true;
    this.shared.growthChartDataNotAvailable = false;
    this.shared.growthChartApiErr = false;
    this.checkGrowthApiRequest = this.http.post(environment.baseUrl + 'checkFundGrowth/?start_date=' + start_date + '&end_date=' + end_date, id, {headers : authorizeHeader()}).subscribe((res)=>{
      if (res["data"]) {
        // this.getSearchedData(res["data"]);
        this.createData(res["data"]);
        this.shared.growthChartData = res["data"];
        this.shared.growthChartObservable.next(res);
        this.shared.growthChartLoading = false;
      } else {
        this.shared.growthChartDataNotAvailable = true;
      }
     }, (err)=>{
      this.shared.growthChartLoading = true;
      this.shared.growthChartDataNotAvailable = false;
      this.shared.growthChartApiErr = true;
      apiError(err);
     })
  }

  checkFundGrowthTimePerioed(id,  year){
    if(this.checkGrowthApiRequest){
      this.checkGrowthApiRequest.unsubscribe();
      this.shared.growthChartLoading = false;
      this.shared.growthChartDataNotAvailable = true;
      this.shared.growthChartApiErr = true;
    }
    this.shared.growthChartLoading = true;
    this.shared.growthChartDataNotAvailable = false;
    this.shared.growthChartApiErr = false;
    this.checkGrowthApiRequest = this.http.post(environment.baseUrl + 'checkFundGrowth/?time_period=' + year, id, {headers : authorizeHeader()}).subscribe((res)=>{
      if (res["data"]) {
        // this.getSearchedData(res["data"]);
        this.createData(res["data"]);
        this.shared.growthChartData = res["data"];
        this.shared.growthChartObservable.next(res);
        this.shared.growthChartLoading = false;
      } else {
        this.shared.growthChartDataNotAvailable = true;
      }
     }, (err)=>{
      this.shared.growthChartLoading = true;
      this.shared.growthChartDataNotAvailable = false;
      this.shared.growthChartApiErr = true;
      apiError(err);
     })
  }

  getTopFundGrowthWithBestPassive(userId, category, id, date){
    return  this.http.post(environment.baseUrl + 'gettopfundgrowthwithbestpassive/' + userId +'?category=' + category + '&date=' + date , id, {headers : authorizeHeader()})
  }

  addBestPassive(date) {
    this.shared.bestPassiveGrowthService = [];
    let bestPassiveIds = [];
    bestPassiveIds = Commons.topFundSecId;
    Commons.bestPassiveIds = [];
    if (localStorage.getItem('bestPassive')) {
      this.shared.bestPassiveGrowthService = JSON.parse(localStorage.getItem('bestPassive'));
      if (Commons.bestPassiveData != undefined) {
        this.shared.bestPassiveGrowthService.forEach(element => {
          if (element.id == "1 YEAR-ILIM Indexed Name" && element.show) {
            Commons.bestPassiveData.forEach(ele => {
              if (ele.Type == "01") {
                bestPassiveIds.push(ele.id);
                Commons.bestPassiveIds.push(ele.id);
              }
            })
          }
          else if (element.id == "3 YEAR-VANGAURD Name" && element.show) {
            Commons.bestPassiveData.forEach(ele => {
              if (ele.Type == "03") {
                bestPassiveIds.push(ele.id);
                Commons.bestPassiveIds.push(ele.id);
              }
            })
          }
          else if (element.id == "5 YEAR-ILIM Indexed Name" && element.show) {
            Commons.bestPassiveData.forEach(ele => {
              if (ele.Type == "05") {
                bestPassiveIds.push(ele.id);
                Commons.bestPassiveIds.push(ele.id);
              }
            })
          }
        })
        if(this.shared.myFundsChart){
          this.shared.myFundIds.forEach(element => {
            if(!bestPassiveIds.includes(element)){
              bestPassiveIds.push(element);
            }
          });
        }
          this.getBestPassive(decode(localStorage.getItem("ID")), localStorage.getItem("chartCategory"), bestPassiveIds, date);
      }
    }else{
      if(this.shared.myFundsChart){
        this.shared.myFundIds.forEach(element => {
          if(!bestPassiveIds.includes(element)){
            bestPassiveIds.push(element);
          }
        });
      }
      this.getBestPassive(decode(localStorage.getItem("ID")), localStorage.getItem("chartCategory"), bestPassiveIds, date);
    }
  }
}
