import { Component, EventEmitter, ViewChild, OnInit, Input, Output, ElementRef } from '@angular/core';
const Highcharts = require("highcharts");
import { Commons, Shared } from '../../utils/common';
import { CommonService } from 'src/services/CommonServices/common.service';
import { DateRangeComponent } from "../date-range/date-range.component";
import { GrowthViewService } from 'src/services/growth-view/growth-view.service';
import { decode } from 'string-encode-decode';
import { NewsFundsService } from 'src/services/news-for-funds/news-funds.service';
import { SummaryPopupService } from 'src/services/summary-popup/summary-popup.service';
import { DatePipe } from "@angular/common";
import { Subscription } from 'rxjs';
import { FundDocumentaionService } from 'src/services/fund-documentation/fund-documentaion.service';
import { FundAlertsService } from 'src/services/fund-Alerts/fund-alerts.service';
import { apiError } from 'src/utils/common-function';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/services/google-Analytics/google-analytics.service';
declare let ga: Function;
@Component({
  selector: 'app-ms-growth-view',
  templateUrl: './ms-growth-view.component.html',
  styleUrls: ['./ms-growth-view.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class MsGrowthViewComponent implements OnInit {
  @Output("closeCharts") closeCharts: EventEmitter<any> = new EventEmitter();
  @Output() summaryPopUpToolTip = new EventEmitter<string>();
  @ViewChild('stockContainer') stockContainer: ElementRef;
  @ViewChild('growthChart') growthChart: ElementRef;
  @ViewChild('dateRange') dateRange: DateRangeComponent;
  @ViewChild('menu') menu: ElementRef;
  @Input() selectedDataDropDown: any;
  @ViewChild("newsComponent") newsComponent: ElementRef;
  @ViewChild("alertComponent") alertComponent: ElementRef;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  isShareClass: Subscription;
  selectColoum = false;
  graphheight: any;
  graphWidth: any;
  bestPassive: any[] = [];
  dataNot: boolean;
  newsFeedData: any[] = [];
  userDashboard = true;
  paging: any = 0;
  showDropDown = false;
  fundDetails: any[] = [];
  bestPassiveIds: any;
  selectedDate: boolean;
  dataAvailable: boolean;
  errMsg: any;
  scrolled: boolean;

  public chartTimePeriod: any = [
    { name: "YTD", select: false },
    { name: "1Y", select: false },
    { name: "2Y", select: false },
    { name: "3Y", select: false },
    { name: "5Y", select: false },
    { name: "MAX", select: false },
  ];
  dataSubscription: Subscription;
  @Input() dataNotAvailable: boolean;
  // dataNotAvailable = false;
  @Output("getHeightWidth") getHeightWidth: EventEmitter<any> = new EventEmitter();
  constructor(public common: CommonService, public growthChartService: GrowthViewService, public datePipe: DatePipe,
    public newsFund: NewsFundsService, public summaryPopupService: SummaryPopupService, public fundService: FundDocumentaionService,
    public fundAlerts: FundAlertsService, public router: Router, public googleAnalyticsEventsService: GoogleAnalyticsService,
    public shared: Shared) {
  }

  closeChart() {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "msGrowth_closechart",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.closeCharts.emit();
  }

  onResizedDivGrowthChart() {
    setTimeout(() => {
      if (this.growthChart.nativeElement.offsetHeight != 0) {
        this.graphheight = this.growthChart.nativeElement.offsetHeight;
        this.graphWidth = this.growthChart.nativeElement.offsetWidth;
        this.drawMsGrowthChart();
      }
    }, 100)

  }



  ngOnInit(): void {
    if (Commons.userDashboardshow) {
      this.router.config.forEach(event => {
        if (event.path == 'user-dashboard') {
          // gtag('config', 'UA-211001570-1', 
          //            {
          //              'page_path': event.path              
          //            }
          //           );
          ga('send', {
            hitType: 'pageview',
            page: event.path
          });
        }
      });
    } else {
      this.router.config.forEach(event => {
        if (event.path == "growthView") {
          // gtag('config', 'UA-211001570-1', 
          //            {
          //              'page_path': event.path          
          //            }
          //           );
          ga('send', {
            hitType: 'pageview',
            page: event.path
          });
        }
      });
    }
    this.selectedDate = false;
    this.shared.selectedCategory.subscribe((res) => {
      if (res && res != "undefined") {
        this.shared.selectedCategoryGrowthView = res;
      }
    });
    const selectCate = localStorage.getItem("chartCategory");
    if (selectCate && selectCate != "undefined") {
      this.shared.selectedCategoryGrowthView = localStorage.getItem("chartCategory");
    }

    this.getTimeSaved();
    this.dataSubscription = this.shared.growthChartObservable.subscribe((res) => {
      this.getTimeSaved();
      this.onResizedDivGrowthChart();
    }, (err) => {

    });
    this.isShareClass = Commons.toggleShareClass.subscribe((res) => {
      this.growthChartService.getChartData();
      this.drawMsGrowthChart();
    });
    // this.drawMsGrowthChart();
    Commons.consistencyScoreChange.subscribe(() => {
      this.growthChartService.getChartData();
      if (Commons.selectedTab == "msGrowthView" || Commons.addViewEnable) {
        this.drawMsGrowthChart();
        // alert('test');
      }
    });
  }

  userGuideLink() {
    window.open("https://storage.googleapis.com/fundsaiq-fundsdocuments/userguide/Fundsaiq_userguide.pdf");
  }

  ngOnDestroy() {
    if (this.isShareClass) {
      this.isShareClass.unsubscribe();
    }
    if (Commons.userDashboardshow) {
      this.router.config.forEach(event => {
        if (event.path == 'user-dashboard') {
          // gtag('config', 'UA-211001570-1', 
          //            {
          //              'page_path': event.path              
          //            }
          //           );
          ga('send', {
            hitType: 'pageview',
            page: event.path
          });
        }
      });
    }
  }


  addBestPassive() {
    let bestPassive = [];
    const bestPassiveIds = [];
    if (localStorage.getItem('bestPassive')) {
      bestPassive = JSON.parse(localStorage.getItem('bestPassive'));
      if (Commons.bestPassiveTopFunds != undefined) {
        bestPassive.forEach(element => {
          if (element.id == "1 YEAR-ILIM Indexed Name") {
            Commons.bestPassiveTopFunds.forEach(ele => {
              if (ele.Type == "01") {
                bestPassiveIds.push(ele.id);
              }
            })
          }
          else if (element.id == "3 YEAR-VANGAURD Name") {
            Commons.bestPassiveTopFunds.forEach(ele => {
              if (ele.Type == "03") {
                bestPassiveIds.push(ele.id);
              }
            })
          }
          else if (element.id == "5 YEAR-ILIM Indexed Name") {
            Commons.bestPassiveTopFunds.forEach(ele => {
              if (ele.Type == "05") {
                bestPassiveIds.push(ele.id);
              }
            })
          }
        })
        this.growthChartService.getTopFundGrowthWithBestPassive(decode(localStorage.getItem("ID")), localStorage.getItem("chartCategory"), bestPassiveIds, this.datePipe.transform(localStorage.getItem('date'), 'yyyy-MM-dd')).subscribe((res) => {
        }, (err) => {
          apiError(err);
        })

      }
    }
  }

  getTimeSaved() {
    if (this.shared.filterTimeSelected) {
      if (localStorage.getItem('time')) {
        this.shared.timePeriod = localStorage.getItem('time');
      }
    } else if (this.shared.dateSelected) {
      this.selectedDate = true;
    } else {
      this.shared.timePeriod = '1Y';
      localStorage.setItem('time', this.shared.timePeriod);
    }
    this.chartTimePeriod.forEach(element => {
      if (element.name == this.shared.timePeriod) {
        element.select = true;
      } else {
        element.select = false;
      }
    });
  }

  onClick(event) {
    if (this.menu != undefined) {
      if (!this.menu.nativeElement.contains(event.target) && this.showDropDown) {
        this.showDropDown = false;
      }
    }
    if (this.shared.growthDropDown) {
      this.shared.growthDropDown = false;
    }
  }

  datePicker() {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "msGrowth_date_picker",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if (!this.showDropDown) {
      setTimeout(() => {
        this.showDropDown = true;
      }, 10);
    } else if (this.showDropDown) {
      setTimeout(() => {
        this.showDropDown = false;
      }, 10)
    }
  }


  openSummaryCardMsGrowth(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.target.series.options.fundId + "_summary_popup",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.alertsAndNotificationSummary = false;
    this.shared.growthViewSummaryPopup = true;
    this.summaryPopupService.fundName = event.target.series.name;
    this.shared.fundNameGrowthView = event.target.series.name;
    this.shared.summaryCardDataLoading = true;
    this.summaryPopupService.getSummaryPopupdetail(event.target.series.userOptions.id).subscribe((res: any) => {
      this.shared.summaryCardDataLoading = false;
      if (res.data) {
        this.fundDetails = res.data[0];
      }
      this.shared.summaryChartObservable.next();
      this.shared.isObservableCall = 0;
    }, (err) => {
      this.shared.summaryCardDataLoading = false;
      apiError(err);
    })
    event.preventDefault();
  }

  getSummeryState(event) {
    this.shared.growthViewSummaryPopup = event.showState;
  }

  onScroll() {
    const element = this.myScrollContainer.nativeElement
    const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
    if (this.scrolled && atBottom) {
      this.scrolled = false;
      this.shared.growthViewChartLoading = true;
      this.growthChartService.searchFundGrowth(this.shared.searchMyFund, this.paging++).subscribe((res) => {
        const data = res["data"];
        data.forEach(element => {
          this.shared.growthData.push(element);
        });
        this.shared.growthViewChartLoading = false;
      }, (err) => {
        this.shared.growthViewChartLoading = false;
        apiError(err);
      })
    } else {
      this.scrolled = true;
    }
  }

  setTotalTime(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.name + "_period",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.selectedDate = false;
    this.shared.filterTimeSelected = true;
    this.shared.dateSelected = false;
    this.shared.growthChartLoading = true;
    this.shared.growthChartDataNotAvailable = false;
    this.chartTimePeriod.forEach(element => {
      if (element.name == event.name) {
        element.select = true;
      } else {
        element.select = false;
      }
    });
    this.shared.timePeriod = event.name;
    localStorage.setItem('time', event.name);
    const fundI: any = [];
    this.shared.chartsObject.forEach((ele) => {
      fundI.push(ele.id)
    })
    this.growthChartService.checkFundGrowthTimePerioed(fundI, this.shared.timePeriod);

  }

  searchFunds(event) {
    if (event.length > 1) {
      this.shared.growthDropDown = true;
      this.paging = 0;
      this.shared.growthViewChartLoading = true;
      this.shared.searchMyFund = event;
      this.shared.growthData = [];
      this.growthChartService.searchFundGrowth(this.shared.searchMyFund, this.paging).subscribe((res) => {
        this.shared.growthData = [];
        this.shared.growthDropDown = true;
        this.shared.growthViewChartLoading = false;
        if (res["data"].length > 0) {
          const data = res["data"];
          data.forEach(element => {
            this.shared.growthData.push(element);
          });
        } else {
          this.shared.growthData = [];
          this.errMsg = "No Data Found";
        }
      }, (err) => {
        this.shared.growthViewChartLoading = false;
        apiError(err);
      })
    } else {
      this.shared.growthDropDown = false;
      this.shared.growthViewChartLoading = false;
      this.shared.growthData = [];
    }
  }

  selectedFromSearch(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.id + "_selected_fund",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.fundSearchValues.push(event.Name_Value);
    this.shared.growthChartLoading = true;
    this.shared.growthChartDataNotAvailable = false;
    this.shared.growthDropDown = false;
    const fundId = [];
    fundId.push(event.id);
    this.shared.chartsObject.forEach((ele) => {
      fundId.push(ele.id)
    })
    if (!this.selectedDate) {
      this.growthChartService.checkFundGrowthTimePerioed(fundId, this.shared.timePeriod);
    } else {
      this.growthChartService.checkFundGrowth(fundId, this.dateRange.startDate, this.dateRange.endDate)
    }
  }

  applyChanges() {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "apply_date_range",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if (this.showDropDown) {
      this.shared.timePeriod = null;
      this.selectedDate = true;
      this.shared.filterTimeSelected = false;
      this.shared.dateSelected = true;
      setTimeout(() => {
        this.showDropDown = false;
      }, 10)
      this.chartTimePeriod.forEach(element => {
        element.select = false;
      });
      const fundId = [];
      this.shared.chartsObject.forEach((ele) => {
        fundId.push(ele.id)
      })
      const endDate = this.dateRange.endDate;
      if (endDate) {
        this.growthChartService.checkFundGrowth(fundId, this.dateRange.startDate, this.dateRange.endDate);
      } else {
        this.growthChartService.checkFundGrowth(fundId, this.dateRange.startDate, this.dateRange.startDate);
      }
    }
  }

  cancel() {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "cancel",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
  }

  chartDraw: any
  drawMsGrowthChart() {

    let graphHeight;
    let graphWidth;
    let graphLabel;
    if (Commons.userDashboardshow) {
      this.userDashboard = true;
      graphHeight = Commons.chartHeightCollapseView - 120;
      graphWidth = this.shared.chartWidthCollapseView - 20;
      graphLabel = false;
      this.shared.yAxisData.forEach((element) => {
        element.showInLegend = false;
      });
      Highcharts.chart("containerChart", {
        chart: {
          backgroundColor: "#000f19",
          type: "line",
          color: "#fffff",
          height: graphHeight,
          style: {
            fontFamily: "sans-serif",
          },
        },
        credits: {
          enabled: false
        },
        tooltip: {
          enabled: false,
          disable: false,
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false
            },
          },
        },
        legend: {
          enabled: false,
          itemStyle: {
            color: "#606f88",
          },
        },
        title: {
          visible: false,
          text: "",
        },
        xAxis: {
          visible: false,
          // categories: this.XaxesData,
          categories: this.shared.xAxisData,
          labels: {
            enabled: false,
          },
          title: {
            // enabled: false,
            text: "",
            style: { color: "#606f88", fontSize: "17px" },
          },
        },
        yAxis: {
          title: {
            // enabled: false,
            text: "Relative Growth (%)",
            style: { color: "#606f88", fontSize: "12px" },
          },
          gridLineWidth: 0.5,
        },
        series: this.shared.chartData,
      });
    }
    else if (!Commons.userDashboardshow) {
      this.userDashboard = false;
      graphHeight = this.graphheight - 100;
      graphWidth = this.graphWidth - 100;
      graphLabel = true;
      this.shared.yAxisData.forEach((element) => {
        element.showInLegend = true;
      });
    }

    const myChart = Highcharts.stockChart("stockContainer", {
      chart: {
        backgroundColor: "#000f19",
        type: "line",
        color: "#fffff",
        height: graphHeight,
        style: {
          fontFamily: "sans-serif",
        },
        events: {
          load: function () {
            const chart = this;
            this.myTooltip = new Highcharts.Tooltip(this, this.options.tooltip);
            const legend = this.legend.group;

            Highcharts.each(this.legend.allItems, function (item) {
              const x = legend.translateX + item.legendGroup.translateX,
                y = this.legend.contentGroup.getBBox().y + legend.translateY + item.legendGroup.translateY + item.itemHeight;
              const styles = {
                fill: '#000f19',
                cursor: 'pointer',
                stroke: 'red',
                'stroke-width': '0',
                border: '0',
                // height:"10px",
                style: {
                  color: '#617087',
                  'font-size': '40px',
                }
              },
                hover = {
                  fill: '#000f19',
                  cursor: 'pointer',
                  stroke: 'red',
                  'stroke-width': '0',
                  border: '0',
                  // height:"10px",
                  style: {
                    color: '#48eee0',
                    'font-size': '40px',
                  }
                }
              if (!item.userOptions.isTopFund && !item.userOptions.isBestPassive) {
                const button = this.renderer
                  .button(
                    '-',
                    x + item.legendItem.actualWidth + 30,
                    y - 45,
                    function () {
                      chart.userOptions.series.forEach((element, i) => {
                        if (element.name == item.name) {
                          chart.userOptions.series.splice(i, 1);
                          item.destroy()
                          button.destroy();
                        }
                      });
                    },
                    styles,
                    hover,
                    { height: "10px", width: "20px" }
                  )
                  .add();
              }

            }, this);
          },
        }
      },
      scrollbar: {
        enabled: false
      },
      navigator: {
        // margin: 130,
        outlineColor: '#606f88',
        series: {
          type: 'line',
          // fillOpacity: 0,
          color: '#712f25',
          lineWidth: 2,
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        // align:"center",
        enabled: true,
        color: "#617087",
        itemDistance: 100,
        itemMarginTop: 10,
        itemStyle: {
          color: '#617087'
        },
        itemHoverStyle: {
          color: null
        },
      },
      rangeSelector: {
        enabled: false,
        buttonStyle: {
          borderColor: "#000f19",
          backgroundColor: "#000f19",
          color: "#606f88"
        },
        selected: 0,
        inputEnabled: false,
        allButtonsEnabled: true,
        verticalAlign: 'top',
        buttonPosition: {
          align: 'right'
        },
        inputPosition: {
          align: 'left'
        },
        buttonTheme: {
          fill: '#48eee0',
          style: {
            fill: '#48eee0',
            color: '#606f88',
          },
          padding: 10,
          fontWeight: 'bold',
          height: 18,
          width: null,
          r: 10,
          states: {
            hover: {
              fill: '#48eee0',
              style: {
                color: '#606f88',
              }
            },
            select: {
              fill: '#48eee0',
              style: {
                color: '#000f19'
              },

            }
          }
        },
      },
      tooltip: {
        enabled: true,
        disable: true,
        useHTML: true,
        stickOnContact: true,
        outside: false,
        borderRadius: 10,
        padding: 18,
        backgroundColor: "#000f19",
        crosshairs: false,
        split: false,
        formatter: function () {
          console.log('point name', this.point);
          let newVal:any;
                if (this.point.series.userOptions.fundSize != null) {
                  newVal = Math.abs(Number(this.point.series.userOptions.fundSize)) >= 1.0e+9

                    ? Math.round((Math.abs(Number(this.point.series.userOptions.fundSize)) / 1.0e+9)) + "B"
                    : Math.abs(Number(this.point.series.userOptions.fundSize)) >= 1.0e+6

                      ? Math.round((Math.abs(Number(this.point.series.userOptions.fundSize)) / 1.0e+6)) + "M"
                      // Three Zeroes for Thousands
                      : Math.abs(Number(this.point.series.userOptions.fundSize)) >= 1.0e+3
                        ?
                        Math.round((Math.abs(Number(this.point.series.userOptions.fundSize)) / 1.0e+3)) + "K"
                        : Math.abs(Number(this.point.series.userOptions.fundSize));
                }
          if (!this.point.series.userOptions.isBestPassive) {
            return (
              `<div id="tooltip-buttons-ms"><div class="tooltip-buttons-parent"><button class="button-tooltip" id="summary-card-popup-container" 
              type="button" onmouseover="this.style.borderColor='`+ this.point.color + `'" onmouseout="this.style.borderColor='#58677f'">SUMMARY CARD</button><br><div class="btn-flex"><button class="button-tooltip fact-btn" id="fact-sheet" onmouseover="this.style.borderColor='` + this.point.color + `'" onmouseout="this.style.borderColor='#58677f'" onmouseover="this.style.borderColor='` + this.point.color + `'" onmouseout="this.style.borderColor='#58677f'">FACTSHEET</button><button class="button-tooltip" id="kiid" onmouseover="this.style.borderColor='` + this.point.color + `'" onmouseout="this.style.borderColor='#58677f'">KIID</button></div><br><div class="btn-flex" style="margin-top:-15px;"><button class="button-tooltip fact-btn" id="alerts-ms-growth" onmouseover="this.style.borderColor='` + this.point.color + `'" onmouseout="this.style.borderColor='#58677f'">ALERTS</button><button class="button-tooltip" id="news-feeds-ms" onmouseover="this.style.borderColor='` + this.point.color + `'" onmouseout="this.style.borderColor='#58677f'">NEWS</button></div></div>` +
              "<br/>" +
              '<span style="color:#617088;max-width:100%; white-space: normal;margin-bottom: -8px;">' +
              this.point.series.name +
              "</span><br/>" +
              '<span style="color:#617088;max-width:100%; white-space: normal;margin-bottom: -8px;">' + Highcharts.dateFormat('%d-%m-%Y', new Date(this.point.x)) + '</span><br/>' +
              '<span style="color:' +
              this.point.color +
              '">' +
              "Relative Growth (%)" +
              ": <b>" +
              this.point.y.toFixed(2) + '% (5Y)' +
              '</span></b><br/></div><div id="newsFeedDataOnTooltip-ms" style="position: relative;top: -15px;left: -12px;"> </div><span style="color:white;max-width:100%; white-space: normal;display: block;  margin-bottom: -8px;">'
              + "Size=€"+(newVal != undefined ? newVal : 0)+" Consistency= 5 "+'</span>'
            );
          } else if (this.point.series.userOptions.isBestPassive) {
            return (`<span   style="color:#617088;position: relative;" 
             onmouseover="this.style.borderColor='`+ this.point.color + `'" onmouseout="this.style.borderColor='#58677f'">` + this.point.series.name + `</span>
             <br><span style="color:#617088;max-width:100%; white-space: normal; top: 7px">`+ Highcharts.dateFormat('%d-%m-%Y', new Date(this.point.x)) + `</span><br>
             <span style="color:` + this.point.color + `;top:5px;">` + this.point.y.toFixed(2) + '% (5Y)' + `</span>`)
          }
          else {
            return false;
          }
        },
      },
      plotOptions: {
        series: {
          stickyTracking: false,
          marker: {
            enabled: false,
            symbol: 'circle'
          },
          point: {
            events: {
              mouseOver: function (event) {
                setTimeout(() => {
                  if (document.getElementById("news-feeds-ms")) {
                    document
                      .getElementById("news-feeds-ms")
                      .addEventListener("click", this.getNewsDataMs.bind(this, event));
                  }
                }, 100);
                setTimeout(() => {
                  if (document.getElementById("fact-sheet")) {
                    document
                      .getElementById("fact-sheet")
                      .addEventListener("click", this.getFactsheet.bind(this, event));
                  }
                }, 100);
                setTimeout(() => {
                  if (document.getElementById("kiid")) {
                    document
                      .getElementById("kiid")
                      .addEventListener("click", this.getKiid.bind(this, event));
                  }
                }, 100);
                setTimeout(() => {
                  if (document
                    .getElementById("summary-card-popup-container")) {
                    document
                      .getElementById("summary-card-popup-container")
                      .addEventListener(
                        "click",
                        this.openSummaryCardMsGrowth.bind(this, event)
                      );
                  }

                }, 100);
                setTimeout(() => {
                  if (document
                    .getElementById("alerts-ms-growth")) {
                    document
                      .getElementById("alerts-ms-growth")
                      .addEventListener("click", this.getAlertsData.bind(this, event));
                  }
                }, 100);
              }.bind(this),
            },
          },
        },
      },
      title: {
        visible: false,
        text: "",
      },
      xAxis: {
        visible: false,
        // range: this.XaxesData,
        range: this.shared.xAxisData,
        labels: {
          enabled: false,
        },
        title: {
          // enabled: false,
          text: "Time (Months)",
          style: { color: "#606f88", fontSize: "17px" },
        },
      },
      yAxis: {
        tickInterval: 50,
        opposite: false,
        title: {
          // enabled: false,
          text: "Normalised Growth (%)",
          style: { color: "#606f88", fontSize: "12px" },
        },
        gridLineWidth: 0.5,
      },
      series: this.shared.chartData,
    })
    this.chartDraw = myChart;
    this.chartDraw.reflow()
  }


  deleteFundFromGrowth(event) {
  }

  getNewsDataMs(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.target.series.options.fundId + "_market_tooltip",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.newsLoadingGrowthView = true;
    this.shared.newsFundDataGrowthView = event;
    this.dataNot = false;
    this.newsFeedData = [];
    this.newsFund.getNewsForFund(event.target.series.userOptions.id).subscribe((res) => {
      this.shared.newsLoadingGrowthView = false;
      if (res["data"]) {
        res["data"].forEach(element => {
          if (!this.newsFeedData.some((item) => item.Title == element.Title && item.Provider == element.Provider)) {
            this.newsFeedData.push(element);
          }
        });
      }
      else {
        this.dataNot = true;
      }
      this.shared.newsLoadingGrowthView = false;
      this.getMarketNewsData();
    }, (err) => {
      this.dataNot = true;
      this.shared.newsLoadingGrowthView = false;
    })
    document.getElementById("tooltip-buttons-ms").style.display = "none";
    document.getElementById("newsFeedDataOnTooltip-ms").style.display = "block";
    setTimeout(() => {
      document.getElementById(
        "newsFeedDataOnTooltip-ms"
      ).innerHTML = this.newsComponent.nativeElement.innerHTML;
    }, 10)
  }

  ClickedOut(event) {
    if (event.target.className === "pg-form-pages login-page") {
      document.getElementById("close-documentation").click();
    }
  }

  openDialog (){
    this.shared.popupTimer = 3;
      document.getElementById("openModalButton").click();
      const interval = setInterval(() => {
        if (this.shared.popupTimer > 0) {
          this.shared.popupTimer--;
        } else if (this.shared.popupTimer == 0) {
          document.getElementById("close-documentation").click();
          clearInterval(interval);
        }
      }, 1000);
  }

  getFactsheet(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.target.series.options.fundId + "_factsheet_tooltip",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if (this.shared.fundDocumentationData.length > 0) {
      this.shared.fundDocumentationData.forEach(element => {
        if (event.target.series.name == element.Name && element.Factsheet_Url != "null" && element.Factsheet_Url != undefined) {
          window.open(element.Factsheet_Url);
        }else{
          this.openDialog();
        }
      });
    } else {
      this.openDialog();
    }
  }

  getKiid(event) {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.target.series.options.fundId + "_kiid_tooltip",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    if (this.shared.fundDocumentationData.length > 0) {
      this.shared.fundDocumentationData.forEach(element => {
        if (event.target.series.name == element.Name && element.KIID_Url != "null" && element.KIID_Url != undefined) {
          window.open(element.KIID_Url);
        }else{
          this.openDialog();
        }
      });
    } else {
      this.openDialog();
    }
  }

  getMarketNewsData() {
    setTimeout(() => {
      document.getElementById(
        "newsFeedDataOnTooltip-ms"
      ).innerHTML = this.newsComponent.nativeElement.innerHTML;
    }, 5)
    setTimeout(() => {
      document
        .getElementById("closeNewsMs")
        .addEventListener("click", this.closeNewsMsGrowth.bind(this));
    }, 10)
  }


  getAlertsData(event) {
    let data: any = [];
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": event.target.series.userOptions.fundId + "_alerts_tooltip",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    this.shared.alertsDataGrowthView = event;
    this.shared.alertLoadingGrowthView = true;
    this.dataAvailable = true;
    this.fundAlerts.getFundAlerts(event.target.series.userOptions.id).subscribe((res) => {
      this.shared.alertLoadingGrowthView = false;
      this.shared.fundAlertsDataGrowthView = [];
      if (res["length"] > 0) {
        data = res;
      data.forEach(element => {
        const test =element.event_value;
       // const da = test.replace(/None/g, '"None"').replace(/datetime/g, '"datetime').replace(')', ')"').replace(/Timestamp/g, '"Timestamp')
       const regex = /\s* :* *\T.*\)(|(?=\s+\}))/g;
          const subst = `""`;
          var d = test.replace(regex, subst);
          var obj = d.replace(/'/g, '"').replace(/None/g, '"None"');
          

         
        element.event_value = JSON.parse(obj);
        this.shared.fundAlertsDataGrowthView.push({
          occurrenceDate: element.effective_date,
          publishedData: element.date_event_noticed,
          newText: element.event_value.new_value != "None" ? element.event_value.new_value : "",
          name: element.Fund_Name, selectLike: false, fundId: element.ShareClass_Id,
          data: element.alert_type + ' : ' + element.event_type.replace(/_/g, " "),
          text: element.event_value.old_value != "None" ? element.event_value.old_value : "",
          selectDelete: false, selectClose: false, show: false
        });
      });
      } else {
        this.dataAvailable = false;
      }
      this.shared.alertLoadingGrowthView = false;
      this.alertData();
    }, (err) => {
      this.shared.alertLoadingGrowthView = false;
      this.dataAvailable = false;
    });
    document.getElementById("tooltip-buttons-ms").style.display = "none";
    document.getElementById("newsFeedDataOnTooltip-ms").style.display = "block";
    setTimeout(() => {
      document.getElementById("newsFeedDataOnTooltip-ms").innerHTML = this.alertComponent.nativeElement.innerHTML;
    }, 10)
  }

  alertData() {
    setTimeout(() => {
      document.getElementById(
        "newsFeedDataOnTooltip-ms"
      ).innerHTML = this.alertComponent.nativeElement.innerHTML;
    }, 5)
    setTimeout(() => {
      document
        .getElementById("closeNewsMs")
        .addEventListener("click", this.closeNewsMsGrowth.bind(this));
    }, 10)
  }

  closeData() {

  }

  closeNewsMsGrowth() {
    let createdTime = new Date();
    let modal = {
      "createdAt": createdTime,
      "eventName": "close_tooltip",
      "sessionId": Commons.sessionId,
      "userId": Commons.userDetails.userID,
      "userEmail": Commons.userDetails.Email,
      "userName": Commons.userDetails.Name,
      "platform": Commons.platform
    }
    this.googleAnalyticsEventsService.webWorkerAddEvent(modal);
    document.getElementById("newsFeedDataOnTooltip-ms").style.display = "none";
    document.getElementById("tooltip-buttons-ms").style.display = "block";
  }


}
