import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject, Subscription } from "rxjs";
import { Commons, Shared } from "src/utils/common";
import { decode } from 'string-encode-decode';
import { apiError, authorizeHeader } from "src/utils/common-function";


const token = decode(localStorage.getItem("userToken"));
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` })
};

const cacheBuster$ = new Subject<void>();
@Injectable({
  providedIn: "root",
})

export class CommonService {
  checkTopFundRequest:Subscription;
  checkActivePassiveRequest:Subscription;
  
  constructor(public http: HttpClient, public shared: Shared) {}

  getDataForActivePassive(category, year, isNew, date) {
    if(this.checkActivePassiveRequest){
      this.checkActivePassiveRequest.unsubscribe();
    }
    let cate;
    if (!isNew) {
      cate = category.replace(/ /g, "_");
    } else {
      cate = category;
    }
    return this.http
      .get<any>(
        environment.baseUrl + "activePassive/" + cate + "?date=" +  date + "&year=" + year + "&isNew=" + isNew
      )
  }


  getActivePasive(category, year, isNew, date) {
    if(this.checkActivePassiveRequest){
      this.checkActivePassiveRequest.unsubscribe();
      this.shared.isShowLoading = false;
    Commons.riskChartLoading = false;
    this.shared.activePassiveApiError = true;
    }
    this.shared.msRiskRewardData = []
    let cate;
    if (!isNew) {
      cate = category.replace(/ /g, "_");
    } else {
      cate = category;
    }
    this.shared.activePassive_Y_yearly1 = [];
    this.shared.activePassive_Y_yearly3 = [];
    this.shared.activePassive_Y_yearly5 = [];
    this.shared.activePassive_Y_percenties_1yr = [];
    this.shared.activePassive_Y_percenties_3yr = [];
    this.shared.activePassive_Y_percenties_5yr = [];
    this.shared.isShowLoading = true;
    this.shared.isShowTopFundLoading = true;
    this.shared.isActivePassiveDataNotAvailable = false;
    Commons.riskChartLoading = true;
    this.shared.activePassiveApiError = false;
    this.checkActivePassiveRequest = this.http
      .get<any>(
        environment.baseUrl + "activePassive/" + cate + "?date=" +  date + "&year=" + year + "&isNew=" + isNew
      ).subscribe(async (response) => {
      if (response.percenties.length <= 0 && response.yearly.length <= 0){
        if(Commons.activePassiveCallTime == 0){
          this.shared.isShowLoading = true;
          this.shared.isActivePassiveDataNotAvailable = false;
          setTimeout(() => {
            this.getActivePassiveWithPreviousDate(category, year, isNew);
          }, 50);
          
        }else if(response.percenties.length == 0 && response.yearly.length == 0){
          this.shared.isShowLoading = false;
          this.shared.isActivePassiveDataNotAvailable = true;
        }
      }
      if (response.percenties.length > 0) {
        this.shared.msRiskRewardData = await response.percenties;
        setTimeout(() => {
        this.shared.isActivePassiveDataNotAvailable = false;
        this.shared.isShowLoading = false;
        this.shared.riskChartGetData = 0;
        this.shared.riskRewardPercenties.next(response.percenties);
        Commons.changeBestPassives.next(Commons.bestPassivesData);
        }, 1000);
        //this.shared.commonTopFundPrecentileOb.next('top')
      } else {
        this.shared.isActivePassiveDataNotAvailable = true;
      }
      
      
      const prar1 = response.percenties.map((e) => e["RAR_1_year_percentile"]);
      const prar3 = response.percenties.map((e) => e["RAR_3_year_percentile"]);
      const prar5 = response.percenties.map((e) => e["RAR_5_year_percentile"]);
      this.shared.rar1items = [];
      this.shared.rar3items = [];
      this.shared.rar5items = [];
      response.percenties.forEach((data) => {
        const rar1dataobject: any = {
          name: data["Name"],
          rar1: data["Total_Ret_1_Yr__Mo_End__USD"] ? data["Total_Ret_1_Yr__Mo_End__USD"] * 100: 0,
          prar1: data["Absolute_Ret_1_year_percentile"] ? data["Absolute_Ret_1_year_percentile"] : 0,
          secId: data["SecId"],
          fundId: data["id"],
          Year: data["Year"]
        };
        const rar3dataobject: any = {
          name: data["Name"],
          rar3: data["Total_Ret_Annlzd_3_Yr__Mo_End__USD"] ? data["Total_Ret_Annlzd_3_Yr__Mo_End__USD"] * 100: 0,
          prar3: data["Absolute_Ret_3_year_percentile"],
          secId: data["SecId"],
          fundId: data["id"],
          Year: data["Year"]
        };
        const rar5dataobject: any = {
          name: data["Name"],
          rar5: data["Total_Ret_Annlzd_5_Yr__Mo_End__USD"]  ? data["Total_Ret_Annlzd_5_Yr__Mo_End__USD"] * 100: 0,
          prar5: data["Absolute_Ret_5_year_percentile"] ? data["Absolute_Ret_5_year_percentile"] : 0,
          secId: data["SecId"],
          fundId: data["id"],
          Year: data["Year"]
        };

        this.shared.rar1items.push(rar1dataobject);
        this.shared.rar3items.push(rar3dataobject);
        this.shared.rar5items.push(rar5dataobject);
      });

      this.shared.rar1items.sort(function (a, b) {
        return a.rar1 - b.rar1;
      });
      this.shared.rar3items.sort(function (a, b) {
        return a.rar3 - b.rar3;
      });
      this.shared.rar5items.sort(function (a, b) {
        return a.rar5 - b.rar5;
      });
      this.shared.activePassivehighchartDataFor1Yr = [];
      this.shared.activePassivehighchartDataFor3Yr = [];
      this.shared.activePassivehighchartDataFor5Yr = [];
      this.shared.activePassiveHighchartDataFor_yearly1 = [];
      this.shared.activePassiveHighchartDataFor_yearly3 = [];
      this.shared.activePassiveHighchartDataFor_yearly5 = [];
      this.shared.legendOneYear = null;
      this.shared.legendThreeYear = null;
      this.shared.legenedFiveYear = null;
      this.shared.activePassivehighchartDataFor1Yr = this.convertRar1itemsDataForHighchart(this.shared.rar1items);
      this.shared.activePassivehighchartDataFor3Yr = this.convertRar3itemsDataForHighchart(this.shared.rar3items);
      this.shared.activePassivehighchartDataFor5Yr = this.convertRar5itemsDataForHighchart(this.shared.rar5items);
      this.shared.activePassiveHighchartDataFor_yearly1 = this.convertHighchartDataFor_X_yearly1(response);
      this.shared.activePassiveHighchartDataFor_yearly3 = this.convertHighchartDataFor_X_yearly3(response);
      this.shared.activePassiveHighchartDataFor_yearly5 = this.convertHighchartDataFor_X_yearly5(response);
      const rar1 = this.shared.rar1items.map((e) => e["rar1"]);
      const rar3 = this.shared.rar3items.map((e) => e["rar3"]);
      const rar5 = this.shared.rar5items.map((e) => e["rar5"]);
      this.shared.activePassive_Y_percenties_1yr = rar1;
      this.shared.activePassive_Y_percenties_3yr = rar3;
      this.shared.activePassive_Y_percenties_5yr = rar5;

      if(response.yearly.length > 0){
        for (var i = 0; i <= prar1.length; i++) {
          if(response.yearly[0]["Total_Ret_1_Yr__Mo_End__USD"]){
            this.shared.activePassive_Y_yearly1.push(response.yearly[0]["Total_Ret_1_Yr__Mo_End__USD"] * 100);
          }
         
        }
  
        for (var i = 0; i <= prar1.length; i++) {
          if(response.yearly[1]["Total_Ret_Annlzd_3_Yr__Mo_End__USD"]){
            this.shared.activePassive_Y_yearly3.push(response.yearly[1]["Total_Ret_Annlzd_3_Yr__Mo_End__USD"] * 100);
          }
      
        }
        for (var i = 0; i <= prar1.length; i++) {
  if(response.yearly[2]["Total_Ret_Annlzd_5_Yr__Mo_End__USD"]){
      this.shared.activePassive_Y_yearly5.push(response.yearly[2]["Total_Ret_Annlzd_5_Yr__Mo_End__USD"] * 100);
    }
  }
      }
      

      //this.isShowLoading = false;
    }, (err)=>{
      this.shared.isShowLoading = false;
      this.shared.isActivePassiveDataNotAvailable = true;
      this.shared.activePassiveApiError = true;
      apiError(err);
    });
  }

   convertRar1itemsDataForHighchart(data: any) {
    const map = [];
    if(Commons.geographyFunds.length > 0){
      console.log('data for 1',data);
      data.forEach((val) => {
        if (map.length < 5000) {
          let fundSize:any = null;
          let fundDetail = this.shared.topFundData.filter((x) => { return x.id == val.fundId});
          if(fundDetail && fundDetail.length > 0){
            fundSize = fundDetail[0]["monthendtna_value"] ? fundDetail[0]["monthendtna_value"]?.toFixed(2):'';
          }
          map.push({
            x: val.prar1,
            y: val.rar1,
            name: val.name,
            fundSize: fundSize,
            color: "#AB036E",
            secId: val.secId,
            fundId: val.fundId,
            date: val.Year,
            year:"1Y",
            isBesPassive: false,
            isTopFund: this.shared.fundsSecIds.includes(val.fundId) && Commons.geographyFunds.includes(val.fundId) ? true : false,
            marker: {
              symbol: "circle",
              lineColor: this.shared.fundsSecIds.includes(val.fundId) && Commons.geographyFunds.includes(val.fundId) ?  "white" : "",
              lineWidth: this.shared.fundsSecIds.includes(val.fundId) && Commons.geographyFunds.includes(val.fundId) ? 2 : 0,
              radius: this.shared.fundsSecIds.includes(val.fundId) && Commons.geographyFunds.includes(val.fundId) ? 7 : 4,
            },
          })
        }
      });
    }else{
      data.forEach((val) => {
        if (map.length < 5000) {
          let fundSize:any = null;
          let fundDetail = this.shared.topFundData.filter((x) => { return x.id == val.fundId});
          if(fundDetail && fundDetail.length > 0){
            fundSize = fundDetail[0]["monthendtna_value"] ? fundDetail[0]["monthendtna_value"]?.toFixed(2):'';
          }
          map.push({
            x: val.prar1,
            y: val.rar1,
            name: val.name,
            fundSize: fundSize,
            color: "#AB036E",
            secId: val.secId,
            fundId: val.fundId,
            date: val.Year,
            year:"1Y",
            isBesPassive: false,
            isTopFund: this.shared.fundsSecIds.includes(val.fundId)  ? true : false,
            marker: {
              symbol: "circle",
              lineColor: this.shared.fundsSecIds.includes(val.fundId)  ?  "white" : "",
              lineWidth: this.shared.fundsSecIds.includes(val.fundId)  ? 2 : 0,
              radius: this.shared.fundsSecIds.includes(val.fundId)  ? 7 : 4,
            },
          })
        }
      });
    }
    return map;
  }

   convertRar3itemsDataForHighchart(data: any) {
    const map = [];
    if(Commons.geographyFunds.length > 0){
      console.log('data for 5',data);
      data.forEach((val) => {
        if (map.length < 5000) {
          let fundSize:any = null;
          let fundDetail = this.shared.topFundData.filter((x) => { return x.id == val.fundId});
          if(fundDetail && fundDetail.length > 0){
            fundSize = fundDetail[0]["monthendtna_value"] ? fundDetail[0]["monthendtna_value"]?.toFixed(2):'';
          }
          map.push({
            x: val.prar3,
            y: val.rar3,
            name: val.name,
            fundSize: fundSize,
            color: "#C71D39",
            year:"3Y",
            secId: val.secId,
            date: val.Year,
            fundId: val.fundId,
            isBesPassive: false,
            isTopFund: this.shared.fundsSecIds.includes(val.fundId) && Commons.geographyFunds.includes(val.fundId) ? true : false,
            marker: {
              symbol: "circle",
              lineColor: this.shared.fundsSecIds.includes(val.fundId) && Commons.geographyFunds.includes(val.fundId) ? "white" : "",
              lineWidth: this.shared.fundsSecIds.includes(val.fundId) && Commons.geographyFunds.includes(val.fundId) ? 2 : 0,
              radius: this.shared.fundsSecIds.includes(val.fundId) && Commons.geographyFunds.includes(val.fundId) ? 7 : 4,
            },
          })
        }
      });
    }else{
      data.forEach((val) => {
        if (map.length < 5000) {
          let fundSize:any = null;
          let fundDetail = this.shared.topFundData.filter((x) => { return x.id == val.fundId});
          if(fundDetail && fundDetail.length > 0){
            fundSize = fundDetail[0]["monthendtna_value"] ? fundDetail[0]["monthendtna_value"]?.toFixed(2):'';
          }
          map.push({
            x: val.prar3,
            y: val.rar3,
            name: val.name,
            color: "#C71D39",
            fundSize: fundSize,
            year:"3Y",
            secId: val.secId,
            date: val.Year,
            fundId: val.fundId,
            isBesPassive: false,
            isTopFund: this.shared.fundsSecIds.includes(val.fundId) ? true : false,
            marker: {
              symbol: "circle",
              lineColor: this.shared.fundsSecIds.includes(val.fundId) ? "white" : "",
              lineWidth: this.shared.fundsSecIds.includes(val.fundId) ? 2 : 0,
              radius: this.shared.fundsSecIds.includes(val.fundId) ? 7 : 4,
            },
          })
        }
      });
    }
    return map;
  }

   convertRar5itemsDataForHighchart(data: any) {
    const map = [];
    if(Commons.geographyFunds.length > 0){
      console.log('data for 5',data);
      data.forEach((val) => {
        if (map.length < 5000) {
          let fundSize:any = null;
          let fundDetail = this.shared.topFundData.filter((x) => { return x.id == val.fundId});
          if(fundDetail && fundDetail.length > 0){
            fundSize = fundDetail[0]["monthendtna_value"] ? fundDetail[0]["monthendtna_value"]?.toFixed(2):'';
          }
          map.push({
            x: val.prar5,
            y: val.rar5,
            name: val.name,
            fundSize: fundSize,
            color: "#FF5734",
            secId: val.secId,
            date: val.Year,
            fundId: val.fundId,
            isBesPassive: false,
            year:"5Y",
            isTopFund: this.shared.fundsSecIds.includes(val.fundId) && Commons.geographyFunds.includes(val.fundId) ? true : false,
            marker: {
              symbol: "circle",
              lineColor: this.shared.fundsSecIds.includes(val.fundId) && Commons.geographyFunds.includes(val.fundId) ? "white" : "",
              lineWidth: this.shared.fundsSecIds.includes(val.fundId) && Commons.geographyFunds.includes(val.fundId) ? 2 : 0,
              radius: this.shared.fundsSecIds.includes(val.fundId) && Commons.geographyFunds.includes(val.fundId) ? 7 : 4,
            },
          })
        }
      });
    }else{
      data.forEach((val) => {
        if (map.length < 5000) {
          let fundSize:any = null;
          let fundDetail = this.shared.topFundData.filter((x) => { return x.id == val.fundId});
          if(fundDetail && fundDetail.length > 0){
            fundSize = fundDetail[0]["monthendtna_value"] ? fundDetail[0]["monthendtna_value"]?.toFixed(2):'';
          }
          map.push({
            x: val.prar5,
            y: val.rar5,
            name: val.name,
            color: "#FF5734",
            fundSize: fundSize,
            secId: val.secId,
            date: val.Year,
            fundId: val.fundId,
            isBesPassive: false,
            year:"5Y",
            isTopFund: this.shared.fundsSecIds.includes(val.fundId) ? true : false,
            marker: {
              symbol: "circle",
              lineColor: this.shared.fundsSecIds.includes(val.fundId) ? "white" : "",
              lineWidth: this.shared.fundsSecIds.includes(val.fundId) ? 2 : 0,
              radius: this.shared.fundsSecIds.includes(val.fundId) ? 7 : 4,
            },
          })
        }
      });
    }
     
    return map;
  }

  convertHighchartDataFor_X_yearly1(data: any) {
    const chartData = [];
    if(Commons.geographyFunds.length > 0){
      data.yearly.forEach(ele => {
        if (ele.Type == "01") {
          this.shared.legendOneYear = '1 Yr Passive - ' + ele["Name"];
          data.percenties.forEach(element => {
            if (chartData.length < 5000) {
              chartData.push({
                y:ele["Total_Ret_1_Yr__Mo_End__USD"] * 100,
                name: ele["Name"],
                color: "#FFC302",
                secId: element.SecId,
                fundId: element["id"],
                year:"1Y",
                isBesPassive: true,
                isTopFund: this.shared.fundsSecIds.includes(ele["SecId"]) && Commons.geographyFunds.includes(ele["SecId"]) ? true : false
              })
            }
          });
          return;
        }
      });
    }else{
      data.yearly.forEach(ele => {
        if (ele.Type == "01") {
          this.shared.legendOneYear = '1 Yr Passive - ' + ele["Name"];
          data.percenties.forEach(element => {
            if (chartData.length < 5000) {
              chartData.push({
                y:ele["Total_Ret_1_Yr__Mo_End__USD"] * 100,
                name: ele["Name"],
                color: "#FFC302",
                secId: element.SecId,
                fundId: element["id"],
                year:"1Y",
                isBesPassive: true,
                isTopFund: this.shared.fundsSecIds.includes(ele["SecId"]) ? true : false
              })
            }
          });
          return;
        }
      });
    }
    const filters = chartData.sort(function (a, b) { return a["x"] - b["x"] });
    return filters;
  }

  convertHighchartDataFor_X_yearly3(data: any) {
    const chartData = [];
    if(Commons.geographyFunds.length > 0){
      data.yearly.forEach(ele => {
        if (ele.Type == "03") {
          this.shared.legendThreeYear = '3 Yr Passive - ' + ele["Name"];
          data.percenties.forEach(element => {
            if (chartData.length < 5000) {
              chartData.push({
                y:ele["Total_Ret_Annlzd_3_Yr__Mo_End__USD"] * 100,
                name: ele["Name"],
                color: "#99FF66",
                secId: element.SecId,
                fundId: element["id"],
                year:"3Y",
                isBesPassive: true,
                isTopFund: this.shared.fundsSecIds.includes(ele["SecId"]) && Commons.geographyFunds.includes(ele["SecId"]) ? true : false
              })
            }
          });
          return;
        }
      })
    }else{
      data.yearly.forEach(ele => {
        if (ele.Type == "03") {
          this.shared.legendThreeYear = '3 Yr Passive - ' + ele["Name"];
          data.percenties.forEach(element => {
            if (chartData.length < 5000) {
              chartData.push({
                y:ele["Total_Ret_Annlzd_3_Yr__Mo_End__USD"] * 100,
                name: ele["Name"],
                color: "#99FF66",
                secId: element.SecId,
                fundId: element["id"],
                year:"3Y",
                isBesPassive: true,
                isTopFund: this.shared.fundsSecIds.includes(ele["SecId"]) ? true : false
              })
            }
          });
          return;
        }
      })
    }
    const filters = chartData.sort(function (a, b) { return a["x"] - b["x"] });
    return filters;
  }

  convertHighchartDataFor_X_yearly5(data: any) {
    const chartData = [];
    if(Commons.geographyFunds.length > 0){
      data.yearly.forEach(ele => {
        if (ele.Type == "05") {
          this.shared.legenedFiveYear = '5 Yr Passive - ' + ele["Name"];
          data.percenties.forEach(element => {
            if (chartData.length < 5000) {
              chartData.push({
                y:ele["Total_Ret_Annlzd_5_Yr__Mo_End__USD"] * 100,
                name: ele["Name"],
                color: "#66CCCC",
                secId: element.SecId,
                fundId: element["id"],
                isBesPassive: true,
                year:"5Y",
                isTopFund: this.shared.fundsSecIds.includes(ele["SecId"]) && Commons.geographyFunds.includes(ele["SecId"]) ? true : false
              })
            }
          });
          return;
        }
      })
    }else{
      data.yearly.forEach(ele => {
        if (ele.Type == "05") {
          this.shared.legenedFiveYear = '5 Yr Passive - ' + ele["Name"];
          data.percenties.forEach(element => {
            if (chartData.length < 5000) {
              chartData.push({
                y:ele["Total_Ret_Annlzd_5_Yr__Mo_End__USD"] * 100,
                name: ele["Name"],
                color: "#66CCCC",
                secId: element.SecId,
                fundId: element["id"],
                isBesPassive: true,
                year:"5Y",
                isTopFund: this.shared.fundsSecIds.includes(ele["SecId"]) ? true : false
              })
            }
          });
          return;
        }
      })
    }
    const filters = chartData.sort(function (a, b) { return a["x"] - b["x"] });
    return filters;
  }

  setRiskChartYAxisMinMedian(category, min, year?) {
    Commons.riskYAxisMin = min;
      return min.toFixed(2);
  }

  setRiskChartYAxisMaxMedian(category, max, year?) {
    Commons.riskYAxisMax = max;
      return max.toFixed(2);
  }

  setRiskChartXAxisMinMedian(category, max, year?) {
    Commons.riskXAxisMin = max;
      return max.toFixed(2);
  }

  setRiskChartXAxisMaxMedian(category, max, year?) {
    Commons.riskXAxisMax = max;
      return max.toFixed(2);
  }

  median(values) {
    if (values.length === 0) return 0;

    values.sort(function (a, b) {
      return a - b;
    });

    const half = Math.floor(values.length / 2);

    if (values.length % 2)
      return values[half];

    return (values[half - 1] + values[half]) / 2.0;
  }


  goToGrowthView() {
    this.shared.summaryPopupGrowth.next();
  }

  dataBestPassive() {
    this.shared.bestPassive.forEach(element => {
      if (element.id == "1 YEAR-ILIM Indexed Name") {
        Commons.bestPassiveTopFunds.forEach(ele => {
          if (ele.Type == "01") {
            element.value = '1 Yr Passive - ' + ele.Name;
          }
        });
      }
      else if (element.id == "3 YEAR-VANGAURD Name") {
        Commons.bestPassiveTopFunds.forEach(ele => {
          if (ele.Type == "03") {
            element.value = '3 Yr Passive - ' + ele.Name;
          }
        });
      }
      else if (element.id == "5 YEAR-ILIM Indexed Name") {
        Commons.bestPassiveTopFunds.forEach(ele => {
          if (ele.Type == "05") {
            element.value = '5 Yr Passive - ' + ele.Name;
          }
        });
      }
    });
  }

  getTopFundDataWithCategory(cate, year, totalyear, hiddenGem, isNew, date, esg) {
    if(this.checkTopFundRequest){
      this.checkTopFundRequest.unsubscribe();
      this.shared.marketInsightLoading = false;
      this.shared.marketInsightDataIsAvailable = false;
      this.shared.isShowTopFundLoading = false;
      this.shared.isDataTopFundNotAvailable = true;
      this.shared.isBestPassiveAvailable = false;
    }
    this.shared.topFundCategoryData = null
    this.shared.topFundErrMsg = false;
    this.shared.marketInsightLoading = true;
    this.shared.marketInsightDataIsAvailable = true;
    this.shared.isShowTopFundLoading = true;
    //this.isDataTopFundNotAvailable = true;
    this.shared.isBestPassiveAvailable = true;
    Commons.consistencyData = [];
    Commons.consistencyTopFund = [];
    Commons.selectedConsistency = [];
    Commons.fundsShareClassId = [];
    Commons.topFundSecId = [];
    localStorage.setItem('chartCategory', cate);
    localStorage.setItem('year', year);
    localStorage.setItem('showDiamond', JSON.stringify(hiddenGem));
    localStorage.setItem('IsNew', isNew);
    Commons.selectedTotalYear = totalyear;
    Commons.selectedHiddenGem = hiddenGem;
    this.shared.selectedYears = year;
    this.shared.selectedCategory.next(cate);
    if (!isNew) {
      this.shared.selectedCategoryMarket = cate.replace(/ /g, "_");
    } else {
      this.shared.selectedCategoryMarket = cate;
    }
    
    this.checkTopFundRequest = this.http.post<any>( environment.baseUrl +
      "topfunds/" +
      this.shared.selectedCategoryMarket +
      "?date=" +
      date +
      "&year=" +
      year +
      "&totalyear=" +
      totalyear + "&isNew=" + isNew,esg, {headers:authorizeHeader()}).subscribe((res) => {
      let topFundData:any;
      Commons.bestPassiveTopFunds = [];
      Commons.ESGRatting = [];
      this.shared.fundsSecIds = [];
      Commons.consistencyFundId = [];
      if(res.data.topData.length <= 0 && res.data.bestPassive.length > 0){
        this.shared.isBestPassive = true;
      }else{
        this.shared.isBestPassive = false;
      }
      if(res.data.topData.length <= 0 && res.data.bestPassive.length <= 0){
        if(Commons.topFundsCallTime == 0){
          this.shared.isShowTopFundLoading = true;
          this.shared.isDataTopFundNotAvailable = false;
          this.getTopFundsWithPreviousDate(cate, year, totalyear, hiddenGem, isNew, esg);
        }
      }
      res.data.topData = res.data.topData.sort((a, b) => (a.Name < b.Name ? -1 : 1));
      var regionOfSale = res.data.topData;
      if(this.shared.regionOfSale == 'North America' && regionOfSale.length > 0){
        res.data.topData = [];
        regionOfSale.forEach((ele) => {
          if(ele["US_Region_Of_Sale"] == true){
            res.data.topData.push(ele);
          }
        });
      }else{
        res.data.topData = [];
        regionOfSale.forEach((ele) => {
          if(ele["EU_Region_Of_Sale"] == true){
            res.data.topData.push(ele);
          }
        });
      }
      this.shared.topFundCategoryData = res.data;
      Commons.tempData = res.data.topData;
      topFundData = res.data.topData.sort((a, b) => (a.Name < b.Name ? -1 : 1));
      
      
      Commons.bestPassiveData = [];
      Commons.bestPassiveData = res.data.bestPassive;
      Commons.bestPassiveTopFunds = res.data.bestPassive;
      this.addBestPassive(decode(localStorage.getItem("ID")), this.shared.selectedCategoryMarket);
      if(res.data.bestPassive.length > 0){
        this.shared.isBestPassiveAvailable = true;
      } else{
        this.shared.isBestPassiveAvailable = false;
      }
      // }
      if (res.data.topData.length > 0) {
        Commons.fundsShareClassId = [];
        this.shared.isDataTopFundNotAvailable = false;
        if (this.shared.riskChartDraw === "Not Exist") {
          this.shared.riskChartDraw = "redraw";
        }
        Commons.myFundsChart.next();
      } else {
        this.shared.riskChartDraw = "Not Exist";
      }
      if(res.data.topData.length > 0 || Commons.bestPassiveTopFunds.length > 0){
        this.shared.isDataTopFundNotAvailable = false;
      }else{
        this.shared.isDataTopFundNotAvailable = true;
      }
      Commons.bestPassiveObeservable.next();
      this.dataBestPassive();
      this.shared.ratingViewArray = [];
      let fundSize: any;
      let fundActive: boolean;
      if (localStorage.getItem("fundSizes")) {
        fundSize = JSON.parse(localStorage.getItem("fundSizes"));
        if (fundSize) {
          const fundSizeDisplay = localStorage.getItem('fundSizesDisplay');
          if (fundSizeDisplay == "On") {
            fundActive = true;
          }
        }
      }
      topFundData.forEach((data, index) => {
        Commons.consistencyTopFund.push(data["consistency"]);
        if(Commons.fundsShareClassId.includes(data['ShareClass_Id'])){
        }else{
          Commons.fundsShareClassId.push(data['ShareClass_Id']);
        }
      });

      if(this.shared.isBestPassive){
        Commons.bestPassiveTopFunds.forEach(ele => {
        if(Commons.fundsShareClassId.includes(ele['ShareClass_Id'])){
        }else{
          Commons.fundsShareClassId.push(ele['ShareClass_Id']);
        }
       });
      }
      
      if(Commons.fundsShareClassId.length == 0){
        this.shared.isShowTopFundLoading = false;
      }
      this.shared.colorToPushIndex = 0;
      
      if(Commons.consistencyTopFund.includes(5)){
        Commons.selectedConsistency.push(5);
      }else if(Commons.consistencyTopFund.includes(4)){
        Commons.selectedConsistency.push(5);
        Commons.selectedConsistency.push(4);
      }else if(Commons.consistencyTopFund.includes(3)){
        Commons.selectedConsistency.push(5);
        Commons.selectedConsistency.push(4);
        Commons.selectedConsistency.push(3);
      }else if(Commons.consistencyTopFund.includes(2)){
        Commons.selectedConsistency.push(5);
        Commons.selectedConsistency.push(4);
        Commons.selectedConsistency.push(3);
        Commons.selectedConsistency.push(2);
      }else if(Commons.consistencyTopFund.includes(1)){
        Commons.selectedConsistency.push(5);
        Commons.selectedConsistency.push(4);
        Commons.selectedConsistency.push(3);
        Commons.selectedConsistency.push(2);
        Commons.selectedConsistency.push(1);
      }
       
      this.shared.consistencyScore.forEach(ele => {
          if(Commons.selectedConsistency.includes(ele.value)){
            ele.select = true;
            ele.showInfo = true;
            Commons.selectedScore.push(ele.value);
          }
        })
      topFundData.forEach((data, index) => {
        if (Commons.selectedScore.includes(data["consistency"])) {
            this.shared.fundsSecIds.push(data["id"]);
            this.shared.topFundData.push(data);
            Commons.consistencyFundId.push(data["id"]);
          }
        Commons.fundIds.push(data.id);
      });
      
      if(Commons.geographyData.length > 0 && Commons.fundsShareClassId.length > 0){
        this.getGeographyOfSale(Commons.fundsShareClassId, Commons.geographyData);
      }else{
        this.getTopFunds();
      }
      
        
      
      this.shared.marketInsightObservable.next();
      
    }, (err) => {
      this.shared.marketInsightLoading = false;
      this.shared.marketInsightDataIsAvailable = false;
      this.shared.isShowTopFundLoading = false;
      this.shared.topFundErrMsg = true;
      this.shared.isDataTopFundNotAvailable = true;
      console.log(err);
      apiError(err);
    })
  }

  getTopFunds(){
    this.shared.isShowTopFundLoading = false;
        if(this.shared.topFundCategoryData.topData.length > 0){
          this.shared.isBestPassive = false;
          this.shared.topFundCategoryData.topData.forEach(element => {
            this.putDataIntoTableView(element, Commons.selectedTotalYear);
          });
        }else{
          this.shared.isBestPassive = true;
        }
        
        
        this.shared.topFundDataObservable.next({ data: this.shared.topFundCategoryData, year: Commons.selectedTotalYear, hiddenGem: Commons.selectedHiddenGem, cate: this.shared.selectedCategoryMarket, years: this.shared.selectedYears });
      
      if(this.shared.rar1items){
        this.shared.activePassivehighchartDataFor1Yr = this.convertRar1itemsDataForHighchart(this.shared.rar1items);
      }
      if(this.shared.rar3items){
        this.shared.activePassivehighchartDataFor3Yr = this.convertRar3itemsDataForHighchart(this.shared.rar3items);
      }
      if(this.shared.rar5items){
        this.shared.activePassivehighchartDataFor5Yr = this.convertRar5itemsDataForHighchart(this.shared.rar5items);
      }
  }

  getDate(){
    let sun = 0;
    let mon = 1;
    var d = new Date();
    let day = d.getDay();
    if(day == sun){
      Commons.previousDates = 2;
    }else if(day == mon){
      Commons.previousDates = 3;
    }else{
      Commons.previousDates = 1;
    }
    d.setDate(d.getDate() - Commons.previousDates);
    var event = new Date(d);
    let datee = JSON.stringify(event);
    Commons.date = datee.slice(1,11);

    var format = function(input) {
      var pattern = /(\d{4})\-(\d{2})\-(\d{2})/;
      if (!input || !input.match(pattern)) {
        return null;
      }
      return input.replace(pattern, '$2/$3/$1');
    };
    let newDate = format(Commons.date);
    localStorage.setItem('date', newDate);
  }

  getActDate(){
    let sun = 0;
    let mon = 1;
    var d = new Date();
    let day = d.getDay();
    if(day == sun){
      Commons.previousDates = 2;
    }else if(day == mon){
      Commons.previousDates = 3;
    }else{
      Commons.previousDates = 1;
    }
    d.setDate(d.getDate() - Commons.previousDates);
    var event = new Date(d);
    let datee = JSON.stringify(event);
    Commons.actDate = datee.slice(1,11);
  }

  getTopFundsWithPreviousDate(cate, year, totalyear, hiddenGem, isNew, esg){
    this.getDate();
    Commons.previousDateChange = true;
    this.getTopFundDataWithCategory(cate, year, totalyear, hiddenGem, isNew, Commons.date, esg);
    Commons.topFundsCallTime++;
  }
  
  getActivePassiveWithPreviousDate(category, year, isNew){
    this.getActDate();
    this.getActivePasive(category, year, isNew, Commons.actDate);
    Commons.activePassiveCallTime++;
  }

  getGeographyOfSale(shareClass, countries){
    this.http.get(environment.baseUrl + 'findFundMatchedCountry?shareClass=' + shareClass + '&countries=' + countries).subscribe(res => {
      Commons.geographyCountries = [];
      Commons.geographyFunds = [];
      if(res["data"].length > 0){
        if(this.shared.topFundCategoryData.topData.length <= 0){
          this.shared.isBestPassive = true;
        }else{
          this.shared.isBestPassive = false;
        }
        res["data"].forEach(ele => {
          if( Commons.geographyData.length > 0 && ele.selectedCountries.length > 0){
            Commons.geographyFunds.push(ele.ShareClass_Id);
          }
          Commons.geographyCountries.push(ele);
        });
      }else{
        this.shared.isBestPassive = true;
        this.shared.topFundCategoryData.topData = [];
      }
      
      this.shared.isShowTopFundLoading = false;
      
      if(Commons.geographyFunds.length > 0){
          this.shared.topFundCategoryData.topData = [];
            Commons.tempData.forEach(ele => {
              if(Commons.geographyFunds.includes(ele.ShareClass_Id)){
                this.shared.topFundCategoryData.topData.push(ele);
                this.putDataIntoTableView(ele, Commons.selectedTotalYear);
              }
            });
            
            
        this.shared.topFundDataObservable.next({ data: this.shared.topFundCategoryData, year: Commons.selectedTotalYear, hiddenGem: Commons.selectedHiddenGem, cate: this.shared.selectedCategoryMarket, years: this.shared.selectedYears });
      }else{
        this.shared.topFundCategoryData.topData.forEach(element => {
          this.putDataIntoTableView(element, Commons.selectedTotalYear);
        });
        this.shared.topFundDataObservable.next({ data: this.shared.topFundCategoryData, year: Commons.selectedTotalYear, hiddenGem: Commons.selectedHiddenGem, cate: this.shared.selectedCategoryMarket, years: this.shared.selectedYears });
      }
      if(this.shared.rar1items){
        this.shared.activePassivehighchartDataFor1Yr = this.convertRar1itemsDataForHighchart(this.shared.rar1items);
      }
      if(this.shared.rar3items){
        this.shared.activePassivehighchartDataFor3Yr = this.convertRar3itemsDataForHighchart(this.shared.rar3items);
      }
      if(this.shared.rar5items){
        this.shared.activePassivehighchartDataFor5Yr = this.convertRar5itemsDataForHighchart(this.shared.rar5items);
      }
    });
  }

  putDataIntoTableView(data, totalyear) {
    
    const j = this.shared.colorToPushIndex.toString();
    if(data["ESG_Ratting"]){
      Commons.ESGRatting.push({name:data["Name"], ESG:data["ESG_Ratting"]});
      Commons.EsgRattingData = data["ESG_Ratting"];
    }
    if(data["Refinitv_ESG_Ratting"]){
      Commons.RefESGRattingData = data["Refinitv_ESG_Ratting"];
    }
    Commons.topFundSecId.push(data["id"]);
    const dataobject: any = {
      consistency: data["consistency"],
      name: data["name_value"],
      priceCurrent: data["Price_Current"],
      priceLow: data["Low_Price_Value"],
      priceHigh: data["High_Price_Value"],
      totalRet: data["total_ret_01"],
      totalRet3: data["total_ret_03"],
      totalRet5: data["total_ret_05"],
      stdDev: data["stdev_01"],
      stdDev3: data["stdev_03"],
      stdDev5: data["stdev_05"],
      RiskAdjustedRet: data["rar_01"],
      RiskAdjustedRet3: data["rar_03"],
      RiskAdjustedRet5: data["rar_05"],
      sharpRatio_1: data["sharpe_1"],
      sharpRatio_3: data["sharpe_3"],
      sharpRatio_5: data["sharpe_5"],
      EsgCurrent: Commons.EsgRattingData?.LATEST_ESG_RATING,
      EsgLow: Commons.EsgRattingData?.LOWEST_ESG_RATING,
      EsgHigh: Commons.EsgRattingData?.HIGHEST_ESG_RATING,
      Refinitiv_ESG_Current: Commons.RefESGRattingData?.LatestLetterScore,
      Refinitiv_ESG_High: Commons.RefESGRattingData?.HighestLetterScore,
      Refinitiv_ESG_Low: Commons.RefESGRattingData?.LowestLetterScore,
      vsBestPassive1Yr: data["net_return_01"],
      vsBestPassive3Yr: data["net_return_03"], 
      vsBestPassive5Yr: data["net_return_05"],
      fifthYrAddValue:data["weighted_net_return"],
      KIID: data["KIID_Ongoing_Charge"],
      fee: data["Fee_Percentile"],
      rarIstYearPercentile: data["RAR_1_year_percentile"],
      weightedPerformance: data["Weighted_Performance"],
      fundSize: data["monthendtna_value"] == "NULL" ? data["monthendtna_value"] == "0" : data["monthendtna_value"],
      color: Commons.allcolor[j.charAt(j.length - 1)],
      //isPrimaryShareClass: data["IsPrimaryShareClass"],
      data:data
    };
    this.shared.ratingViewArray.push(dataobject);
    this.shared.colorToPushIndex++;
  }

  addBestPassive(userId, category) {
    let bestPassive = [];
    const bestPassiveIds = [];
    if (localStorage.getItem('bestPassive')) {
      bestPassive = JSON.parse(localStorage.getItem('bestPassive'));
      if (Commons.bestPassiveTopFunds != undefined) {
        this.shared.bestPassive.forEach(element => {
          if (element.id == "1 YEAR-ILIM Indexed Name") {
            Commons.bestPassiveTopFunds.forEach(ele => {
              if (ele.Type == "01") {
                bestPassiveIds.push(ele.id);
              }
            })
          }
          else if (element.id == "3 YEAR-VANGAURD Name") {
            Commons.bestPassiveTopFunds.forEach(ele => {
              if (ele.Type == "03") {
                bestPassiveIds.push(ele.id);
              }
            })
          }
          else if (element.id == "5 YEAR-ILIM Indexed Name") {
            Commons.bestPassiveTopFunds.forEach(ele => {
              if (ele.Type == "05") {
                bestPassiveIds.push(ele.id);
              }
            })
          }
        })
      }
    }
  }

}