import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject } from "rxjs";
import { apiError } from 'src/utils/common-function';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  profileObservable: Subject<any> = new Subject<any>();
  profileData:any;
  isPressed:boolean;
  constructor(public http: HttpClient,) { }

  getProfile(userId){
    this.isPressed = true;
    this.http.get(environment.baseUrl + 'getProfile/' + userId).subscribe((res) =>{
      this.profileObservable.next({data:res["data"]});
      const data = {
        data:res["data"]
      }
      this.profileData = data;
      this.isPressed = false;
    }, (err) =>{
      this.isPressed = false;
      apiError(err);
    })
  }

}
