import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuardService {
  constructor(private router: Router,) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // let localData = localStorage.getItem("login")
    
    // if (localData!=null && localData == '0') {
    //   // alert('not login')
    //   this.router.navigate(["/login"]);
    //   return false;
    // }
    // else if (localData!=null && localData == '1'){
    //   return true;
    // }
    //  else {
    //   this.router.navigate(["/login"]);
    //   return false;
    // }
    const localData = localStorage.getItem("userId")
    
    if (localData == null) {
      // alert('not login')
      this.router.navigate(["/login"]);
      return false;
    }
    else if (localData != null){
      return true;
    }
     else {
      this.router.navigate(["/login"]);
      return false;
    }
  }

}
