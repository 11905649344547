import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "src/services/CommonServices/common.service";
import { MnFullpageService } from "ngx-fullpage";
import { VerfyAuthCodeService } from "src/services/AuthServices/verfy-auth-code.service";
import { ForgetPasswordService } from "src/services/AuthServices/forget-password.service";
import { CookieService } from "ngx-cookie-service";
import { LoginService } from "src/services/AuthServices/login.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  @ViewChild("verfication") verfication;
  @ViewChild("loginModal") loginModal;
  authCode: any;
  isPressed = false;
  mySubscription: any;
  constructor(
    public http: HttpClient,
    public cookieService: CookieService,
    public userLoginService: LoginService,
    public verifyService: VerfyAuthCodeService,
    public forgetPass: ForgetPasswordService,
    public router: Router,
    public common: CommonService,
    public fullpageService: MnFullpageService
  ) {
    this.isPressed = false;
    this.authCode = null;
    const user = localStorage.getItem("activeRaoter");
    //localStorage.setItem("login", "0");
    localStorage.removeItem("userId");
    // localStorage.setItem("userId", "123");
    localStorage.removeItem("ID");
  }

  ngOnInit() {
    localStorage.setItem("activeRaoter", "home");
  }

}
