import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Cacheable } from 'ngx-cacheable';
import { Subject, Subscription } from "rxjs";
import { apiError, authorizeHeader } from 'src/utils/common-function';
import { Shared } from 'src/utils/common';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class SummaryPopupService {
  fundName: any;
  fundDetails: any[] = [];
  checkRequestStart:Subscription;
  
  constructor(public http: HttpClient, public shared: Shared) { }

  getSummaryPopupdetail(id) {
    return this.http.get(environment.baseUrl + 'fundsummerydetails?fundid=' + id);
  }

  openSummaryCardTopFund(event) {
    this.fundDetails = [];
    this.shared.alertsAndNotificationSummary = false;
    this.fundName = event.target.category;
    this.shared.showPopupSummaryCard = true;
    this.shared.summaryCardDataLoading = true;
    if(this.checkRequestStart){
      this.checkRequestStart.unsubscribe();
    }
    this.checkRequestStart = this.getSummaryPopupdetail(event.target.fundId).subscribe((res:any) => {
      this.shared.isObservableCall = 0;
      this.shared.summaryChartObservable.next();
      
      this.shared.summaryCardDataLoading = false;
      if(res.data){
        this.fundDetails = res.data[0];
        this.shared.fundSize = res.data[0].Fund_Size.toFixed(2);
      }
    }, (err) => {
      this.shared.summaryCardDataLoading = false;
      apiError(err);
    })
  }

  getSummeryState(event) {
    this.shared.showPopupSummaryCard = event.showState;
  }
  
  summaryCardAlertsAndNotification(event){
    this.fundDetails = [];
    this.shared.alertsAndNotificationSummary = true;
    this.fundName = event.name;
    this.shared.showPopupOfSummaryAlerts = true;
    this.shared.summaryCardDataLoading = true;
    if(this.checkRequestStart){
      this.checkRequestStart.unsubscribe();
    }
    this.checkRequestStart = this.getSummaryPopupdetail(event.fundId).subscribe((res:any) => {
      this.shared.isObservableCall = 0;
      this.shared.summaryChartObservable.next();
     
      this.shared.summaryCardDataLoading = false;
      if(res.data){
        this.fundDetails = res.data[0];
      }
    }, (err) => {
      this.shared.summaryCardDataLoading = false;
      apiError(err);
    })
  }

  getSummaryStateAlert(event){
    this.shared.showPopupOfSummaryAlerts = event.showState;
  }

  getAlertsDataTooltip(event){
    this.fundDetails = [];
    this.shared.summaryCardDataLoading = true;
    if(this.checkRequestStart){
      this.checkRequestStart.unsubscribe();
    }
    this.checkRequestStart = this.getSummaryPopupdetail(event.fundId).subscribe((res:any) => {
      this.shared.summaryCardDataLoading = false;
      if(res.data){
        this.fundDetails = res.data[0];
      }
    }, (err) => {
      this.shared.summaryCardDataLoading = false;
      apiError(err);
    })
  }
}
