import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Cacheable } from 'ngx-cacheable';
import { Subject } from "rxjs";

const cacheBuster$ = new Subject<void>();

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class ForgetPasswordService {
  userEmail: any;
  sendResetLink: boolean;
  resetLoader: boolean;
  constructor(public http: HttpClient,) { }

  // @Cacheable({
  //   cacheBusterObserver:cacheBuster$
  // })
  sendRequestForResetPassword(email){
    // For prod release
        return this.http.post(environment.baseUrl + 'sendresetrequest/'+ email + '?environment=prod', httpOptions);
    // For Qa release
      //  return this.http.post(environment.baseUrl + 'sendresetrequest/'+ email + '?environment=qa', httpOptions); 
   }

  @Cacheable({
    cacheBusterObserver:cacheBuster$
  })
  resetPassword(data){
    return this.http.post(environment.baseUrl + 'resetpassword',  data , httpOptions);
  }

  changePassword() {
    this.resetLoader = true;
     this.sendResetLink = false;
     this.userEmail = localStorage.getItem("userName");
     if (this.userEmail != null && this.userEmail != undefined) {
       this.sendRequestForResetPassword(this.userEmail).subscribe((res) => {
         this.resetLoader = false;
        document.getElementById("reset-link").click();
         setTimeout(() => {
           document.getElementById("close").click();
         }, 5000);
       }, (err) => {
         this.resetLoader = false;
       });
     }
  }
  
}
